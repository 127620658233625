import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo'; 
// sections
import { RegisterForm } from '../sections/auth/login';
 
import LogoBanner from './LogoBanner';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column', 
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2, 0),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(12, 0),
  }, 


  [theme.breakpoints.up('md')]: {
    marginTop: 100,
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: 90,
  },  
  [theme.breakpoints.down('xs')]: {
    marginTop: 90,
  },   
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Sign Up </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
            zIndex:99,
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h6" sx={{ px: 5, mt: 0, mb: 5 }}>
              Create an account! Enjoy the app!
            </Typography>
            <img src="/assets/illustrations/planet.gif" alt="login" />
          </StyledSection> 
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom className="notranslate">
              Sign Up to PlanetryVilage
            </Typography>  

            <Typography variant="body2" sx={{ mb: 4 }}>
              Have an account? {''}
              <Link component={RouterLink} variant="subtitle2" to='/login'>Log In</Link>
            </Typography>   

            <RegisterForm />
          </StyledContent>
        </Container>

        <LogoBanner/>
      </StyledRoot>
    </>
  );
}
