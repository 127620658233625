
import { useState, useRef, useEffect} from 'react';  
import { useNavigate, } from 'react-router-dom';  
import { useCookies  } from 'react-cookie';  
import axios from 'axios'; 
import { useSpeechRecognition } from "react-speech-recognition";

// @mui
import { Stack, TextField, Typography, Card, Grid, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import GrassIcon from '@mui/icons-material/Grass';
import BrushIcon from '@mui/icons-material/Brush';
import DeleteIcon from '@mui/icons-material/Delete';
  
  


import {domainName} from '../../../utils/domain';


import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, fisrtletterofEachStringToUpperCase
     } from '../../../utils/translateFunctions';
 
  
import { cloud129aa, cloud130aa, cloud131aa} from '../../../utils/automateCreateVillageFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ---------------------------------------------------------------------- 

export default function CreateVillage() {   
  const navigate = useNavigate();   

  const token = useRef(null); 
  const type = useRef(null);


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 


  const [error, setError] = useState('');
  const [Description, setDescription] = useState('');
  const [WhoAreYou, setWhoAreYou] = useState('');

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);

    setError('');
  }
 
  const handleChangeWhoAreYou = (e) => {
    setWhoAreYou(e.target.value);

    setError('');
  }


  /*  Begin upload */ 
  const urlLocalRequest = `${domainName}/u/create/village`;

  const upload = async () => {  
    if(await (Description).trim() === ''){
      setError('The village name is required');
      return;
    }   
    if(await (WhoAreYou).trim() === ''){
      setError('The personality is required');
      return;
    } 

    const token_ = await token.current;
    const type_ = await type.current;
    const Description_ = await Description;
    const WhoAreYou_ = await WhoAreYou;
 
    const resp = await sendFileData(token_, type_, Description_, WhoAreYou_, urlLocalRequest);
 
    const jsonResponse = await resp.data; 
    const jsres = await jsonResponse.message; 
    setError(jsres);
    setDescription('');
    setWhoAreYou('');
  } 
 

  const sendFileData = (token_, type_, Description_, WhoAreYou_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { 
        token: token_,
        type: type_, 
        Description: Description_,
        WhoAreYou: WhoAreYou_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  } 
  /*  End upload */  





  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
      
  const triggerUploadClick = useRef(); 

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {  
        let description; let buffer; let about;

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          let firstElementOfString_ = await firstElementOfString(t);  
          const removeFirstElementFromString_ = await removeFirstElementFromString(t);   
  
          // add village name
          if (await stringComparison([cloud129aa.a, cloud129aa.b], firstElementOfString_)) { 
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm)); 
            description = await fisrtletterofEachStringToUpperCase(tm);
            await setDescription(description);

            speech.text = await transMany__("The village name has been updated") || "The village name has been updated";
            speechSynth.speak(speech); 
          } 

          if (await (firstElementOfString_ === 'the')) {
            buffer = await firstElementOfString_;
            firstElementOfString_ = await firstElementOfString(removeFirstElementFromString_)
          }
          
          // add who are you
          if (await stringComparison([cloud130aa.a, cloud130aa.b, cloud130aa.c, cloud130aa.d], firstElementOfString_)) {
            t = await firstElementOfString_;

            if (await (buffer === 'the')) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            } else {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            }
            about = await fisrtletterofEachStringToUpperCase(tm);
            await setWhoAreYou(about);

            speech.text = await transMany__("Who are you? has been updated") || "Who are you? has been updated";
            speechSynth.speak(speech); 
          } 

          
          // save create a village
          if (await stringComparison([cloud131aa.a, cloud131aa.b, cloud131aa.c, cloud131aa.d, cloud131aa.e, cloud131aa.f, cloud131aa.g, cloud131aa.h, cloud131aa.i, cloud131aa.j, cloud131aa.k, cloud131aa.l, cloud131aa.m, cloud131aa.n], t)) {
            await triggerUploadClick.current.click();

            speech.text = await transMany__("The save has been triggered") || "The save has been triggered";
            speechSynth.speak(speech); 
          }  
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate]);

 
  /* End Michael */  
 

  return ( 
    <> 
    <Grid  item xs={12} sm={12} md={8} lg={6}>
     <Card sx={{mb:1}}>   
          <IconButton aria-label="view village" sx={{fontSize:{xs:13, md:15}}} onClick={() => {navigate('/my/villages', { replace: true }); }}>
            <GrassIcon sx={{fontSize:{xs:15, md:20}, color: 'black'}} /> 
            <Typography variant='button' sx={{ml:1, color: 'black'}}>My villages</Typography>
          </IconButton>  

          <IconButton aria-label="update village" sx={{fontSize:{xs:13, md:15}}} onClick={() => {navigate('/update/village', { replace: true }); }}>
            <BrushIcon sx={{fontSize:{xs:15, md:20}, color: 'black'}} />  
            <Typography variant='button' sx={{ml:1, color: 'black'}}>Update village</Typography>
          </IconButton>   

          <IconButton aria-label="delete village" sx={{fontSize:{xs:13, md:15}}} onClick={() => {navigate('/delete/village', { replace: true }); }}>
            <DeleteIcon sx={{fontSize:{xs:15, md:20}, color: 'black'}} />  
            <Typography variant='button' sx={{ml:1, color: 'black'}}>Delete village</Typography>
          </IconButton>  
     </Card>

     <Card > 
        <Stack spacing={3} sx={{p:1, }}>
          <Typography variant="body1">
            Create a Village
          </Typography> 

          <TextField name="Description" value={Description} onChange={handleChangeDescription} label="What is the village name?" inputProps={{ maxLength: 20 }} />

          <TextField name="WhoAreYou" value={WhoAreYou} onChange={handleChangeWhoAreYou} label="Who are You?" inputProps={{ maxLength: 20 }} />
          
          <Typography variant='subtitle2' color='red'>{error}</Typography>

          <LoadingButton fullWidth size="large" ref={triggerUploadClick}  title='button Village' variant="contained" onClick={upload}> 
            Create
          </LoadingButton> 
        </Stack>  
     </Card>
    </Grid>
    </>
  );
}
