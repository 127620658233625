
import { useNavigate, } from 'react-router-dom';
// @mui
import {
  Box, 
  IconButton, 
  Stack,
  Typography
} from '@mui/material';


// icons
import { Icon } from '@iconify/react';



// ----------------------------------------------------------------------
 
export default function DreamsPopover() {   
  const navigate = useNavigate();   


  return (
    <> 
      <Stack sx={{textAlign:'center', justifyContent:'center', ml:1,}}>
        <IconButton color='primary' sx={{width:35, height:15}} onClick={() => {navigate('/dream', { replace: true });}}>   
          <Box component={Icon} icon='fluent-emoji-high-contrast:brain' />  
        </IconButton>
        <Typography sx={{fontSize:'11px', color:'black'}}>{`${'My dream'}`}</Typography>
      </Stack> 

       
    </>
  );
}

// ----------------------------------------------------------------------

