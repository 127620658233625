
import AllDataOnceActionTypes from './allDataOnceTypes';
import { shufflePhotoAllDataOnce, shuffleAudioAllDataOnce, shuffleVideoAllDataOnce, 
         shuffleMagazineAllDataOnce, shuffleEventCardAllDataOnce, shuffleWishSentAllDataOnce, shuffleWishReveivedAllDataOnce
        } from './allDataOnceUtils';

const INITIAL_STATE = {
    loading : false,
    user : null,
    userShuffle : null,
    error : null
}

export const allDataOnceReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) { 
        case AllDataOnceActionTypes.All_Data_Once_REQUEST:
            return {
                ...state,
                loading: true, 
            };

        case AllDataOnceActionTypes.All_Data_Once_SUCCESS:
            return {
                ...state,
                user: action.response,
                loading: false,
            };

        case AllDataOnceActionTypes.All_Data_Once_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            }; 

        case AllDataOnceActionTypes.All_Data_Once_SHUFFLE_PHOTO:
            return {
                ...state,
                userShuffle: shufflePhotoAllDataOnce(state.user),
                // loading: true,
            };

        case AllDataOnceActionTypes.All_Data_Once_SHUFFLE_AUDIO:
            return {
                ...state,
                userShuffle: shuffleAudioAllDataOnce(state.user),
                // loading: true,
            };

        case AllDataOnceActionTypes.All_Data_Once_SHUFFLE_VIDEO:
            return {
                ...state,
                userShuffle: shuffleVideoAllDataOnce(state.user),
                // loading: true,
            };

            

        case AllDataOnceActionTypes.All_Data_Once_SHUFFLE_MAGAZINE:
            return {
                ...state,
                userShuffle: shuffleMagazineAllDataOnce(state.user),
                // loading: true,
            };

        case AllDataOnceActionTypes.All_Data_Once_SHUFFLE_EVENT_CARD:
            return {
                ...state,
                userShuffle: shuffleEventCardAllDataOnce(state.user),
                // loading: true,
            };

        case AllDataOnceActionTypes.All_Data_Once_SHUFFLE_WISH_SENT:
            return {
                ...state,
                userShuffle: shuffleWishSentAllDataOnce(state.user),
                // loading: true,
            };

        case AllDataOnceActionTypes.All_Data_Once_SHUFFLE_WISH_RECEIVED:
            return {
                ...state,
                userShuffle: shuffleWishReveivedAllDataOnce(state.user),
                // loading: true,
            };
        default:
            return state;
    }
}
