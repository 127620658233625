
const ViewVillageVideoAudioActionTypes = { 
    View_Village_Video_Audio_REQUEST: 'View_Village_Video_Audio_REQUEST',
    View_Village_Video_Audio_SUCCESS:'View_Village_Video_Audio_SUCCESS',
    View_Village_Video_Audio_FAILURE: 'View_Village_Video_Audio_FAILURE', 
}
 
export default ViewVillageVideoAudioActionTypes

  

 