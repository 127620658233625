
import { useState, useRef, useEffect } from 'react'; 
import { useDispatch, useSelector } from 'react-redux'; 
import { useNavigate, } from 'react-router-dom';  
import { useCookies  } from 'react-cookie'; 
import { faker } from '@faker-js/faker'; 
import { useSpeechRecognition } from "react-speech-recognition";

// @mui 
import { Stack, TextField, Typography, Card, Grid, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
 
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';   
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import {CategoryMenuItem} from './AllCreatedFiles';


// utils
import {convertISO8601DateToNormalDate, 
  convertISO8601DateToNormalTime, dateOnlineDateNow, dateNow, dateTimeFromDateTimeTo} from '../../../utils/formatWord';


import {CreateConferenceRoomREQUEST, 
  CreateConferenceRoomRESET} from '../../../redux/createConferenceRoom/createConferenceRoomActions'; 

 
import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, 
     } from '../../../utils/translateFunctions'; 


import { cloud55, cloud179, cloud53, cloud51, cloud15, cloud149, cloud169, cloud173, cloud7,
         cloud153, cloud175, cloud165, cloud167, cloud171, cloud161, cloud177, cloud155, cloud151, cloud163, cloud157,
         cloud181, cloud183, cloud186, cloud187, cloud112, cloud112a, cloud112b, cloud112c, cloud112d } from '../../../utils/automateAllCommonFunctions';


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;

// ---------------------------------------------------------------------- 

export default function CreateConferenceRoom() { 
  const navigate = useNavigate(); 
  const dispatch = useDispatch();   

  const _token = useRef(null); 
  const _type = useRef(null);


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      _token.current = cookies._token_planetryvilage.token; 
      _type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 

   
  
  const createConferenceRoomReducerValue = useSelector(state => state.createConferenceRoomReducerValue);


  const newDate = new Date();
  const newTime = newDate.getTime();

  const [day, setDay] = useState(dayjs(newDate));
  const [_timeFrom, setTimeFrom] = useState(dayjs(newTime));
  const [_timeTo, setTimeTo] = useState(dayjs(newTime)); 
  const [selectedCategory, setSelectedCategory] = useState('Birthday'); 
  const [message, setMessage] = useState(''); 
  const [error, setError] = useState(""); 

  
  useEffect(() => {  
    if(createConferenceRoomReducerValue.user){
      if(createConferenceRoomReducerValue.user.message === "An error occured!"){
        setError("An error occured!"); 
      }  else {
        setError("It has been successfully created"); 
      }

      dispatch(CreateConferenceRoomRESET(null));
    } 
  },[createConferenceRoomReducerValue.user, dispatch]); 


 

  const handleMessage = (e) => {
    setMessage(e.target.value);

    setError('');
  };  

  const handleChangeDay = (newValue) => { 
    setError('');
    setDay(newValue); 
  };

  const handleChangeTimeFrom = (newValue) => {
    setError('');
    setTimeFrom(newValue); 
  };

  const handleChangeTimeTo = (newValue) => {
    setError('');
    setTimeTo(newValue); 
  };

  const handleSelectCategory = (a) => {
    setError('');
    setSelectedCategory(a);  
  }


  /* submit the form create */
  const handleCreate = async () => {  
    if(await (message).trim() === ''){
      setError('The concept is required');
      return;
    }  

    const _DateOnline = await convertISO8601DateToNormalDate(day); 

    const note = await {
      token: _token.current,
      type: _type.current,
      DivisionName: selectedCategory,
      RoomName: faker.datatype.uuid(),
      Description: message, 
      DateOnline: day, 
      TimeFrom: convertISO8601DateToNormalTime(_timeFrom), 
      TimeTo: convertISO8601DateToNormalTime(_timeTo)
    } 

    const dateOnlineDate = await dateOnlineDateNow(_DateOnline, dateNow());
    if(await !dateOnlineDate){
      setError('The date online must be greater than or equal to the current date');
      return;
    }


    const dateTimeFromDateTimeToValue = await dateTimeFromDateTimeTo(note.TimeFrom, note.TimeTo);
    if(await !dateTimeFromDateTimeToValue){
      setError('The start time must be less than the end time');
      return;
    }
 
    
    await dispatch(CreateConferenceRoomREQUEST(note));  
    setMessage('');
  }





  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
    
  const triggerClick = useRef();
  const dateTrigger = useRef();
  const startTimeTrigger = useRef();
  const endTimeTrigger = useRef();

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {  
        let description;  

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  

           

          /* add a description */
          if (await stringComparison([cloud112.a], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            description = await tm; 
            await setMessage(description);  

            speech.text = await transMany__('The description has been updated') || 'The description has been updated';
            speechSynth.speak(speech);
          } else {
            description = await '';
          }

          /* trigger date pop-up */
          if (await stringComparison([cloud112a.a, cloud112a.b, cloud112a.c, cloud112a.d, cloud112a.e], firstElementOfString_)) {
            await dateTrigger.current.click();
          } 

          /* trigger time start pop-up */
          if (await stringComparison([cloud112b.a, cloud112b.b, cloud112b.c, cloud112b.d, cloud112b.e, cloud112b.f, cloud112b.g, cloud112b.h, cloud112b.i], t)) {
            await startTimeTrigger.current.click();
          } 

          /* trigger time end pop-up */
          if (await stringComparison([cloud112c.a, cloud112c.b, cloud112c.c, cloud112c.d, cloud112c.e, cloud112c.f, cloud112c.g, cloud112c.h, cloud112c.i, cloud112c.j, cloud112c.k, cloud112c.l, cloud112c.m], t)) {
            await endTimeTrigger.current.click();
          } 
 
 
          /* trigger send a notifications */
          if (await stringComparison([cloud112d.a, cloud112d.b, cloud112d.c, cloud112d.d, cloud112d.e, cloud112d.f, cloud112d.g, cloud112d.h, cloud112d.i, cloud112d.j], t)) {
            await triggerClick.current.click();

            speech.text = await transMany__('The save has been triggered') || 'The save has been triggered';
            speechSynth.speak(speech);
          }



          /* beginning select a category */
          if (await stringComparison([cloud55.a, cloud55.b], t)) {  
            await handleSelectCategory('Birthday'); 

            speech.text = await transMany__('The birthday celebration is selected') || 'The birthday celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud179.a, cloud179.b, cloud179.c, cloud179.d], t)) {  
            await handleSelectCategory('Wedding'); 

            speech.text = await transMany__('The wedding celebration is selected') || 'The wedding celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud53.a, cloud53.b], t)) {  
            await handleSelectCategory('Easter'); 

            speech.text = await transMany__('The easter celebration is selected') || 'The easter celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud51.a, cloud51.b], t)) {  
            await handleSelectCategory('Christmas'); 

            speech.text = await transMany__('The christmas celebration is selected') || 'The christmas celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud15.a, cloud15.b], t)) {  
            await handleSelectCategory('New Year'); 

            speech.text = await transMany__('The new year celebration is selected') || 'The new year celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud149.a, cloud149.b, cloud149.c, cloud149.d, cloud149.e], t)) {  
            await handleSelectCategory('Collective Intelligence'); 

            speech.text = await transMany__('The creativity and imagination is selected') || 'The creativity and imagination is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud169.a, cloud169.b, cloud169.c, cloud169.d, cloud169.e, cloud169.f, cloud169.g, cloud169.h, cloud169.i], t)) {  
            await handleSelectCategory('Motivational Speech'); 

            speech.text = await transMany__('The motivational speech is selected') || 'The motivational speech is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud173.a, cloud173.b, cloud173.c, cloud173.d, cloud173.e, cloud173.f, cloud173.g, cloud173.h], t)) {  
            await handleSelectCategory('Reality TV'); 

            speech.text = await transMany__('The reality tv is selected') || 'The reality tv is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud7.a, cloud7.b, cloud7.c, cloud7.d], t)) {  
            await handleSelectCategory('Life Experience'); 

            speech.text = await transMany__('The life experience is selected') || 'The life experience is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud153.a, cloud153.b, cloud153.c, cloud153.d, cloud153.e, cloud153.f], t)) {  
            await handleSelectCategory('Dancers'); 

            speech.text = await transMany__('The dance is selected') || 'The dance is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud175.a, cloud175.b, cloud175.c, cloud175.d, cloud175.e, cloud175.f, cloud175.g], t)) {  
            await handleSelectCategory('Singers'); 

            speech.text = await transMany__('The song is selected') || 'The song is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud165.a, cloud165.b, cloud165.c, cloud165.d, cloud165.e, cloud165.f, cloud165.g, cloud165.h, cloud165.i, cloud165.j], t)) {  
            await handleSelectCategory('Hairstyle'); 

            speech.text = await transMany__('The hairstyle is selected') || 'The hairstyle is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud167.a, cloud167.b, cloud167.c, cloud167.d, cloud167.e, cloud167.f, cloud167.g, cloud167.h], t)) {  
            await handleSelectCategory('Make-Up'); 

            speech.text = await transMany__('The make-up is selected') || 'The make-up is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud171.a, cloud171.b, cloud171.c, cloud171.d, cloud171.e, cloud171.f, cloud171.g, cloud171.h, cloud171.i, cloud171.j, cloud171.k], t)) {  
            await handleSelectCategory('Outfit'); 

            speech.text = await transMany__('The outfit is selected') || 'The outfit is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud161.a, cloud161.b, cloud161.c, cloud161.d, cloud161.e, cloud161.f, cloud161.g, cloud161.h, cloud161.i], t)) {  
            await handleSelectCategory('Gossip'); 

            speech.text = await transMany__('The gossip is selected') || 'The gossip is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud177.a, cloud177.b, cloud177.c, cloud177.d, cloud177.e, cloud177.f, cloud177.g, cloud177.h, cloud177.i, cloud177.j, cloud177.k, cloud177.l], t)) {  
            await handleSelectCategory('Talking Non-Stop'); 

            speech.text = await transMany__('Talking non-stop is selected') || 'Talking non-stop is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud155.a, cloud155.b, cloud155.c, cloud155.d, cloud155.e, cloud155.f], t)) {  
            await handleSelectCategory('Drawing'); 

            speech.text = await transMany__('The drawing is selected') || 'The drawing is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud151.a, cloud151.b, cloud151.c, cloud151.d], t)) {  
            await handleSelectCategory('Cooking'); 

            speech.text = await transMany__('The cooking is selected') || 'The cooking is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud163.a, cloud163.b, cloud163.c, cloud163.d, cloud163.e], t)) {  
            await handleSelectCategory('Gym'); 

            speech.text = await transMany__('The gym is selected') || 'The gym is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud157.a, cloud157.b, cloud157.c, cloud157.d], t)) {  
            await handleSelectCategory('Freestyle'); 

            speech.text = await transMany__('The freestyle is selected') || 'The freestyle is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud181.a, cloud181.b, cloud181.c, cloud181.d, cloud181.e, cloud181.f, cloud181.g, cloud181.h, cloud181.i], t)) {  
            await handleSelectCategory('Advertising'); 

            speech.text = await transMany__('The invention showcase is selected') || 'The invention showcase is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud183.a, cloud183.b, cloud183.c, cloud183.d], t)) {  
            await handleSelectCategory('Opportunity Zones'); 

            speech.text = await transMany__('The opportunity zone is selected') || 'The opportunity zone is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud186.a, cloud186.b], t)) {  
            await handleSelectCategory('Anniversary'); 

            speech.text = await transMany__('The anniversary celebration is selected') || 'The anniversary celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud187.a, cloud187.b, cloud187.c, cloud187.d, cloud187.e], t)) {  
            await handleSelectCategory('Help'); 

            speech.text = await transMany__('The help is selected') || 'The help is selected';
            speechSynth.speak(speech); 
          }
          /* end select a category */
 
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, selectedCategory]);

 
  /* End Michael */  
 


  return (
    <> 
    <Grid  item xs={12} sm={12} md={8} lg={6}>
     <Card sx={{borderRadius:{xs:0, sm:0, md:'10px'}, mb:1}}>     
          <IconButton aria-label="go live now" sx={{fontSize:{xs:13, md:15}}} onClick={() => {navigate('/join/conference', { replace: true }); }}>
            <EventAvailableIcon sx={{fontSize:{xs:15, md:20}, color: 'black'}} /> 
            <Typography variant='button' sx={{ml:1, color: 'black'}}>Join a conference</Typography>
          </IconButton> 
     </Card>

     <Card sx={{borderRadius:{xs:0, sm:0, md:'10px'}}}> 
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3} sx={{p:1, }}>
          <Typography variant="body1">
            Create a conference room
          </Typography> 
          
          {selectedCategory === 'Collective Intelligence' ? 
          <Typography variant="subtitle1">
            According to Einstein, the imagination is everything 
          </Typography> :
            null}

          <Typography variant="subtitle2">
            {selectedCategory === 'Collective Intelligence' ? 
            "Develop your creativity and imagination regarding a specific topic with other people" : 
            selectedCategory === 'Birthday' ? 
            "I will be celebrating my incredible birthday with other people" : 
            selectedCategory === 'Wedding' ? 
            "I will be celebrating my incredible wedding with other people" : 
            selectedCategory === 'Easter' ? 
            "I will be celebrating Easter with other people" : 
            selectedCategory === 'Christmas' ? 
            "I will be celebrating Christmas with other people" : 
            selectedCategory === 'New Year' ? 
            "I will be celebrating New Year with other people" : 
             selectedCategory === 'Motivational Speech' ? 
             'Make a motivational speech that will uplift the discouraged generation':
             selectedCategory === 'Reality TV' ? 'Show your lifestyle to the world' :
             selectedCategory === 'Life Experience' ? 'Am I ready to share my life experience with others' :
             selectedCategory === 'Dancers' ? 'I would like to rehearse a dance with other people' :
             selectedCategory === 'Singers' ? 'I would like to rehearse a song with other people' :
             selectedCategory === 'Hairstyle' ? 'I would like to teach others how to do a famous hairstyle' :
             selectedCategory === 'Make-Up' ? 'I would like to teach others how to do a famous make-up' :
             selectedCategory === 'Outfit' ? 'I would like to teach others how to dress' : 
             selectedCategory === 'Gossip' ? 'What celebrity will I be gossiping about?' :
             selectedCategory === 'Talking Non-Stop' ? 'I would like to rehearse how to talk non-stop with others' :
             selectedCategory === 'Drawing' ? 'I would like to rehearse a drawing technique with others' :
             selectedCategory === 'Cooking' ? 'I would like to teach others how to cook an outstanding meal' :
             selectedCategory === 'Gym' ? 'I would like to teach others how to do some gym moves' :
             selectedCategory === 'Freestyle' ? 'I would like to rehearse a freestyle with others' :
             selectedCategory === 'Study Room' ? 'Brief description of the lesson I will be studying with others' :
             selectedCategory === 'Help' ? 'Brief description of the assistance that is needed' :
             selectedCategory === 'Anniversary' ? 'I will be celebrating my organisation anniversary with the window shoppers' :
             selectedCategory === 'Advertising' ? 'Showcase your invention (product / service)' :
             selectedCategory === 'Opportunity Zones' ? 'Brief description of the opportunity zones all around the world' :
            ''}
          </Typography> 

          <CategoryMenuItem handleSelectCategory={handleSelectCategory} selectedCategory={selectedCategory}/>

          <TextField name="message"  value={message} onChange={handleMessage}
            label={selectedCategory === 'Collective Intelligence' ? "What research subject topic am I willing to debate about?" :
                   selectedCategory === 'Birthday' ? "Say something about your incredible birthday?" :
                   selectedCategory === 'Wedding' ? "Say something about your incredible wedding?" :
                   selectedCategory === 'Motivational Speech' ? "What is it all about?" :
                   selectedCategory === 'Reality TV' ? "What is it all about?" :
                   selectedCategory === 'Life Experience' ? "What am I going to talk about?" :
                   selectedCategory === 'Dancers' ? "What dance do I want to rehearse?" :
                   selectedCategory === 'Singers' ? "What song do I want to rehearse?" :
                   selectedCategory === 'Hairstyle' ? "What hairstyle do I want to teach?" :
                   selectedCategory === 'Make-Up' ? "What make-up style do I want to teach?" :
                   selectedCategory === 'Outfit' ? "What dress code do I want to teach?" :
                   selectedCategory === 'Gossip' ? "What are the details of the celebrity?" :
                   selectedCategory === 'Talking Non-Stop' ? "What technique do I want to rehearse?" :
                   selectedCategory === 'Drawing' ? "What drawing technique do I want to rehearse?" :
                   selectedCategory === 'Cooking' ? "What food am I going to cook?" :
                   selectedCategory === 'Gym' ? "What gym moves am I going to teach?" :
                   selectedCategory === 'Freestyle' ? "What freestyle do I want to rehearse?" :
                   selectedCategory === 'Study Room' ? "What lesson will I be studying?" :
                   selectedCategory === 'Help' ? "What assignment am I struggling with?" :
                   selectedCategory === 'Anniversary' ? "Say something?" :
                   selectedCategory === 'Advertising' ? "Short description of invention (product/service)" :
                   selectedCategory === 'Opportunity Zones' ? "What kind of investment can be performed?" :
                   "What is the concept?"} />  
        
        
          <MobileDatePicker
            label="What day will the hall be available?"
            inputFormat="DD-MM-YYYY"
            ref={dateTrigger}
            value={day}
            onChange={handleChangeDay}
            renderInput={(params) => <TextField {...params} />}
          /> 
        

          <Typography variant="body2">
            What time will the hall be available?
          </Typography>

          <MobileTimePicker
            label="From" 
            ref={startTimeTrigger} 
            value={_timeFrom}
            onChange={handleChangeTimeFrom}
            renderInput={(params) => <TextField {...params} />} 
            onError={() => null}
          /> 

          <MobileTimePicker
            label="To"  
            ref={endTimeTrigger} 
            value={_timeTo}
            onChange={handleChangeTimeTo}
            renderInput={(params) => <TextField {...params} />}
            onError={() => null}
          /> 

          <Typography variant='subtitle2' color='red'>{error}</Typography>


          <LoadingButton fullWidth size="large" title='button Event' variant="contained" ref={triggerClick} onClick={()=>{handleCreate()}} >
            Notify
          </LoadingButton>
        </Stack> 
      </LocalizationProvider>
     </Card>
    </Grid>
    </>
  );
}
