import { Navigate, useRoutes } from 'react-router-dom'; 
// import { lazy, } from "react";
// layouts
import DashboardLayout from './layouts/dashboard'; 

import SimpleLayout from './layouts/simple';
import "./index.css";
//
// import BlogPage from './pages/BlogPage';
// import UserPage from './pages/UserPage';

/* const LoginPage = lazy(() => import("./pages/LoginPage"));
const RegisterPage = lazy(() => import("./pages/RegisterPage")); 
const ForgottenPasswordPage = lazy(() => import("./pages/ForgottenPasswordPage"));
const ForgottenPassword2Page = lazy(() => import("./pages/ForgottenPassword2Page"));  
const Page404 = lazy(() => import("./pages/Page404"));  */

/**/ 
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ForgottenPasswordPage from './pages/ForgottenPasswordPage';
import ForgottenPassword2Page from './pages/ForgottenPassword2Page';
import Page404 from './pages/Page404';  
/**/
// import ProductsPage from './pages/ProductsPage';
// import DashboardAppPage from './pages/DashboardAppPage';



// import CoursePage from './pages/CoursePage';
// import GalleryPage from './pages/GalleryPage';
// import EventPage from './pages/EventPage';
// import ContactPage from './pages/ContactPage';
// import AdmissionPage from './pages/AdmissionPage';


// import CartPage from './pages/CartPage';



/**/
import HomePage from './pages/home/HomePage';
import HomeLoadPage from './pages/home/HomeLoadPage';
import ShowOneFile from './pages/home/ShowOneFile';
import MagazineWishPage from './pages/magazinewish/MagazineWishPage'; 

import MagazinePage from './pages/magazinesWishes/MagazinePage';
import MagazineLoadPage from './pages/magazinesWishes/MagazineLoadPage';
import ShowOneMagazineVideo from './pages/magazinesWishes/ShowOneMagazineVideo';

import EventCardPage from './pages/magazinesWishes/EventCardPage';
import EventCardLoadPage from './pages/magazinesWishes/EventCardLoadPage';
import ShowOneEventCardVideo from './pages/magazinesWishes/ShowOneEventCardVideo';

import WishesSentPage from './pages/magazinesWishes/WishesSentPage';
import WishesSentLoadPage from './pages/magazinesWishes/WishesSentLoadPage';
import ShowOneFeastCardSentVideo from './pages/magazinesWishes/ShowOneFeastCardSentVideo';

import WishesReceivedPage from './pages/magazinesWishes/WishesReceivedPage';
import WishesReceivedLoadPage from './pages/magazinesWishes/WishesReceivedLoadPage';
import ShowOneFeastCardReceivedVideo from './pages/magazinesWishes/ShowOneFeastCardReceivedVideo';  



import VideoPage from './pages/videos/VideoPage';
import VideoLoadPage from './pages/videos/VideoLoadPage';
import ShowOneVideoOnly from './pages/videos/ShowOneVideoOnly'; 


import AudioPage from './pages/audios/AudioPage';
import AudioLoadPage from './pages/audios/AudioLoadPage';
import ShowOneAudioOnly from './pages/audios/ShowOneAudioOnly';



import VillagePage from './pages/village/VillagePage'; 
import ShowOneFileVillage from './pages/village/ShowOneFileVillage';

import MyVillagesPage from './pages/village/myVillage/MyVillagesPage';
import UpdateVillagePage from './pages/village/myVillage/UpdateVillagePage';
import DeleteVillagePage from './pages/village/myVillage/DeleteVillagePage';
import VideoAudioPhotoByVillagePage from './pages/village/myVillage/VideoAudioPhotoByVillagePage';
import ShowOneFileMyVillage from './pages/village/myVillage/ShowOneFileMyVillage';
import CreateVideoAudioPhotoVillagePage from './pages/village/myVillage/CreateVideoAudioPhotoVillagePage'; 



import AllDreamsPage from './pages/allDream/AllDreamsPage';

import PlaySongWithMichaelPage from './pages/song/PlaySongWithMichaelPage';

import CreateVillagePage from './pages/create/CreateVillagePage';
import CreateVideoAudioPhotoPage from './pages/create/CreateVideoAudioPhotoPage';
import CreateBusinessCardPage from './pages/create/CreateBusinessCardPage';
import CreateMagazinePage from './pages/create/CreateMagazinePage';
import CreateFeastCardPage from './pages/create/CreateFeastCardPage';
import CreateEventCardPage from './pages/create/CreateEventCardPage';
import CreateTvChannelPage from './pages/create/CreateTvChannelPage';
import CreateConferenceRoomPage from './pages/create/CreateConferenceRoomPage';  

/**/

// import LiveVideoPage from './pages/liveVideoConference/LiveVideoPage';


/**/ 
import TvStreamingPage from './pages/create/TvStreamingPage';
import TvWatcherPage from './pages/create/TvWatcherPage';
import ConferenceLivePage from './pages/create/ConferenceLivePage';


import ChangeProfilePhotoPage from './pages/create/ChangeProfilePhotoPage';
import ChangeProfileContentPage from './pages/create/ChangeProfileContentPage';
import ChangePasswordPage from './pages/create/ChangePasswordPage';


import CompetitionPage from './pages/competition/CompetitionPage';
import SpecificCompetitionPage from './pages/competition/SpecificCompetitionPage'; 
import SeasonHistoryPage from './pages/competition/SeasonHistoryPage';
import ShowOneFileCompetition from './pages/competition/ShowOneFileCompetition'; 
/**/

/* import BreakingNewsPage from './pages/competition/BreakingNewsPage';
import ChallengesPage from './pages/competition/ChallengesPage';
import WatchPage from './pages/competition/WatchPage';
import TeacherAssignedToMePage from './pages/competition/TeacherAssignedToMePage';
import WinnerPage from './pages/competition/WinnerPage';
import QualifiersPage from './pages/competition/QualifiersPage';
import DisqualifiersPage from './pages/competition/DisqualifiersPage';
import CompetitorsPage from './pages/competition/CompetitorsPage';
import VideoAudioPage from './pages/competition/VideoAudioPage';
import SeasonHistoryPage from './pages/competition/SeasonHistoryPage'; */

 
/* const HomePage = lazy(() => import("./pages/home/HomePage"));
const ShowOneFile = lazy(() => import("./pages/home/ShowOneFile"));

const MagazineWishPage = lazy(() => import("./pages/magazinewish/MagazineWishPage")); 

const MagazinePage = lazy(() => import("./pages/magazinesWishes/MagazinePage"));
const ShowOneMagazineVideo = lazy(() => import("./pages/magazinesWishes/ShowOneMagazineVideo")); 


const EventCardPage = lazy(() => import("./pages/magazinesWishes/EventCardPage"));
const ShowOneEventCardVideo = lazy(() => import("./pages/magazinesWishes/ShowOneEventCardVideo")); 

const WishesSentPage = lazy(() => import("./pages/magazinesWishes/WishesSentPage"));
const ShowOneFeastCardSentVideo = lazy(() => import("./pages/magazinesWishes/ShowOneFeastCardSentVideo")); 

const WishesReceivedPage = lazy(() => import("./pages/magazinesWishes/WishesReceivedPage"));
const ShowOneFeastCardReceivedVideo = lazy(() => import("./pages/magazinesWishes/ShowOneFeastCardReceivedVideo")); 



const VideoPage = lazy(() => import("./pages/videos/VideoPage"));
const ShowOneVideoOnly = lazy(() => import("./pages/videos/ShowOneVideoOnly")); 


const AudioPage = lazy(() => import("./pages/audios/AudioPage"));
const ShowOneAudioOnly = lazy(() => import("./pages/audios/ShowOneAudioOnly")); 



const VillagePage = lazy(() => import("./pages/village/VillagePage"));
const ShowOneFileVillage = lazy(() => import("./pages/village/ShowOneFileVillage"));  


const MyVillagesPage = lazy(() => import("./pages/village/myVillage/MyVillagesPage"));
const UpdateVillagePage = lazy(() => import("./pages/village/myVillage/UpdateVillagePage"));  
const DeleteVillagePage = lazy(() => import("./pages/village/myVillage/DeleteVillagePage"));
const VideoAudioPhotoByVillagePage = lazy(() => import("./pages/village/myVillage/VideoAudioPhotoByVillagePage"));  
const ShowOneFileMyVillage = lazy(() => import("./pages/village/myVillage/ShowOneFileMyVillage"));
const CreateVideoAudioPhotoVillagePage = lazy(() => import("./pages/village/myVillage/CreateVideoAudioPhotoVillagePage")); 


const AllDreamsPage = lazy(() => import("./pages/allDream/AllDreamsPage")); 

const PlaySongWithMichaelPage = lazy(() => import("./pages/song/PlaySongWithMichaelPage"));  



const CreateVillagePage = lazy(() => import("./pages/create/CreateVillagePage"));
const CreateVideoAudioPhotoPage = lazy(() => import("./pages/create/CreateVideoAudioPhotoPage")); 
const CreateBusinessCardPage = lazy(() => import("./pages/create/CreateBusinessCardPage"));
const CreateMagazinePage = lazy(() => import("./pages/create/CreateMagazinePage"));  
const CreateFeastCardPage = lazy(() => import("./pages/create/CreateFeastCardPage"));
const CreateEventCardPage = lazy(() => import("./pages/create/CreateEventCardPage")); 
const CreateTvChannelPage = lazy(() => import("./pages/create/CreateTvChannelPage"));
const CreateConferenceRoomPage = lazy(() => import("./pages/create/CreateConferenceRoomPage"));


const TvStreamingPage = lazy(() => import("./pages/create/TvStreamingPage"));
const TvWatcherPage = lazy(() => import("./pages/create/TvWatcherPage")); 
const ConferenceLivePage = lazy(() => import("./pages/create/ConferenceLivePage")); 


const ChangeProfilePhotoPage = lazy(() => import("./pages/create/ChangeProfilePhotoPage"));
const ChangeProfileContentPage = lazy(() => import("./pages/create/ChangeProfileContentPage")); 
const ChangePasswordPage = lazy(() => import("./pages/create/ChangePasswordPage")); 



const CompetitionPage = lazy(() => import("./pages/competition/CompetitionPage"));
const SpecificCompetitionPage = lazy(() => import("./pages/competition/SpecificCompetitionPage")); 
const SeasonHistoryPage = lazy(() => import("./pages/competition/SeasonHistoryPage")); 
const ShowOneFileCompetition = lazy(() => import("./pages/competition/ShowOneFileCompetition"));  

 

const DashboardLayout = lazy(() => import("./layouts/dashboard")); */
 


// ----------------------------------------------------------------------

export default function Router() {  

  const routes = useRoutes([ 
    {
      path: '/',
      element: <DashboardLayout />, 
      children: [
        { element: <Navigate to="/home" />, index: true },  
        { path: 'home', element: <HomePage /> },  
        { path: 'home-load', element: <HomeLoadPage /> },  
        { path: 'home/:_uservideoaudiophotoId', element: <ShowOneFile /> },  
        { path: 'magazine/wishes', element: <MagazineWishPage /> },  

        { path: 'magazine', element: <MagazinePage /> }, 
        { path: 'magazine-load', element: <MagazineLoadPage /> },  
        { path: 'magazine/:_magazineId', element: <ShowOneMagazineVideo /> },  

        { path: 'event/card', element: <EventCardPage /> }, 
        { path: 'event/card-load', element: <EventCardLoadPage /> },   
        { path: 'event/card/:_EventCardsId', element: <ShowOneEventCardVideo /> }, 

        { path: 'wishes/sent', element: <WishesSentPage /> }, 
        { path: 'wishes/sent-load', element: <WishesSentLoadPage /> }, 
        { path: 'wishes/sent/:_FeastCardsId', element: <ShowOneFeastCardSentVideo /> }, 
        
        { path: 'wishes/received', element: <WishesReceivedPage /> },  
        { path: 'wishes/received-load', element: <WishesReceivedLoadPage /> },  
        { path: 'wishes/received/:_FeastCardsId', element: <ShowOneFeastCardReceivedVideo /> },  




        { path: 'videos', element: <VideoPage /> }, 
        { path: 'videos-load', element: <VideoLoadPage /> },  
        { path: 'videos/:_uservideoaudiophotoId', element: <ShowOneVideoOnly /> },  

        { path: 'audios', element: <AudioPage /> },  
        { path: 'audios-load', element: <AudioLoadPage /> },   
        { path: 'audios/:_uservideoaudiophotoId', element: <ShowOneAudioOnly /> }, 



        { path: 'view/village/:villageId', element: <VillagePage /> }, 
        { path: 'view/village/video/audio/:_villageIdentifier/:_sessionvideoaudioId', element: <ShowOneFileVillage /> }, 

        { path: 'view/my/village/:villageId', element: <VideoAudioPhotoByVillagePage /> }, 
        { path: 'view/my/village/video/audio/:_villageIdentifier/:_sessionvideoaudioId', element: <ShowOneFileMyVillage /> }, 

        { path: 'my/villages', element: <MyVillagesPage /> },  
        { path: 'update/village', element: <UpdateVillagePage /> }, 
        { path: 'delete/village', element: <DeleteVillagePage /> },  
        { path: 'view/my/village/upload/file/:_villageIdentifier', element: <CreateVideoAudioPhotoVillagePage /> }, 



        { path: 'dream', element: <AllDreamsPage /> }, 

        { path: 'play/song/using/michael', element: <PlaySongWithMichaelPage /> },   
        
        { path: 'upload/photo/video/audio', element: <CreateVideoAudioPhotoPage /> },
        { path: 'create/business/card', element: <CreateBusinessCardPage /> },
        { path: 'create/magazine', element: <CreateMagazinePage /> },
        { path: 'create/feast/card', element: <CreateFeastCardPage /> },
        { path: 'create/event/card', element: <CreateEventCardPage /> },
        { path: 'create/tv/channel', element: <CreateTvChannelPage /> },
        { path: 'create/conference/room', element: <CreateConferenceRoomPage /> },
        { path: 'create/village', element: <CreateVillagePage /> },

        { path: 'tv/streaming', element: <TvStreamingPage /> },
        { path: 'tv/watcher', element: <TvWatcherPage /> },
        { path: 'join/conference', element: <ConferenceLivePage /> },    




        // { path: '/u/V-M/index&:room_&:descriptionIdentifier_&:DivisionName_&:UserSession_&:FirstExtension_&:SecondExtension_&:ThirdExtension_&:FourExtension_&:FifthExtension_&:token_&:type_', element: <LiveVideoPage /> },


 

        { path: 'update/profile/photo', element: <ChangeProfilePhotoPage /> },
        { path: 'update/profile/content', element: <ChangeProfileContentPage /> },  
        { path: 'update/password', element: <ChangePasswordPage /> },


        { path: 'competition', element: <CompetitionPage /> },
        { path: 'competition/:_message/:_competitionId', element: <SpecificCompetitionPage /> },
        { path: 'compete/season/:_competitionId/:_seasonId', element: <SeasonHistoryPage /> },
        { path: 'view/competition/video/audio/:_competitionId/:_competitionvideoaudioId/', element: <ShowOneFileCompetition /> },
        /* { path: 'compete/breaking/news/:id', element: <BreakingNewsPage /> },
        { path: 'compete/challenges/:id', element: <ChallengesPage /> },
        { path: 'compete/watch/:id', element: <WatchPage /> },
        { path: 'compete/teacher/:id', element: <TeacherAssignedToMePage /> },
        { path: 'compete/winner/:id', element: <WinnerPage /> },
        { path: 'compete/qualifiers/:id', element: <QualifiersPage /> },
        { path: 'compete/disqualifiers/:id', element: <DisqualifiersPage /> },
        { path: 'compete/competitors/:id', element: <CompetitorsPage /> },
        { path: 'compete/video/audio/:id', element: <VideoAudioPage /> }, 


        { path: 'compete/season/history/:id', element: <SeasonHistoryPage /> }, */


        /* { path: 'course', element: <CoursePage /> }, 
        { path: 'gallery', element: <GalleryPage /> }, 
        { path: 'event', element: <EventPage /> },   
        { path: 'contact', element: <ContactPage /> },   
        { path: 'admission', element: <AdmissionPage /> },  */
      ],
    },
    /* {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true }, 
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },   
        { path: 'cart', element: <CartPage /> },
      ],
    }, */
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: 'forgotten/password',
      element: <ForgottenPasswordPage />,
    },
    {
      path: 'forgotten/password/:_emailVerificationId',
      element: <ForgottenPassword2Page />,
    }, 
 
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    }, 
  ]); 

  return routes;
}


 