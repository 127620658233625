import ReportAbuseActionTypes from './reportAbuseTypes'


export const ReportAbuseREQUEST = (item) => ({
    type: ReportAbuseActionTypes.Report_Abuse_REQUEST,
    payload: item
}) 


export const ReportAbuseRESET = item => ({
    type: ReportAbuseActionTypes.Report_Abuse_RESET,
    payload: item
}) 

