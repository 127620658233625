import EventHallLiveActionTypes from './eventHallLiveTypes'


export const EventHallLiveREQUEST = (item) => ({
    type: EventHallLiveActionTypes.Event_Hall_Live_REQUEST,
    payload: item
}) 


export const EventHallLiveRESET = item => ({
    type: EventHallLiveActionTypes.Event_Hall_Live_RESET,
    payload: item
}) 

