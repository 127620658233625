


export const cloud125aa = {
    a: 'update session',
    b: 'update a session',
    c: 'updates session.',
    d: 'updates a session',

    a1: 'update',
    a2: 'updates',
    a3: 'updated',
    a4: 'update a village',
    a5: 'updates a village',
    a6: 'updated a village',
    a7: 'update the village',
    a8: 'updates the village',
    a9: 'updated the village',
    a10: 'update village',
    a11: 'updates village',
    a12: 'updated village', 
    a13: 'update villages',
    a14: 'updates villages',
    a15: 'updated villages'
}

export const cloud127aa = { 
    a1: 'my village',
    a2: 'my villages',
    a3: 'see my village',
    a4: 'see my villages',
    a5: 'village',
    a6: 'villages',

    a7: 'my session',
    a8: 'my sessions',
    a9: 'see my session',
    a10: 'see my sessions',
    a11: 'session',
    a12: 'sessions',
}
 

export const cloud119a = { 
    a1: 'remove a vilage',
    a2: 'delete a village',
    a3: 'suppress a village',
    a4: 'cancel a village',
    a5: 'remove vilage',
    a6: 'delete village',
    a7: 'suppress village',
    a8: 'cancel village',
    a9: 'remove the vilages',
    a10: 'delete the villages',
    a11: 'suppress the villages',
    a12: 'cancel the villages',
    a13: 'remove vilages',
    a14: 'delete villages',
    a15: 'suppress villages',
    a16: 'cancel villages',

    a17: 'removes a vilage',
    a18: 'deletes a village',
    a19: 'suppresses a village',
    a20: 'cancels a village',
    a21: 'removes vilage',
    a22: 'deletes village',
    a23: 'suppresses village',
    a24: 'cancels village',
    a25: 'removes the vilages',
    a26: 'deletes the villages',
    a27: 'suppresses the villages',
    a28: 'cancels the villages',
    a29: 'removes vilages',
    a30: 'deletes villages',
    a31: 'suppresses villages',
    a32: 'cancels villages',

    a33: 'removed a vilage',
    a34: 'deleted a village',
    a35: 'suppressed a village',
    a36: 'canceled a village',
    a37: 'removed vilage',
    a38: 'deleted village',
    a39: 'suppressed village',
    a40: 'canceled village',
    a41: 'removed the vilages',
    a42: 'deleted the villages',
    a43: 'suppressed the villages',
    a44: 'canceled the villages',
    a45: 'removed vilages',
    a46: 'deleted villages',
    a47: 'suppressed villages',
    a48: 'canceled villages',



    b1: 'remove a session',
    b2: 'delete a session',
    b3: 'suppress a session',
    b4: 'cancel a session',
    b5: 'remove session',
    b6: 'delete session',
    b7: 'suppress session',
    b8: 'cancel session',
    b9: 'remove the sessions',
    b10: 'delete the sessions',
    b11: 'suppress the sessions',
    b12: 'cancel the sessions',
    b13: 'remove sessions',
    b14: 'delete sessions',
    b15: 'suppress sessions',
    b16: 'cancel sessions',


    b17: 'removes a session',
    b18: 'deletes a session',
    b19: 'suppresses a session',
    b20: 'cancels a session',
    b21: 'removes session',
    b22: 'deletes session',
    b23: 'suppresses session',
    b24: 'cancels session',
    b25: 'removes the sessions',
    b26: 'deletes the sessions',
    b27: 'suppresses the sessions',
    b28: 'cancels the sessions',
    b29: 'removes sessions',
    b30: 'deletes sessions',
    b31: 'suppresses sessions',
    b32: 'cancels sessions',

    b33: 'removed a session',
    b34: 'deleted a session',
    b35: 'suppressed a session',
    b36: 'canceled a session',
    b37: 'removed session',
    b38: 'deleted session',
    b39: 'suppressed session',
    b40: 'canceled session',
    b41: 'removed the sessions',
    b42: 'deleted the sessions',
    b43: 'suppressed the sessions',
    b44: 'canceled the sessions',
    b45: 'removed sessions',
    b46: 'deleted sessions',
    b47: 'suppressed sessions',
    b48: 'canceled sessions',
}

export const cloud117a = { 
    a: 'the villages created',
    b: 'the village created',
    c: 'a village created',
    d: 'villages created',
    e: 'village created',
    f: 'the created villages',
    g: 'the created village',
    h: 'a created village',
    i: 'created villages',
    j: 'created village',
    k: 'created a village',


    a1: 'the sessions created',
    b1: 'the session created',
    c1: 'a session created',
    d1: 'sessions created',
    e1: 'session created',
    f1: 'the created sessions',
    g1: 'the created session',
    h1: 'a created session',
    i1: 'created sessions',
    j1: 'created session',
    k1: 'created a session',
  
}


export const cloud123aa = {
    a: 'create session',
    b: 'create a session',
    c: 'creates session',
    d: 'creates a session',
    e: 'created session',
    f: 'created a session',

    g: 'create village',
    h: 'create a village',
    i: 'creates village',
    j: 'creates a village',
    k: 'created village',
    l: 'created a village'
}


export const cloud130aa = { 
    a: 'about',
    b: 'name',
    c: 'regarding',
    d: 'concerning'
}

export const cloud129aa = {
    a: 'description',
    b: 'village'
}

export const cloud131aa = {
    a: 'save',
    b: 'saves',
    c: 'saved',
    d: 'share',
    e: 'shares',
    f: 'shared',
    g: 'send',
    h: 'sends',
    i: 'sent',
    l: 'notify',
    m: 'notified',
    n: 'notifies'
}
