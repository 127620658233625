
const ViewOneVideoPhotoActionTypes = { 
    View_Video_Photo_REQUEST: 'View_One_Video_Photo_REQUEST',
    View_Video_Photo_SUCCESS:'View_One_Video_Photo_SUCCESS',
    View_Video_Photo_FAILURE: 'View_One_Video_Photo_FAILURE', 
    View_Video_Photo_RESET: 'View_Video_Photo_RESET', 
}
 
export default ViewOneVideoPhotoActionTypes

  

 