import createSagaMiddleware from 'redux-saga';
import { createStore, combineReducers, applyMiddleware } from 'redux'; 

import {
    persistReducer, 
    persistStore 
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';


 
import { cartReducer } from '../redux/cart/cartReducerIndex';

import { loginReducer } from '../redux/login/loginReducerIndex';

import { registerReducer } from '../redux/register/registerReducerIndex';

import { forgottenPasswordReducer } from '../redux/password/forgottenPasswordReducerIndex';

import { passwordActivationReducer } from '../redux/passwordActivation/passwordActivationReducerIndex';

import { businessCardReducer } from '../redux/businessCard/businessCardReducerIndex';

import { viewVideoPhotoReducer } from '../redux/viewVideoPhoto/viewVideoPhotoReducerIndex';

import { viewOneVideoPhotoReducer } from '../redux/viewOneVideoPhoto/viewOneVideoPhotoReducerIndex';

import { reportAbuseReducer } from '../redux/reportAbuse/reportAbuseReducerIndex';

import { userInfoReducer } from '../redux/userInfo/userInfoReducerIndex';

import { tvRadioStreamingReducer } from '../redux/tvRadioStreaming/tvRadioStreamingReducerIndex';

import { tvRadioWatcherReducer } from '../redux/tvRadioWatcher/tvRadioWatcherReducerIndex';

import { eventHallLiveReducer } from '../redux/eventHallLive/eventHallLiveReducerIndex';

import { viewVillageReducer } from '../redux/viewVillage/viewVillageReducerIndex';

import { viewVillageVideoAudioReducer } from '../redux/viewVillageVideoAudio/viewVillageVideoAudioReducerIndex';

import { viewOneVillageVideoAudioReducer } from '../redux/viewOneVillageVideoAudio/viewOneVillageVideoAudioReducerIndex';

import { userDetailsReducer } from '../redux/userDetails/userDetailsReducerIndex';

import { allDataOnceReducer } from '../redux/allDataOnce/allDataOnceReducerIndex';

import { viewOneEventCardVideoReducer } from '../redux/viewOneEventCardVideo/viewOneEventCardVideoReducerIndex';

import { viewOneFeastCardVideoReducer } from '../redux/viewOneFeastCardVideo/viewOneFeastCardVideoReducerIndex';

import { viewOneMagazineVideoReducer } from '../redux/viewOneMagazineVideo/viewOneMagazineVideoReducerIndex';

import { createConferenceRoomReducer } from '../redux/createConferenceRoom/createConferenceRoomReducerIndex';
import { createTvChannelReducer } from '../redux/createTvChannel/createTvChannelReducerIndex';

import { viewMyVillageVideoAudioReducer } from '../redux/viewMyVillageVideoAudio/viewMyVillageVideoAudioReducerIndex';

import { viewOneMyVillageVideoAudioReducer } from '../redux/viewOneMyVillageVideoAudio/viewOneMyVillageVideoAudioReducerIndex';

import { specificCompetitionReducer } from '../redux/specificCompetition/specificCompetitionReducerIndex';

import { viewOneCompetitionVideoAudioReducer } from '../redux/viewOneCompetitionVideoAudio/viewOneCompetitionVideoAudioReducerIndex';

import { specificSeasonReducer } from '../redux/specificSeason/specificSeasonReducerIndex';

import { innerLiveConferencingReducer } from '../redux/innerLiveConferencing/innerLiveConferencingReducerIndex';


import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];




// combine all reducers
const reducers = combineReducers({
    cartItem: cartReducer,
    registerValue: registerReducer,
    logInValue: loginReducer,
    forgottenPasswordValue: forgottenPasswordReducer,
    passwordActivationValue: passwordActivationReducer,
    businessCardReducerValue: businessCardReducer,
    viewVideoPhotoReducerValue: viewVideoPhotoReducer,
    viewOneVideoPhotoReducerValue: viewOneVideoPhotoReducer,
    reportAbuseReducerValue: reportAbuseReducer,
    userInfoReducerValue: userInfoReducer,
    tvRadioStreamingReducerValue: tvRadioStreamingReducer,
    tvRadioWatcherReducerValue: tvRadioWatcherReducer,
    eventHallLiveReducerValue: eventHallLiveReducer,
    viewVillageReducerValue: viewVillageReducer,
    viewVillageVideoAudioReducerValue: viewVillageVideoAudioReducer,
    viewOneVillageVideoAudioReducerValue: viewOneVillageVideoAudioReducer,
    userDetailsReducerValue: userDetailsReducer,
    allDataOnceReducerValue: allDataOnceReducer,
    viewOneEventCardVideoReducerValue: viewOneEventCardVideoReducer,
    viewOneFeastCardVideoReducerValue: viewOneFeastCardVideoReducer,
    viewOneMagazineVideoReducerValue: viewOneMagazineVideoReducer, 
    createConferenceRoomReducerValue: createConferenceRoomReducer,
    createTvChannelReducerValue: createTvChannelReducer,
    viewMyVillageVideoAudioReducerValue: viewMyVillageVideoAudioReducer,
    viewOneMyVillageVideoAudioReducerValue: viewOneMyVillageVideoAudioReducer,
    specificCompetitionReducerValue: specificCompetitionReducer,
    viewOneCompetitionVideoAudioReducerValue: viewOneCompetitionVideoAudioReducer, 
    specificSeasonReducerValue: specificSeasonReducer,
    innerLiveConferencingReducerValue: innerLiveConferencingReducer,  
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers);


export const store = createStore(persistedReducer, applyMiddleware(...middlewares),);

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga);


/*
export const store = createStore(combineReducers({ 
    cartItem: cartReducer,
    registerValue: registerReducer,
    logInValue: loginReducer,
    forgottenPasswordValue: forgottenPasswordReducer,
    passwordActivationValue: passwordActivationReducer,
    businessCardReducerValue: businessCardReducer,
    viewVideoPhotoReducerValue: viewVideoPhotoReducer,
    viewOneVideoPhotoReducerValue: viewOneVideoPhotoReducer,
    reportAbuseReducerValue: reportAbuseReducer,
    userInfoReducerValue: userInfoReducer,
    tvRadioStreamingReducerValue: tvRadioStreamingReducer,
    tvRadioWatcherReducerValue: tvRadioWatcherReducer,
    eventHallLiveReducerValue: eventHallLiveReducer,
    viewVillageReducerValue: viewVillageReducer,
    viewVillageVideoAudioReducerValue: viewVillageVideoAudioReducer,
    viewOneVillageVideoAudioReducerValue: viewOneVillageVideoAudioReducer,
    userDetailsReducerValue: userDetailsReducer,
    allDataOnceReducerValue: allDataOnceReducer,
    viewOneEventCardVideoReducerValue: viewOneEventCardVideoReducer,
    viewOneFeastCardVideoReducerValue: viewOneFeastCardVideoReducer,
    viewOneMagazineVideoReducerValue: viewOneMagazineVideoReducer, 
    createConferenceRoomReducerValue: createConferenceRoomReducer,
    createTvChannelReducerValue: createTvChannelReducer,
    viewMyVillageVideoAudioReducerValue: viewMyVillageVideoAudioReducer,
    viewOneMyVillageVideoAudioReducerValue: viewOneMyVillageVideoAudioReducer,
    specificCompetitionReducerValue: specificCompetitionReducer,
    viewOneCompetitionVideoAudioReducerValue: viewOneCompetitionVideoAudioReducer, 
    specificSeasonReducerValue: specificSeasonReducer,
    innerLiveConferencingReducerValue: innerLiveConferencingReducer,
}), applyMiddleware(...middlewares),); 

sagaMiddleware.run(rootSaga);
*/