import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, } from '@mui/material';

// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo'; 
// sections
import { LoginForm } from '../sections/auth/login';
 
import LogoBanner from './LogoBanner';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default, 
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Login </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
            zIndex:99,
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h5" sx={{ px: 5, mt: 10, mb: 5 }} className="notranslate">
              Hi, Welcome to PlanetryVilage 
            </Typography>
            <img src="/assets/illustrations/planet.gif" alt="login" />
          </StyledSection>
        )}
 
        <Container maxWidth="sm">
           
          <StyledContent>
            <Typography variant="h4" gutterBottom className="notranslate">
              Sign in to PlanetryVilage
            </Typography>

            <Typography variant="body2" sx={{ mb: 4 }}>
              Don’t have an account? {''}
              <Link component={RouterLink} variant="subtitle2" to='/register'>Register</Link>
            </Typography>  

            <LoginForm />

            <Typography variant="body2" sx={{ mt: 4 }}>
              Have forgotten your account? {''}
              <Link component={RouterLink} variant="subtitle2" to='/forgotten/password'>Forgotten Password</Link>
            </Typography> 

            <Typography variant="body2" sx={{ mt: 4 }}> 
              <Link component={RouterLink} variant="subtitle2" onClick={() => {window.open(`${"https://planetryvilage.com"}`, '_parent')}}>Complete App</Link>
            </Typography> 

          </StyledContent>  
          
        </Container>

        <LogoBanner/>

      </StyledRoot>
    </>
  );
}
