  
import { useState, useRef, useEffect} from 'react';  
import { useNavigate, } from 'react-router-dom';  
import { useCookies  } from 'react-cookie';  
import axios from 'axios'; 
import { useSpeechRecognition } from "react-speech-recognition";

// mui
import { Stack, Typography, Grid, TextField, Card, Divider, IconButton, LinearProgress, FormLabel   } from '@mui/material';  

import { LoadingButton } from '@mui/lab'; 
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import PhotoCamera from '@mui/icons-material/PhotoCamera';  


import {isFileAnImage, isFileAnAudio, isFileAVideo, fileDuration_} from '../../../utils/otherFunctions';
  
import {domainName} from '../../../utils/domain';

 
import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, transMany, firstElementOfString, 
  removeFirstElementFromString, stringComparison, cloud83, fisrtletterofEachStringToUpperCase
     } from '../../../utils/translateFunctions';
 

import { cloud123a, cloud124a, cloud125a, cloud126a, cloud127a, cloud128a, cloud129a, cloud130a, cloud131a, cloud132a, 
        cloud133a, cloud124, cloud125, cloud126, cloud127, cloud128, cloud129, cloud130, cloud101
      } from '../../../utils/automateCreateMagazineFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------

export default function CreateMagazine() {     
  const navigate = useNavigate();   

  const token = useRef(null); 
  const type = useRef(null);


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 


  const [maxMinute, setMaxMinute] = useState('');
  const [files, setFiles] = useState([]);    
  const [preview, setPreview] = useState({imageURL:'', audioURL: '', videoURL: ''});
  const [progressPercentage, setProgressPercentage] = useState(0); 
  const [fileTypeCheck, setFileTypeCheck] = useState({imageFile:false, audioFile:false, videoFile:false});
  

  const [photoFile1, setPhotoFile1] = useState([]);
  const [previewPhoto1, setPreviewPhoto1] = useState();
  const [progressPercentagePhoto1, setProgressPercentagePhoto1] = useState(0);

  const [photoFile2, setPhotoFile2] = useState([]);
  const [previewPhoto2, setPreviewPhoto2] = useState();
  const [progressPercentagePhoto2, setProgressPercentagePhoto2] = useState(0);

  const [photoFile3, setPhotoFile3] = useState([]);
  const [previewPhoto3, setPreviewPhoto3] = useState();
  const [progressPercentagePhoto3, setProgressPercentagePhoto3] = useState(0);

  const [photoFile4, setPhotoFile4] = useState([]);
  const [previewPhoto4, setPreviewPhoto4] = useState();
  const [progressPercentagePhoto4, setProgressPercentagePhoto4] = useState(0);

  const [photoFile5, setPhotoFile5] = useState([]);
  const [previewPhoto5, setPreviewPhoto5] = useState();
  const [progressPercentagePhoto5, setProgressPercentagePhoto5] = useState(0);

  const [photoFile6, setPhotoFile6] = useState([]);
  const [previewPhoto6, setPreviewPhoto6] = useState();
  const [progressPercentagePhoto6, setProgressPercentagePhoto6] = useState(0);


  const [TitleCoverPage, setTitleCoverPage] = useState('');  
  const [SecretCoverPage, setSecretCoverPage] = useState(''); 
  const [SummarizeCoverPage, setSummarizeCoverPage] = useState('');  
  const [WhoCoverPage, setWhoCoverPage] = useState(''); 
  const [Comment1Page2, setComment1Page2] = useState(''); 
  const [Content1Page3, setContent1Page3] = useState(''); 
  const [Content2Page3, setContent2Page3] = useState('');  
  const [Content3Page3, setContent3Page3] = useState('');   
  const [Comment1Page4, setComment1Page4] = useState('');   
  const [Comment2Page4, setComment2Page4] = useState('');    
  const [Content1Page5, setContent1Page5] = useState('');  


  const handleChangeTitleCoverPage = (e) => {
    setTitleCoverPage(e.target.value);

    setMaxMinute('');
  }
  const handleChangeSecretCoverPage = (e) => {
    setSecretCoverPage(e.target.value);

    setMaxMinute('');
  }
  const handleChangeSummarizeCoverPage = (e) => {
    setSummarizeCoverPage(e.target.value);

    setMaxMinute('');
  }
  const handleChangeWhoCoverPage = (e) => {
    setWhoCoverPage(e.target.value);

    setMaxMinute('');
  }
  const handleChangeComment1Page2 = (e) => {
    setComment1Page2(e.target.value);

    setMaxMinute('');
  }
  const handleChangeContent1Page3 = (e) => {
    setContent1Page3(e.target.value);

    setMaxMinute('');
  }
  const handleChangeContent2Page3 = (e) => {
    setContent2Page3(e.target.value); 

    setMaxMinute('');
  }
  const handleChangeContent3Page3 = (e) => {
    setContent3Page3(e.target.value);

    setMaxMinute('');
  }
  const handleChangeComment1Page4 = (e) => {  
    setComment1Page4(e.target.value);

    setMaxMinute('');
  }
  const handleChangeComment2Page4 = (e) => {
    setComment2Page4(e.target.value);

    setMaxMinute('');
  }
  const handleChangeContent1Page5 = (e) => {
    setContent1Page5(e.target.value);

    setMaxMinute('');
  }
  
 

  /*  Begin upload */
  let urlLocalComplete; 
  let urlLocalData;
  let urlLink2;
  let urlLink;
  let options;
  let EndPoints;
  let fileCategory;
  const urlLocalRequest = `${domainName}/u/create/magazine`;
  const TFile = [];
  let numberOfFiles = 0; 


  const generateUrlFile = (urlLink_) => {
    urlLink2 = `${urlLink_}${'-data'}`;
    urlLink = `${urlLink_}${'-complete'}`;

    return {
      urlLink2,
      urlLink
    };
  }
 

  const upload = async () => {  
    if(await (TitleCoverPage).trim() === ''){
      setMaxMinute('The title of the cover page is required');
      return;
    }  

    if(await (SecretCoverPage).trim() === ''){
      setMaxMinute('The secret of the cover page is required');
      return;
    }  

    if(await (SummarizeCoverPage).trim() === ''){
      setMaxMinute('The summary of the cover page is required');
      return;
    }  

    if(await (WhoCoverPage).trim() === ''){
      setMaxMinute('Who you will be talking about is required');
      return;
    }      

    if(await photoFile1.length === 0){
      setMaxMinute('Select a photo for the cover page');
      return;
    }   

    if(await photoFile2.length === 0){
      setMaxMinute('Select a photo for the page 2');
      return;
    } 

    if(await (Comment1Page2).trim() === ''){
      setMaxMinute('The comment 1 of the page 2 is required');
      return;
    }   
   
    if(await photoFile3.length === 0){
      setMaxMinute('Select a photo for the page 3');
      return;
    } 

    if(await (Content1Page3).trim() === ''){
      setMaxMinute('The content 1 of the page 3 is required');
      return;
    }   

    if(await (Content2Page3).trim() === ''){
      setMaxMinute('The content 2 of the page 3 is required');
      return;
    }   

    if(await (Content3Page3).trim() === ''){
      setMaxMinute('The content 3 of the page 3 is required');
      return;
    }  

    if(await photoFile4.length === 0){
      setMaxMinute('Select a photo for the page 4');
      return;
    } 

    if(await (Comment1Page4).trim() === ''){
      setMaxMinute('The comment 1 of the page 4 is required');
      return;
    } 

    if(await photoFile5.length === 0){
      setMaxMinute('Select another photo for the page 4');
      return;
    }

    if(await (Comment2Page4).trim() === ''){
      setMaxMinute('The comment 2 of the page 4 is required');
      return;
    }    

    if(await (Content1Page5).trim() === ''){
      setMaxMinute('The content 1 of the page 5 is required');
      return;
    }  
 
    if(await photoFile6.length === 0){
      setMaxMinute('Select a photo for the page 5');
      return;
    }

    if(await files.length === 0){
      setMaxMinute('Select a video for the page 5');
      return;
    }
 
  

    // TFile[0] = files;
    TFile[0] = await photoFile1;
    TFile[1] = await photoFile2;
    TFile[2] = await photoFile3;
    TFile[3] = await photoFile4;
    TFile[4] = await photoFile5;
    TFile[5] = await photoFile6;
    TFile[6] = await files;

    numberOfFiles = await TFile.length; 
 

    urlLink2 = await generateUrlFile(urlLocalRequest).urlLink2;

    urlLink = await generateUrlFile(urlLocalRequest).urlLink;

    urlLocalComplete = await urlLink;
    urlLocalData = await urlLink2;


    EndPoints = await {
      UPLOAD: urlLocalComplete,
      UPLOAD_DATA: urlLocalData,
      UPLOAD_REQUEST: urlLocalRequest
    }
 

    options = await {
      url: EndPoints.UPLOAD,
      startingByte: 0,
      // chunkSize: 100000,
      chunkSize: 1000000,
      fileId: '',
      arrayId: ''
    };
 

    uploadTrigger();  
  }


  const uploadTrigger = async () => {  
    const optionResult = await uploadCreateDirectory();

    const optionResultArrayIdLength = await optionResult.arrayId.length;
    const filesLength = await numberOfFiles;

    if (await (optionResultArrayIdLength > 0)) {
      for (let z = 0; z < filesLength; z+=1) {
        // const files_ = TFile[0].files[z];
        const files_ = (TFile[z])[0];
        if (files_) {   
          uploadExecution(files_, z);
        }
      }
    }
  }


  const uploadCreateDirectory = async () => {
    const TitleCoverPage_ = await TitleCoverPage;
    const SecretCoverPage_ = await SecretCoverPage;
    const SummarizeCoverPage_ = await SummarizeCoverPage;
    const WhoCoverPage_ = await WhoCoverPage; 
    const Comment1Page2_ = await Comment1Page2;  
    const Content1Page3_ = await Content1Page3;   
    const Content2Page3_ = await Content2Page3;    
    const Content3Page3_ = await Content3Page3;    
    const Comment1Page4_ = await Comment1Page4;    
    const Comment2Page4_ = await Comment2Page4;    
    const Content1Page5_ = await Content1Page5; 
 

    const resp = await sendFile(numberOfFiles, EndPoints.UPLOAD_REQUEST);

    try {
      const jsonResponse = await resp.data;   

      if (await (resp.status === 400)) {
        await console.log('An error occured in the directory');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      }  


      options = await {
        ...options,
        ...jsonResponse
      };

      const respData = await sendFileData(options.arrayId, TitleCoverPage_, SecretCoverPage_, SummarizeCoverPage_, WhoCoverPage_, Comment1Page2_, Content1Page3_, Content2Page3_, Content3Page3_, Comment1Page4_, Comment2Page4_, Content1Page5_, token.current, type.current, EndPoints.UPLOAD_DATA);
       
      if (await (respData.status === 400)) {
        await console.log('Missing file and content');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      } 

      return options; 
    } catch (err) {
      console.error('failed to read file: ', err);

      options = await {
        ...options,
        ...{
          arrayId: []
        }
      };
      return options;
    } 
  }


  const uploadFileChunks = async (file, options, i) => {
    let options_;

    if (await isFileAnImage(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          // chunkSize: 1000
          chunkSize: 100000
        }
      };
    } else
    if (await isFileAnAudio(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          // chunkSize: 10000
          chunkSize: 100000
        }
      };
    } else {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        }
      };
    }
    await sendFileChunks(file, options_.startingByte, options_.chunkSize, options_, i);

  }


  const sendFileChunks = async (file, startingByte, endingByte_, options_, i) => {
    const fileSize = await file.size;
    let endingByte = await endingByte_;

    const formData = await new FormData();
    const xhr = await new XMLHttpRequest();

    if (fileSize < endingByte) {
      endingByte = await fileSize;
    }
 

    if (endingByte < fileSize) {
      xhr.onreadystatechange = async () => {
        if (await (xhr.readyState === XMLHttpRequest.DONE)) {
          const percentageUploaded = await (endingByte / fileSize) * 100;  

          await setMaxMinute('Upload in progress');

          if(await (i === 0)) {
            await setProgressPercentagePhoto1(parseInt(percentageUploaded, 10)); 
          } else
          if(await (i === 1)) {
            await setProgressPercentagePhoto2(parseInt(percentageUploaded, 10)); 
          } else
          if(await (i === 2)) {
            await setProgressPercentagePhoto3(parseInt(percentageUploaded, 10)); 
          } else
          if(await (i === 3)) {
            await setProgressPercentagePhoto4(parseInt(percentageUploaded, 10)); 
          } else
          if(await (i === 4)) {
            await setProgressPercentagePhoto5(parseInt(percentageUploaded, 10)); 
          } else
          if(await (i === 5)) {
            await setProgressPercentagePhoto6(parseInt(percentageUploaded, 10)); 
          } else
          if(await (i === 6)) {
            await setProgressPercentage(parseInt(percentageUploaded, 10)); 
          }   

          await sendFileChunks(file, startingByte + options_.chunkSize, startingByte + (options_.chunkSize * 2), options_, i);
        }
      }
    } else {
      if(await (i === 0)) {
        await setProgressPercentagePhoto1(100); 
        setProgressPercentagePhoto1(0); 
      } else 
      if(await (i === 1)) {
        await setProgressPercentagePhoto2(100); 
        setProgressPercentagePhoto2(0); 
      } else 
      if(await (i === 2)) {
        await setProgressPercentagePhoto3(100); 
        setProgressPercentagePhoto3(0); 
      } else 
      if(await (i === 3)) {
        await setProgressPercentagePhoto4(100); 
        setProgressPercentagePhoto4(0); 
      } else 
      if(await (i === 4)) {
        await setProgressPercentagePhoto5(100); 
        setProgressPercentagePhoto5(0); 
      } else 
      if(await (i === 5)) {
        await setProgressPercentagePhoto6(100);
        setProgressPercentagePhoto6(0); 
      } else 
      if(await (i === 6)) {
        await setProgressPercentage(100); 
        setProgressPercentage(0)
      }   
 

      await setTitleCoverPage('');  
      await setSecretCoverPage('');
      await setSummarizeCoverPage(''); 
      await setWhoCoverPage(''); 
      await setComment1Page2('');
      await setContent1Page3('');  
      await setContent2Page3('');  
      await setContent3Page3('');  
      await setComment1Page4('');  
      await setComment2Page4(''); 
      await setContent1Page5('');


      await setPreview({
        preview, 
        ...{
          imageURL: '', 
          audioURL: '', 
          videoURL: ''
        }
      });

      setPreviewPhoto1('');
      setPreviewPhoto2('');
      setPreviewPhoto3('');
      setPreviewPhoto4('');
      setPreviewPhoto5('');
      setPreviewPhoto6('');

      await setFiles([]);
      await setPreviewPhoto1([]);
      await setPreviewPhoto2([]);
      await setPreviewPhoto3([]);
      await setPreviewPhoto4([]);
      await setPreviewPhoto5([]);
      await setPreviewPhoto6([]); 

      await setMaxMinute('Upload Complete'); 
      setMaxMinute(''); 
    }


    const chunk = await file.slice(startingByte, endingByte);

    await formData.append('chunk', chunk, file.name);
    await formData.append('startingByte', startingByte);
    await formData.append('endingByte', endingByte);
    await formData.append('fileId', options_.fileId);
    await formData.append('X-fileCategory', fileCategory);

    await xhr.open('POST', options_.url, true);
    await xhr.setRequestHeader('X-File-Id', options_.fileId);
    await xhr.setRequestHeader('X-File-Size', fileSize);
    await xhr.setRequestHeader('X-startingByte', startingByte);
    await xhr.setRequestHeader('X-endingByte', endingByte);
    await xhr.setRequestHeader('X-fileCategory', fileCategory);

    await xhr.send(formData);
  }


  const sendFile = (numberOfFiles_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        numberOfFiles: numberOfFiles_,  
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }

    
  const sendFileData = (fileData, TitleCoverPage_, SecretCoverPage_, SummarizeCoverPage_, WhoCoverPage_, Comment1Page2_, Content1Page3_, Content2Page3_, Content3Page3_, Comment1Page4_, Comment2Page4_, Content1Page5_, token_, type_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        fileData_: fileData,
        TitleCoverPage: TitleCoverPage_,
        SecretCoverPage: SecretCoverPage_,
        SummarizeCoverPage: SummarizeCoverPage_,
        WhoCoverPage: WhoCoverPage_,
        Comment1Page2: Comment1Page2_,
        Content1Page3: Content1Page3_,
        Content2Page3: Content2Page3_,
        Content3Page3: Content3Page3_,
        Comment1Page4: Comment1Page4_,
        Comment2Page4: Comment2Page4_,
        Content1Page5: Content1Page5_,
        token: token_,
        type: type_, 
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }



  const uploadExecution = async (files_, i) => {
    let newFile;

    if (await isFileAnImage(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.jpg');
    } else 
    if (await isFileAnAudio(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.wav');
    } else {
      newFile = await renameFile(files_, 'planetryvilage.webm');
    }

    if (!newFile) {
      await console.log("You must first select the file to upload it");
    } else {
      try {
        await uploadFileChunks(newFile, options, i);
      } catch (err) {
        console.error('failed to read file: ', err);
      }
    }
  }


  function renameFile (originalFile_, newName_) {
    return new File([originalFile_], newName_, {
      type: originalFile_.type,
      lastModified: originalFile_.lastModified,
    });
  }
  /*  End upload */



  const resetAllFile = () => {
  	setFileTypeCheck({
  		fileTypeCheck, 
  		...{
  			imageFile:false, 
  			audioFile:false, 
  			videoFile:false
  		}
  	});

  	setPreview({
  		preview, 
  		...{
  			imageURL: '', 
  			audioURL: '', 
  			videoURL: ''
  		}
  	});

  	setFiles([]);
  	setMaxMinute('Max 2 minutes');
  }


 

  // check if a file is an image or an audio or a video
  const fileChecker = async (file) => {
  	if (await isFileAnImage(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:true, 
  				audioFile:false, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: URL.createObjectURL(file), 
  				audioURL: '', 
  				videoURL: ''
  			}
  		});
  	} else 
  	if(await isFileAnAudio(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:true, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: URL.createObjectURL(file), 
  				videoURL: ''
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else
  	if(await isFileAVideo(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:false, 
  				videoFile:true
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: '', 
  				videoURL: URL.createObjectURL(file)
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else{
  		// the file is nor an image nor an audio nor a video.  Reset everything
  		resetAllFile();
  	}
  }
   
  const handleFilesChange = async (e) => {
   	if (await e.target.files) {
      await setFiles(e.target.files); 
      await fileChecker(e.target.files[0]); 
      await setMaxMinute('');
    }   
  }; 

   

  const handlePhoto1Change = async (e) => {
   	if (await e.target.files) {
        await setPhotoFile1(e.target.files);

        await setMaxMinute('');
        
        if (await isFileAnImage(e.target.files[0])) { 
            setPreviewPhoto1(URL.createObjectURL(e.target.files[0]));
        } else {
        	setPhotoFile1([]);

  		    setPreviewPhoto1('');
        }
    }   
  }

  const handlePhoto2Change = async (e) => {
   	if (await e.target.files) {
        await setPhotoFile2(e.target.files);

        await setMaxMinute('');
        
        if (await isFileAnImage(e.target.files[0])) { 
            setPreviewPhoto2(URL.createObjectURL(e.target.files[0]));
        } else {
        	setPhotoFile2([]);

  		    setPreviewPhoto2('');
        }
    }   
  }

  const handlePhoto3Change = async (e) => {
   	if (await e.target.files) {
        await setPhotoFile3(e.target.files);

        await setMaxMinute('');
        
        if (await isFileAnImage(e.target.files[0])) { 
            setPreviewPhoto3(URL.createObjectURL(e.target.files[0]));
        } else {
        	setPhotoFile3([]);

  		    setPreviewPhoto3('');
        }
    }   
  }

  const handlePhoto4Change = async (e) => {
   	if (await e.target.files) {
        await setPhotoFile4(e.target.files);

        await setMaxMinute('');
        
        if (await isFileAnImage(e.target.files[0])) { 
            setPreviewPhoto4(URL.createObjectURL(e.target.files[0]));
        } else {
        	setPhotoFile4([]);

  		    setPreviewPhoto4('');
        }
    }   
  }

  const handlePhoto5Change = async (e) => {
   	if (await e.target.files) {
        await setPhotoFile5(e.target.files);

        await setMaxMinute('');
        
        if (await isFileAnImage(e.target.files[0])) { 
            setPreviewPhoto5(URL.createObjectURL(e.target.files[0]));
        } else {
        	setPhotoFile5([]);

  		    setPreviewPhoto5('');
        }
    }   
  }

  const handlePhoto6Change = async (e) => {
   	if (await e.target.files) {
        await setPhotoFile6(e.target.files);

        await setMaxMinute('');
        
        if (await isFileAnImage(e.target.files[0])) { 
            setPreviewPhoto6(URL.createObjectURL(e.target.files[0]));
        } else {
        	setPhotoFile6([]);

  		    setPreviewPhoto6('');
        }
    }   
  }

    




  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
   
  const inputElement1 = useRef(); 
  const inputElement2 = useRef(); 
  const inputElement3 = useRef(); 
  const inputElement4 = useRef(); 
  const inputElement5 = useRef(); 
  const inputElement6 = useRef(); 
  const inputElement7 = useRef();  
  const triggerUploadClick = useRef(); 

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {  
        let buffer; let TitleCoverPage; let SecretCoverPage; let SummarizeCoverPage; let WhoCoverPage; let CommentNumber1Page2;
        let ContentNumber1Page3; let ContentNumber2Page3; let ContentNumber3Page3; let CommentNumber1Page4;
        let CommentNumber2Page4; let ContentNumber1Page5;

        let  tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          let firstElementOfString_ = await firstElementOfString(t);  
          const removeFirstElementFromString_ = await removeFirstElementFromString(t);   
 
          
          const firstElementOfString2_ = await firstElementOfString(removeFirstElementFromString_);
          const removeFirstElementFromString2_ = await removeFirstElementFromString(removeFirstElementFromString_);

          const firstElementOfString3_ = await firstElementOfString(removeFirstElementFromString2_);
          const removeFirstElementFromString3_ = await removeFirstElementFromString(removeFirstElementFromString2_);

          const firstElementOfString4_ = await firstElementOfString(removeFirstElementFromString3_);
          const removeFirstElementFromString4_ = await removeFirstElementFromString(removeFirstElementFromString3_);
 

          
          if (await (firstElementOfString_ === 'the')) {
            buffer = await firstElementOfString_;
            firstElementOfString_ = await firstElementOfString(removeFirstElementFromString_);
          }

          
          /* add the title of the magazine */
          if (await stringComparison([cloud123a.a], firstElementOfString_)) {
            t = await firstElementOfString_;

            if (await (buffer === 'the')) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            } else {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            }

            TitleCoverPage = await tm;  
            await setTitleCoverPage(TitleCoverPage);

            speech.text = await transMany__('The title of the magazine has been updated') || 'The title of the magazine has been updated';
            speechSynth.speak(speech); 
          }

          
          /* add the secret of the magazine */
          if (await stringComparison([cloud124a.a], firstElementOfString_)) {
            t = await firstElementOfString_;

            if (await (buffer === 'the')) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            } else {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            }

            SecretCoverPage = await tm;
            await setSecretCoverPage(SecretCoverPage);

            speech.text = await transMany__('The secret has been updated') || 'The secret has been updated';
            speechSynth.speak(speech); 
          }


          /* add the summary of the magazine */
          if (await stringComparison([cloud125a.a, cloud125a.b, cloud125a.c, cloud125a.d], firstElementOfString_)) {
             t = await firstElementOfString_;

             if (await (buffer === 'the')) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            } else {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            }

            SummarizeCoverPage = await tm;
            await setSummarizeCoverPage(SummarizeCoverPage);

            speech.text = await transMany__('The summary has been updated') || 'The summary has been updated';
            speechSynth.speak(speech); 
          }

          
          /* add about */
          if (await stringComparison([cloud126a.a, cloud126a.b, cloud126a.c, cloud126a.d], firstElementOfString_)) {
            t = await firstElementOfString_;

            if (await (buffer === 'the')) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            } else {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            }

            WhoCoverPage = await fisrtletterofEachStringToUpperCase(tm);
            await setWhoCoverPage(WhoCoverPage);

            speech.text = await transMany__('The entity has been updated') || 'The entity has been updated';
            speechSynth.speak(speech); 
          }
 

          /* Comment 1 Page 2 */
          if (await stringComparison([cloud127a.b1, cloud127a.b2, cloud127a.b3, cloud127a.b4, cloud127a.b5, cloud127a.b6, cloud127a.b7, cloud127a.b8, cloud127a.b9, cloud127a.b10, cloud127a.b11, cloud127a.b12, cloud127a.b13, cloud127a.b14, cloud127a.a1, cloud127a.a2, cloud127a.a3, cloud127a.a4, cloud127a.a5, cloud127a.a6, cloud127a.a7, cloud127a.a8, cloud127a.a9, cloud127a.a10, cloud127a.a11, cloud127a.a12, cloud127a.a13, cloud127a.a14], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`; 

            tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            CommentNumber1Page2 = await tm;
            await setComment1Page2(CommentNumber1Page2); 

            speech.text = await transMany__('The comment one of page two has been updated') || 'The comment one of page two has been updated';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud127a.a, cloud127a.b, cloud127a.c, cloud127a.d, cloud127a.e, cloud127a.f], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}${' '}${firstElementOfString4_}`)) {
            const Comment1Page2 = await removeFirstElementFromString4_;
            await setComment1Page2(Comment1Page2); 

            speech.text = await transMany__('The comment one of page two has been updated') || 'The comment one of page two has been updated';
            speechSynth.speak(speech); 
          }

          
          /* Content 1 Page 3 */   
          if (await stringComparison([cloud128a.b1, cloud128a.b2, cloud128a.b3, cloud128a.b4, cloud128a.b5, cloud128a.b6, cloud128a.b7, cloud128a.b8, cloud128a.b9, cloud128a.b10, cloud128a.b11, cloud128a.b12, cloud128a.b13, cloud128a.b14, cloud128a.a1, cloud128a.a2, cloud128a.a3, cloud128a.a4, cloud128a.a5, cloud128a.a6, cloud128a.a7, cloud128a.a8, cloud128a.a9, cloud128a.a10, cloud128a.a11, cloud128a.a12, cloud128a.a13, cloud128a.a14], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`;

            tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            ContentNumber1Page3 = await tm;
            await setContent1Page3(ContentNumber1Page3);

            speech.text = await transMany__('The content one of page three has been updated') || 'The content one of page three has been updated';
            speechSynth.speak(speech); 
          } 

          if (await stringComparison([cloud128a.a, cloud128a.b, cloud128a.c, cloud128a.d, cloud128a.e, cloud128a.f], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}${' '}${firstElementOfString4_}`)) {
            const Content1Page3 = await removeFirstElementFromString4_;
            await setContent1Page3(Content1Page3);

            speech.text = await transMany__('The content one of page three has been updated') || 'The content one of page three has been updated';
            speechSynth.speak(speech); 
          }

          
          /* Content 2 Page 3 */ 
          if (await stringComparison([cloud129a.b1, cloud129a.b2, cloud129a.b3, cloud129a.b4, cloud129a.b5, cloud129a.b6, cloud129a.b7, cloud129a.b8, cloud129a.b9, cloud129a.b10, cloud129a.b11, cloud129a.b12, cloud129a.b13, cloud129a.b14, cloud129a.a1, cloud129a.a2, cloud129a.a3, cloud129a.a4, cloud129a.a5, cloud129a.a6, cloud129a.a7, cloud129a.a8, cloud129a.a9, cloud129a.a10, cloud129a.a11, cloud129a.a12, cloud129a.a13, cloud129a.a14], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`;

            tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            ContentNumber2Page3 = await tm;
            await setContent2Page3(ContentNumber2Page3);

            speech.text = await transMany__('The content two of page three has been updated') || 'The content two of page three has been updated';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud129a.a, cloud129a.b, cloud129a.c, cloud129a.d, cloud129a.e, cloud129a.f, cloud129a.g, cloud129a.h, cloud129a.i], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}${' '}${firstElementOfString4_}`)) {
            const Content2Page3 = await removeFirstElementFromString4_;
            await setContent2Page3(Content2Page3);

            speech.text = await transMany__('The content two of page three has been updated') || 'The content two of page three has been updated';
            speechSynth.speak(speech); 
          }

          
          /* Content 3 Page 3 */ 
          if (await stringComparison([cloud130a.b1, cloud130a.b2, cloud130a.b3, cloud130a.b4, cloud130a.b5, cloud130a.b6, cloud130a.b7, cloud130a.b8, cloud130a.b9, cloud130a.b10, cloud130a.b11, cloud130a.b12, cloud130a.b13, cloud130a.b14, cloud130a.a1, cloud130a.a2, cloud130a.a3, cloud130a.a4, cloud130a.a5, cloud130a.a6, cloud130a.a7, cloud130a.a8, cloud130a.a9, cloud130a.a10, cloud130a.a11, cloud130a.a12, cloud130a.a13, cloud130a.a14], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`;

            tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            ContentNumber3Page3 = await tm;
            await setContent3Page3(ContentNumber3Page3);

            speech.text = await transMany__('The content three of page three has been updated') || 'The content three of page three has been updated';
            speechSynth.speak(speech); 
          } 

          if (await stringComparison([cloud130a.a, cloud130a.b, cloud130a.c, cloud130a.d, cloud130a.e, cloud130a.f, cloud130a.g, cloud130a.h, cloud130a.i], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}${' '}${firstElementOfString4_}`)) {
            const Content3Page3 = await removeFirstElementFromString4_;
            await setContent3Page3(Content3Page3);

            speech.text = await transMany__('The content three of page three has been updated') || 'The content three of page three has been updated';
            speechSynth.speak(speech); 
          }
 
          
          /* Comment 1 Page 4 */ 
          if (await stringComparison([cloud131a.b1, cloud131a.b2, cloud131a.b3, cloud131a.b4, cloud131a.b5, cloud131a.b6, cloud131a.b7, cloud131a.b8, cloud131a.b9, cloud131a.b10, cloud131a.b11, cloud131a.b12, cloud131a.b13, cloud131a.b14, cloud131a.a1, cloud131a.a2, cloud131a.a3, cloud131a.a4, cloud131a.a5, cloud131a.a6, cloud131a.a7, cloud131a.a8, cloud131a.a9, cloud131a.a10, cloud131a.a11, cloud131a.a12, cloud131a.a13, cloud131a.a14], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`;

            tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            CommentNumber1Page4 = await tm;
            await setComment1Page4(CommentNumber1Page4);

            speech.text = await transMany__('The comment one of page four has been updated') || 'The comment one of page four has been updated';
            speechSynth.speak(speech); 
          } 

          if (await stringComparison([cloud131a.a, cloud131a.b, cloud131a.c, cloud131a.d, cloud131a.e, cloud131a.f], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}${' '}${firstElementOfString4_}`)) {
            const Comment1Page4 = await removeFirstElementFromString4_;
            await setComment1Page4(Comment1Page4);

            speech.text = await transMany__('The comment one of page four has been updated') || 'The comment one of page four has been updated';
            speechSynth.speak(speech); 
          } 

          
          /* Comment 2 Page 4 */ 
          if (await stringComparison([cloud132a.b1, cloud132a.b2, cloud132a.b3, cloud132a.b4, cloud132a.b5, cloud132a.b6, cloud132a.b7, cloud132a.b8, cloud132a.b9, cloud132a.b10, cloud132a.b11, cloud132a.b12, cloud132a.b13, cloud132a.b14, cloud132a.a1, cloud132a.a2, cloud132a.a3, cloud132a.a4, cloud132a.a5, cloud132a.a6, cloud132a.a7, cloud132a.a8, cloud132a.a9, cloud132a.a10, cloud132a.a11, cloud132a.a12, cloud132a.a13, cloud132a.a14], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`;

            tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            CommentNumber2Page4 = await tm;
            await setComment2Page4(CommentNumber2Page4);

            speech.text = await transMany__('The comment two of page four has been updated') || 'The comment two of page four has been updated';
            speechSynth.speak(speech); 
          } 

          if (await stringComparison([cloud132a.a, cloud132a.b, cloud132a.c, cloud132a.d, cloud132a.e, cloud132a.f, cloud132a.g, cloud132a.h, cloud132a.i], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}${' '}${firstElementOfString4_}`)) {
            const Comment2Page4 = await removeFirstElementFromString4_;
            await setComment2Page4(Comment2Page4);

            speech.text = await transMany__('The comment two of page four has been updated') || 'The comment two of page four has been updated';
            speechSynth.speak(speech); 
          }
          
          
          /* Content 1 Page 5 */ 
          if (await stringComparison([cloud133a.b1, cloud133a.b2, cloud133a.b3, cloud133a.b4, cloud133a.b5, cloud133a.b6, cloud133a.a1, cloud133a.a2, cloud133a.a3, cloud133a.a4, cloud133a.a5, cloud133a.a6], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}`;

            tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            ContentNumber1Page5 = await tm;
            await setContent1Page5(ContentNumber1Page5);

            speech.text = await transMany__('The content one of page five has been updated') || 'The content one of page five has been updated';
            speechSynth.speak(speech); 
          } 


          if (await stringComparison([cloud133a.a, cloud133a.b], `${firstElementOfString_}${' '}${firstElementOfString2_}${' '}${firstElementOfString3_}${' '}${firstElementOfString4_}`)) {
            const Content1Page5 = await removeFirstElementFromString4_; 
            await setContent1Page5(Content1Page5);

            speech.text = await transMany__('The content one of page five has been updated') || 'The content one of page five has been updated';
            speechSynth.speak(speech); 
          }

          // one
          if (await stringComparison([cloud124.a, cloud124.b, cloud124.c], t)) {
            await inputElement1.current.click();

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech); 
          }
          
          // two  
          if (await stringComparison([cloud125.a, cloud125.b, cloud125.c, cloud125.d], t)) {
            await inputElement2.current.click();

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech); 
          }
          
          // three
          if (await stringComparison([cloud126.a, cloud126.b, cloud126.c, cloud126.d], t)) { 
            await inputElement3.current.click();

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech); 
          }
          
          // four 
          if (await stringComparison([cloud127.a, cloud127.b, cloud127.c, cloud127.d], t)) {
            await inputElement4.current.click();

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech); 
          }
          
          // five  
          if (await stringComparison([cloud128.a, cloud128.b, cloud128.c], t)) {
            await inputElement5.current.click();

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech); 
          }
          
          // six     
          if (await stringComparison([cloud129.a, cloud129.b, cloud129.c], t)) {
            await inputElement6.current.click();

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech); 
          } 
          
          // seven video
          if (await stringComparison([cloud130.a, cloud130.b, cloud130.c, cloud130.d], t)) {
            await inputElement7.current.click();

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech); 
          }

          
          // create the magazine
          if (await stringComparison([cloud101.a, cloud101.b, cloud101.c, cloud101.d, cloud101.e, cloud101.f, cloud101.g, cloud101.h, cloud101.i], t)) {
            await triggerUploadClick.current.click();

            speech.text = await transMany__('The save has been triggered') || 'The save has been triggered';
            speechSynth.speak(speech); 
          }  
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate]);

 
  /* End Michael */  


 
 
  return (
    <>    
      <Grid  item xs={12} sm={12} md={8} lg={6}>
        <Card sx={{borderRadius:{xs:0, sm:0, md:'10px'}, mt:0.2}}>
          <Stack spacing={1} sx={{p:1, textAlign:'center', justifyContent:'center'}}>
            <Typography variant="string">
              Create a magazine 
            </Typography> 

            <Divider/> 

            <Typography variant="h6" color="text.secondary">
              Cover page
            </Typography>

            <TextField name="TitleCoverPage" value={TitleCoverPage} onChange={handleChangeTitleCoverPage} label="Title of the magazine" inputProps={{ maxLength: 35 }}/> 

            <TextField name="SecretCoverPage" value={SecretCoverPage} onChange={handleChangeSecretCoverPage}  label="What secret are you sharing?" inputProps={{ maxLength: 60 }}/> 

            <TextField name="SummarizeCoverPage" value={SummarizeCoverPage} onChange={handleChangeSummarizeCoverPage}  label="Summarize the magazine" inputProps={{ maxLength: 130 }}/> 

            <TextField name="WhoCoverPage" value={WhoCoverPage} onChange={handleChangeWhoCoverPage}  label="Who are you going to talk about?" inputProps={{ maxLength: 22 }}/> 
                
            <FormLabel id="select-a-photo">select a photo</FormLabel>
            <IconButton color="primary" aria-label="upload picture"    title='select a photo' component="label">
              <input hidden accept="image/*" type="file" ref={inputElement1} onChange={handlePhoto1Change} />
              <PhotoCamera sx={{fontSize:{xs:30, md:40}}} /> 
            </IconButton>

            {!!progressPercentagePhoto1 && 
            (<>
              <LinearProgress variant="determinate" value={progressPercentagePhoto1}/>
              <Typography variant="body2" color="text.secondary">
                {`${Math.round(progressPercentagePhoto1)}%`}
              </Typography> 
            </>
            )}

            {previewPhoto1 && <img src={previewPhoto1} alt='' style={{maxWidth: '100px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}

            {/* */}


            <Typography variant="h6" color="text.secondary" sx={{pt:5}}>
              Page 2
            </Typography>
                
            <FormLabel id="select-a-photo">select a photo</FormLabel>
            <IconButton color="primary" aria-label="upload picture"   title='select a photo' component="label">
              <input hidden accept="image/*" type="file" ref={inputElement2} onChange={handlePhoto2Change} />
              <PhotoCamera sx={{fontSize:{xs:30, md:40}}} /> 
            </IconButton>

            {!!progressPercentagePhoto2 && 
            (<>
                <LinearProgress variant="determinate" value={progressPercentagePhoto2}/>
                <Typography variant="body2" color="text.secondary">
                  {`${Math.round(progressPercentagePhoto2)}%`}
                </Typography> 
              </>
            )}

            {previewPhoto2 && <img src={previewPhoto2} alt='' style={{maxWidth: '100px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}

            <TextField name="Comment1Page2" value={Comment1Page2} onChange={handleChangeComment1Page2}  label="Comment" inputProps={{ maxLength: 169 }}/> 


            {/* */}


            <Typography variant="h6" color="text.secondary" sx={{pt:5}}>
              Page 3
            </Typography>
                
            <FormLabel id="select-a-photo">select a photo</FormLabel>
            <IconButton color="primary" aria-label="upload picture"   title='select a photo' component="label">
              <input hidden accept="image/*" type="file" ref={inputElement3} onChange={handlePhoto3Change} />
              <PhotoCamera sx={{fontSize:{xs:30, md:40}}} /> 
            </IconButton>

            {!!progressPercentagePhoto3 && 
            (<>
                <LinearProgress variant="determinate" value={progressPercentagePhoto3}/>
                <Typography variant="body2" color="text.secondary">
                  {`${Math.round(progressPercentagePhoto3)}%`}
                </Typography> 
            </>
            )}

            {previewPhoto3 && <img src={previewPhoto3} alt='' style={{maxWidth: '100px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}
                
            <TextField name="Content1Page3" value={Content1Page3} onChange={handleChangeContent1Page3} label="Content 1" inputProps={{ maxLength: 378 }}/> 

            <TextField name="Content2Page3" value={Content2Page3} onChange={handleChangeContent2Page3}  label="Content 2" inputProps={{ maxLength: 382 }}/> 

            <TextField name="Content3Page3" value={Content3Page3} onChange={handleChangeContent3Page3} label="Content 3" inputProps={{ maxLength: 356 }}/> 
                
            {/* */}



            <Typography variant="h6" color="text.secondary" sx={{pt:5}}>
              Page 4
            </Typography>
                
            <FormLabel id="select-a-photo">select a photo</FormLabel>
            <IconButton color="primary" aria-label="upload picture"   title='select a photo' component="label">
              <input hidden accept="image/*" type="file" ref={inputElement4} onChange={handlePhoto4Change} />
              <PhotoCamera sx={{fontSize:{xs:30, md:40}}} /> 
            </IconButton>

            {!!progressPercentagePhoto4 && 
            (<>
                <LinearProgress variant="determinate" value={progressPercentagePhoto4}/>
                <Typography variant="body2" color="text.secondary">
                  {`${Math.round(progressPercentagePhoto4)}%`}
                </Typography> 
             </>
            )}

            {previewPhoto4 && <img src={previewPhoto4} alt='' style={{maxWidth: '100px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}
                
            <TextField name="Comment1Page4" value={Comment1Page4} onChange={handleChangeComment1Page4}  label="Comment" inputProps={{ maxLength: 199 }}/>

            <FormLabel id="select-a-photo">select a photo</FormLabel>
            <IconButton color="primary" aria-label="upload picture"   title='select a photo' component="label">
              <input hidden accept="image/*" type="file" ref={inputElement5} onChange={handlePhoto5Change} />
              <PhotoCamera sx={{fontSize:{xs:30, md:40}}} /> 
            </IconButton>

            {!!progressPercentagePhoto5 && 
            (<>
                <LinearProgress variant="determinate" value={progressPercentagePhoto5}/>
                <Typography variant="body2" color="text.secondary">
                  {`${Math.round(progressPercentagePhoto5)}%`}
                </Typography> 
             </>
            )}

            {previewPhoto5 && <img src={previewPhoto5} alt='' style={{maxWidth: '100px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}
                
            <TextField name="Comment2Page4" value={Comment2Page4} onChange={handleChangeComment2Page4} label="Comment" inputProps={{ maxLength: 199 }}/> 

            {/* */}


            <Typography variant="h6" color="text.secondary" sx={{pt:5}}>
              Page 5
            </Typography>

            <TextField name="Content1Page5" value={Content1Page5} onChange={handleChangeContent1Page5} label="Content" inputProps={{ maxLength: 101 }}/> 
                
            <FormLabel id="select-a-photo">select a photo</FormLabel>
            <IconButton color="primary" aria-label="upload picture"   title='select a photo' component="label">
              <input hidden accept="image/*" type="file" ref={inputElement6} onChange={handlePhoto6Change} />
              <PhotoCamera sx={{fontSize:{xs:30, md:40}}} /> 
            </IconButton>

            {!!progressPercentagePhoto6 && 
            (<>
                <LinearProgress variant="determinate" value={progressPercentagePhoto6}/>
                <Typography variant="body2" color="text.secondary">
                  {`${Math.round(progressPercentagePhoto6)}%`}
                </Typography> 
             </>
            )}

            {previewPhoto6 && <img src={previewPhoto6} alt='' style={{maxWidth: '100px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}
                
            <FormLabel id="select-a-video">select a video</FormLabel>
            <IconButton color="primary" aria-label="upload picture"   title='select a video' component="label">
              <input hidden accept="video/*" ref={inputElement7} type="file" onChange={handleFilesChange} />
              <VideoCameraBackIcon sx={{fontSize:{xs:30, md:40},}} />  
            </IconButton>
                
            {!!progressPercentage && 
            (<>
                <LinearProgress variant="determinate" value={progressPercentage}/>
                <Typography variant="body2" color="text.secondary">
                  {`${Math.round(progressPercentage)}%`}
                </Typography> 
             </>
            )}  
                
            {(!!files.length) &&  
            (<>
                {fileTypeCheck.imageFile && <img src={preview.imageURL} alt='' style={{maxWidth: '250px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}

                {fileTypeCheck.videoFile && <video controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                    loop 
                    src={preview.videoURL}
                ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></video>}
                

                {fileTypeCheck.audioFile && <audio controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                    loop 
                    src={preview.audioURL}
                ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></audio>}   
            </>)}

            <Typography variant='subtitle2' color='red'>{maxMinute}</Typography>
 
            
            <LoadingButton fullWidth size="large"  type="button"  title='Share' variant="contained" ref={triggerUploadClick} onClick={upload}> 
              Create
            </LoadingButton> 
              
          </Stack>
        </Card> 
      </Grid>
    </>
  );
}
