import PropTypes from 'prop-types';
import { subDays,  } from 'date-fns';
import { noCase } from 'change-case'; 
import { useEffect, useRef, useState, useMemo, memo } from 'react';  
import { useSelector  } from 'react-redux';  

// @mui
import {
  Box,
  List, 
  Button, 
  Avatar, 
  Divider, 
  Typography, 
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,   
} from '@mui/material';
 

// utils
import { fToNow } from '../../../../utils/formatTime';

// components 
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';

// util
import {useWindowDimensions} from '../../../../utils/otherFunctions';
import {reverseString, reverseArray, lowerCaseAllWordsExceptFirstLetters} from '../../../../utils/formatWord';
import {domainName} from '../../../../utils/domain';
 

// ----------------------------------------------------------------------
   
export default function AllDreams() { 
  const [numberNotifications, setNumberNotifications] = useState(0); 
  const notifications = useRef([]);  
 

  const { height } = useWindowDimensions();  

  const HEIGHTDesktop = `${height/3 *2}px`;  
  const HEIGHTMobile = `${height/2}px`; 

   

  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);

  let allDreamsValue = useMemo(() => [], []); 

  if(allDataOnceReducerValue.user){
    allDreamsValue = reverseArray(allDataOnceReducerValue.user.allDreams);     
  }
 

  useEffect(() => {    
      notifications.current = allDreamsValue;
      setNumberNotifications(notifications.current.length);
  }, [allDreamsValue]); 
 
 


  return (
    <> 

        <Scrollbar sx={{ height: { xs: HEIGHTMobile, sm: HEIGHTMobile, md:HEIGHTDesktop } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                What an amazing dream?  
              </ListSubheader>
            }
          >
            {notifications.current.map((notification, key) => (
              <NotificationItem key={key} notification={notification} />
            ))}
          </List> 
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            All Dreams ({numberNotifications})
          </Button>
        </Box> 
    </>
  );
}

// ----------------------------------------------------------------------


const NotificationItem = memo(({ notification }) => {
  const { Photo, NewFullName } = renderContent(notification);
 
  const DateCreatedAt = fToNow( `${subDays(new Date(`${reverseString(notification.DateSent)} ${notification.TimeSent}`), 0)}` );

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral', width:30, height:30 }}>{Photo}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={NewFullName}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.secondary',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {DateCreatedAt}
          </Typography>
        }
      />
    </ListItemButton>
  );
})


NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date), 
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    NewFullName: PropTypes.string,
    Content: PropTypes.string,
    TimeSent: PropTypes.string,
    DateSent: PropTypes.string,
    type: PropTypes.string,
    Photo: PropTypes.any,
  }),
};
 
// ----------------------------------------------------------------------

function renderContent(notification) {
  const NewFullName = (
    <Typography variant="subtitle2">
      <Typography component="span"  className="notranslate">
        {lowerCaseAllWordsExceptFirstLetters(notification.NewFullName)} 
      </Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
        &nbsp; {noCase(notification.Content)}
      </Typography>
    </Typography>
  );

  return {
    Photo: notification.Photo ? <img alt={notification.NewFullName} src={`${domainName}/EddyDreams/Home/images/${notification.Photo}`} /> : null,
    NewFullName,
  }; 
}
