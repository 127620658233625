
import { useState, useRef, useEffect} from 'react';  
import { useNavigate, } from 'react-router-dom';  
import { useCookies  } from 'react-cookie';  
import axios from 'axios';  
import { useSpeechRecognition } from "react-speech-recognition";
 
// mui 
import { Stack, Typography, Grid, TextField, Card, Divider, IconButton, LinearProgress, FormLabel   } from '@mui/material';  

import { LoadingButton } from '@mui/lab'; 
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';


import {isFileAnImage, isFileAnAudio, isFileAVideo, fileDuration_} from '../../../utils/otherFunctions';
  
import {domainName} from '../../../utils/domain';



import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, 
     } from '../../../utils/translateFunctions';
 

import { cloud117, cloud100, cloud101, removeSpaceBetweenString} from '../../../utils/automateCreateBusinessCardFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------

export default function CreateBusinessCard() {  
  const navigate = useNavigate();   

  const token = useRef(null); 
  const type = useRef(null);

  const [typeCurrent, setTypeCurrent] = useState('');
 


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type; 
      setTypeCurrent(type.current);
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 
  


  const textTitle = typeCurrent === 'Elderly People'? 'an Old School Card':
                    typeCurrent === 'Investor'? 'an investor card':
                    (typeCurrent === 'Organisation Owner' || 
                      typeCurrent === 'Shop Owner' || 
                      typeCurrent === 'Patient')? 'a business card':
                    typeCurrent === 'New Generation'? 'a universal card': 
                    typeCurrent === 'Pupil'? 'a school card': 
                    'a student card';  

  const labelProfession = typeCurrent === 'Elderly People'? 'What was your profession?':
                    typeCurrent === 'Investor'? 'Role':
                    (typeCurrent === 'Organisation Owner' || 
                      typeCurrent === 'Shop Owner')? 'Role':
                    typeCurrent === 'Patient'? 'What was your profession?':
                    typeCurrent === 'New Generation'? 'What is your profession?': 
                    typeCurrent === 'Pupil'? 'Level': 
                    'Level';  

  const labelInstitution = typeCurrent === 'Elderly People'? 'What company did you work for?':
                    typeCurrent === 'Investor'? 'Institution':
                    (typeCurrent === 'Organisation Owner' || 
                      typeCurrent === 'Shop Owner')? 'Institution':
                    typeCurrent === 'Patient'? 'What company did you work for?':
                    typeCurrent === 'New Generation'? 'What company are you working for?': 
                    typeCurrent === 'Pupil'? 'School': 
                    'College / University';  


 
  const [files, setFiles] = useState([]);    
  const [maxMinute, setMaxMinute] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);   
  const [preview, setPreview] = useState({imageURL:'', audioURL: '', videoURL: ''});
  const [fileTypeCheck, setFileTypeCheck] = useState({imageFile:false, audioFile:false, videoFile:false});
  
  const [professionOrLevel, setProfessionOrLevel] = useState(''); 
  const [website1, setWebsite1] = useState(''); 
  const [website2, setWebsite2] = useState(''); 
  const [phone, setPhone] = useState(''); 
  const [address, setAddress] = useState(''); 
  const [companyNameOrSchool, setCompanyNameOrSchool] = useState(''); 

  const handleChangeProfessionOrLevel = (e) => {   
    setProfessionOrLevel(e.target.value);

    setMaxMinute('');
  }
  const handleChangeWebsite1 = (e) => {
    setWebsite1(e.target.value);

    setMaxMinute('');
  }
  const handleChangeWebsite2 = (e) => {
    setWebsite2(e.target.value);

    setMaxMinute('');
  }
  const handleChangePhone = (e) => {
    setPhone(e.target.value);

    setMaxMinute('');
  }
  const handleChangeAddress = (e) => {
    setAddress(e.target.value);

    setMaxMinute('');
  }
  const handleChangeCompanyNameOrSchool = (e) => {
    setCompanyNameOrSchool(e.target.value);

    setMaxMinute('');
  }


  
  

  /*  Begin upload */
  let urlLocalComplete; 
  let urlLocalData;
  let urlLink2;
  let urlLink;
  let options;
  let EndPoints;
  let fileCategory;
  const urlLocalRequest = `${domainName}/u/create/business/card`;
  const TFile = [];
  let numberOfFiles = 0; 


  const generateUrlFile = (urlLink_) => {
    urlLink2 = `${urlLink_}${'-data'}`;
    urlLink = `${urlLink_}${'-complete'}`;

    return {
      urlLink2,
      urlLink
    };
  }


  const upload = async () => {  
    if(await (professionOrLevel).trim() === ''){
      setMaxMinute('The profession or Level is required');
      return;
    }  
    if(await (website1).trim() === ''){
      setMaxMinute('The website 1 is required');
      return;
    }  
    if(await (website2).trim() === ''){
      setMaxMinute('The website 2 is required');
      return;
    }  
    if(await (phone).trim() === ''){
      setMaxMinute('The phone is required');
      return;
    }  
    if(await (address).trim() === ''){
      setMaxMinute('The address is required');
      return;
    }  
    if(await (companyNameOrSchool).trim() === ''){
      setMaxMinute('The company name or school or university is required');
      return;
    }   

    if(await files.length === 0){
      setMaxMinute('Select a file');
      return;
    }
 
 

    TFile[0] = files;
    numberOfFiles = await files.length;

    if(fileTypeCheck.imageFile) {  
      fileCategory = 'images';
    } else 
    if(fileTypeCheck.audioFile) { 
      fileCategory = 'audios';
    } else 
    if (fileTypeCheck.videoFile) { 
      fileCategory = 'videos';
    }
 

    urlLink2 = await generateUrlFile(urlLocalRequest).urlLink2;

    urlLink = await generateUrlFile(urlLocalRequest).urlLink;

    urlLocalComplete = await urlLink;
    urlLocalData = await urlLink2;


    EndPoints = await {
      UPLOAD: urlLocalComplete,
      UPLOAD_DATA: urlLocalData,
      UPLOAD_REQUEST: urlLocalRequest
    }
 

    options = await {
      url: EndPoints.UPLOAD,
      startingByte: 0,
      // chunkSize: 100000,
      chunkSize: 1000000,
      fileId: '',
      arrayId: ''
    };
 

    uploadTrigger();  
  }


  const uploadTrigger = async () => {  
    const optionResult = await uploadCreateDirectory();

    const optionResultArrayIdLength = await optionResult.arrayId.length;
    const filesLength = await numberOfFiles;

    if (await (optionResultArrayIdLength > 0)) {
      for (let z = 0; z < filesLength; z+=1) {
        // const files_ = TFile[0].files[z];
        const files_ = (TFile[0])[z];
        if (files_) {   
          uploadExecution(files_, z);
        }
      }
    }
  }


  const uploadCreateDirectory = async () => {
    const professionOrLevel_ = await professionOrLevel;  
    const website1_ = await website1; 
    const website2_ = await website2; 
    const phone_ = await phone;
    const address_ = await address;
    const companyNameOrSchool_ = await companyNameOrSchool;

    const resp = await sendFile(numberOfFiles, fileCategory, token.current, type.current, EndPoints.UPLOAD_REQUEST);

    try {
      const jsonResponse = await resp.data; 
      const jsres = await jsonResponse.message;

      if (await (jsres === 'You have already created this card')) {
        await setMaxMinute(jsres);
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        };    
        return options;
      }   

      if (await (resp.status === 400)) {
        await console.error('An error occured in the directory');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      }  


      options = await {
        ...options,
        ...jsonResponse
      };

      const respData = await sendFileData(options.arrayId, professionOrLevel_, website1_, website2_, phone_, address_, companyNameOrSchool_, token.current, type.current, fileCategory, EndPoints.UPLOAD_DATA);
       
      if (await (respData.status === 400)) {
        await console.error('Missing file and content');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      } 

      return options; 
    } catch (err) {
      console.error('failed to read file: ', err);

      options = await {
        ...options,
        ...{
          arrayId: []
        }
      };
      return options;
    } 
  }


  const uploadFileChunks = async (file, options, i) => {
    let options_;

    if (await isFileAnImage(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          chunkSize: 1000
        }
      };
    } else
    if (await isFileAnAudio(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          chunkSize: 10000
        }
      };
    } else {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        }
      };
    }
    await sendFileChunks(file, options_.startingByte, options_.chunkSize, options_, i);

  }


  const sendFileChunks = async (file, startingByte, endingByte_, options_, i) => {
    const fileSize = await file.size;
    let endingByte = await endingByte_;

    const formData = await new FormData();
    const xhr = await new XMLHttpRequest();

    if (fileSize < endingByte) {
      endingByte = await fileSize;
    }
 

    if (endingByte < fileSize) {
      xhr.onreadystatechange = async () => {
        if (await (xhr.readyState === XMLHttpRequest.DONE)) {
          const percentageUploaded = await (endingByte / fileSize) * 100;  

          await setMaxMinute('Upload in progress');
          await setProgressPercentage(parseInt(percentageUploaded, 10)); 

          await sendFileChunks(file, startingByte + options_.chunkSize, startingByte + (options_.chunkSize * 2), options_, i);
        }
      }
    } else { 
      await setProgressPercentage(0);
 
      await setProfessionOrLevel(''); 
      await setWebsite1(''); 
      await setWebsite2(''); 
      await setPhone(''); 
      await setAddress(''); 
      await setCompanyNameOrSchool('');

      await setPreview({
        preview, 
        ...{
          imageURL: '', 
          audioURL: '', 
          videoURL: ''
        }
      });

      await setFiles([]);
      await setMaxMinute(''); 
    }


    const chunk = await file.slice(startingByte, endingByte);

    await formData.append('chunk', chunk, file.name);
    await formData.append('startingByte', startingByte);
    await formData.append('endingByte', endingByte);
    await formData.append('fileId', options_.fileId);
    await formData.append('X-fileCategory', fileCategory);

    await xhr.open('POST', options_.url, true);
    await xhr.setRequestHeader('X-File-Id', options_.fileId);
    await xhr.setRequestHeader('X-File-Size', fileSize);
    await xhr.setRequestHeader('X-startingByte', startingByte);
    await xhr.setRequestHeader('X-endingByte', endingByte);
    await xhr.setRequestHeader('X-fileCategory', fileCategory);

    await xhr.send(formData);
  }


  const sendFile = (numberOfFiles_, fileCategory_, token_, type_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        numberOfFiles: numberOfFiles_, 
        fileCategory: fileCategory_,
        token: token_,
        type: type_, 
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }
   

  const sendFileData = (fileData, professionOrLevel_, website1_, website2_, phone_, address_, companyNameOrSchool_, token_, type_, fileCategory_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        fileData_: fileData,
        Profession_Or_Level: professionOrLevel_,
        Website1: website1_,
        Website2: website2_,
        Phone: phone_,
        Address: address_,
        CompanyName_Or_School: companyNameOrSchool_,
        token: token_,
        type: type_, 
        fileCategory: fileCategory_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }



  const uploadExecution = async (files_, i) => {
    let newFile;

    if (await isFileAnImage(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.jpg');
    } else 
    if (await isFileAnAudio(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.wav');
    } else {
      newFile = await renameFile(files_, 'planetryvilage.webm');
    }

    if (!newFile) {
      await console.log("You must first select the file to upload it");
    } else {
      try {
        await uploadFileChunks(newFile, options, i);
      } catch (err) {
        console.error('failed to read file: ', err);
      }
    }
  }


  function renameFile (originalFile_, newName_) {
    return new File([originalFile_], newName_, {
      type: originalFile_.type,
      lastModified: originalFile_.lastModified,
    });
  }
  /*  End upload */



  const resetAllFile = () => {
  	setFileTypeCheck({
  		fileTypeCheck, 
  		...{
  			imageFile:false, 
  			audioFile:false, 
  			videoFile:false
  		}
  	});

  	setPreview({
  		preview, 
  		...{
  			imageURL: '', 
  			audioURL: '', 
  			videoURL: ''
  		}
  	});

  	setFiles([]);
  	setMaxMinute('Max 2 minutes');
  }


 

  // check if a file is an image or an audio or a video
  const fileChecker = async (file) => {
  	if (await isFileAnImage(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:true, 
  				audioFile:false, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: URL.createObjectURL(file), 
  				audioURL: '', 
  				videoURL: ''
  			}
  		});
  	} else 
  	if(await isFileAnAudio(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:true, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: URL.createObjectURL(file), 
  				videoURL: ''
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else
  	if(await isFileAVideo(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:false, 
  				videoFile:true
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: '', 
  				videoURL: URL.createObjectURL(file)
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else{
  		// the file is nor an image nor an audio nor a video.  Reset everything
  		resetAllFile();
  	}
  }
   
  const handleFilesChange = (e) => {
   	if (e.target.files) {
      setFiles(e.target.files); 
      fileChecker(e.target.files[0]); 
    }   
  }; 





  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
   
  const inputElement = useRef();  
  const triggerUploadClick = useRef(); 

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) { 
      let profession; let website1; let website2; let phone; let address; let company;

        let  tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  
          const removeFirstElementFromString_ = await removeFirstElementFromString(t);  
          const firstElementOfString2_ = await firstElementOfString(removeFirstElementFromString_); 
 
          
          /* add the profession */
          if (await stringComparison([cloud117.a, cloud117.a2, cloud117.a3, cloud117.a4, cloud117.a5], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            profession = await tm;  
            await setProfessionOrLevel(profession); 

            speech.text = await transMany__('The profession has been updated') || 'The profession has been updated';
            speechSynth.speak(speech); 
          }  

          
          /* add website 1 */
          if (await stringComparison([cloud117.b, cloud117.c, cloud117.c2], `${firstElementOfString_}${' '}${firstElementOfString2_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}`;
            const a = await transMany__(firstElementOfString_) || firstElementOfString_;

            if (await (a.match(/(\w+)/g).length === 1)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)))));
            }

            website1 = await removeSpaceBetweenString(tm);
            await setWebsite1(website1);

            speech.text = await transMany__('The website one has been updated') || 'The website one has been updated';
            speechSynth.speak(speech);
          }

          
          /* add website 2 */
          if (await stringComparison([cloud117.d, cloud117.e, cloud117.f, cloud117.f2], `${firstElementOfString_}${' '}${firstElementOfString2_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}`;
            const a = await transMany__(firstElementOfString_) || firstElementOfString_;

            /* Count number of words in a string */
            if (await (a.match(/(\w+)/g).length === 1)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)))));
            }

            website2 = await removeSpaceBetweenString(tm);
            await setWebsite2(website2);

            speech.text = await transMany__('The website two has been updated') || 'The website two has been updated';
            speechSynth.speak(speech);
          } 

          
          /* add phone number */
          if (await stringComparison([cloud117.g, cloud117.g2, cloud117.g3, cloud117.g4, cloud117.g5, cloud117.g6], firstElementOfString_)) {
            t = await firstElementOfString_;
            phone = await removeSpaceBetweenString(removeFirstElementFromString_);  
            await setPhone(phone);

            speech.text = await transMany__('The phone number has been updated') || 'The phone number has been updated';
            speechSynth.speak(speech);
          } 


          /* add address */
          if (await stringComparison([cloud117.i, cloud117.i2, cloud117.i3], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            address = await tm;
            await setAddress(address);

            speech.text = await transMany__('The address has been updated') || 'The address has been updated';
            speechSynth.speak(speech);
          } 
          

          /* add company or school */
          if (await stringComparison([cloud117.h, cloud117.h2, cloud117.h3, cloud117.h4, cloud117.h5], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            company = await tm;
            await setCompanyNameOrSchool(company);

            speech.text = await transMany__('The institution has been updated') || 'The institution has been updated';
            speechSynth.speak(speech);
          }  

          /* select a file */
          if (await stringComparison([cloud100.a, cloud100.b, cloud100.c, cloud100.d, cloud100.e, cloud100.f, cloud100.g, cloud100.h, cloud100.i], t)) {
            await inputElement.current.click();  

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech);
          }

          
          /* trigger the creation of a business card */
          if (await stringComparison([cloud101.a, cloud101.b, cloud101.c, cloud101.d, cloud101.e, cloud101.f, cloud101.g, cloud101.h, cloud101.i], t)) {
            await triggerUploadClick.current.click();

            speech.text = await transMany__('The save has been triggered') || 'The save has been triggered';
            speechSynth.speak(speech);
          } 
    
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate]);

 
  /* End Michael */  

 
 
  return (
    <>   
      <Grid  item xs={12} sm={12} md={8} lg={6}>
        <Card sx={{borderRadius:{xs:0, sm:0, md:'10px'}}}>
          <Stack spacing={1} sx={{p:1, textAlign:'center', justifyContent:'center'}}>
            <Typography variant="string">
              Create {textTitle} 
            </Typography>

            <Divider/> 

            <TextField name="Profession_Or_Level"  value={professionOrLevel} onChange={handleChangeProfessionOrLevel} label={labelProfession} inputProps={{ maxLength: 33 }} /> 

            <TextField name="Website1" value={website1} onChange={handleChangeWebsite1}  label="Website 1" inputProps={{ maxLength: 37 }} /> 

            <TextField name="Website2" value={website2} onChange={handleChangeWebsite2}  label="Website 2" inputProps={{ maxLength: 37 }} /> 

            <TextField name="Phone" value={phone} onChange={handleChangePhone}  label="Phone" inputProps={{ maxLength: 14 }} /> 

            <TextField name="Address" value={address} onChange={handleChangeAddress}  label="Address" inputProps={{ maxLength: 30 }} /> 

            <TextField name="CompanyName_Or_School" value={companyNameOrSchool} onChange={handleChangeCompanyNameOrSchool}  label={labelInstitution} inputProps={{ maxLength: 33 }} /> 
                

            <FormLabel id="selct-a-video">select a video</FormLabel>
            <IconButton color="primary" aria-label="upload picture"   title='select a video' component="label">
              <input hidden accept="video/*" type="file" onChange={handleFilesChange} ref={inputElement} />
              <VideoCameraBackIcon sx={{fontSize:{xs:30, md:40},}} /> 
            </IconButton>
                
            {!!progressPercentage && 
            (<>
              <LinearProgress variant="determinate" value={progressPercentage}/>
              <Typography variant="body2" color="text.secondary">
                {`${Math.round(progressPercentage)}%`}
              </Typography> 
             </>
            )} 
                
            {(!!files.length) &&  
            (<>
              {fileTypeCheck.imageFile && <img src={preview.imageURL} alt='' style={{maxWidth: '250px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}

              {fileTypeCheck.videoFile && <video controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                loop 
                src={preview.videoURL}
              ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></video>}
                

              {fileTypeCheck.audioFile && <audio controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                loop 
                src={preview.audioURL}
              ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></audio>}    
            </>)}
            
            <Typography variant='subtitle2' color='red'>{maxMinute}</Typography>
            
            <LoadingButton fullWidth size="large"  type="button"  title='Share' variant="contained" ref={triggerUploadClick} onClick={upload}> 
              Create
            </LoadingButton> 
              
          </Stack>
        </Card> 
      </Grid>
    </>
  );
}
