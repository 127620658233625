  
import { useState, useRef, useEffect} from 'react';  
import { useNavigate, useParams } from 'react-router-dom';  
import { useCookies  } from 'react-cookie';  
import axios from 'axios'; 

// mui
import { Stack, Typography, Grid, TextField, Card, Divider, IconButton, LinearProgress, FormLabel   } from '@mui/material';  

import { LoadingButton } from '@mui/lab'; 
// import PhotoCamera from '@mui/icons-material/PhotoCamera'; 
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
 


import {isFileAnImage, isFileAnAudio, isFileAVideo, fileDuration_} from '../../../../utils/otherFunctions';


import {domainName} from '../../../../utils/domain';
  
// ----------------------------------------------------------------------

export default function CreateVideoAudioPhotoVillage() {    
  const navigate = useNavigate();  
  const { _villageIdentifier } = useParams();  

  const token = useRef(null); 
  const type = useRef(null);


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 


  const [files, setFiles] = useState([]);  
  const [maxMinute, setMaxMinute] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);   
  const [preview, setPreview] = useState({imageURL:'', audioURL: '', videoURL: ''});
  const [fileTypeCheck, setFileTypeCheck] = useState({imageFile:false, audioFile:false, videoFile:false}); 
  const [message, setMessage] = useState(''); 

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);

    setMaxMinute('');
  }
  



  /*  Begin upload */
  let urlLocalComplete; 
  let urlLocalData;
  let urlLink2;
  let urlLink;
  let options;
  let EndPoints;
  let fileCategory;
  const urlLocalRequest = `${domainName}/u/upload/my/village/photo/video/audio`;
  const TFile = [];
  let numberOfFiles = 0; 


  const generateUrlFile = (urlLink_) => {
    urlLink2 = `${urlLink_}${'-data'}`;
    urlLink = `${urlLink_}${'-complete'}`;

    return {
      urlLink2,
      urlLink
    };
  }


  const upload = async () => {  
    if(await (message).trim() === ''){
      setMaxMinute('The description is required');
      return;
    }  

    if(await files.length === 0){
      setMaxMinute('Select a file');
      return;
    }
 
 

    TFile[0] = files;
    numberOfFiles = await files.length;

    if(fileTypeCheck.imageFile) {  
      fileCategory = 'images';
    } else 
    if(fileTypeCheck.audioFile) { 
      fileCategory = 'audios';
    } else 
    if (fileTypeCheck.videoFile) { 
      fileCategory = 'videos';
    }
 

    urlLink2 = await generateUrlFile(urlLocalRequest).urlLink2;

    urlLink = await generateUrlFile(urlLocalRequest).urlLink;

    urlLocalComplete = await urlLink;
    urlLocalData = await urlLink2;


    EndPoints = await {
      UPLOAD: urlLocalComplete,
      UPLOAD_DATA: urlLocalData,
      UPLOAD_REQUEST: urlLocalRequest
    }
 

    options = await {
      url: EndPoints.UPLOAD,
      startingByte: 0,
      chunkSize: 100000,
      fileId: '',
      arrayId: ''
    };
 

    uploadTrigger();  
  }


  const uploadTrigger = async () => {  
    const optionResult = await uploadCreateDirectory();

    const optionResultArrayIdLength = await optionResult.arrayId.length;
    const filesLength = await numberOfFiles;

    if (await (optionResultArrayIdLength > 0)) {
      for (let z = 0; z < filesLength; z+=1) {
        // const files_ = TFile[0].files[z];
        const files_ = (TFile[0])[z];
        if (files_) {   
          uploadExecution(files_, z);
        }
      }
    }
  }

   

  const uploadCreateDirectory = async () => {
    const message_ = await message; 
    const sessionIdentifier = await _villageIdentifier;

    const resp = await sendFile(numberOfFiles, fileCategory, EndPoints.UPLOAD_REQUEST);

    try {
      const jsonResponse = await resp.data;   

      if (await (resp.status === 400)) {
        await console.log('An error occured in the directory');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      }  


      options = await {
        ...options,
        ...jsonResponse
      };

      const respData = await sendFileData(options.arrayId, message_, fileCategory, sessionIdentifier, token.current, type.current, EndPoints.UPLOAD_DATA);
       

      if (await (respData.status === 400)) {
        await console.log('Missing file and content');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      } 

      return options; 
    } catch (err) {
      console.error('failed to read file: ', err); 
      setMaxMinute('You are not authorized! Warning');

      options = await {
        ...options,
        ...{
          arrayId: []
        }
      };
      return options;
    } 
  }


  const uploadFileChunks = async (file, options, i) => {
    let options_;

    if (await isFileAnImage(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          chunkSize: 1000
        }
      };
    } else
    if (await isFileAnAudio(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          chunkSize: 10000
        }
      };
    } else {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        }
      };
    }
    await sendFileChunks(file, options_.startingByte, options_.chunkSize, options_, i);

  }


  const sendFileChunks = async (file, startingByte, endingByte_, options_, i) => {
    const fileSize = await file.size;
    let endingByte = await endingByte_;

    const formData = await new FormData();
    const xhr = await new XMLHttpRequest();

    if (fileSize < endingByte) {
      endingByte = await fileSize;
    }
 

    if (endingByte < fileSize) {
      xhr.onreadystatechange = async () => {
        if (await (xhr.readyState === XMLHttpRequest.DONE)) {
          const percentageUploaded = await (endingByte / fileSize) * 100;  

          await setMaxMinute('Upload in progress');
          await setProgressPercentage(parseInt(percentageUploaded, 10)); 

          await sendFileChunks(file, startingByte + options_.chunkSize, startingByte + (options_.chunkSize * 2), options_, i);
        }
      }
    } else { 
      await setProgressPercentage(0);

      await setMessage('');

      await setPreview({
        preview, 
        ...{
          imageURL: '', 
          audioURL: '', 
          videoURL: ''
        }
      });

      await setFiles([]);
      await setMaxMinute(''); 
    }


    const chunk = await file.slice(startingByte, endingByte);

    await formData.append('chunk', chunk, file.name);
    await formData.append('startingByte', startingByte);
    await formData.append('endingByte', endingByte);
    await formData.append('fileId', options_.fileId);
    await formData.append('X-fileCategory', fileCategory);

    await xhr.open('POST', options_.url, true);
    await xhr.setRequestHeader('X-File-Id', options_.fileId);
    await xhr.setRequestHeader('X-File-Size', fileSize);
    await xhr.setRequestHeader('X-startingByte', startingByte);
    await xhr.setRequestHeader('X-endingByte', endingByte);
    await xhr.setRequestHeader('X-fileCategory', fileCategory);

    await xhr.send(formData);
  }


  const sendFile = (numberOfFiles_, fileCategory_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        numberOfFiles: numberOfFiles_, 
        fileCategory: fileCategory_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }
  

  const sendFileData = (fileData, message_, fileCategory_, sessionIdentifier_, token_, type_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        fileData_: fileData,
        Description: message_,
        token: token_,
        type: type_, 
        sessionIdentifier: sessionIdentifier_,
        fileCategory: fileCategory_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }



  const uploadExecution = async (files_, i) => {
    let newFile;

    if (await isFileAnImage(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.jpg');
    } else 
    if (await isFileAnAudio(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.wav');
    } else {
      newFile = await renameFile(files_, 'planetryvilage.webm');
    }

    if (!newFile) {
      await console.log("You must first select the file to upload it");
    } else {
      try {
        await uploadFileChunks(newFile, options, i);
      } catch (err) {
        console.error('failed to read file: ', err);
      }
    }
  }


  function renameFile (originalFile_, newName_) {
    return new File([originalFile_], newName_, {
      type: originalFile_.type,
      lastModified: originalFile_.lastModified,
    });
  }
  /*  End upload */




  const resetAllFile = () => {
  	setFileTypeCheck({
  		fileTypeCheck, 
  		...{
  			imageFile:false, 
  			audioFile:false, 
  			videoFile:false
  		}
  	});

  	setPreview({
  		preview, 
  		...{
  			imageURL: '', 
  			audioURL: '', 
  			videoURL: ''
  		}
  	});

  	setFiles([]);
  	setMaxMinute('Max 2 minutes');
  }


 

  // check if a file is an image or an audio or a video
  const fileChecker = async (file) => {
  	if (await isFileAnImage(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:true, 
  				audioFile:false, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: URL.createObjectURL(file), 
  				audioURL: '', 
  				videoURL: ''
  			}
  		});
        
        // I added this because the user is not authorized to upload a photo in a village
  		resetAllFile();
  	} else 
  	if(await isFileAnAudio(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:true, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: URL.createObjectURL(file), 
  				videoURL: ''
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else
  	if(await isFileAVideo(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:false, 
  				videoFile:true
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: '', 
  				videoURL: URL.createObjectURL(file)
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else{
  		// the file is nor an image nor an audio nor a video.  Reset everything
  		resetAllFile();
  	}
  }
   
  const handleFilesChange = (e) => {
   	if (e.target.files) {
      setFiles(e.target.files); 
      fileChecker(e.target.files[0]);  
    }   
  }; 
 
  
  return (
    <>   
      <Grid  item xs={12} sm={12} md={8} lg={6}>
        <Card >
          <Stack spacing={1} sx={{p:1, textAlign:'center', justifyContent:'center'}}>
            <Typography variant="string">
              Upload a video / audio  
            </Typography>

            <Divider/> 
 

            <TextField name="Description" value={message} onChange={handleChangeMessage} label="Description" />

            <FormLabel id="selct-a-file">select a file</FormLabel>
            <IconButton color="primary" aria-label="upload picture video audio"  title='select a video or audio' component="label">
              <input hidden accept="*" type="file" onChange={handleFilesChange} />
              {/* <PhotoCamera sx={{fontSize:{xs:30, md:40}}} /> */}
              <VideoCameraBackIcon sx={{fontSize:{xs:30, md:40}}} /> 
              <AudiotrackIcon sx={{fontSize:{xs:30, md:40}}} /> 
            </IconButton>
                
            {!!progressPercentage && 
            ( <>
                <LinearProgress variant="determinate" value={progressPercentage}/>
                <Typography variant="body2" color="text.secondary">
                  {`${Math.round(progressPercentage)}%`}
                </Typography> 
              </>
            )} 
                
            {(!!files.length) &&  
            (<>
              {fileTypeCheck.imageFile && <img src={preview.imageURL} alt='' style={{maxWidth: '250px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}

              {fileTypeCheck.videoFile && <video controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                loop 
                src={preview.videoURL}
              ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></video>}
                

              {fileTypeCheck.audioFile && <audio controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                loop 
                src={preview.audioURL}
              ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></audio>}              
            </>)}
            

            <Typography variant='subtitle2' color='red'>{maxMinute}</Typography>
            
            <LoadingButton fullWidth size="large"  type="button"  title='Share' variant="contained"  onClick={upload}> 
              Upload
            </LoadingButton> 
              
          </Stack>
          </Card> 
        </Grid>
    </>
  );
}
