import {useEffect, useRef, memo, } from 'react';
import { useNavigate,  } from 'react-router-dom'; 
import { Card, CardHeader, CardContent, CardActions, Avatar, IconButton, Typography, } from '@mui/material';
     
import { grey } from '@mui/material/colors';
   
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat'; 
 
import PropTypes from 'prop-types';
 
 

// utils
import { fShortenNumber } from '../../../../utils/formatNumber'; 
import {lowerCaseAllWordsExceptFirstLetters} from '../../../../utils/formatWord';

import {playCurrentVideoPauseOther, playCurrentAudioPauseOther, } from '../../../../utils/otherFunctions';
 
 


import {domainName} from '../../../../utils/domain';
 

 

// ----------------------------------------------------------------------------



export const AudioOnly = memo(({keyProp, cardItem}) => {   
  const navigate = useNavigate();   
  // const { height } = useWindowDimensions();
  // const isDesktop = useResponsive('up', 'lg'); 
  
  // const HEIGHT = 350;
  // const HEIGHT = isDesktop? height*2/3 : height*2/3;
  // const HEIGHT = isDesktop? height/4 : height*2/3; 
  
  const {
    Description, 
    categoryDivision,
    DateCreated, 
    NumberLikes, 
    NumberViews, 
    NumberComments, 
    fileTypeDivision, 
    VideoAudioPhoto, 
    Photo,
    NewFullName, 
    uservideoaudiophotoId
  } = cardItem;

  
  const titleTV = fileTypeDivision === "Video" ? `${lowerCaseAllWordsExceptFirstLetters(NewFullName)} ${'TV'}`:
                  fileTypeDivision === "Audio" ? `${lowerCaseAllWordsExceptFirstLetters(NewFullName)} ${'Radio'}`:
                  `${lowerCaseAllWordsExceptFirstLetters(NewFullName)}`;


  const viewSingleFile = () => {
    navigate(`/audios/${uservideoaudiophotoId}`, { replace: true });
  }

  const handleVideoAudio = async () => {  
    await playCurrentVideoPauseOther();
    await playCurrentAudioPauseOther();
  }; 


  const fileId = useRef();

  const playFileOnTouch = ()=> {
    fileId.current.play();  
  }
  
  
  useEffect(() => {
    handleVideoAudio(); 
  });


  
  /* beginning define an observer to make the video load progressively */
  /* const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting)
  } */

  const callbackFunction = () => { 
  }
  
  const options = useRef({});

  options.current = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  }

  useEffect(() => {
    const fileIdx = fileId.current
    const lazyVideoObserver = new IntersectionObserver(callbackFunction, options.current);
    if(fileIdx) lazyVideoObserver.observe(fileIdx);

    return () => {
      if(fileIdx) lazyVideoObserver.unobserve(fileIdx);
    }
  }, [fileId, options]);
  /* end define an observer to make the video load progressively */


   

  

  return (
    <Card className="showItem" sx={{ maxWidth: 680, mb:1, borderRadius:{xs:0, sm:1, md:1}, p:0, m:'auto'  }} onClick={viewSingleFile}> 
       
      {/* <audio ref={fileId}  controls controlsList="nodownload" style={{width: '100%'}}
        className={`${"play track "}${keyProp}`}
        onClick={handleVideoAudio} 
        onTouchStart={playFileOnTouch}
        onMouseOver={playFileOnTouch}
        onFocus={ () => null }  preload="none"

        loop 
        src={`${domainName}/EddyDreams/otherFiles/audios/${VideoAudioPhoto}`}
      ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></audio> */}

      <audio ref={fileId} controls controlsList="nodownload" style={{width: '100%'}} 
        className={`${"play track "}${keyProp}`}
        onClick={handleVideoAudio} 
        onTouchStart={playFileOnTouch}
        onMouseOver={playFileOnTouch}
        onFocus={ () => null }  preload="none" autoPlay loop 
         
      >
        <source src={`${domainName}/EddyDreams/otherFiles/audios/${VideoAudioPhoto}`} type="audio/webm"/>
        <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/>
      </audio>
   



      <CardActions sx={{justifyContent:'center'}}>
        <IconButton aria-label="add to favorites" sx={{fontSize:{xs:13, md:15}, color:"black", }} >
          {fShortenNumber(NumberLikes)} <FavoriteBorderIcon sx={{fontSize:{xs:20, md:25}, }} />
        </IconButton>
        <IconButton aria-label="views" sx={{fontSize:{xs:13, md:15}, color:"black"}}>
          {fShortenNumber(NumberViews)} <VisibilityIcon  sx={{fontSize:{xs:20, md:25}, color:"black"}}/>
        </IconButton> 

        <IconButton aria-label="comment" sx={{fontSize:{xs:13, md:15}, color:"black"}}  >
          {fShortenNumber(NumberComments)} <ChatIcon  sx={{fontSize:{xs:20, md:25}, color:"black"}}/>
        </IconButton>  
      </CardActions> 

       
      <CardHeader  sx={{pt:0}}
        avatar={
          <Avatar sx={{ bgcolor: grey[500] }} alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} /> 
        } 

        titleTypographyProps={{
          fontSize: 15,
          noWrap: true
        }}

        classes={{
          title: "notranslate"
        }}

        title={titleTV}         
        subheader={DateCreated}

      /> 
    
      <CardContent>
        <Typography variant="body1" color="black" noWrap>
          {categoryDivision}{': '}{Description}
        </Typography>
      </CardContent>
       
    </Card>
  );
})


 
AudioOnly.propTypes = {
  cardItem: PropTypes.object,
  keyProp: PropTypes.any,
};
 