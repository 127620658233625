import PropTypes from 'prop-types';
import { subDays } from 'date-fns';
import { noCase } from 'change-case'; 
import { useState, useRef, memo } from 'react';
import { useSelector  } from 'react-redux';
import { useNavigate, } from 'react-router-dom'; 
import axios from 'axios'; 

// @mui
import {
  Box,
  List,
  Badge,
  Button, 
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton, Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// util
import {useWindowDimensions} from '../../../utils/otherFunctions';

import {reverseString, lowerCaseAllWordsExceptFirstLetters} from '../../../utils/formatWord'; 

import {domainName} from '../../../utils/domain';
// ----------------------------------------------------------------------


const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999, 
  right: 4,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(40),
  height: theme.spacing(5),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary, 
  backgroundColor: theme.palette.background.paper,
  // borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  // borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderRadius: 50,
  width:40,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));
 

export default function NotificationsWidget() {
  // const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const [notifications, setNotifications] = useState([]);

  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;
  const totalUnRead = notifications.filter((item) => item.Unread === 1).length;


  const { height } = useWindowDimensions();  

  const HEIGHTDesktop = `${height/3 *2}px`;  
  const HEIGHTMobile = `${height/2}px`; 
  

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        Unread: 0,
      }))
    );
  };


  // --------------

  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);
 
  const i = useRef(0);
  if(i.current === 0 && allDataOnceReducerValue.user){
    const {notificationsUser} = allDataOnceReducerValue.user;      
    
    setNotifications(notificationsUser);

    i.current +=1;
  }
  
  // ------------------

  return (
    <>
      {/* <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>   eva:bell-fill */}

      <StyledRoot onClick={handleOpen}> 
        <Tooltip title="Notifications"> 
         <IconButton color={open ? 'default' : 'primary'} className="notification" onClick={handleOpen}>
          <Badge badgeContent={totalUnRead} color="primary">
            <Iconify icon="gridicons:bell" color='blue' width='60' />
          </Badge>
         </IconButton> 
        </Tooltip>
      </StyledRoot>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75, 
            width: 360, 
          },
        }}
      > 
        <Grid container spacing={0} textAlign='right'> 
          <Grid  item xs={12} sm={12} md={12}>
            <IconButton color="default" className="close-notification" onClick={handleClose}>
              <Iconify icon="material-symbols:close" />
            </IconButton>  
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', alignItems: 'center', px: 2.5, }}> 
          <Box sx={{ flexGrow: 1}}> 
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
  
          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )} 
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ maxHeight: { xs: HEIGHTMobile, sm: HEIGHTMobile, md:HEIGHTDesktop } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications.map((notification) => (
              <NotificationItem key={notification.postsId} notification={notification}  />
            ))}
          </List> 
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box>
      </Popover>
    </>
  );
}
 

// ----------------------------------------------------------------------


const NotificationItem = memo(({ notification }) => {   
  const navigate = useNavigate();  
  const { Photo, NewFullName } = renderContent(notification); 
 
  const DateCreatedAt = fToNow( `${subDays(new Date(`${reverseString(notification.DateSent)} ${notification.TimeSent}`), 0)}` );
  


  /* Beginning Mark read a notification */
  const urlLocalRequest = `${domainName}/u/mark/read/new/posts/user`;

  const markReadAFile = async (postsId, url1, url2) => {
    if(await (url2 === '')){ 
      const photoVideoPhotoId = await (url1.split('&'))[1];
      await sendToServerAndNavigateToFile(postsId, photoVideoPhotoId);
    }

    if(await (url1 === '')){
      const photoVideoPhotoId = await (url2.split('&'))[1];
      await sendToServerAndNavigateToFile(postsId, photoVideoPhotoId);
    }
  }
 

  const sendToServerAndNavigateToFile = async (postsId, photoVideoPhotoId) => {   
    const postsId_ = await postsId; 
    const photoVideoPhotoId_ = await photoVideoPhotoId;

    await sendFileData(postsId_, urlLocalRequest);
 
    /* const resp = await sendFileData(postsId_, urlLocalRequest);
    const jsonResponse = await resp.data; 
    const jsres = await jsonResponse.message; */ 

    await navigate(`/home/${photoVideoPhotoId_}`, { replace: true }); 
  } 


  const sendFileData = (postsId_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {  
        postsId: postsId_, 
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  } 
  /* End Mark read a notification */


  return (
    <ListItemButton   
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.Unread && {
          bgcolor: 'action.selected',
        }),
      }}
      className="notify"
      onClick={() => {markReadAFile(notification.postsId, notification.url1, notification.url2)}}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral', width:30, height:30 }} >{Photo}</Avatar>
      </ListItemAvatar>
      <ListItemText 
        primary={NewFullName}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.secondary',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {DateCreatedAt}
          </Typography>
        }
      />
    </ListItemButton>
  );
})


NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    postsId: PropTypes.number,
    Unread: PropTypes.number,
    NewFullName: PropTypes.string,
    Description: PropTypes.string, 
    TimeSent: PropTypes.string,
    DateSent: PropTypes.string,
    Photo: PropTypes.any,
    url1: PropTypes.any,
    url2: PropTypes.any,
  }),
};


// ----------------------------------------------------------------------
function renderContent(notification) {
  const NewFullName = (
    <Typography variant="subtitle2">
      <Typography component="span"  className="notranslate">
        {lowerCaseAllWordsExceptFirstLetters(notification.NewFullName)}
      </Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.primary' }} >
        &nbsp; {noCase(notification.Description)}
      </Typography>
    </Typography>
  );

  return {
    Photo: notification.Photo ? <img alt={notification.NewFullName} src={`${domainName}/EddyDreams/Home/images/${notification.Photo}`} /> : null,
    NewFullName,
  }; 
}
 