 
import { Helmet } from 'react-helmet-async';  
// mui
import { Container, Grid } from '@mui/material';  


// sections 
import {  
  CreateEventCard, 
} from '../../sections/@users/createAllFilesUser';


// ----------------------------------------------------------------------

export default function CreateEventCardPage() { 

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Create </title>
      </Helmet>
      
      <Container maxWidth="xl"  sx={{p:0}}>

        <Grid container spacing={1} sx={{color: 'black', textAlign:'center', justifyContent:'center'}}>  
          <CreateEventCard /> 
        </Grid>

        
      </Container> 
    </>
  );
}
