

// ----------------------------------------------------------------------

const monthBackgroundDetails = [
  {
  	id: 1, 
    back: 'month.gif'
   },  

  {
  	id: 2, 
    back: 'monthb.gif'
   },  
   
  {
  	id: 3, 
    back: 'monthc.gif'
   },  
   
  {
  	id: 4, 
    back: 'monthd.gif'
   },  
   
  {
  	id: 5, 
    back: 'monthe.gif'
   },  
   
  {
  	id: 6, 
    back: 'monthf.gif'
   },  
   
  {
  	id: 7, 
    back: 'monthg.gif'
   },  
   
  {
  	id: 8, 
    back: 'monthh.gif'
   },  
   
  {
  	id: 9, 
    back: 'monthi.gif'
   },  
   
  {
  	id: 10, 
    back: 'monthj.gif'
   },  
   
  {
  	id: 11, 
    back: 'month1.jpg'
   },  
   
  {
  	id: 12, 
    back: 'month2.jpg'
   },  
   
  {
  	id: 13, 
    back: 'month3.jpg'
   },  
   
  {
  	id: 14, 
    back: 'month4.jpg'
   },  
   
  {
  	id: 15, 
    back: 'month5.jpg'
   },  
   
  {
  	id: 16, 
    back: 'month6.jpg'
   },  
   
  {
  	id: 17, 
    back: 'month7.jpg'
   },  
   
  {
  	id: 18, 
    back: 'month8.jpg'
   },  
   
  {
  	id: 19, 
    back: 'month9.jpg'
   },  
   
  {
  	id: 20, 
    back: 'month10.jpg'
   },   
   
  {
  	id: 21, 
    back: 'month11.jpg'
   },   
   
  {
  	id: 22, 
    back: 'month12.jpg'
   },   
   
  {
  	id: 23, 
    back: 'month13.jpg'
   },   
   
  {
  	id: 24, 
    back: 'month14.jpg'
   },   
   
  {
  	id: 25, 
    back: 'month15.jpg'
   },   
   
  {
  	id: 26, 
    back: 'month16.jpg'
   },   
   
  {
  	id: 27, 
    back: 'month17.jpg'
   },   
   
  {
  	id: 28, 
    back: 'month18.jpg'
   },   
   
  {
  	id: 29, 
    back: 'month19.jpg'
   },   
   
  {
  	id: 30, 
    back: 'month20.jpg'
   },   
 
];

export default monthBackgroundDetails;
