import SpecificCompetitionActionTypes from './specificCompetitionTypes'


export const SpecificCompetitionREQUEST = (item) => ({
    type: SpecificCompetitionActionTypes.Specific_Competition_REQUEST,
    payload: item
}) 


export const SpecificCompetitionRESET = item => ({
    type: SpecificCompetitionActionTypes.Specific_Competition_RESET,
    payload: item
}) 