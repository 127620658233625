 
import { Helmet } from 'react-helmet-async';  
// mui
import { Container, } from '@mui/material';   


// sections 
import {  
  PlaySongWithMichael, 
} from '../../sections/@users/homeUser';

 
// ----------------------------------------------------------------------

export default function PlaySongWithMichaelPage() {   


  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Play a song </title>
      </Helmet>
      
      <Container maxWidth="xl"  sx={{p:0, }}> 

        <PlaySongWithMichael/>
        
      </Container> 
    </>
  );
}
