 
import { useState, useRef, useEffect, } from 'react'; 
import { Helmet } from 'react-helmet-async';  
import { useCookies  } from 'react-cookie'; 
import { useNavigate,  } from 'react-router-dom'; 
import { useDispatch, useSelector  } from 'react-redux';  
import { useSpeechRecognition } from "react-speech-recognition"; 

// mui 
import { Container, Grid, InputAdornment, IconButton, Autocomplete, Popper, TextField, } from '@mui/material';  

import { styled } from '@mui/material/styles';


import { LoadingButton } from '@mui/lab';

import BubblePreloader from 'react-bubble-preloader';
  

 
// components  
import Iconify from '../../components/iconify';


// sections 
import {  
  PhotoOnly, PhotoOnlyPub, HomeWidget, NotificationsWidget, AccountWidget, TopMenu,  SideScreen, 
} from '../../sections/@users/homeUser';


// hooks
import useResponsive from '../../hooks/useResponsive';
  


/* import {BusinessCardREQUEST,} from '../../redux/businessCard/businessCardActions';  
import {ViewVideoPhotoREQUEST,} from '../../redux/viewVideoPhoto/viewVideoPhotoActions'; */

import {ViewOneVideoPhotoRESET,} from '../../redux/viewOneVideoPhoto/viewOneVideoPhotoActions'; 
import {ReportAbuseRESET,} from '../../redux/reportAbuse/reportAbuseActions'; 
  
 

// util
import { removeCommaQuestionMarkFullStopLowerCase, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, stringInclude,
        cloud31, cloud144, cloud110, replaceWordImage, cardBack, cardNext
     } from '../../utils/translateFunctions';

import {  cloud57, cloud58, cloud59, cloud60, cloud61} from '../../utils/automateAllCommonFunctions';
 

// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

export default function HomePage() {  
  const navigate = useNavigate();  
  const dispatch = useDispatch(); 


  const inputValue = useRef(''); 
 
  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i-=1) {
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array; 
  };  


  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue); 

  // let businessCardValue = useMemo(() => [], []); 
  const businessCardValue =  useRef([]);
  const viewVideoPhotoValue =  useRef([]);
  const isloading = useRef(true);
  

  const i = useRef(0);

  if(allDataOnceReducerValue.user && i.current === 0){  
    businessCardValue.current = allDataOnceReducerValue.user.businessCard;
    // viewVideoPhotoValue = allDataOnceReducerValue.user.ViewVideoAudioPhoto;     
    isloading.current = allDataOnceReducerValue.loading;   
    viewVideoPhotoValue.current = allDataOnceReducerValue.user.AllCategoryViewVideoAudioPhotoSentPerDivisionPhoto; 
    viewVideoPhotoValue.current = shuffle(viewVideoPhotoValue.current).slice(0, 10); 
    
    i.current +=1 
  } 
   

  
  const handleReloadPage = async () => { 
    // window.location.reload();  
    navigate('/home-load', { replace: true });  
  }
    

  const [cookies, ] = useCookies(['_token_planetryvilage']);  

   useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      dispatch(ViewOneVideoPhotoRESET(null));
      dispatch(ReportAbuseRESET(null)); 
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, dispatch, navigate]);




  const isDesktop = useResponsive('up', 'lg'); 

  const initialMenuValue = isDesktop?{
    businessCardBool: false,
    allDreamsBool: true,
    viewVillageBool: false, 
  }:
  {
    businessCardBool: false,
    allDreamsBool: true,
    viewVillageBool: false, 
  }
 
  const [menuBool, setMenuBool] = useState(initialMenuValue); 

  const [searchField, setSearchField] = useState({
    videoBool: true, 
    audioBool: false, 
    photoBool: false
  });   

  // const [searchCategory, setSearchCategory] = useState('');
    
 


  /* beginning photo */ 

  /* const filteredCategoryPhoto = useMemo(() => viewVideoPhotoValue.filter(
    (file) =>  
       (
        (`${file.NewFullName}${' '}${file.categoryDivision}${' '}${file.DateCreated}${' '}${file.uservideoaudiophotoId}${' '}${file.fileTypeDivision}`)  
        .toLowerCase()
        .includes(searchCategory.toLowerCase())    
      )  
  ) , [viewVideoPhotoValue, searchCategory]);  */
 
  /* end photo */
 

  /* const handleSearchName = () => { 
    setSearchCategory(inputValue.current.value); 
    inputValue.current.value = ''; 
  }  */
  
  // const filteredCategoryPhoto = useMemo(() => viewVideoPhotoValue, [viewVideoPhotoValue]);
  const filteredCategoryPhoto = viewVideoPhotoValue.current;

  const handleSearchName = async () => {  
    let txtValue;

    const showItemElement = await document.getElementsByClassName('showItem');
    const input = await inputValue.current;

    const filter = await input.value.toUpperCase();


    for (let i = 0; i < showItemElement.length; i+=1) {
      const a1 = showItemElement[i];
      txtValue = a1.textContent || a1.innerText;

      const filterValue = txtValue.toUpperCase().indexOf(filter);
      if ((filterValue > -1)) {
        showItemElement[i].style.display = "";
      } else {
        showItemElement[i].style.display = "none";
      }
    } 
  }

  const handleSearchNameSpecific = async (song) => {  
    let txtValue;
    inputValue.current.value= await song;

    const showItemElement = await document.getElementsByClassName('showItem'); 

    const filter = await song.toUpperCase();


    for (let i = 0; i < showItemElement.length; i+=1) {
      const a1 = showItemElement[i];
      txtValue = a1.textContent || a1.innerText;

      const filterValue = txtValue.toUpperCase().indexOf(filter);
      if ((filterValue > -1)) {
        showItemElement[i].style.display = "";
      } else {
        showItemElement[i].style.display = "none";
      }
    }
  }

 

  const OpenBusinessCard = () => {
    setMenuBool({
      menuBool, 
      ...{
        businessCardBool: true,
        allDreamsBool: false,
        viewVillageBool: false, 
      }
    });
 
  }

  const OpenAllDreams = () => {
    setMenuBool({
      menuBool, 
      ...{
        businessCardBool: false,
        allDreamsBool: true,
        viewVillageBool: false, 
      }
    });

  }

  const OpenViewVillage = () => {
    setMenuBool({
      menuBool, 
      ...{
        businessCardBool: false,
        allDreamsBool: false,
        viewVillageBool: true, 
      }
    });
  }
 
  


  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
       

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {     
        let searchSomething;  

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  
          // const removeFirstElementFromString_ = await removeFirstElementFromString(t); 

          
          // next universal card or business card
          if (await stringComparison(['next'], t)) {
            await cardNext();
          }
          
          // previous universal card or business card
          if (await stringComparison(['come back', 'back'], t)) {
            await cardBack();
          }
          
          // search a specific song 
          if (await stringComparison([cloud144.a, cloud144.b, cloud144.c, cloud144.d, cloud144.e, cloud144.f, cloud144.g, cloud144.h, cloud144.i, cloud144.j, cloud144.k, cloud144.l], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            searchSomething = await tm; 
            // await setSearchCategory(searchSomething);
            await handleSearchNameSpecific(searchSomething)
          }

          /* the shared audios */
          /* if (await stringComparison([cloud29a.a, cloud29a.b, cloud29a.c, cloud29a.d, cloud29a.e, cloud29a.f, cloud29a.g, cloud29a.h, cloud29a.i, cloud29a.j, cloud29a.k, cloud29a.l, cloud29a.m, cloud29a.n, cloud29a.o, cloud29a.p], t)) {
            inputValue.current.value = await '';  
            await setSearchCategory(inputValue.current.value); 

            const a = await {videoBool: false, audioBool: true, photoBool : false}
            await setSearchField({
              searchField,
              ...a
            });
          } */

          /* the shared videos */
          /* if (await stringComparison([cloud27a.a, cloud27a.b, cloud27a.c, cloud27a.d, cloud27a.e, cloud27a.f, cloud27a.g, cloud27a.h, cloud27a.i, cloud27a.j, cloud27a.k, cloud27a.l, cloud27a.m, cloud27a.n, cloud27a.o, cloud27a.p], t)) {
            inputValue.current.value = await '';  
            await setSearchCategory(inputValue.current.value); 
            
            const a = await {videoBool: true, audioBool: false, photoBool : false}
            await setSearchField({
              searchField,
              ...a
            });
          } */
          
          /* the shared photos */
          if (await stringComparison([cloud31.a, cloud31.b, cloud31.c, cloud31.d, cloud31.e, cloud31.f, cloud31.g, cloud31.h, cloud31.i, cloud31.j, cloud31.k, cloud31.l, cloud31.m, cloud31.n, cloud31.o, cloud31.p, cloud31.q, cloud31.r, cloud31.s, cloud31.t, cloud31.u, cloud31.v, cloud31.w, cloud31.a2, cloud31.a3, cloud31.a4, cloud31.a5, cloud31.a6, cloud31.a7, cloud31.a8, cloud31.a9, cloud31.a10, cloud31.a11, cloud31.a12, cloud31.a13, cloud31.a14], t)) {
            inputValue.current.value = await '';  
            // await setSearchCategory(inputValue.current.value); 
            
            const a = await {videoBool: false, audioBool: false, photoBool : true}
            await setSearchField({
              searchField,
              ...a
            });
          }
          
          // view village
          if (await stringComparison(['join village', 'join a village', 'view a village', 'view village',    'joins village', 'joins a village', 'views a village', 'views village',    'joined village', 'joined a village', 'viewed a village', 'viewed village'], t)) {
            await setMenuBool({
              menuBool, 
              ...{
                businessCardBool: false,
                allDreamsBool: false,
                viewVillageBool: true, 
              }
            });
          }

          // all dreams
          if (await stringComparison(['daydreams', 'the daydreams', 'dreams', 'the dreams', 'all dreams', 'dream', 'dreaming', 'dreamed', 'dreamt'], t)) {
            await setMenuBool({
              menuBool, 
              ...{
                businessCardBool: false,
                allDreamsBool: true,
                viewVillageBool: false, 
              }
            });
          }

          // open notifications
          if (await stringComparison(['notification', 'notifications', 'the notification', 'the notifications'], t)) {
            const a = document.getElementsByClassName("notification")[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          // close notifications
          if (await stringComparison(['close notification', 'close notifications', 'close the notification', 'close the notifications',    'closes notification', 'closes notifications', 'closes the notification', 'closes the notifications',   'closed notification', 'closed notifications', 'closed the notification', 'closed the notifications'], t)) {
            const a = document.getElementsByClassName("close-notification")[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          // universal card
          if (await stringComparison([cloud57.a, cloud57.b, cloud57.c, cloud57.d, cloud57.e, cloud57.f, cloud57.g, cloud57.h, cloud57.i, cloud57.j, cloud57.k, cloud57.l, cloud57.m, cloud57.n, cloud57.o, cloud57.p], t)) {
            await setMenuBool({
              menuBool, 
              ...{
                businessCardBool: true,
                allDreamsBool: false,
                viewVillageBool: false, 
              }
            });
          }

          // old school card
          if (await stringComparison([cloud58.a, cloud58.b, cloud58.c, cloud58.d, cloud58.e, cloud58.f, cloud58.g, cloud58.h, cloud58.i, cloud58.j, cloud58.k, cloud58.l, cloud58.m, cloud58.n, cloud58.o, cloud58.p], t)) {
            await setMenuBool({
              menuBool, 
              ...{
                businessCardBool: true,
                allDreamsBool: false,
                viewVillageBool: false, 
              }
            });
          }

          // business card
          if (await stringComparison([cloud59.a, cloud59.b, cloud59.c, cloud59.d, cloud59.e, cloud59.f, cloud59.g, cloud59.h, cloud59.i, cloud59.j, cloud59.k, cloud59.l, cloud59.m, cloud59.n, cloud59.o, cloud59.p], t)) {
            await setMenuBool({
              menuBool, 
              ...{
                businessCardBool: true,
                allDreamsBool: false,
                viewVillageBool: false, 
              }
            });
          }

          // school card
          if (await stringComparison([cloud60.a, cloud60.b, cloud60.c, cloud60.d, cloud60.e, cloud60.f, cloud60.g, cloud60.h, cloud60.i, cloud60.j, cloud60.k, cloud60.l, cloud60.m, cloud60.n, cloud60.o, cloud60.p], t)) {
            await setMenuBool({
              menuBool, 
              ...{
                businessCardBool: true,
                allDreamsBool: false,
                viewVillageBool: false, 
              }
            });
          }

          // student card
          if (await stringComparison([cloud61.a, cloud61.b, cloud61.c, cloud61.d, cloud61.e, cloud61.f, cloud61.g, cloud61.h, cloud61.i, cloud61.j, cloud61.k, cloud61.l, cloud61.m, cloud61.n, cloud61.o, cloud61.p], t)) {
            await setMenuBool({
              menuBool, 
              ...{
                businessCardBool: true,
                allDreamsBool: false,
                viewVillageBool: false, 
              }
            });
          }
 


          // beginning show picture using michael
          if (await stringInclude(t, "displayed")) {
            t = await replaceWordImage(t, 'displayed');
          } else
          if (await stringInclude(t, "displays")) {
            t = await replaceWordImage(t, 'displays');
          } else
          if (await stringInclude(t, 'is displaying')) {
            t = await replaceWordImage(t, 'is displaying');
          } else
          if (await stringInclude(t, "display")) {
            t = await replaceWordImage(t, 'display');
          }


          if (await stringInclude(t, "showed")) {
            t = await replaceWordImage(t, 'showed');
          }
          if (await stringInclude(t, "shows")) {
            t = await replaceWordImage(t, 'shows');
          }
          if (await stringInclude(t, 'is showing')) {
            t = await replaceWordImage(t, 'is showing');
          } else


          if (await stringInclude(t, "exhibited")) {
            t = await replaceWordImage(t, 'exhibited');
          } else
          if (await stringInclude(t, "exhibits")) {
            t = await replaceWordImage(t, 'exhibits');
          } else
          if (await stringInclude(t, 'is exhibiting')) {
            t = await replaceWordImage(t, 'is exhibiting');
          } else
          if (await stringInclude(t, "exhibit")) {
            t = await replaceWordImage(t, 'exhibit');
          } else


          if (await stringInclude(t, "presented")) {
            t = await replaceWordImage(t, 'presented');
          } else
          if (await stringInclude(t, "presents")) {
            t = await replaceWordImage(t, 'presents');
          } else
          if (await stringInclude(t, 'is presenting')) {
            t = await replaceWordImage(t, 'is presenting');
          } else
          if (await stringInclude(t, "present")) {
            t = await replaceWordImage(t, 'present');
          } else


          if (await stringInclude(t, "exposed")) {
            t = await replaceWordImage(t, 'exposed');
          } else
          if (await stringInclude(t, "exposes")) {
            t = await replaceWordImage(t, 'exposes');
          } else
          if (await stringInclude(t, 'is exposing')) {
            t = await replaceWordImage(t, 'is exposing');
          } else
          if (await stringInclude(t, "expose")) {
            t = await replaceWordImage(t, 'expose');
          } else


          if (await stringInclude(t, "unveiled")) {
            t = await replaceWordImage(t, 'unveiled');
          } else
          if (await stringInclude(t, "unveils")) {
            t = await replaceWordImage(t, 'unveils');
          } else
          if (await stringInclude(t, 'is unveiling')) {
            t = await replaceWordImage(t, 'is unveiling');
          } else
          if (await stringInclude(t, "unveil")) {
            t = await replaceWordImage(t, 'unveil');
          } else


          if (await stringInclude(t, "watched")) {
            t = await replaceWordImage(t, 'watched');
          } else
          if (await stringInclude(t, "watches")) {
            t = await replaceWordImage(t, 'watches');
          } else
          if (await stringInclude(t, 'is watching')) {
            t = await replaceWordImage(t, 'is watching');
          } else
          if (await stringInclude(t, "watch")) {
            t = await replaceWordImage(t, 'watch');
          } else


          if (await stringInclude(t, "poster")) {
            t = await replaceWordImage(t, 'poster');
          } else
          if (await stringInclude(t, "posted")) {
            t = await replaceWordImage(t, 'posted');
          } else
          if (await stringInclude(t, "posts")) {
            t = await replaceWordImage(t, 'posts');
          } else
          if (await stringInclude(t, 'is posting')) {
            t = await replaceWordImage(t, 'is posting');
          } else
          if (await stringInclude(t, "post")) {
            t = await replaceWordImage(t, 'post');
          }


          if (await stringInclude(t, "image")) {
            t = await replaceWordImage(t, 'image');
          } else
          if (await stringInclude(t, "photograph")) {
            t = await replaceWordImage(t, 'photograph');
          } else
          if (await stringInclude(t, "likeness")) {
            t = await replaceWordImage(t, 'likeness');
          } else
          if (await stringInclude(t, "portrait")) {
            t = await replaceWordImage(t, 'portrait');
          } else
          if (await stringInclude(t, "pictures")) {
            t = await replaceWordImage(t, 'pictures');
          } else
          if (await stringInclude(t, "photos")) {
            t = await replaceWordImage(t, 'photos');
          } else
          if (await stringInclude(t, "photographs")) {
            t = await replaceWordImage(t, 'photographs');
          } else
          if (await stringInclude(t, "number")) {
            t = await replaceWordImage(t, 'number');
          } else
          if (await stringInclude(t, "segment")) {
            t = await replaceWordImage(t, 'segment');
          } else
          if (await stringInclude(t, "photo")) {
            t = await replaceWordImage(t, 'photo');
          } else
          if (await stringInclude(t, "picture")) {
            t = await replaceWordImage(t, 'picture');
          } else
          if (await stringInclude(t, 'pic')) {
            t = await replaceWordImage(t, 'pic');
          } 

          /* if(await searchField.photoBool) {  
            for (let i = 0; i < filteredFilesPhoto.length; i+=1) {  
              if (stringComparison([`${"show picture "}${i+1}`], t)) {    
                const a = document.getElementsByClassName(`${"show picture "}${i}`)[0];
                if (typeof(a) !== 'undefined' && a !== null) {
                  a.click();
                }
              }
            }
          } */  

          for (let i = 0; i < viewVideoPhotoValue.current.length; i+=1) {  
            if (stringComparison([`${"show picture "}${i+1}`], t)) {    
              const a = document.getElementsByClassName(`${"show picture "}${i}`)[0];
              if (typeof(a) !== 'undefined' && a !== null) {
                a.click();
              }
            }
          }


          if (await stringComparison([cloud110.a], t)) { 
            const a = document.getElementsByClassName(`${"show picture "}${0}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.b, cloud110.c, cloud110.b2], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${1}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.d, cloud110.e, cloud110.f], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${2}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.g, cloud110.h], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${3}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.i, cloud110.j], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${4}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.k, cloud110.l], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${5}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.m], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${6}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.n], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${8}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          } 
  
          // end show picture using michael

        } 
      }
    }
    michaelExexution(); 
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, menuBool, searchField, viewVideoPhotoValue.current.length]);
   
 
  /* End Michael */  

   
  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Home </title>
      </Helmet>
      
      
      <Container maxWidth="xl"  sx={{px:0, }} > 
        
          <Grid container spacing={1} sx={{pb:1, }} > 

            <Grid  item xs={12} sm={12} md={12} lg={6}> 
              <Grid container spacing={0}  alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={12} md={12}> 
                  <TopMenu 
                    OpenBusinessCard={OpenBusinessCard} 
                    OpenAllDreams={OpenAllDreams} 
                    OpenViewVillage={OpenViewVillage}  
                  />
                </Grid> 

                <Grid item xs={12} sm={12} md={12}> 
                  <SideScreen businessCardDetails={businessCardValue.current} menuBool={menuBool}/>
                </Grid> 
              </Grid>
            </Grid>  


            <Grid  item xs={12} sm={12} md={12} lg={6}> 
              <Grid container spacing={1}  alignItems="center" justifyContent="center">
                <Grid  item xs={12} sm={6} md={6} lg={12} > 
                  {/* <CategoryFiles handleFilterCategory={handleFilterCategory} /> */}
                  <Grid container spacing={1}  alignItems="center" justifyContent="center">
                   <Grid  item xs={12} sm={12} md={8}  >  
                    <Autocomplete
                    autoHighlight  
                    popupIcon={null}
                    PopperComponent={StyledPopper}
                    options={viewVideoPhotoValue.current}     
                    getOptionLabel={(post) => `${post.NewFullName}${' '}${post.categoryDivision}${' '}${post.DateCreated}${' '}${post.uservideoaudiophotoId}${' '}${post.fileTypeDivision}`} 
                    isOptionEqualToValue={(option, value) => option.id === value.id}   
                    renderInput={(params) => (
                      <TextField  variant="standard"
                        inputRef={inputValue} 

                        onKeyDown={(event) => {
                          if (event.key === 'Enter'){
                            handleSearchName()
                          } 
                        }}

                        onChange={handleSearchName}

                        {...params}
                        placeholder="Search..."
                        InputProps={{ 
                          style: { color: 'white' },
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton color="primary" onClick={handleSearchName} >
                                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                   />
                  </Grid>
                 </Grid>
                </Grid>
                
                <Grid    item xs={12} sm={12} md={6}  >
                  <PhotoOnlyPub key="pvpub" keyProp="pvpub" />
                </Grid> 
 

                { filteredCategoryPhoto.map((item, key) => (
                  <Grid key={key}  item xs={12} sm={12} md={6}  >
                    <PhotoOnly key={key} keyProp={key} cardItem = {item}/>
                  </Grid>
                  )) 
                 }    
                 
              </Grid> 

            {/**/}
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}  alignItems="center" justifyContent="center"> 
                 
                {isloading.current && 
                  <BubblePreloader
                  bubble={{ width: '2rem', height: '2rem' }}
                  animation={{ speed: 2 }}
                  style={{margin:'auto', padding:0, textAlign:'center', position: 'relative'}}
                  colors={['blue', 'aqua', '#f3f3f3']}
                 />  
                } 
 
                 
                {!isloading.current && 
                <LoadingButton sx={{marginTop:5}}  size="small" title='button load more photos' variant="contained"  onClick={()=>{handleReloadPage()}} >
                  Load more photos
                </LoadingButton> 
                }
              </Grid> 
            </Grid> 
            {/**/}
            </Grid>
            
          </Grid> 

          <HomeWidget  />      
           

          <AccountWidget/>
          
          <NotificationsWidget/>

          
      </Container>  
    </>
  );
}



