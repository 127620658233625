
import {useEffect, useRef, useState, useMemo, memo} from 'react';
import { useSelector } from 'react-redux'; 
import { useCookies  } from 'react-cookie';
import { useNavigate, } from 'react-router-dom'; 
import { useSpeechRecognition } from "react-speech-recognition";

import axios from 'axios';

import io from 'socket.io-client';


import {Box, Card, CardHeader, CardActions, Avatar, IconButton, Typography, Button } from '@mui/material';
     
import { grey } from '@mui/material/colors';
  
// import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import FavoriteIcon from '@mui/icons-material/Favorite';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import IosShareIcon from '@mui/icons-material/IosShare';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import FlagIcon from '@mui/icons-material/Flag';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

import PropTypes from 'prop-types';


// import { faker } from '@faker-js/faker';
// import { subDays, sub  } from 'date-fns';

// utils
import { fShortenNumber } from '../../../../utils/formatNumber'; 
import {lowerCaseAllWordsExceptFirstLetters} from '../../../../utils/formatWord';

import {playCurrentVideoPauseOther, playCurrentAudioPauseOther, useWindowDimensions} from '../../../../utils/otherFunctions';
 

// hooks
import useResponsive from '../../../../hooks/useResponsive'; 


import {domainName} from '../../../../utils/domain';


import {ReportUser} from './ReportUser';
import {UserInfo} from './UserInfo';
import {CommentChat} from './CommentChat';
// import MoreVertInfo from './MoreVertInfo';



// util
import { removeCommaQuestionMarkFullStopLowerCase, transMany, transMany__, newLang, firstElementOfString, 
         removeFirstElementFromString, stringComparison, cloud83, cloud105, cloud106, cloud142, cloud143, cloud199, cloud200,
         cloud201, cloud202,
     } from '../../../../utils/translateFunctions';
 

const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------------

 

// const socket = io(`${domainName}`);

export const OneEventCardVideo = memo(({cardItem}) => {     
  const socket = useMemo(() => io(`${domainName}`), []);   
  const navigate = useNavigate();   
  const token = useRef(null); 
  const type = useRef(null);



  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
        token.current = cookies._token_planetryvilage.token; 
        type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]);   

 


  const { height } = useWindowDimensions();
  const isDesktop = useResponsive('up', 'lg'); 
  
  // const HEIGHT = 350;
  const HEIGHT = isDesktop? height*0.90 : height*0.80; 

  
  let numberTrackerValue;
  let reportValue = ''; 
 
  const viewOneEventCardVideoReducerValue = useSelector(state => state.viewOneEventCardVideoReducerValue);
  const reportAbuseReducerValue = useSelector(state => state.reportAbuseReducerValue);
 

  if(viewOneEventCardVideoReducerValue.user){
     numberTrackerValue = viewOneEventCardVideoReducerValue.user.numberTrackers;
  } 

  if(reportAbuseReducerValue.user){
     reportValue = reportAbuseReducerValue.user.message;
  } 

  
  const {
    Message, 
    DateCreated, 
    NumberLikes, 
    NumberViews, 
    NumberComments,  
    Video, 
    Photo,
    NewFullName, 
    RoomId,
    EventCardsId
  } = cardItem; 

  const fileTypeDivision = 'Video';  

  const titleTV = fileTypeDivision === "Video" ? `${lowerCaseAllWordsExceptFirstLetters(NewFullName)} ${'TV'}`:
                  fileTypeDivision === "Audio" ? `${lowerCaseAllWordsExceptFirstLetters(NewFullName)} ${'Radio'}`:
                  `${lowerCaseAllWordsExceptFirstLetters(NewFullName)}`;

  const [colorHeart, setColorHeart] = useState('black');
  const [colorTrack, setColortrack] = useState('blue');
  const [openComment, setOpenComment] = useState(null); 
  const [numberofComments, setNumberComments] = useState(NumberComments);  
  const [NumberOfLikes, setNumberLikes] = useState(NumberLikes);      
  const [increment, setIncrement] = useState(0);  
  


  const [openReport, setOpenReport] = useState(null);

  const handleOpenReport = (event) => {
    setOpenReport(event.currentTarget);
  };

  const handleCloseReport = () => {
    setOpenReport(null);
  }; 




  const [openUserInfo, setOpenUserInfo] = useState(null);

  const handleOpenUserInfo = (event) => {
    setOpenUserInfo(event.currentTarget);
  };

  const handleCloseUserInfo = () => {
    setOpenUserInfo(null);
  }; 


 /*
  const [openMoreVertInfo, setOpenMoreVertInfo] = useState(null);

  const handleOpenMoreVertInfo = (event) => {
    setOpenMoreVertInfo(event.currentTarget);
  };

  const handleCloseMoreVertInfo = () => {
    setOpenMoreVertInfo(null);
  }; */



  const handleNumberComment = (totalComment) => {
    setNumberComments(totalComment)
  }

  const handleOpenComment = (event) => {
    setOpenComment(event.currentTarget);
  };

  const handleCloseComment = () => {
    setOpenComment(null);
  };

  const handleColorHeart = () => {
    if(colorHeart === 'black') {
      setColorHeart('red');
    } else {
      setColorHeart('black');
    }
  }

  const handleVideoAudio = async () => {  
    await playCurrentVideoPauseOther();
    await playCurrentAudioPauseOther();
  }; 


  const fileId = useRef();

  const playFileOnTouch = ()=> {
    fileId.current.play(); 
  }
  
  
  useEffect(() => {
    handleVideoAudio(); 
    if(fileId.current){
      // fileId.current.play(); 
    }
  });


  const sendLikes = async () => {
    await setNumberLikes(NumberOfLikes + 1); 

    const message = await {
      token: token.current,
      user_type: type.current,
      room: RoomId
    };

    await socket.emit('messageLikesEventcard', message);
  }
 
  const handleLikes = async () => {  
    if (await (increment === 0)) {
      await sendLikes(); 
    }  

    await setIncrement(increment + 1);
  }



  const sendTrackMe = async () => { 
    const message = await {
      token: token.current,
      user_type: type.current,
      room: RoomId
    };

    await socket.emit('messageTrackerEventcard', message);
  }

  const handleTrackMe = async () => {  
    await sendTrackMe();  
    await setColortrack('black');
  }



  const handleShareFile = async (fileTypeDivision, Video, Message) => { 
    let file;
    const urlFile = await fileTypeDivision === 'Video'?`${domainName}/EddyDreams/otherFiles/videos/${Video}`:
                        fileTypeDivision === 'Audio'?`${domainName}/EddyDreams/otherFiles/audios/${Video}`:
                        `${domainName}/EddyDreams/otherFiles/images/${Video}`;
    
    const urlBlob = await axios({
      method: 'GET',
      url: urlFile,
      responseType: "blob"
    });

    const responseBlob = await urlBlob.data;
    const responseBlobSize = await responseBlob.size;

    if (await (responseBlobSize !== 202)) {
      if (await fileTypeDivision === 'Photo') {
        file = await new File([responseBlob], "picture.jpg", {
          type: 'image/jpeg'
        });
      } else
      if (await fileTypeDivision === 'Audio') {
        file = await new File([responseBlob], "audio.webm", {
          type: 'audio/webm'
        });
      } else
      if (await fileTypeDivision === 'Video') {
        file = await new File([responseBlob], "video.webm", {
          type: 'video/webm'
        });
      } else
      if (await (fileTypeDivision === 'Card' || fileTypeDivision === 'Magazines')) {
        file = await new File([responseBlob], "video.webm", {
          type: 'video/webm'
        });
      }
    }

    const filesArray = await [file]; 

    const shareConfig = await {
      title: 'PlanetryVilage',
      text: Message,
      url: window.location.href,
      files: filesArray,
    }

    try {
      if (await (!navigator.canShare && !navigator.canShare({
        files: filesArray
      }))) {
        // resultShare.textContent = 'Your device does not support the files sharing.';
        // await $("#resultShare").fadeOut(20000);
      } else {
        await navigator.share(shareConfig);
        // resultShare.textContent = 'Thanks for sharing!';
        // await $("#resultShare").fadeOut(20000);
      }
    } catch (err) {
      // resultShare.textContent = 'Your device cannot share this file';
      // await $("#resultShare").fadeOut(20000);
    }
  }




  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition();  
    

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {    

        // const tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          const t = await removeFirstElementFromString__;      
          // const firstElementOfString_ = await firstElementOfString(t);  
          // const removeFirstElementFromString_ = await removeFirstElementFromString(t); 
          
          // like a file
          if (await stringComparison([cloud142.a, cloud142.b], t)) {
            const a = await document.getElementsByClassName("like-file")[0];

            if(await (typeof a !== 'undefined' && a !== null)) {
              await a.click();
            }

            speech.text = await transMany__('The likes have been sent') || 'The likes have been sent';
            await speechSynth.speak(speech); 
          }
          
          // track a file
          if (await stringComparison([cloud143.a, cloud143.b, cloud143.c, cloud143.d, cloud143.e, cloud143.f], t)) {
            const a = await document.getElementsByClassName("track-file")[0];

            if(await (typeof a !== 'undefined' && a !== null)) {
              await a.click();
            }

            speech.text = await transMany__('It is a pleasure to track this') || 'It is a pleasure to track this';
            await speechSynth.speak(speech); 
          }

          // share a file
          if (await stringComparison(['shea', 'share', 'shared', 'shares'], t)) {
            const a = await document.getElementsByClassName("share-file")[0];

            if(await (typeof a !== 'undefined' && a !== null)) {
              await a.click();
            }

            speech.text = await transMany__('You can now share the file') || 'You can now share the file';
            await speechSynth.speak(speech); 
          }
          
          // open the comment room
          if (await stringComparison([cloud105.a, cloud105.b, cloud105.c, cloud105.d, cloud105.e, cloud105.f], t)) {   
            const a = await document.getElementsByClassName("showComment")[0];

            if(await (typeof a !== 'undefined' && a !== null)) {
              await a.click();
            }
          }
          
          // close the comment room
          if (await stringComparison([cloud106.a, cloud106.b, cloud106.c, cloud106.d, cloud106.e, cloud106.f, cloud106.g, cloud106.h, cloud106.i, cloud106.j, cloud106.k], t)) {
            await setOpenComment(false);
          }

          // open report panel
          if (await stringComparison([cloud199.a, cloud199.b, cloud199.c, cloud199.d, cloud199.e, cloud199.f, cloud199.g, cloud199.h, cloud199.i, cloud199.j, cloud199.k, cloud199.l, cloud199.m, cloud199.n, cloud199.o, cloud199.p, cloud199.q], t)) {
            const a = await document.getElementsByClassName("open-report")[0];

            if(await (typeof a !== 'undefined' && a !== null)) {
              await a.click();
            }
          }
          
          // close report panel
          if (await stringComparison([cloud200.a, cloud200.b, cloud200.c, cloud200.d, cloud200.e, cloud200.f, cloud200.g, cloud200.h, cloud200.i, cloud200.j, cloud200.k, cloud200.l], t)) {
            await setOpenReport(false);
          }
          
          // open user information
          if (await stringComparison([cloud201.a, cloud201.b, cloud201.c, cloud201.d, cloud201.e, cloud201.f, cloud201.g, cloud201.h, cloud201.i, cloud201.j, cloud201.k, cloud201.l, cloud201.m, cloud201.n], t)) {
            const a = await document.getElementsByClassName("open-user-information")[0];

            if(await (typeof a !== 'undefined' && a !== null)) {
              await a.click();
            }
          }
          
          // close user information
          if (await stringComparison([cloud202.a, cloud202.b, cloud202.c, cloud202.d, cloud202.e, cloud202.f, cloud202.g, cloud202.h, cloud202.i, cloud202.j, cloud202.k, cloud202.l, cloud202.m, cloud202.n, cloud202.o, cloud202.p, cloud202.q], t)) {
            await setOpenUserInfo(false);
          } 



        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate]);

 
  /* End Michael */  
  
 

  return (
    <Card sx={{ width: '100%', mb:1, borderRadius:{xs:0, sm:0, md:1}}}> 
      
 
      { fileTypeDivision === "Video" ? 
      (
      <video ref={fileId} controls controlsList="nodownload" style={{width: '100%', height: HEIGHT, objectFit:'cover'}}
        poster="/assets/images/planetryvilage/video-to-lazy-load-1x.jpg"
        onClick={handleVideoAudio} 
        onTouchStart={playFileOnTouch}
        onMouseOver={playFileOnTouch}
        onFocus={ () => null }

        preload="none" 
        autoPlay loop playsInline   
      >
        <source src={`${domainName}/EddyDreams/otherFiles/videos/${Video}`} type="video/webm"/>
        <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/>
      </video>
      ) : 
       fileTypeDivision === "Audio" ? 
      (
      <audio ref={fileId}  controls controlsList="nodownload" style={{width: '100%'}}
      
        onClick={handleVideoAudio} 
        onTouchStart={playFileOnTouch}
        onMouseOver={playFileOnTouch}
        onFocus={ () => null }

        loop   
        src={`${domainName}/EddyDreams/otherFiles/audios/${Video}`}
      ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></audio>
      ) : 
      (
      <Box  component="img"
        sx={{ 
          // height: '20%',
          width: { xs: '100%', sm: '100%', md: 'auto' },
          // maxHeight: { xs: HEIGHT, md: HEIGHT },
          // maxHeight: { xs: 'auto', md: 'auto' },
          height: HEIGHT,
          // maxWidth: { xs: '100%', sm: '100%', md: WIDTH  }, 
          m:'auto',
          p:0,
          objectFit:'cover'
        }}
 
        alt="photo"   
        src={`${domainName}/EddyDreams/otherFiles/images/${Video}`}
      />
      )
      }


       
      <CardActions disableSpacing  sx={{justifyContent:'center'}}>
        <IconButton aria-label="add to favorites" sx={{fontSize:{xs:13, md:15}, color:"black", }} className="like-file" onClick={() => {handleColorHeart(); handleLikes();}}>
          {fShortenNumber(NumberOfLikes)} <FavoriteIcon sx={{fontSize:{xs:20, md:25}, color:colorHeart, }} /> 
        </IconButton>

        <IconButton aria-label="views" sx={{fontSize:{xs:13, md:15}, color:"black"}}>
          {fShortenNumber(NumberViews)} <VisibilityIcon  sx={{fontSize:{xs:20, md:25}, color:"black"}}/>
        </IconButton> 

        <IconButton aria-label="comment" sx={{fontSize:{xs:13, md:15}, color:"black"}} className="showComment" onClick={handleOpenComment}>
          {fShortenNumber(numberofComments)} <ChatIcon  sx={{fontSize:{xs:20, md:25}, color:"black"}}/>
        </IconButton>
        <CommentChat openComment={openComment} handleCloseComment={handleCloseComment} handleNumberComment={handleNumberComment} RoomId={RoomId} /> 

        <IconButton aria-label="share" sx={{fontSize:{xs:13, md:15}, color:"black"}} className="share-file" onClick={() => {handleShareFile(fileTypeDivision, Video, Message)}}>
          <IosShareIcon  sx={{fontSize:{xs:20, md:25}, color:"black"}}/>
        </IconButton> 

        <IconButton aria-label="report" sx={{fontSize:{xs:13, md:15}, color:"black"}} className="open-report" onClick={(e) => {handleOpenReport(e);}}>
          <FlagIcon  sx={{fontSize:{xs:20, md:25}, color:"black"}}/> 
        </IconButton> 
        <ReportUser openReport={openReport} handleCloseReport={handleCloseReport} EventCardsId={EventCardsId}/>
   
      </CardActions> 

      <Typography sx={{ml:3, color:'red'}}>{reportValue}</Typography>



      <CardHeader   sx={{pt:0}}
        avatar={
          <Avatar sx={{ bgcolor: grey[500], pt:0 }} alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} /> 
        }
        action={
          <>
            <IconButton aria-label="settings" className="open-user-information" onClick={(e) => {handleOpenUserInfo(e);}}>
              <PersonOutlineIcon />
            </IconButton>
            <UserInfo openUserInfo={openUserInfo} handleCloseUserInfo={handleCloseUserInfo}  EventCardsId={EventCardsId}/>   
          </>
        }

        titleTypographyProps={{
          fontSize: 15,
        }}

        classes={{
          title: "notranslate"
        }}
        
        title={titleTV}
        subheader={numberTrackerValue[0].trackNumber === 0 ? 'has 0 tracker':
        numberTrackerValue[0].trackNumber === 1? 'has 1 tracker':
        `${'has '} ${fShortenNumber(numberTrackerValue[0].trackNumber)} ${' trackers'}`}
      /> 


      <Button sx={{pl:3, color:colorTrack}} aria-label="settings" className="track-file"  onClick={handleTrackMe}>
        <DirectionsRunIcon /> <Typography >Track me</Typography>
      </Button> 

      <CardHeader   
        title={Message}
        subheader={DateCreated} 
      />  
        
       
    </Card>
  );
})


OneEventCardVideo.propTypes = {
  cardItem: PropTypes.object,
};

// export default memo(OneEventCardVideo);