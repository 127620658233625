

import ViewVillageVideoAudioActionTypes from './viewVillageVideoAudioTypes'


export const ViewVillageVideoAudioREQUEST = (item) => ({
    type: ViewVillageVideoAudioActionTypes.View_Village_Video_Audio_REQUEST,
    payload: item
}) 
 


