
import { useEffect, } from 'react'; 
import { Helmet } from 'react-helmet-async';  
import { useCookies  } from 'react-cookie';
import { useNavigate, useParams  } from 'react-router-dom'; 
import { useDispatch, useSelector } from 'react-redux';
// mui
import { Container, Typography, Grid, IconButton } from '@mui/material';  
import WestIcon from '@mui/icons-material/West';
 


// sections 
import {  
  OneMagazineVideo,
} from '../../sections/@users/homeUser';


// hooks
// import useResponsive from '../../hooks/useResponsive';
  

 
import {ViewOneMagazineVideoREQUEST,} from '../../redux/viewOneMagazineVideo/viewOneMagazineVideoActions'; 
  
// ----------------------------------------------------------------------
 

export default function ShowOneMagazineVideo() { 
  const { _magazineId } = useParams();  
  const navigate = useNavigate();  
  const dispatch = useDispatch();  

 
  const viewOneMagazineVideoReducerValue = useSelector(state => state.viewOneMagazineVideoReducerValue);
 
  let viewOneMagazineVideoValue = []; 


  if(viewOneMagazineVideoReducerValue.user){
    viewOneMagazineVideoValue = viewOneMagazineVideoReducerValue.user.message; 
  } 
 



  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      dispatch(ViewOneMagazineVideoREQUEST({
        token: cookies._token_planetryvilage.token, 
        type: cookies._token_planetryvilage.type,
        magazineId: _magazineId
      }));
    } else {
      navigate('/login', { replace: true });  
    }
  }, [cookies._token_planetryvilage, dispatch, navigate, _magazineId]); 
 

  const filteredCategory = viewOneMagazineVideoValue;
       
   
  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Home </title>
      </Helmet>
      
      
      <Container maxWidth="xl"  sx={{px:0, }} >  

          <Grid container spacing={0} sx={{pb:1, }}>   

            <Grid  item xs={12} sm={12} md={12}  sx={{m:'auto', p:0}}>  
              <IconButton aria-label="back" sx={{fontSize:13, color:'white', p:0}} className="back" onClick={() => navigate('/magazine') }>
                <WestIcon/>   
              </IconButton>
            </Grid> 

            <Grid  item xs={12} sm={12} md={12}  sx={{m:'auto', p:0}}>  

              {filteredCategory.length ? filteredCategory.map((item, key) => (
              <OneMagazineVideo key={key} articleItem = {item}/>
              )) :
               (<Typography sx={{color:'white'}}>{''}</Typography>)
              }  
            </Grid>
            
          </Grid>  

      </Container>  
    </>
  );
}
