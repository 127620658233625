
import { useState, useRef, useEffect } from 'react'; 
import { Helmet } from 'react-helmet-async';  
import { useCookies  } from 'react-cookie'; 
import { useNavigate, useParams  } from 'react-router-dom'; 
import { useDispatch, useSelector  } from 'react-redux';   


// mui
import { Container, Grid, IconButton, Typography, Stack, Card } from '@mui/material';  
import WestIcon from '@mui/icons-material/West';
import { grey } from '@mui/material/colors';


// sections
import {   
  TimelinePeople, JuryDecision, VotesHistory
} from '../../sections/@users/competitionUser';
 

// mock    
// import winnerDetails from '../../_mock/winnerDetails';      
// import juryDecisionHistoryDetails from '../../_mock/juryDecisionHistoryDetails';   
// import votesHistoryDetails from '../../_mock/votesHistoryDetails';     
 


import {SpecificSeasonREQUEST,} from '../../redux/specificSeason/specificSeasonActions'; 
// ----------------------------------------------------------------------

export default function SeasonHistoryPage() { 
  const navigate = useNavigate();  
  const dispatch = useDispatch();   
  const { _competitionId, _seasonId } = useParams();
  const token = useRef(null); 
  const type = useRef(null);  


  const [cookies, ] = useCookies(['_token_planetryvilage']); 
 
  const i = useRef(0); // help to stop sending a dispatch everytime a component changes
  useEffect(() => {
    if(i.current === 0){
      if(cookies._token_planetryvilage !== undefined) {  
        token.current = cookies._token_planetryvilage.token; 
        type.current = cookies._token_planetryvilage.type;

        dispatch(SpecificSeasonREQUEST({
          token: token.current, 
          type: type.current,
          setBreakingNewsIdentifier: _competitionId,
          seasonIdentifier: _seasonId
        }));  
      } else {
        navigate('/login', { replace: true });   
      } 
    }
    i.current += 1;
  }, [dispatch, navigate, cookies._token_planetryvilage, _competitionId, _seasonId]); 


  let getSeasonTypeNameValue = [{seasonTypeName: ''}];
  let getDisqualifiersHistoryValue = [];
  let getQualifiersHistoryValue = [];
  let getWinnerHistoryValue = [];
  let getJuryDecisionHistoryValue = [];
  let getAllVotesHistoryValue = [];

  const specificSeasonReducerValue = useSelector(state => state.specificSeasonReducerValue);


  if(specificSeasonReducerValue.user){
    getSeasonTypeNameValue = specificSeasonReducerValue.user.getSeasonTypeName;
    getDisqualifiersHistoryValue = specificSeasonReducerValue.user.getDisqualifiersHistory;
    getQualifiersHistoryValue = specificSeasonReducerValue.user.getQualifiersHistory;
    getWinnerHistoryValue = specificSeasonReducerValue.user.getWinnerHistory;
    getJuryDecisionHistoryValue = specificSeasonReducerValue.user.getJuryDecisionHistory; 
    getAllVotesHistoryValue = specificSeasonReducerValue.user.getAllVotesHistory;  
  }

 

  const featuresVotes = {
    buttonText: 'Vote Now',
    backColor: grey[600], 
    color: 'black'
  };

  const featuresJuryDecision = {
    buttonText: 'Meet Now',
    backColor: 'white', 
    color: 'black'
  };
  

 
  const [competitionId, setCompetitionId] = useState(_competitionId);    

  const [seasonId, setSeasonId] = useState(_seasonId); 

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Season History </title>
      </Helmet>

      <Container maxWidth="xl" sx={{p:0}}>  
        <Stack textAlign="center" justifyContent="center">
          <IconButton aria-label="back" sx={{fontSize:13, color:'white'}} onClick={() => navigate(-1) }>
            <WestIcon/>   
          </IconButton>

          <Typography variant="subtitle" color="white" fontSize="14px">
            {getSeasonTypeNameValue[0].seasonTypeName}
          </Typography>
        </Stack> 

        <Grid container spacing={1} sx={{p:2}} textAlign="center" justifyContent="center">  

          {/* jury decision */}
          <Grid  item xs={12} sm={12} md={12}>
            <Grid container spacing={1} textAlign="center" justifyContent="center"> 

              {getAllVotesHistoryValue.map((item, key) => (
              <VotesHistory key={key} item={item} features={featuresVotes} token={token.current} type={type.current} competitionId={competitionId} setCompetitionId={setCompetitionId} setSeasonId={setSeasonId} seasonId={seasonId} />
              ))}

              {getJuryDecisionHistoryValue.map((item, key) => (
              <JuryDecision key={key} item={item} features={featuresJuryDecision}  />
              ))}

            </Grid>
          </Grid> 
          
          {/* winner qualifiers disqualifiers */}
          <Grid  item xs={12} sm={12} md={12}>
            <Grid container spacing={1} textAlign="center" justifyContent="center"> 
              {!!getWinnerHistoryValue.length &&
              <Grid  item xs={12} sm={12} md={6}>
                <Card sx={{borderRadius:'3px', m:1, }}>
                  <Typography variant="subtitle" color="black" fontSize="14px">
                    The winner history
                  </Typography> 
                </Card> 
                <TimelinePeople people={getWinnerHistoryValue}  />
              </Grid>}
              
              {!!getQualifiersHistoryValue.length &&
              <Grid  item xs={12} sm={12} md={6}>
                <Card sx={{borderRadius:'3px', m:1, }}>
                  <Typography variant="subtitle" color="black" fontSize="14px">
                    The qualifiers history
                  </Typography> 
                </Card>   
                <TimelinePeople people={getQualifiersHistoryValue}   />
              </Grid>}
              
              {!!getDisqualifiersHistoryValue.length &&
              <Grid  item xs={12} sm={12} md={6}>
                <Card sx={{borderRadius:'3px', m:1, }}>
                  <Typography variant="subtitle" color="black" fontSize="14px">
                    The disqualifiers history
                  </Typography> 
                </Card>    
                <TimelinePeople people={getDisqualifiersHistoryValue}   />
              </Grid>}
            </Grid>
          </Grid> 

        </Grid> 
      </Container>
    </>
  );
}
