

export const cloud2 = {
    a: 'discard',
    b: 'discard this video',

    a1: 'discards',
    b1: 'discards this video',

    a2: 'discarded',
    b2: 'discarded this video',


    a3: 'cancel',
    b3: 'cancel this video',

    a4: 'cancels',
    b4: 'cancels this video',

    a5: 'canceled',
    b5: 'canceled this video',


    a6: 'remove',
    b6: 'remove this video',

    a7: 'removes',
    b7: 'removes this video',

    a8: 'removed',
    b8: 'removed this video',


    b9: 'discard the video', 
    b10: 'discards the video',
    b11: 'discarded the video',

    b12: 'cancel the video', 
    b13: 'cancels the video',
    b14: 'canceled the video',

    b15: 'remove the video', 
    b16: 'removes the video',
    b17: 'removed the video',

    b18: 'discard video', 
    b19: 'discards video',
    b20: 'discarded video',

    b21: 'cancel video', 
    b22: 'cancels video',
    b23: 'canceled video',

    b24: 'remove video', 
    b25: 'removes video',
    b26: 'removed video',
 
};

export const cloud3 = {
    a: 'Vimeo Music',
    b: 'DailyMotion Music',
    c: 'Youtube Music',
    d: 'Amazon Music',
    e: 'Spotify Music',
    f: 'Apple Music'
};

export const cloud4 = {
    a: 'resume',
    b: 'resume this video',

    a1: 'resumes',
    b1: 'resumes this video',

    a2: 'resumed',
    b2: 'resumed this video',

    a3: 'replay',
    b3: 'replay this video',

    a4: 'replays',
    b4: 'replays this video',

    a5: 'replayed',
    b5: 'replayed this video',



    b6: 'resume the video',
    b7: 'resume video',

    b8: 'resumes the video',
    b9: 'resumes video',

    b10: 'resumed the video',
    b11: 'resumed video',



    b12: 'replay the video',
    b13: 'replay video',

    b14: 'replays the video',
    b15: 'replays video',

    b16: 'replayed the video',
    b17: 'replayed video',
};


export const cloud5 = {
    a: 'turn',
    b: 'turn down',
    c: 'turn down the volume',

    a1: 'turns',
    b1: 'turns down',
    c1: 'turns down the volume',

    a2: 'turned',
    b2: 'turned down',
    c2: 'turned down the volume',

    c3: 'turn the volume down',
    c4: 'turns the volume down',
    c5: 'turned the volume down',
};

export const cloud6 = {
    a: 'turn',
    b: 'turn up',
    c: 'turn up the volume',

    a1: 'turns',
    b1: 'turns up',
    c1: 'turns up the volume',

    a2: 'turned',
    b2: 'turned up',
    c2: 'turned up the volume',

    c3: 'turn the volume up',
    c4: 'turns the volume up',
    c5: 'turned the volume up',
};

export const cloud7 = {
    a: 'stop',
    b: 'stop playing',
    c: 'stop playing this video',

    a1: 'stops',
    b1: 'stops playing',
    c1: 'stops playing this video',

    a2: 'stopped',
    b2: 'stopped playing',
    c2: 'stopped playing this video',

    c3: 'stop playing the video',
    c4: 'stops playing the video',
    c5: 'stopped playing the video',

    c6: 'stop playing video',
    c7: 'stops playing video',
    c8: 'stopped playing video',
};

export const cloud8 = {
    a: false,
    b: true,
    c: 20
};

export const cloud9 = {
    a: 'album',
    b: 'playlist',
    c: 'artist',
    d: 'track'
};

export const cloud10 = { 
    a: 0.2
};

export const cloud11 = {
    a: 'process command play'
};

export const cloud12 = {
    a: 'play',
    b: 'plays'
};


export const cloud74 = {
    a: 'Hold on please,  I am dealing with that'
};


export const cloud75 = {
    a: 'process command search'
};

export const cloud76 = {
    a: 'process command radio',
    b: 'radio'
};

export const cloud77 = {
    a: 'process command tv',
    b: 'tv'
};

export const cloud78 = {
    a: 'process command radio tv'
};




export const DMPlay = async (player) => {
    await player.map((item) => (
        item.play() 
    ));
}


export const DMPause = async (player) => {
    await player.map((item) => (
        item.pause() 
    ));
}

export const YTPlay = async (player) => {
    await player.map((item) => (
        item.playVideo() 
    ));
}

export const YTPause = async (player) => {
    await player.map((item) => (
        item.pauseVideo() 
    ));
}