
export const cloud104a = {
    a: 'comment',
    b: 'add', 
    c: 'comments',
    d: 'adds',
    e: 'commented',
    f: 'added',
    g: 'dreams',
    h: 'the dreams',
    i: 'dream',
    j: 'the dream',
    k: 'dreamed'
};


export const cloud107a = {
    a: 'send comment',
    b: 'send comment.',
    c: 'send a comment',
    d: 'send this comment',
    e: 'sent comment',
    f: 'sent comment.',
    g: 'sent a comment',
    h: 'sent this comment',
    i: 'sends comment',
    j: 'sends comment.',
    k: 'sends a comment',
    l: 'sends this comment',

    a1: 'send dream',
    a2: 'send dreams.',
    a3: 'send a dream',
    a4: 'send this dream',
    a5: 'sent dream',
    a6: 'sent dreams',
    a7: 'sent a dream',
    a8: 'sent this dream',
    a9: 'sends dream',
    a10: 'sends dreams',
    a11: 'sends a dream',
    a12: 'sends this dream'
};
