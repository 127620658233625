import PropTypes from 'prop-types';
import { subDays,  } from 'date-fns';
import { noCase } from 'change-case';
// import { faker } from '@faker-js/faker';
import { useEffect, useRef, useState, useMemo, memo } from 'react';  
import { useCookies  } from 'react-cookie';
import { useNavigate,  } from 'react-router-dom'; 
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import { useSpeechRecognition } from "react-speech-recognition";


// @mui 
import {
  Box,
  List,  
  Button, 
  Avatar, 
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText, 
  ListItemAvatar,
  ListItemButton,
  TextField,
  Grid,
  InputAdornment 
} from '@mui/material';
 

// utils
import { fToNow } from '../../../utils/formatTime';
import {reverseString, } from '../../../utils/formatWord';

// components 
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

// util
import {useWindowDimensions} from '../../../utils/otherFunctions';


import {domainName} from '../../../utils/domain';



// util
import { removeCommaQuestionMarkFullStopLowerCase, transMany__, newLang, transMany, firstElementOfString, 
         removeFirstElementFromString, stringComparison, cloud83, cloud104, cloud107
     } from '../../../utils/translateFunctions';



const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;

// ----------------------------------------------------------------------


// const socket = io(`${domainName}`);

export const CommentChat = memo(({openComment, handleCloseComment, handleNumberComment, RoomId}) => { 
  const navigate = useNavigate();   
  const socket = useMemo(() => io(`${domainName}`), []); 


  const [numberNotifications, setNumberNotifications] = useState(0);
  const notifications = useRef([]); 
  const inputValue = useRef('');  
  const token = useRef(null); 
  const type = useRef(null);


  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);
 
  let profilename = ''; 
  let imagename = '';
  const newRoom = RoomId;
  


  if(allDataOnceReducerValue.user){  
    profilename = allDataOnceReducerValue.user.usersDetails[0].NewFullName;
    imagename = allDataOnceReducerValue.user.usersDetails[0].Photo;
  } 

  
  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
        token.current = cookies._token_planetryvilage.token; 
        type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 
 
 
  // const totalComments = notifications.current.length; 

  // const totalComments = notifications.current.length;


  
  const i = useRef(0);
  useEffect(() => { 
    if(i.current === 0) socket.emit('startCommentCompetitionVideoAudio', newRoom, imagename, profilename);

    socket.on('messageCommentCompetitionVideoAudio', async (message) => {    

      const CommentElement = await { 
        fullName: message.personName,
        description: message.description,
        photo: message.imagename,  
        createdAt: subDays(new Date(`${reverseString(message.current_date)} ${message.current_time}`), 0), 
      }   

      notifications.current = [].concat(CommentElement, notifications.current); 
      setNumberNotifications(notifications.current.length); 
    });

    i.current +=1;

    return () => { 
      socket.off('messageCommentCompetitionVideoAudio'); 
    }; 
  }, [socket, newRoom, imagename, profilename]); 
 
 



  const { height } = useWindowDimensions();  

  const HEIGHTDesktop = `${height/3 *1.5}px`;  
  const HEIGHTMobile = `${height/2}px`; 




  const sendMessage = async (token, userType, room, profilename, imagename, text, currentTime, currentDate) => { 
    const message = await {
      token,
      userType,
      room,
      profilename,
      imagename,
      text,
      currentTime,
      currentDate
    }; 

    await socket.emit('messageCommentCompetitionVideoAudio2', message);
  }
 

  const handleSendDream = async () => {  
    if(inputValue.current.value){   
      const messageMessage = await inputValue.current.value;
      const currentTime = await new Date().toLocaleTimeString();  
      const currentDate = await new Date().toLocaleDateString();  
 

      const CommentElement = await {
        // id: faker.datatype.uuid(),
        fullName: profilename,
        description: messageMessage,
        photo: imagename,  
        createdAt: subDays(new Date(`${reverseString(currentDate)} ${currentTime}`), 0), 
      }   

      notifications.current = [].concat(CommentElement, notifications.current);

      await sendMessage(token.current, type.current, newRoom, profilename, imagename, messageMessage, currentTime, currentDate);

      setNumberNotifications(notifications.current.length);
      inputValue.current.value = '';  
    } 
  };
  
  useEffect(() => {
    handleNumberComment(numberNotifications); 
  }); 



  


  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition();  
    

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) { 
        let comment;  

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  
          // const removeFirstElementFromString_ = await removeFirstElementFromString(t); 
          
          // add a comment
          if (await stringComparison([cloud104.a, cloud104.b, cloud104.c, cloud104.d, cloud104.e, cloud104.f], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            comment = await tm;   
            inputValue.current.value = await comment;  
            await inputValue.current.focus();

            speech.text = await transMany__('The comment has been updated') || 'The comment has been updated';
            await speechSynth.speak(speech); 
          }
          
          // send a comment
          if (await stringComparison([cloud107.a, cloud107.b, cloud107.c, cloud107.d, cloud107.e, cloud107.f, cloud107.g, cloud107.h, cloud107.i, cloud107.j, cloud107.k, cloud107.l], t)) {
            const a = await document.getElementsByClassName("send-comment")[0];

            if(await (typeof a !== 'undefined' && a !== null)) {
              await a.click();
            }

            speech.text = await transMany__('The comment has been sent') || 'The comment has been sent';
            await speechSynth.speak(speech); 
          }
           

        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate]);

 
  /* End Michael */ 





  return (
    <> 

      <Popover
        open={Boolean(openComment)}
        anchorEl={openComment}
        onClose={handleCloseComment}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            // ml: 0.75, 
            width: 500, 
          },
        }}
      >  

        <Grid container spacing={1}>
          <Grid  item xs={12} sm={12} md={12}  > 
            <IconButton color="primary" onClick={handleCloseComment}  sx={{p:1,}}>
              <Iconify icon="material-symbols:close" />
            </IconButton>
          </Grid>
          <Grid  item xs={12} sm={12} md={12}  > 
            <Box sx={{p:0, m:0}}>
              <Button fullWidth disableRipple>
                Comments
              </Button>
            </Box> 
          </Grid> 
        </Grid> 

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ maxHeight: { xs: HEIGHTMobile, sm: HEIGHTMobile, md:HEIGHTDesktop } }}>
          <List
            disablePadding 
          >
            {notifications.current.map((notification, key) => (
              <NotificationItem key={key} notification={notification} />
            ))}
          </List> 
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />
 
        <Grid container spacing={1}  sx={{p:1}}> 
          <Grid  item xs={12} sm={12} md={12}  > 
            <Box sx={{ flexGrow: 1, bgcolor:"lightgray"  }}>  
              <TextField fullWidth inputRef={inputValue} // value={inputValue}  // onChange={(e) => setInputValue(e.target.value)}
              InputProps={{
              	endAdornment: (
              		inputValue &&  <InputAdornment position="end"> 
              		  <IconButton color="primary" className="send-comment" onClick={handleSendDream}>
                        <Iconify icon="fa:send-o" />
                      </IconButton>
              		</InputAdornment>
                ),
              }} 
              name="dream" label="Comment" /> 
            </Box>
          </Grid> 
        </Grid> 
      </Popover>
    </>
  );
})

CommentChat.propTypes = { 
  openComment: PropTypes.any, 
  handleCloseComment: PropTypes.func,
  handleNumberComment: PropTypes.func, 
  RoomId: PropTypes.string, 
};

// ----------------------------------------------------------------------


const NotificationItem = memo(({ notification }) => {
  const { photo, fullName } = renderContent(notification);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral', width:30, height:30 }}>{photo}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={fullName}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.secondary',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
})


NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    fullName: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    photo: PropTypes.any,
  }),
};

// ----------------------------------------------------------------------

function renderContent(notification) {
  const fullName = (
    <Typography variant="subtitle2">
      {notification.fullName}
      <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  return {
    photo: notification.photo ? <img alt={notification.fullName} src={`${domainName}/EddyDreams/Home/images/${notification.photo}`} /> : null,
    fullName,
  }; 
}
