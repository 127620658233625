
const BusinessCardActionTypes = { 
    Business_Card_REQUEST: 'Business_Card_REQUEST',
    Business_Card_SUCCESS:'Business_Card_SUCCESS',
    Business_Card_FAILURE: 'Business_Card_FAILURE', 
}
 
export default BusinessCardActionTypes

  

