

// ----------------------------------------------------------------------

const easterBackgroundDetails = [
  {
  	id: 1, 
    back: 'product4.gif'
   },  

  {
  	id: 2, 
    back: 'product4b.gif'
   },  
   
  {
  	id: 3, 
    back: 'product4c.gif'
   },  
   
  {
  	id: 4, 
    back: 'product4d.gif'
   },  
   
  {
  	id: 5, 
    back: 'product4e.gif'
   },  
   
  {
  	id: 6, 
    back: 'product4f.gif'
   },  
   
  {
  	id: 7, 
    back: 'product4g.gif'
   },  
   
  {
  	id: 8, 
    back: 'product4h.gif'
   },  
   
  {
  	id: 9, 
    back: 'product4i.gif'
   },  
   
  {
  	id: 10, 
    back: 'product4j.gif'
   },  
   
  {
  	id: 11, 
    back: 'easter1.jpg'
   },  
   
  {
  	id: 12, 
    back: 'easter2.jpg'
   },  
   
  {
  	id: 13, 
    back: 'easter3.jpg'
   },  
   
  {
  	id: 14, 
    back: 'easter4.jpg'
   },  
   
  {
  	id: 15, 
    back: 'easter5.jpg'
   },  
   
  {
  	id: 16, 
    back: 'easter6.jpg'
   },  
   
  {
  	id: 17, 
    back: 'easter7.jpg'
   },  
   
  {
  	id: 18, 
    back: 'easter8.jpg'
   },  
   
  {
  	id: 19, 
    back: 'easter9.jpg'
   },  
   
  {
  	id: 20, 
    back: 'easter10.jpg'
   },   
   
  {
  	id: 21, 
    back: 'easter11.jpg'
   },   
   
  {
  	id: 22, 
    back: 'easter12.jpg'
   },   
   
  {
  	id: 23, 
    back: 'easter13.jpg'
   },   
   
  {
  	id: 24, 
    back: 'easter14.jpg'
   },   
   
  {
  	id: 25, 
    back: 'easter15.jpg'
   },   
   
  {
  	id: 26, 
    back: 'easter16.jpg'
   },   
   
  {
  	id: 27, 
    back: 'easter17.jpg'
   },   
   
  {
  	id: 28, 
    back: 'easter18.jpg'
   },   
   
  {
  	id: 29, 
    back: 'easter19.jpg'
   },   
   
  {
  	id: 30, 
    back: 'easter20.jpg'
   },   
 
];

export default easterBackgroundDetails;
