

import ViewOneVideoPhotoActionTypes from './viewOneVideoPhotoTypes'


export const ViewOneVideoPhotoREQUEST = (item) => ({
    type: ViewOneVideoPhotoActionTypes.View_Video_Photo_REQUEST,
    payload: item
}) 
 

export const ViewOneVideoPhotoRESET = item => ({
    type: ViewOneVideoPhotoActionTypes.View_Video_Photo_RESET,
    payload: item
}) 