// ----------------------------------------------------------------------

const eventCardDetails = [
  {
    id: 1,  
    numberOfLikes: '20000',
    numberOfViews: '588888000',
    numberOfComments: '5',
    message: "Bill Gates never worked alone, he started Microsoft with his friend Paul Allen,  and then worked with a team. At the beginning of Microsoft, he read every code sold.",
    excited: "Is this video extremely astonishing?",
    category: 'Christmas',
    fileURL: 'ai.mp4',
    photoURL: 'inves2.jpeg',
    backgroundPhotoURL: 'inves1.jpeg',
    fullName: 'Ariana Poline',
    city: 'California',
    country: 'USA',
    continent: 'America'
   },

  {
    id: 2,
    numberOfLikes: '358952',
    numberOfViews: '952125544',
    numberOfComments: '560011222',
    message: "Bill Gates never worked alone, he started Microsoft with his friend Paul Allen,  and then worked with a team. At the beginning of Microsoft, he read every code sold.",
    excited: 'Is this video extremely astonishing?',
    category: 'New Year',
    fileURL: 'ai.mp4',
    photoURL: 'inves2.jpeg',
    backgroundPhotoURL: 'inves1.jpeg',
    fullName: 'Ariana Poline',
    city: 'California',
    country: 'USA',
    continent: 'America'
  },

  {
    id: 3,
    numberOfLikes: '8562132000',
    numberOfViews: '85544',
    numberOfComments: '24551586',
    message: "Bill Gates never worked alone, he started Microsoft with his friend Paul Allen,  and then worked with a team. At the beginning of Microsoft, he read every code sold.",
    excited: 'Is this video extremely astonishing?',
    category: 'Thanksgiving',
    fileURL: 'ai.mp4',
    photoURL: 'inves2.jpeg',
    backgroundPhotoURL: 'inves1.jpeg',
    fullName: 'Ariana Poline',
    city: 'California',
    country: 'USA',
    continent: 'America'
  },
 
];

export default eventCardDetails;
 