import { useState, useEffect, useRef, useCallback  } from 'react'; 
import { useDispatch, useSelector } from 'react-redux'; 
import { useParams } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

// @mui
import { Stack, TextField, IconButton, InputAdornment, Card, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab'; 

// icons
import { Icon } from '@iconify/react'; 

// components
import Iconify from '../../../components/iconify'; 


import {PasswordActivationREQUEST, PasswordActivationRESET} from '../../../redux/passwordActivation/passwordActivationActions'; 


import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud, cloud13, cloud63,
       cloud66, cloud67, cloud83, cloud88, cloud89___, cloud101, restructurePassword,
       scrollDownMain, scrollUpMain} from '../../../utils/translateFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------

export default function ForgottenPasswordForm2() {
  const dispatch = useDispatch(); 
  const { _emailVerificationId } = useParams();   


  const passwordActivationValue = useSelector(state => state.passwordActivationValue);

  
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");  
  const [initializePasswordActivation, setInitializePasswordActivation] = useState({
    emailVerificationId:_emailVerificationId, 
    password: ''
  });


  
  useEffect(() => {  
    if(passwordActivationValue.user){
      if(passwordActivationValue.user.message === "An error occured!"){
        setError("An error occured!"); 
      } 
      else 
      if(passwordActivationValue.user.message === "You cannot change your password."){
        setError("You cannot change your password."); 
      }else 
      if(passwordActivationValue.user.message === "Your password has been activated. You can now log in"){
        setError("Your password has been activated. You can now log in"); 
      } 

      dispatch(PasswordActivationRESET(null));
    } 
  },[passwordActivationValue.user, dispatch]);  



  const handleClick = useCallback(async () => {
    
    if(await (initializePasswordActivation.emailVerificationId).trim() === ''){
      setError('The email Verification Id is required');
      return;
    } 

    if(await (initializePasswordActivation.password).trim() === ''){
      setError('The new password is required');
      return;
    }

    dispatch(PasswordActivationREQUEST(initializePasswordActivation));
  }, [dispatch, initializePasswordActivation]);



  const onChangePassword = (e) => {
    setInitializePasswordActivation({
      initializePasswordActivation, 
      ...{
        password: e.target.value, 
        emailVerificationId: initializePasswordActivation.emailVerificationId, 
      }
    });

    setError('');
  }




  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    isMicrophoneAvailable
  } = useSpeechRecognition(); 
  
  const scrollMain = useRef(0); 

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {     
        let password_;  

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));     
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_); 


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;
          const firstElementOfString_ = await firstElementOfString(t); 

  
          if (await stringComparison([cloud89___.a], firstElementOfString_)) {
            t = await firstElementOfString_;

            const a2 = await transMany__(`${'4 '}${firstElementOfString_}`) || `${'4 '}${firstElementOfString_}`;
            const a = await a2.replace("4 ", "");

            if (await (a.match(/(\w+)/g).length === 1)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            }

            password_ = await tm;
          } else {
            password_ = await '';
          }

 
          
          /* set password */
          if (await stringComparison([cloud89___.a], t)) {
            await setInitializePasswordActivation({
              initializePasswordActivation, 
              ...{
                emailVerificationId: initializePasswordActivation.emailVerificationId, 
                password: restructurePassword(password_), 
              }
            }); 

            await setError('');

            speech.text = await transMany__('The password has been updated') || 'The password has been updated';
            speechSynth.speak(speech);
          }
          
          /* show password */
          if (await stringComparison([cloud101.a1, cloud101.a2, cloud101.a3, cloud101.a4, cloud101.a5, cloud101.a6, cloud101.a7, cloud101.a8, cloud101.a9, cloud101.a10, cloud101.a11, cloud101.a12, cloud101.a13, cloud101.a14, cloud101.a15, cloud101.a16, cloud101.a17, cloud101.a18], t)) {
            await setShowPassword(!showPassword);

            speech.text = await transMany__('The visibility of the password has changed') || 'The visibility of the password has changed';
            speechSynth.speak(speech);
          } 
           
           
          
          /* send the form */
          if (await stringComparison([cloud88.a, cloud88.b, cloud88.c, cloud88.d, cloud88.e, cloud88.f, cloud88.g, cloud88.h, cloud88.i, cloud88.j], t)) {
            await handleClick();  
          }

          
          /* beginning down up stop scrollbar */
          if (await stringComparison([cloud67.a, cloud67.b, cloud67.c], t)) { 
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollDownMain(window);
          }

          if (await stringComparison([cloud66.a, cloud66.b, cloud66.c], t)) {  
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollUpMain(window);
          }

          if (await stringComparison([cloud63.a, cloud63.b, cloud63.c], t)) {  
            await clearInterval(scrollMain.current);
          }
          /* end down up stop scrollbar */


          /* Michael what's your name */
          if (await stringComparison([cloud13.a, cloud13.b, cloud13.c, cloud13.d, cloud13.a2, cloud13.b2, cloud13.c2, cloud13.d2], t)) {
            speech.text = await transMany__(cloud13.e) || cloud13.e;
            await speechSynth.speak(speech); 
          }
          
          /* Michael where do you live */
          if (await stringComparison([cloud.a, cloud.b, cloud.c, cloud.d, cloud.a2, cloud.b2, cloud.c2, cloud.d2, cloud.a3, cloud.b3, cloud.c3, cloud.d3, cloud.d4, cloud.d5], t)) {
            speech.text = await transMany__(cloud.e) || cloud.e;
            await speechSynth.speak(speech); 
          } 
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, initializePasswordActivation, showPassword, handleClick]);


  const listenContinuously = () => {
    if (isMicrophoneAvailable && SpeechRecognition.browserSupportsSpeechRecognition()) {
      SpeechRecognition.startListening({
        continuous: true,
        language: newLang(),
      });
    }
  }; 
   
  
  useEffect(() => {
    listenContinuously();
  })

  const changeContinuityStop = () => {

    if(listening) {
      SpeechRecognition.stopListening();
    } else {
      listenContinuously();
    } 
  }
  /* End Michael */  

  return (
    <>
     <Card sx={{p:2, boxShadow:10, }}>
      <Stack sx={{ my: 2 }}>
        <Box component={Icon} icon='game-icons:robot-golem' onClick={changeContinuityStop} sx={{ width: {xs:30, md:30}, height: {xs:30, md:30}, cursor:'pointer', m:1 }} />  

        <TextField
          value={initializePasswordActivation.password} onChange ={onChangePassword}
          name="password"
          label="New Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Typography variant='subtitle2' color='red'>{error}</Typography>
      </Stack> 

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Send
      </LoadingButton> 
     </Card>
    </>
  );
}
