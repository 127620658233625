 
import PropTypes from 'prop-types';
import {Grid} from '@mui/material'; 



import {domainName} from '../../../../utils/domain';


export default function Page4 ({page4Features}) {  
  
  const {
    height,  
    eventCardBackgroundName 
  } = page4Features;
 
  const backImage = `${domainName}${'/EddyDreams/gifs/'}${eventCardBackgroundName}`;  

  return (   
      <>
        <Grid container spacing={1} sx={{height:{height}, backgroundColor:'white', backgroundImage:`url(${backImage})`, backgroundSize: 'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} > 
          {}
        </Grid>
      </>   
  );
}


Page4.propTypes = {    
  page4Features: PropTypes.object, 
}; 
 

