
import { useEffect, useRef, memo  } from 'react';  
import { useCookies  } from 'react-cookie';
import { useNavigate,  } from 'react-router-dom'; 
import { useDispatch, } from 'react-redux';
import PropTypes from 'prop-types';

import FlagCircleIcon from '@mui/icons-material/FlagCircle';
import CloseIcon from '@mui/icons-material/Close'; 

// @mui 
import { Popover, IconButton, Typography, Divider, Grid } from '@mui/material'; 
 

import {ReportAbuseREQUEST,} from '../../../../redux/reportAbuse/reportAbuseActions';  


const abuseTypeReport = [{
        id: '1',
        type: 'Intellectual Property Infringement'
    },
    {
        id: '2',
        type: 'The user is under 13 years old'
    },
    {
        id: '3',
        type: 'Verbal abuse'
    },
    {
        id: '4',
        type: 'Nudity'
    },
    {
        id: '5',
        type: 'Harassment'
    },
    {
        id: '6',
        type: 'Incitement'
    },
    {
        id: '7',
        type: 'Illegal activities'
    },
    {
        id: '8',
        type: 'Privacy violation'
    },
    {
        id: '9',
        type: 'Hacked account'
    },
    {
        id: '10',
        type: 'Fake account'
    }
]

// ----------------------------------------------------------------------


export const ReportUser = memo(({openReport, handleCloseReport, magazineId}) => { 
   
  const dispatch = useDispatch();  
  const navigate = useNavigate();
  const _token = useRef(null); 
  const _type = useRef(null);
  

  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
        _token.current = cookies._token_planetryvilage.token; 
        _type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 




  const handleReportUser = async (a) => {
    const token = await _token.current
    const type = await _type.current
    const userDivision = await 'Magazines';
    const vId = await magazineId; 
    const abuseTypeName = await a;

    await dispatch(ReportAbuseREQUEST({token, type, userDivision, vId, abuseTypeName}));
  } 
 

  return (
    <> 

      <Popover
        open={Boolean(openReport)}
        anchorEl={openReport}
        onClose={handleCloseReport}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          sx: {
            // p: 1,
            mt: 1.5,
            // ml: 0.75,
            width: 300,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            }, 
          },
        }}
      > 
        <Grid container spacing={0} > 
          <Grid  item xs={12} sm={12} md={12}> 
            <Grid container spacing={0} textAlign='right'> 
              <Grid  item xs={12} sm={12} md={12}>
                <IconButton aria-label="close"  onClick={handleCloseReport}>
                  <CloseIcon sx={{fontSize:{xs:20, md:20}, color: 'black'}} />   
                </IconButton>  
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>  
          
          <Grid  item xs={12} sm={12} md={12}>
          <Typography variant='button' sx={{ml:1}}>Report this user</Typography>
          <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
          

          {abuseTypeReport.map((item, key) => (
          <Grid key={key}  item xs={12} sm={12} md={12}>
            <IconButton aria-label="all" sx={{fontSize:{xs:13, md:15}, }} onClick={() => {handleCloseReport(); handleReportUser(item.type)}}>
              <FlagCircleIcon sx={{fontSize:{xs:15, md:20}}} />  
              <Typography variant='button' sx={{ml:1}}>{item.type}</Typography>
            </IconButton>
          </Grid>
          ))} 
       
        </Grid> 
      </Popover>
    </>
  );
})



ReportUser.propTypes = {     
  openReport: PropTypes.any, 
  handleCloseReport: PropTypes.func, 
  magazineId: PropTypes.number, 
};
 