
const AllDataOnceActionTypes = { 
    All_Data_Once_REQUEST: 'All_Data_Once_REQUEST',
    All_Data_Once_SUCCESS:'All_Data_Once_SUCCESS',
    All_Data_Once_FAILURE: 'All_Data_Once_FAILURE', 

    All_Data_Once_SHUFFLE_PHOTO: 'All_Data_Once_SHUFFLE_PHOTO', 

    All_Data_Once_SHUFFLE_AUDIO: 'All_Data_Once_SHUFFLE_AUDIO', 

    All_Data_Once_SHUFFLE_VIDEO: 'All_Data_Once_SHUFFLE_VIDEO', 

    

    All_Data_Once_SHUFFLE_MAGAZINE: 'All_Data_Once_SHUFFLE_MAGAZINE', 

    All_Data_Once_SHUFFLE_EVENT_CARD: 'All_Data_Once_SHUFFLE_EVENT_CARD', 

    All_Data_Once_SHUFFLE_WISH_SENT: 'All_Data_Once_SHUFFLE_WISH_SENT', 

    All_Data_Once_SHUFFLE_WISH_RECEIVED: 'All_Data_Once_SHUFFLE_WISH_RECEIVED', 
}
 
export default AllDataOnceActionTypes

  

