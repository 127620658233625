 
import { memo } from 'react'; 
import PropTypes from 'prop-types';
// @mui 
import { Typography, ListItemButton, Box } from '@mui/material';

// icons
import { Icon } from '@iconify/react';
 

  
// ---------------------------------------------------------------------- 


export const ViewVillageWidget = memo(({OpenViewVillage}) => {
  const title = "View Village"  

  return (
    <> 
      <ListItemButton onClick={OpenViewVillage}>
        <Typography  sx={{mr:{md:1}, fontSize:{xs:12} }} >{title}</Typography> 
        <Box component={Icon} icon='fontisto:holiday-village' sx={{ width: {xs:35, md:25}, height: {xs:30, md:25}, }} /> 
      </ListItemButton> 
    </>
  );
})

ViewVillageWidget.propTypes = {  
  OpenViewVillage: PropTypes.func, 
};
 