import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
 
// @mui 
import { Stack, IconButton, InputAdornment, 
	TextField, FormControl, Select, MenuItem, InputLabel, Card, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// icons 
import { Icon } from '@iconify/react'; 

// components
import Iconify from '../../../components/iconify'; 


import {RegisterREQUEST, RegisterRESET} from '../../../redux/register/registerActions'; 


import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud, cloud13, cloud63,
       cloud66, cloud67, cloud83, cloud85, cloud87, cloud88, cloud89, cloud89_, cloud89___, cloud89__, cloud90, cloud91, cloud92, cloud93, cloud94, 
       cloud95, cloud96, cloud97, cloud98, cloud99, cloud101, restructureEmail, restructurePassword, 
       fisrtletterofEachStringToUpperCase, scrollDownMain, scrollUpMain, } from '../../../utils/translateFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1; 

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate(); 
  const dispatch = useDispatch(); 


  const registerValue = useSelector(state => state.registerValue);


  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [initializeRegister, setInitializeRegister] = useState({
    full_name: '',
    continent: '',
    country: '',
    city: '',
    user_type: 'New Generation',
    email: '',
    password: ''
  }); 


 

  useEffect(() => {  
    if(registerValue.user){
      if(registerValue.user.message === "An error occured!"){ 
        setError("An error occured!"); 
      } 
      else 
      if(registerValue.user.message === "This account (email) is already in use. Please log in."){
        setError("This account (email) is already in use. Please log in."); 
      } 
      else 
      if(registerValue.user.message === "success"){ 
        navigate('/login', { replace: true }); 
      }  

      dispatch(RegisterRESET(null));
    } 
  },[registerValue.user, dispatch, navigate]);  


   

  const handleClick = useCallback(async () => { 

    if(await (initializeRegister.full_name).trim() === ''){
      setError('The full name is required');
      return;
    } 

    if(await (initializeRegister.continent).trim() === ''){
      setError('The continent is required');
      return;
    }

    if(await (initializeRegister.country).trim() === ''){
      setError('The country is required');
      return;
    } 

    if(await (initializeRegister.city).trim() === ''){
      setError('The city is required');
      return;
    }

    if(await (initializeRegister.email).trim() === ''){
      setError('The email is required');
      return;
    } 

    if(await (initializeRegister.password).trim() === ''){
      setError('The password is required');
      return;
    }

    dispatch(RegisterREQUEST(initializeRegister));

  }, [dispatch, initializeRegister]);
 
 

  const onChangeFullName = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: e.target.value,
        continent: initializeRegister.continent,
        country: initializeRegister.country,
        city: initializeRegister.city,
        user_type: initializeRegister.user_type,
        email: initializeRegister.email,
        password: initializeRegister.password
      }
    });

    setError('');
  }


  const onChangeContinent = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: initializeRegister.full_name,
        continent: e.target.value,
        country: initializeRegister.country,
        city: initializeRegister.city,
        user_type: initializeRegister.user_type,
        email: initializeRegister.email,
        password: initializeRegister.password
      }
    });

    setError('');
  }
 

  const onChangeCountry = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: initializeRegister.full_name,
        continent: initializeRegister.continent,
        country: e.target.value,
        city: initializeRegister.city,
        user_type: initializeRegister.user_type,
        email: initializeRegister.email,
        password: initializeRegister.password
      }
    });

    setError('');
  }

  const onChangeCity = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: initializeRegister.full_name,
        continent: initializeRegister.continent,
        country: initializeRegister.country,
        city: e.target.value,
        user_type: initializeRegister.user_type,
        email: initializeRegister.email,
        password: initializeRegister.password
      }
    });

    setError('');
  }


  const onChangeEmail = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: initializeRegister.full_name,
        continent: initializeRegister.continent,
        country: initializeRegister.country,
        city: initializeRegister.city,
        user_type: initializeRegister.user_type,
        email: e.target.value,
        password: initializeRegister.password
      }
    });

    setError('');
  }

  const onChangePassword = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: initializeRegister.full_name,
        continent: initializeRegister.continent,
        country: initializeRegister.country,
        city: initializeRegister.city,
        user_type: initializeRegister.user_type,
        email: initializeRegister.email,
        password: e.target.value
      }
    });
    
    setError('');
  }

  const onChangeDivision = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: initializeRegister.full_name,
        continent: initializeRegister.continent,
        country: initializeRegister.country,
        city: initializeRegister.city,
        user_type: e.target.value,
        email: initializeRegister.email,
        password: initializeRegister.password
      }
    });
    
    setError('');
  };

 


  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    isMicrophoneAvailable
  } = useSpeechRecognition(); 
  
  const scrollMain = useRef(0); 

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {     
        let names; let continent_; let country_; let city_; let email_; let password_; 

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));     
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_); 




        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11, cloud83.a12], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;
          const firstElementOfString_ = await firstElementOfString(t);  

          
          /* navigate to login page */
          if (await stringComparison([cloud85.a, cloud85.b, cloud85.c, cloud85.d, cloud85.e, cloud85.f, cloud85.g, cloud85.h, cloud85.i], t)) {
            navigate('/login', { replace: true }); 
          }
          
          /* navigate to forgotten password page */
          if (await stringComparison([cloud87.a, cloud87.b, cloud87.c, cloud87.d, cloud87.e, cloud87.f, cloud87.g], t)) {
            navigate('/forgotten/password', { replace: true }); 
          }

          if (await stringComparison([cloud89_.a, cloud89_.b, cloud89_.c], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            names = await tm;
          } else {
            names = await '';
          }

          if (await stringComparison([cloud89.a], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            continent_ = await tm;
          } else {
            continent_ = await '';
          }

          if (await stringComparison([cloud90.a], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            country_ = await tm;
          } else {
            country_ = await '';
          }

          if (await stringComparison([cloud91.a], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            city_ = await tm;
          } else {
            city_ = await '';
          }


          if (await stringComparison([cloud89__.a], firstElementOfString_)) {
            t = await firstElementOfString_; 

            const a3 = await transMany__(`${'4 '}${firstElementOfString_}`) || `${'4 '}${firstElementOfString_}`;
            const a = await a3.replace("4 ", "");

            if (await (a.match(/(\w+)/g).length === 1)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            }

            email_ = await tm;
          } else {
            email_ = await '';
          }


          if (await stringComparison([cloud89___.a], firstElementOfString_)) {
            t = await firstElementOfString_;

            const a2 = await transMany__(`${'4 '}${firstElementOfString_}`) || `${'4 '}${firstElementOfString_}`;
            const a = await a2.replace("4 ", "");

            if (await (a.match(/(\w+)/g).length === 1)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            }

            password_ = await tm;
          } else {
            password_ = await '';  
          } 
          
          
          /* set names */
          if (await stringComparison([cloud89_.a, cloud89_.b, cloud89_.c], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: fisrtletterofEachStringToUpperCase(names),
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: initializeRegister.user_type,
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');

            speech.text = await transMany__('The name has been updated') || 'The name has been updated';
            speechSynth.speak(speech);
          }

          
          /* set continent */
          if (await stringComparison([cloud89.a], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: fisrtletterofEachStringToUpperCase(continent_),
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: initializeRegister.user_type,
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');

            speech.text = await transMany__('The continent has been updated') || 'The continent has been updated';
            speechSynth.speak(speech);
          }

          
          /* set country */
          if (await stringComparison([cloud90.a], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: fisrtletterofEachStringToUpperCase(country_),
                city: initializeRegister.city,
                user_type: initializeRegister.user_type,
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');

            speech.text = await transMany__('The country has been updated') || 'The country has been updated';
            speechSynth.speak(speech);
          } 

          
          /* set city */
          if (await stringComparison([cloud91.a], t)) { 
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: fisrtletterofEachStringToUpperCase(city_),
                user_type: initializeRegister.user_type,
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');

            speech.text = await transMany__('The city has been updated') || 'The city has been updated';
            speechSynth.speak(speech);
          }



          if (await stringComparison([cloud92.a, cloud92.b, cloud92.c, cloud92.d, cloud92.e], t)) {  
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: 'New Generation',
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');  

            speech.text = await transMany__('New Generation has been selected') || 'New Generation has been selected';
            speechSynth.speak(speech);
          }


          if (await stringComparison([cloud93.a, cloud93.b, cloud93.c, cloud93.d, cloud93.e, cloud93.f], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: 'Student',
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');  

            speech.text = await transMany__('Student has been selected') || 'Student has been selected';
            speechSynth.speak(speech);
          }


          if (await stringComparison([cloud94.a, cloud94.b, cloud94.c, cloud94.d, cloud94.e, cloud94.f], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: 'Pupil',
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');  

            speech.text = await transMany__('Pupil has been selected') || 'Pupil has been selected';
            speechSynth.speak(speech);
          }


          if (await stringComparison([cloud95.a, cloud95.b, cloud95.c, cloud95.d, cloud95.e], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: 'Investor',
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');  

            speech.text = await transMany__('Investor has been selected') || 'Investor has been selected';
            speechSynth.speak(speech);
          }

          if (await stringComparison([cloud96.a, cloud96.b, cloud96.c], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: 'Organisation Owner',
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');  

            speech.text = await transMany__('Organisation Owner has been selected') || 'Organisation Owner has been selected';
            speechSynth.speak(speech);
          }


          if (await stringComparison([cloud97.a, cloud97.b, cloud97.c], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: 'Shop Owner',
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');  

            speech.text = await transMany__('Shop Owner has been selected') || 'Shop Owner has been selected';
            speechSynth.speak(speech);
          }
 

          if (await stringComparison([cloud98.a, cloud98.b, cloud98.c, cloud98.d], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: 'Elderly People',
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');  

            speech.text = await transMany__('Elderly People has been selected') || 'Elderly People has been selected';
            speechSynth.speak(speech);
          }

          if (await stringComparison([cloud99.a, cloud99.b, cloud99.c], t)) {
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: 'Patient',
                email: initializeRegister.email,
                password: initializeRegister.password
              }
            });

            await setError('');  

            speech.text = await transMany__('Patient has been selected') || 'Patient has been selected';
            speechSynth.speak(speech);
          }
 
          
          /* set email */
          if (await stringComparison([cloud89__.a], t)) { 
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: initializeRegister.user_type,
                email: restructureEmail(email_),
                password: initializeRegister.password
              }
            });

            await setError('');

            speech.text = await transMany__('The email has been updated') || 'The email has been updated';
            speechSynth.speak(speech);
          }

          
          /* set password */
          if (await stringComparison([cloud89___.a], t)) { 
            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city,
                user_type: initializeRegister.user_type,
                email: initializeRegister.email,
                password: restructurePassword(password_)
              }
            });

            await setError('');

            speech.text = await transMany__('The password has been updated') || 'The password has been updated';
            speechSynth.speak(speech);
          }
          
          /* show password */
          if (await stringComparison([cloud101.a1, cloud101.a2, cloud101.a3, cloud101.a4, cloud101.a5, cloud101.a6, cloud101.a7, cloud101.a8, cloud101.a9, cloud101.a10, cloud101.a11, cloud101.a12, cloud101.a13, cloud101.a14, cloud101.a15, cloud101.a16, cloud101.a17, cloud101.a18], t)) {
            await setShowPassword(!showPassword);

            speech.text = await transMany__('The visibility of the password has changed') || 'The visibility of the password has changed';
            speechSynth.speak(speech);
          } 
           
          
          /* send the form */
          if (await stringComparison([cloud88.a, cloud88.b, cloud88.c, cloud88.d, cloud88.e, cloud88.f, cloud88.g, cloud88.h, cloud88.i, cloud88.j], t)) {
            await handleClick();  
          }

          
          /* beginning down up stop scrollbar */
          if (await stringComparison([cloud67.a, cloud67.b, cloud67.c], t)) { 
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollDownMain(window);
          }

          if (await stringComparison([cloud66.a, cloud66.b, cloud66.c], t)) {  
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollUpMain(window);
          }

          if (await stringComparison([cloud63.a, cloud63.b, cloud63.c], t)) {  
            await clearInterval(scrollMain.current);
          }
          /* end down up stop scrollbar */


          /* Michael what's your name */
          if (await stringComparison([cloud13.a, cloud13.b, cloud13.c, cloud13.d, cloud13.a2, cloud13.b2, cloud13.c2, cloud13.d2], t)) {
            speech.text = await transMany__(cloud13.e) || cloud13.e;
            await speechSynth.speak(speech); 
          }
          
          /* Michael where do you live */
          if (await stringComparison([cloud.a, cloud.b, cloud.c, cloud.d, cloud.a2, cloud.b2, cloud.c2, cloud.d2, cloud.a3, cloud.b3, cloud.c3, cloud.d3, cloud.d4, cloud.d5], t)) {
            speech.text = await transMany__(cloud.e) || cloud.e;
            await speechSynth.speak(speech); 
          } 
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, showPassword, initializeRegister, handleClick, navigate]);


  const listenContinuously = () => {
    if (isMicrophoneAvailable && SpeechRecognition.browserSupportsSpeechRecognition()) {
      SpeechRecognition.startListening({
        continuous: true,
        language: newLang(),
      });
    }
  }; 
   
  
  useEffect(() => {
    listenContinuously();
  })

  const changeContinuityStop = () => {

    if(listening) {
      SpeechRecognition.stopListening();
    } else {
      listenContinuously();
    } 
  }
  /* End Michael */  


  return (
    <>
     <Card sx={{p:2, boxShadow:10, }}> 
      <Stack spacing={3}>
        <Box component={Icon} icon='game-icons:robot-golem' onClick={changeContinuityStop} sx={{ width: {xs:30, md:30}, height: {xs:30, md:30}, cursor:'pointer' }} />  


        <TextField value={initializeRegister.full_name} onChange={onChangeFullName} name="full_name" label="Full Name" />

        {/* <FormControl>
            <InputLabel id="demo-simple-select-autowidth-label-continent">Continent</InputLabel>
            <Select
                labelId="demo-simple-select-autowidth-label-continent"
                id="demo-simple-select-autowidth-g"
                value={initializeRegister.continent}
                onChange={onChangeContinent}
                autoWidth
                label="Continent" 
            >   

              <MenuItem  value={'Africa'}>Africa</MenuItem> 
              <MenuItem  value={'Asia'}>Asia</MenuItem>  
              <MenuItem  value={'Antarctica'}>Antarctica</MenuItem> 
              <MenuItem  value={'Europe'}>Europe</MenuItem> 
              <MenuItem  value={'Oceania'}>Oceania</MenuItem> 
              <MenuItem  value={'North America'}>North America</MenuItem> 
              <MenuItem  value={'South America'}>South America</MenuItem> 
            </Select>
        </FormControl>


        <FormControl>
            <InputLabel id="demo-simple-select-autowidth-label-country">Country</InputLabel>
            <Select
                labelId="demo-simple-select-autowidth-label-country"
                id="demo-simple-select-autowidth-g"
                value={initializeRegister.country}
                onChange={onChangeCountry}
                autoWidth
                label="Country" 
            >   
              
              {ContinentCountry.filter((file) => file.continent.includes(initializeRegister.continent) ).map((item, key) => (
              <MenuItem  key={key} value={item.country}>{item.country}</MenuItem>  
              ))} 
            </Select>
        </FormControl> */}

        <TextField value={initializeRegister.continent} onChange={onChangeContinent} name="Continent" label="Continent" /> 
        
        <TextField value={initializeRegister.country} onChange={onChangeCountry} name="Country" label="Country" /> 
        
        <TextField value={initializeRegister.city} onChange={onChangeCity} name="City" label="City" />


        <FormControl>
            <InputLabel id="demo-simple-select-autowidth-label-g">Select a category</InputLabel>
            <Select
                labelId="demo-simple-select-autowidth-label-g"
                id="demo-simple-select-autowidth-g"
                value={initializeRegister.user_type}
                onChange={onChangeDivision}
                autoWidth
                label="Select a category" 
            >   
                <MenuItem value={'New Generation'}>New Generation</MenuItem>
                <MenuItem value={'Student'}>Student</MenuItem>
                <MenuItem value={'Pupil'}>Pupil</MenuItem> 
                <MenuItem value={'Investor'}>Investor</MenuItem> 
                <MenuItem value={'Organisation Owner'}>Organisation Owner</MenuItem> 
                <MenuItem value={'Shop Owner'}>Shop Owner</MenuItem> 
                <MenuItem value={'Elderly People'}>Elderly People</MenuItem> 
                <MenuItem value={'Patient'}>Patient</MenuItem>  
            </Select>
        </FormControl>


        <TextField name="email" value={initializeRegister.email} onChange={onChangeEmail} label="Email address" />

        <TextField
          name="password"
          label="Password"
          value={initializeRegister.password}
          onChange={onChangePassword}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Typography variant='subtitle2' color='red'>{error}</Typography>
      </Stack> 

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} sx={{ mt: 2 }}>
        Sign Up
      </LoadingButton> 
     </Card>
    </>
  );
}
