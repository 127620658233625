
import { useState, useEffect, useRef } from 'react';   
import { useCookies  } from 'react-cookie';
import { useNavigate,  } from 'react-router-dom'; 
import { useDispatch, useSelector } from 'react-redux';   

// mui
import { Typography, Grid, Stack, InputAdornment, IconButton, Autocomplete, Popper, TextField, } from '@mui/material';  
import { grey } from '@mui/material/colors'; 

import { styled } from '@mui/material/styles';

 

// components  
import Iconify from '../../../components/iconify';


import {GoLiveItem, } from './AllCreatedFiles';
 
  
import {TvRadioStreamingREQUEST,} from '../../../redux/tvRadioStreaming/tvRadioStreamingActions';
 


// ---------------------------------------------------------------------- 

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

export default function TvStreaming() { 
  const navigate = useNavigate();  
  const dispatch = useDispatch();

  const inputValue = useRef('');  
 
 
  const features = {
  	buttonText: 'TV stream',  
  	backColor: grey[400], 
  	color: grey[900], 
    spec: 'Tv streaming'
  };

  

  const tvRadioStreamingReducerValue = useSelector(state => state.tvRadioStreamingReducerValue); 

  let tvRadioStreamingValue = [];  

  if(tvRadioStreamingReducerValue.user){
    tvRadioStreamingValue = tvRadioStreamingReducerValue.user.message;   
  } 
 


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) { 
      dispatch(TvRadioStreamingREQUEST({
        token: cookies._token_planetryvilage.token, 
        type: cookies._token_planetryvilage.type,
        ChannelType:'TV Channel'
      })); 
 
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, dispatch, navigate]); 



  const [searchCategory, setSearchCategory] = useState(''); 
 
  const filteredCategory = tvRadioStreamingValue.filter(
    (file) =>  
       (
        (`${file.NewFullName}${' '}${file.categoryDivision}${' '}${file.DateOnline}${' '}${file.TimeFrom}${' '}${file.usertvchannelradiochannelIdentifier}`)  
        .toLowerCase()
        .includes(searchCategory.toLowerCase())    
      )  
  );



  const handleSearchName = () => { 
    setSearchCategory(inputValue.current.value); 
    inputValue.current.value = ''; 
  }

  /* const filteredCategory = tvRadioStreamingValue.filter(
    (file) => 
       (
        file
        .categoryDivision
        .toLowerCase()
        .includes(searchCategory.toLowerCase())    
      )  
  );

  const handleFilterCategory = a => {
    setSearchCategory(a); 
  }; */


  return (
    <>  
      <Stack spacing={0} sx={{p:1, }}>
        <Grid container spacing={1} textAlign="center" justifyContent="center">
          <Grid  item xs={12} sm={12} md={12}>
            <Typography variant="body1" >
              TV Streaming 
            </Typography> 
          </Grid>
          {/* <Grid  item xs={10} sm={10} md={10}>
            <CategoryMenuFilter handleSelectCategory={handleFilterCategory} searchCategory={searchCategory} />
          </Grid> */}

          <Grid  item xs={12} sm={12} md={12} sx={{mb:1}} >  
            <Grid container spacing={1}  alignItems="center" justifyContent="center">
              <Grid  item xs={12} sm={12} md={5}  > 
                <Autocomplete
                    autoHighlight 
                    popupIcon={null}
                    PopperComponent={StyledPopper}
                    options={tvRadioStreamingValue}     
                    getOptionLabel={(post) => `${post.NewFullName}${' '}${post.categoryDivision}${' '}${post.DateOnline}${' '}${post.TimeFrom}${' '}${post.usertvchannelradiochannelIdentifier}`} 
                    isOptionEqualToValue={(option, value) => option.id === value.id}   
                    renderInput={(params) => (
                      <TextField  variant="standard"
                        inputRef={inputValue} 

                        {...params}
                        placeholder="Search..."
                        InputProps={{ 
                          style: { color: 'black' },
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton color="primary" onClick={handleSearchName}>
                                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'black' }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                />
               </Grid>
            </Grid>
          </Grid>  

        </Grid>

        <Grid container spacing={1} sx={{pb:1}} textAlign="center" justifyContent="center"> 
          {filteredCategory.map((item, key) => (
            <GoLiveItem key={key} item={item} features={features}/>
          ))}
        </Grid>

      </Stack>
    </>
  );
}
