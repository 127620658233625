

import ViewOneEventCardVideoActionTypes from './viewOneEventCardVideoTypes'


export const ViewOneEventCardVideoREQUEST = (item) => ({
    type: ViewOneEventCardVideoActionTypes.View_One_Event_Card_Video_REQUEST,
    payload: item
}) 
 

export const ViewOneEventCardVideoRESET = item => ({
    type: ViewOneEventCardVideoActionTypes.View_One_Event_Card_Video_RESET,
    payload: item
}) 