
const ViewMyVillageVideoAudioActionTypes = { 
    View_My_Village_Video_Audio_REQUEST: 'View_My_Village_Video_Audio_REQUEST',
    View_My_Village_Video_Audio_SUCCESS:'View_My_Village_Video_Audio_SUCCESS',
    View_My_Village_Video_Audio_FAILURE: 'View_My_Village_Video_Audio_FAILURE', 
}
 
export default ViewMyVillageVideoAudioActionTypes

  

 