export const addItemToCart = (prevItems, product) => {
  const {id} = product;
  const item = prevItems.find((item) => (item.id === id));

  if (!item) {
    return [...prevItems, {
      id,
      qty: 1,
      product,
      totalPrice: product.price
    }];
  } 

  return prevItems.map((item) => {
    if (item.id === id) {
      item.qty += 1;
      item.totalPrice += product.price;
    }
    return item;
  });
}


export const removeItemFromCart = (prevItems, product) => {
  const {id} = product;
  const item = prevItems.find((item) => (item.id === id));

  if (item.qty === 1) { 
    return prevItems.filter(item => item.id !== id);
  }  

  return prevItems.map((item) => {
    if (item.id === id) {
      item.qty -= 1;
      item.totalPrice -= product.price;
    }
    return item;
  });
}


 
export const clearItemFromCart = (prevItems, product) => {
  const {id} = product;  

  return prevItems.filter(item => item.id !== id); 
}


export function getItemsCount (items) {
  return items.reduce((sum, item) => (sum + item.qty), 0);
}


export function getTotalPrice (items) {
  return items.reduce((sum, item) => (sum + item.totalPrice), 0);
}
 