

// ----------------------------------------------------------------------

const christmasBackgroundDetails = [
  {
  	id: 1, 
    back: 'product01.gif'
   },  

  {
  	id: 2, 
    back: 'product01b.gif'
   },  
   
  {
  	id: 3, 
    back: 'product01c.gif'
   },  
   
  {
  	id: 4, 
    back: 'product01d.gif'
   },  
   
  {
  	id: 5, 
    back: 'product01e.gif'
   },  
   
  {
  	id: 6, 
    back: 'product01f.gif'
   },  
   
  {
  	id: 7, 
    back: 'product01g.gif'
   },  
   
  {
  	id: 8, 
    back: 'product01h.gif'
   },  
   
  {
  	id: 9, 
    back: 'product01i.gif'
   },  
   
  {
  	id: 10, 
    back: 'product01j.gif'
   },  
   
  {
  	id: 11, 
    back: 'christ1.jpg'
   },  
   
  {
  	id: 12, 
    back: 'christ2.jpg'
   },  
   
  {
  	id: 13, 
    back: 'christ3.jpg'
   },  
   
  {
  	id: 14, 
    back: 'christ4.jpg'
   },  
   
  {
  	id: 15, 
    back: 'christ5.jpg'
   },  
   
  {
  	id: 16, 
    back: 'christ6.jpg'
   },  
   
  {
  	id: 17, 
    back: 'christ7.jpg'
   },  
   
  {
  	id: 18, 
    back: 'christ8.jpg'
   },  
   
  {
  	id: 19, 
    back: 'christ9.jpg'
   },  
   
  {
  	id: 20, 
    back: 'christ10.jpg'
   },   
   
  {
  	id: 21, 
    back: 'christ11.jpg'
   },   
   
  {
  	id: 22, 
    back: 'christ12.jpg'
   },   
   
  {
  	id: 23, 
    back: 'christ13.jpg'
   },   
   
  {
  	id: 24, 
    back: 'christ14.jpg'
   },   
   
  {
  	id: 25, 
    back: 'christ15.jpg'
   },   
   
  {
  	id: 26, 
    back: 'christ16.jpg'
   },   
   
  {
  	id: 27, 
    back: 'christ17.jpg'
   },   
   
  {
  	id: 28, 
    back: 'christ18.jpg'
   },   
   
  {
  	id: 29, 
    back: 'christ19.jpg'
   },   
   
  {
  	id: 30, 
    back: 'christ20.jpg'
   },   
 
];

export default christmasBackgroundDetails;
