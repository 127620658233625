 
import PropTypes from 'prop-types';
import {CardFullScreen} from '../cardFeast/AllPages';



export default function WishCardFullScreen ({wishItem, eventOrWish, wishOption}) { 
  const _wishItem = wishItem;
  const _eventOrWish = eventOrWish;
  const _wishOption = wishOption;

  return (  
    <CardFullScreen wishItem={_wishItem} eventOrWish={_eventOrWish} wishOption={_wishOption} />
  );
}



WishCardFullScreen.propTypes = {    
  wishItem: PropTypes.object,
  eventOrWish: PropTypes.string,
  wishOption: PropTypes.string,
}; 
 