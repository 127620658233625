
import { useState, useRef, useEffect } from 'react'; 
import { Helmet } from 'react-helmet-async';  
import { useCookies  } from 'react-cookie'; 
import { useNavigate, useParams  } from 'react-router-dom'; 
import { useDispatch, useSelector  } from 'react-redux';   

// mui
import { Container, Grid, IconButton, Typography, Stack, Card } from '@mui/material';  
import WestIcon from '@mui/icons-material/West';
import { grey } from '@mui/material/colors';


// sections
import {   
  BreakingNews, Challenges, TeacherAssignedToMe, Watch, SeasonHistory, TimelinePeople, VideoAudio, HomeWidget
} from '../../sections/@users/competitionUser';
 

// mock
// import breakingNewsDetails from '../../_mock/breakingNewsDetails';
// import challengesDetails from '../../_mock/challengesDetails'; 
// import teacherAssignedToMeDetails from '../../_mock/teacherAssignedToMeDetails';  
// import seasonHistoryDetails from '../../_mock/seasonHistoryDetails';
// import winnerDetails from '../../_mock/winnerDetails';  
// import allFileDetails from '../../_mock/allFileDetails';   


// Links
import seasonHistoryLinks from '../../LinkURI/seasonHistoryLinks';


import {SpecificCompetitionREQUEST,} from '../../redux/specificCompetition/specificCompetitionActions'; 
// ----------------------------------------------------------------------

export default function SpecificCompetitionPage() { 
  const navigate = useNavigate();  
  const dispatch = useDispatch();   
  const { _message, _competitionId } = useParams(); 
  const token = useRef(null); 
  const type = useRef(null);  


  const [cookies, ] = useCookies(['_token_planetryvilage']); 
 
  const i = useRef(0); // help to stop sending a dispatch everytime a component changes
  useEffect(() => {
    if(i.current === 0){
      if(cookies._token_planetryvilage !== undefined) {  
        token.current = cookies._token_planetryvilage.token; 
        type.current = cookies._token_planetryvilage.type;

        dispatch(SpecificCompetitionREQUEST({
          token: token.current, 
          type: type.current,
          setBreakingNewsIdentifier: _competitionId
        }));  
      } else {
        navigate('/login', { replace: true });   
      } 
    }
    i.current += 1;
  }, [dispatch, navigate, cookies._token_planetryvilage, _competitionId]); 


  const specificCompetitionReducerValue = useSelector(state => state.specificCompetitionReducerValue);
  let getSetBreakingNewsValue = [];
  let getSetChallenges3Value = [];
  let getSetTeacher3Value = []; 
  let televisionValue = [];
  let getCompetitorsValue = [];
  let getDisqualifiersValue = []; 
  let getQualifiersValue = [];   
  let getWinnerValue = [];    
  let getPromoterSeasonValue = [];
  let AllCompetitionVideoAudioValue = [];   


  if(specificCompetitionReducerValue.user){
    getSetBreakingNewsValue = specificCompetitionReducerValue.user.getSetBreakingNews;
    getSetChallenges3Value = specificCompetitionReducerValue.user.getSetChallenges3;   
    getSetTeacher3Value = specificCompetitionReducerValue.user.getSetTeacher3;    
    televisionValue = specificCompetitionReducerValue.user.television;    
    getCompetitorsValue = specificCompetitionReducerValue.user.getCompetitors;  
    getDisqualifiersValue = specificCompetitionReducerValue.user.getDisqualifiers; 
    getQualifiersValue = specificCompetitionReducerValue.user.getQualifiers;  
    getWinnerValue = specificCompetitionReducerValue.user.getWinner;   
    getPromoterSeasonValue = specificCompetitionReducerValue.user.getPromoterSeason; 
    AllCompetitionVideoAudioValue = specificCompetitionReducerValue.user.AllCompetitionVideoAudio;  
  } 
 
  const featuresBreakingNews = {
    buttonText: 'Enroll Now', 
    backColor: grey[400], 
    color: grey[900], 
  };

  const featuresChallenges = {
    buttonText: 'Join',
    backColor: grey[600], 
    color: 'black'
  };

  const featuresTeacherAssigned = {
    buttonText: 'Meet Now',
    backColor: 'white', 
    color: 'black'
  };

  const featuresWatch = {
    buttonText: 'Watch',
    backColor: 'white', 
    color: 'black'
  };
 

  const [competitionId, setCompetitionId] = useState(_competitionId);  

  const [message, setMessage] = useState(_message); 


  /* const [searchField, setSearchField] = useState('Video'); 
 


  const filteredFiles = AllCompetitionVideoAudioValue.filter(
    (file) => 
       (
        file
        .fileTypeDivision
        .toLowerCase()
        .includes(searchField.toLowerCase())    
      )  
  ); */

  
  const [searchField, setSearchField] = useState({
    videoBool: true, 
    audioBool: false, 
    photoBool: false
  }); 

  // const [searchCategory, setSearchCategory] = useState('');
 

  /* beginning video */
  const filteredFiles = AllCompetitionVideoAudioValue.filter(
    (file) => 
       (
        file
        .fileTypeDivision
        .toLowerCase()
        .includes(('Video').toLowerCase())    
      )  
  );   

  const filteredCategory = filteredFiles.filter(
    (file) =>  
       (
        (`${file.NewFullName}${' '}${file.Description}${' '}${file.DateCreated}${' '}${file.sessionvideoaudioId}`)
        .toLowerCase()
        .includes(('').toLowerCase())    
      )  
  ); 
  /* end video */


  /* beginning audio */
  const filteredFilesAudio = AllCompetitionVideoAudioValue.filter(
    (file) => 
       (
        file
        .fileTypeDivision
        .toLowerCase()
        .includes(('Audio').toLowerCase())    
      )  
  );  

  const filteredCategoryAudio = filteredFilesAudio.filter(
    (file) =>  
       (
        (`${file.NewFullName}${' '}${file.Description}${' '}${file.DateCreated}${' '}${file.sessionvideoaudioId}`)
        .toLowerCase()
        .includes(('').toLowerCase())    
      )  
  ); 
  /* end audio */

  const handleFilterFiles = a => { 
    setSearchField({
      searchField, 
      ...a
    }); 
  }; 
 

  /* const handleFilterFiles = a => { 
    setSearchField(a); 
  }; */


  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Specific Competition </title>
      </Helmet>

      <Container maxWidth="xl" sx={{p:0}}>  
        <Stack textAlign="center" justifyContent="center">
          <IconButton aria-label="back" sx={{fontSize:13, color:'white'}} className="back" onClick={() => navigate(-1) }>
            <WestIcon/>   
          </IconButton>

          <Typography variant="subtitle" color="white" fontSize="14px">
            {message}
          </Typography> 
        </Stack> 

        <Grid container spacing={1} sx={{p:2}} textAlign="center" justifyContent="center"> 
          {/* BreakingNews Challenges TeacherAssignedToMe Watch */}
          <Grid  item xs={12} sm={12} md={12}>
            <Grid container spacing={1} textAlign="center" justifyContent="center">
              {getSetBreakingNewsValue.map((item, key) => (
              <BreakingNews key={key} item={item} features={featuresBreakingNews} tokenUser={token.current} type={type.current} competitionId={competitionId} setCompetitionId={setCompetitionId} setMessage={setMessage}/>
              ))}

              {getSetChallenges3Value.map((item, key) => (
              <Challenges key={key} item={item} features={featuresChallenges} specificCompetitionReducerValue={specificCompetitionReducerValue}  competitionId={competitionId} setCompetitionId={setCompetitionId} />
              ))}

              {getSetTeacher3Value.map((item, key) => (
              <TeacherAssignedToMe key={key} item={item} features={featuresTeacherAssigned} specificCompetitionReducerValue={specificCompetitionReducerValue}  competitionId={competitionId} setCompetitionId={setCompetitionId} />
              ))}
              
              
              {televisionValue.map((item, key) => (
              <Watch key={key} item={item} features={featuresWatch} specificCompetitionReducerValue={specificCompetitionReducerValue} competitionId={competitionId} setCompetitionId={setCompetitionId} />
              ))}

            </Grid>
          </Grid>
          
          {/* SeasonHistory */}
          {!!getPromoterSeasonValue.length && 
          <Grid  item xs={12} sm={12} md={12}>
            <Card sx={{borderRadius:'3px', m:1, }}>
              <Typography variant="subtitle" color="black" fontSize="14px">
                Season History
              </Typography>
            </Card>
            <Grid container spacing={1} textAlign="center" justifyContent="center">
              {getPromoterSeasonValue.map((item, key) => (
              <SeasonHistory key={key} item={item} competitionId={competitionId} setCompetitionId={setCompetitionId} seasonHistoryLinks={seasonHistoryLinks}/>
              ))}
            </Grid>
          </Grid>}
          
          {/* competitors winner qualifiers disqualifiers */}
          <Grid  item xs={12} sm={12} md={12}>
            <Grid container spacing={1} textAlign="center" justifyContent="center">
              {!!getCompetitorsValue.length && 
              <Grid  item xs={12} sm={12} md={6}>
                <Card sx={{borderRadius:'3px', m:1, }}>
                  <Typography variant="subtitle" color="black" fontSize="14px">
                    The competitors
                  </Typography> 
                </Card>
                <TimelinePeople people={getCompetitorsValue} competitionId={competitionId} setCompetitionId={setCompetitionId} />
              </Grid>}
              
              {!!getWinnerValue.length && 
              <Grid  item xs={12} sm={12} md={6}>
                <Card sx={{borderRadius:'3px', m:1, }}>
                  <Typography variant="subtitle" color="black" fontSize="14px">
                    The winner
                  </Typography> 
                </Card> 
                <TimelinePeople people={getWinnerValue} competitionId={competitionId} setCompetitionId={setCompetitionId} />
              </Grid> }

              {!!getQualifiersValue.length && 
              <Grid  item xs={12} sm={12} md={6}>
                <Card sx={{borderRadius:'3px', m:1, }}>
                  <Typography variant="subtitle" color="black" fontSize="14px">
                    The qualifiers
                  </Typography> 
                </Card>   
                <TimelinePeople people={getQualifiersValue} competitionId={competitionId} setCompetitionId={setCompetitionId} />
              </Grid>}

              {!!getDisqualifiersValue.length && 
              <Grid  item xs={12} sm={12} md={6}>
                <Card sx={{borderRadius:'3px', m:1, }}>
                  <Typography variant="subtitle" color="black" fontSize="14px">
                    The disqualifiers
                  </Typography> 
                </Card>    
                <TimelinePeople people={getDisqualifiersValue} competitionId={competitionId} setCompetitionId={setCompetitionId} />
              </Grid> }
            </Grid>
          </Grid>
          
          {/* competition videos audio */}
          {!!AllCompetitionVideoAudioValue.length && 
          <Grid  item xs={12} sm={12} md={12}>
            <Card sx={{borderRadius:'3px', m:2, }}>
              <Typography variant="subtitle" color="black" fontSize="14px">
                Competition Videos / Audio
              </Typography>
            </Card>
            <Grid container spacing={1} textAlign="center" justifyContent="center"> 
              {searchField.videoBool && filteredCategory.map((item, key) => (
              <Grid key={key}  item xs={12} sm={12} md={3}>
                <VideoAudio key={key} keyProp={key} cardItem = {item} competitionId={competitionId} setCompetitionId={setCompetitionId} />
              </Grid>
              )) }

              {searchField.audioBool && filteredCategoryAudio.map((item, key) => (
              <Grid key={key}  item xs={12} sm={12} md={3}>
                <VideoAudio key={key} keyProp={key} cardItem = {item} competitionId={competitionId} setCompetitionId={setCompetitionId} />
              </Grid>
              )) }
            </Grid>
          </Grid> }

        </Grid> 

        <HomeWidget handleFilterFiles={handleFilterFiles} /> 
      </Container>
    </>
  );
}
