
import PropTypes from 'prop-types'; 

// @mui
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';

// component
import Iconify from '../../components/iconify'; 

 


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999, 
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(15),
  height: theme.spacing(5),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },

}));


// ----------------------------------------------------------------------


export const StopMichaelwidget = ({changeContinuityStop}) => {  

  const handleContinuity = () => {
    changeContinuityStop()
  }

  return (
    <>
      <StyledRoot onClick={handleContinuity}> 
        <Tooltip title="Michael" sx={{cursor:'pointer'}}> 
          <Iconify icon="game-icons:robot-golem" width={24} height={24}  color='blue' /> 
        </Tooltip>
      </StyledRoot>
 
    </>
  );
}



StopMichaelwidget.propTypes = {    
  changeContinuityStop: PropTypes.func,  
};
