
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import SpecificSeasonActionTypes from '../specificSeason/specificSeasonTypes'; 

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}/u/specific/season`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            token: user.token,
            type: user.type,
            setBreakingNewsIdentifier: user.setBreakingNewsIdentifier,
            seasonIdentifier: user.seasonIdentifier,
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaSpecificSeason({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: SpecificSeasonActionTypes.Specific_Season_SUCCESS, response });
    } catch (error) {
        yield put({ type: SpecificSeasonActionTypes.Specific_Season_FAILURE, error: error.message });
    }
}

export function* sagaSpecificSeasonScreen() {
    yield takeLatest(SpecificSeasonActionTypes.Specific_Season_REQUEST, sagaSpecificSeason);
}
