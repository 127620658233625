
import {useState} from 'react';  
import PropTypes from 'prop-types';

import {InputLabel, MenuItem, FormControl, Select, } from '@mui/material';


// ----------------------------------------------------------------------  
CategoryMenuItem.propTypes = {
  handleSelectCategory: PropTypes.func, 
  selectedCategory: PropTypes.string, 
};

export default function CategoryMenuItem({handleSelectCategory, selectedCategory}) {
  const [division, setDivision] = useState(selectedCategory); 

  const handleChange = (event) => {
    setDivision(event.target.value); 
    handleSelectCategory(event.target.value);
  };

  return (  
      <FormControl>
        <InputLabel id="demo-simple-select-autowidth-label-g">Select a category</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label-g"
          id="demo-simple-select-autowidth-g"
          value={division}
          onChange={handleChange}
          autoWidth
          label="Select a category" 
        >     
          <MenuItem value={'Birthday'}>Birthday Celebration</MenuItem>
          <MenuItem value={'Wedding'}>Wedding Celebration</MenuItem> 
          <MenuItem value={'Easter'}>Easter Celebration</MenuItem> 
          <MenuItem value={'Christmas'}>Christmas Celebration</MenuItem> 
          <MenuItem value={'New Year'}>New Year Celebration</MenuItem> 
          <MenuItem value={'Collective Intelligence'}>Creativity and Imagination</MenuItem> 
          <MenuItem value={'Motivational Speech'}>Motivational Speech</MenuItem>  
          <MenuItem value={'Reality TV'}>Reality TV</MenuItem>  
          <MenuItem value={'Life Experience'}>Life Experience</MenuItem>  
          <MenuItem value={'Dancers'}>Dancers</MenuItem>   
          <MenuItem value={'Singers'}>Singers</MenuItem>   
          <MenuItem value={'Hairstyle'}>Hairstyle</MenuItem>  
          <MenuItem value={'Make-Up'}>Make-Up</MenuItem>   
          <MenuItem value={'Outfit'}>Outfit</MenuItem> 
          <MenuItem value={'Gossip'}>Gossip</MenuItem>  
          <MenuItem value={'Talking Non-Stop'}>Talking Non-Stop</MenuItem> 
          <MenuItem value={'Drawing'}>Drawing</MenuItem>  
          <MenuItem value={'Cooking'}>Cooking</MenuItem>  
          <MenuItem value={'Gym'}>Gym</MenuItem>  
          <MenuItem value={'Freestyle'}>Freestyle</MenuItem> 
          <MenuItem value={'Study Room'}>Study Room</MenuItem>  
          <MenuItem value={'Help'}>Help</MenuItem>  
          <MenuItem value={'Anniversary'}>Anniversary Celebration</MenuItem>
          <MenuItem value={'Advertising'}>Invention Showcase</MenuItem> 
          <MenuItem value={'Opportunity Zones'}>Opportunity Zones</MenuItem> 
        </Select>
      </FormControl>  
  );
}

 