
import { memo } from 'react'; 
import PropTypes from 'prop-types';
// @mui 
import { Typography, ListItemButton, Box } from '@mui/material';

// icons
import { Icon } from '@iconify/react';
 
 
 
// ---------------------------------------------------------------------- 


export const AllDreamsWidget = memo(({OpenAllDreams}) => {  
  const title = "All dreams"

  return (
    <> 
      <ListItemButton onClick={OpenAllDreams}>
        <Typography  sx={{mr:{md:1}, fontSize:{xs:12} }} >{title}</Typography> 
        <Box component={Icon} icon='emojione-monotone:thinking-face' sx={{ width: {xs:40, md:25}, height: {xs:40, md:25},}} /> 
      </ListItemButton> 
    </>
  );
})

AllDreamsWidget.propTypes = {  
  OpenAllDreams: PropTypes.func, 
};
 