import './LogoBanner.css';

// ----------------------------------------------------------------------

export default function LogoBanner() { 

  return (
    <>
      <div className="position_logo_header"> 
        <div className="header_logo">  
          <div className="stage_logo" id="logo"> 
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
            <div className="layer_logo">{}</div>
          </div>  
        </div>
      </div>
    </>
  );
}
