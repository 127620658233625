
import {useState} from 'react'; 
import PropTypes from 'prop-types';

import {InputLabel, MenuItem, FormControl, Select, Card, Grid} from '@mui/material';


// ---------------------------------------------------------------------- 
CategoryEvent.propTypes = {
  handleSelectedCard: PropTypes.func, 
};

export default function CategoryEvent({handleSelectedCard}) {
  const [division, setDivision] = useState('New Week');

  const updateSelectCard = (category) => {
  	handleSelectedCard(category);
  }

  const handleChange = (event) => {
    setDivision(event.target.value);
    updateSelectCard(event.target.value);
  };
 

  return (
   <Grid  item xs={12} sm={12} md={6.01}>
    <Card sx={{ mt: 0.3, borderRadius:{xs:0, sm:0, md:2}, }}> 
      <FormControl sx={{m:1, }}>
        <InputLabel id="demo-simple-select-autowidth-label">Select a card</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={division}
          onChange={handleChange}
          autoWidth
          label="Select a card"
          sx={{height:'25px'}} 
        >  
          <MenuItem value={'New Week'}>Create a New Week card</MenuItem>
          <MenuItem value={'New Month'}>Create a New Month Card</MenuItem>
          <MenuItem value={'Easter'}>Create an Easter Card</MenuItem>
          <MenuItem value={'Halloween'}>Create a Halloween Card</MenuItem>
          <MenuItem value={'Thanksgiving'}>Create a Thanksgiving Card</MenuItem>
          <MenuItem value={'Christmas'}>Create a Christmas Card</MenuItem>
          <MenuItem value={'New Year'}>Create a New Year Card</MenuItem>
        </Select>
      </FormControl>
    </Card>
   </Grid>
  );
}