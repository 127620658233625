

export const cloud100 = {
    a: 'select',
    b: 'select a file',
    c: 'select file',
    d: 'selects a file',
    e: 'selects file',
    f: 'selected a file',
    g: 'selected file',
    h: 'selects',
    i: 'selected'
};

export const cloud101 = {
    a: 'save',
    b: 'saves',
    c: 'saved',
    d: 'share',
    e: 'shares',
    f: 'shared',
    g: 'send',
    h: 'sends',
    i: 'sent'
};

export const cloud117 = {
    a: 'profession',
    a2: 'job',
    a3: 'work',
    a4: 'worked',
    a5: 'works',
    b: 'website one',
    c: 'website 1',
    c2: 'website a',
    d: 'website to',
    e: 'website two',
    f: 'website 2',
    f2: 'website of',
    g: 'phone',
    g2: 'telephone',
    g3: 'telephones',
    g4: 'telephoned',
    g5: 'phones',
    g6: 'phoned',
    h: 'company',
    h2: 'keeps',
    h3: 'keep',
    h4: 'kept',
    h5: 'business',
    i: 'address',
    i2: 'addresses',
    i3: 'addressed',
    j: 'select'
};



export const removeSpaceBetweenString = (string_) => { 
	const string = string_.split(" ").join("");
    return string;
}