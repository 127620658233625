 
import PropTypes from 'prop-types';
import { FlippingPages } from 'flipping-pages';
import 'flipping-pages/dist/style.css';
import { useState, memo } from 'react'; 

// mui
import {Button} from '@mui/material'; 
    
import './BusinessCards.css'; 


import {BusinessCardContent} from './AllSideScreens';



export const BusinessCards = memo(({height, businessCardDetails}) => {   
	const HEIGHT = height
    const [selected, setSelected] = useState(0);

    const back = () => {
        setSelected(selected => Math.max(selected - 1, 0));
    };

    const next = () => {
        setSelected(selected => Math.min(selected + 1, businessCardDetails.length));
    };

    return ( 
        <div> 
            <div className="pages"  style={{height:HEIGHT}}>
                <FlippingPages
                    direction="right-to-left"
                    onSwipeEnd={setSelected}
                    selected={selected}
                >
                  {businessCardDetails.length && businessCardDetails.map((item, key) => (
                    <div className="page" key={key}>
                      <BusinessCardContent content={item}  key={key} height={HEIGHT}/>
                    </div> 
                   ))}
                </FlippingPages>
            </div> 
            <Button variant="outlined" title='button' aria-label="button" className="prev_" onClick={back}>Back</Button>
            <Button variant="outlined" title='button' aria-label="button" className="next_" onClick={next}>Next</Button>
        </div> 
    );
})


BusinessCards.propTypes = {    
  height: PropTypes.string, 
  businessCardDetails: PropTypes.arrayOf(PropTypes.object), 
  
};
 