

import ViewMyVillageVideoAudioActionTypes from './viewMyVillageVideoAudioTypes'


export const ViewMyVillageVideoAudioREQUEST = (item) => ({
    type: ViewMyVillageVideoAudioActionTypes.View_My_Village_Video_Audio_REQUEST,
    payload: item
}) 
 


