import { useState} from 'react';
import { Helmet } from 'react-helmet-async';  
// mui
import { Container, Grid } from '@mui/material';  



// sections 
import {  
  MagazineFullScreen, EventCardFullScreen, WishCardFullScreen, MagazineWishWidget,
} from '../../sections/@users/homeUser';

// mock   
import eventCardDetails from '../../_mock/eventCardDetails';  
import wishCardDetails from '../../_mock/wishCardDetails'; 

  
// ----------------------------------------------------------------------

export default function MagazineWishPage() {  
  const {
    _event,
    wish
  } = {
    _event:'EVENT',
    wish: 'WISH'
  };

  const initialArticleValue = {
    magazineBool: true,
    eventCardBool: true,
    wishesSentBool: true,
    wishesReceivedBool: true, 
  };

  const [articleBool, setArticle] = useState(initialArticleValue);  

  const showAllArticles = () => {
    setArticle({
      articleBool, 
      ...{
        magazineBool: true,
        eventCardBool: true,
        wishesSentBool: true,
        wishesReceivedBool: true, 
      }
    });
  }

  const showMagazines = () => {
    setArticle({
      articleBool, 
      ...{
        magazineBool: true,
        eventCardBool: false,
        wishesSentBool: false,
        wishesReceivedBool: false, 
      }
    });
  }

  const showEventCards = () => {
    setArticle({
      articleBool, 
      ...{
        magazineBool: false,
        eventCardBool: true,
        wishesSentBool: false,
        wishesReceivedBool: false, 
      }
    });
  }

  const showWishesSent = () => {
    setArticle({
      articleBool, 
      ...{
        magazineBool: false,
        eventCardBool: false,
        wishesSentBool: true,
        wishesReceivedBool: false, 
      }
    });
  }

  const showWishesReceived = () => {
    setArticle({
      articleBool, 
      ...{
        magazineBool: false,
        eventCardBool: false,
        wishesSentBool: false,
        wishesReceivedBool: true, 
      }
    });
  }

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Wishes and Magazines </title>
      </Helmet>
      
      
      <Container maxWidth="xl"> 
        
          <Grid container spacing={2} sx={{pb:1}}>  
            
            {/* magazines */}

            {articleBool.magazineBool && (
            <Grid  item xs={12} sm={12} md={12} > 
              <MagazineFullScreen/> 
            </Grid> )
            }

 
            {/* event cards */}
            {articleBool.eventCardBool && eventCardDetails.length && eventCardDetails.map((item, key) => (
              <Grid  item xs={12} sm={12} md={12} key={key}>  
                <EventCardFullScreen wishItem={item} eventOrWish={_event}/> 
              </Grid>
            ))}
 
            {/*  wishes sent */}
            {articleBool.wishesSentBool && wishCardDetails.length && wishCardDetails.map((item, key) => (
              <Grid  item xs={12} sm={12} md={12} key={key}>  
                <WishCardFullScreen wishItem={item} eventOrWish={wish}/> 
              </Grid>
            ))}
 
            {/*  wishes received */} 
            {articleBool.wishesReceivedBool && wishCardDetails.length && wishCardDetails.map((item, key) => (
              <Grid  item xs={12} sm={12} md={12} key={key}>   
                <WishCardFullScreen wishItem={item} eventOrWish={wish}/> 
              </Grid>
            ))}
  
          </Grid> 

          <MagazineWishWidget showAllArticles={showAllArticles} showMagazines={showMagazines} showEventCards={showEventCards} showWishesSent={showWishesSent} showWishesReceived={showWishesReceived} />  

          
        
      </Container>  
    </>
  );
}
