
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import InnerLiveConferencingActionTypes from '../innerLiveConferencing/innerLiveConferencingTypes'; 

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}${'/u/V-M/index'}`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            room: user.room,
            descriptionIdentifier: user.descriptionIdentifier,
            DivisionName: user.DivisionName,
            User_Session: user.UserSession,
            First_Extension: user.FirstExtension,
            Second_Extension: user.SecondExtension,
            Third_Extension: user.ThirdExtension,
            Four_Extension: user.FourExtension,
            Fifth_Extension: user.FifthExtension,
            token: user.token,
            type: user.type, 
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaInnerLiveConferencing({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: InnerLiveConferencingActionTypes.Inner_Live_Conferencing_SUCCESS, response });
    } catch (error) {
        yield put({ type: InnerLiveConferencingActionTypes.Inner_Live_Conferencing_FAILURE, error: error.message });
    }
}

export function* sagaInnerLiveConferencingScreen() {
    yield takeLatest(InnerLiveConferencingActionTypes.Inner_Live_Conferencing_REQUEST, sagaInnerLiveConferencing);
}
