 
import { useSelector  } from 'react-redux';  
import { Helmet } from 'react-helmet-async';  

// mui 
import { Container, Grid, } from '@mui/material';   


 
// sections
import {   
  MyVillages,
} from '../../../sections/@users/villageUser';
 

// mock 
// import teacherAssignedToMeDetails from '../../../_mock/teacherAssignedToMeDetails';    
 


// ----------------------------------------------------------------------

export default function MyVillagesPage() {   
  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);

  let myVillagesValue = []; 

  if(allDataOnceReducerValue.user){
    myVillagesValue = allDataOnceReducerValue.user.myVillages;   
  } 
 

  const features = {
    buttonText: 'View',
    backColor: 'white', 
    color: 'black'
  };
  
 

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | My Villages </title>
      </Helmet>

      <Container maxWidth="xl" sx={{p:0}}>  
        {/* <Stack textAlign="center" justifyContent="center">
          <IconButton aria-label="back" sx={{fontSize:13, color:'white'}} onClick={() => navigate('/create/village') }>
            <WestIcon/>   
          </IconButton>  

          <Typography variant="subtitle" color="white" fontSize="14px">
            My Villages
          </Typography>
        </Stack> */}

        <Grid container spacing={1} sx={{p:2}} textAlign="center" justifyContent="center">  
          <Grid  item xs={12} sm={12} md={12}>
            <Grid container spacing={1} textAlign="center" justifyContent="center">  

              {myVillagesValue.map((item, key) => (
              <MyVillages key={key} item={item} features={features} />
              ))} 

            </Grid>
          </Grid> 

        </Grid> 
      </Container>
    </>
  );
}
