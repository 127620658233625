
import { useState, useRef, memo } from 'react';   
// import { useCookies  } from 'react-cookie';
// import { useNavigate,  } from 'react-router-dom'; 
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types'; 

// mui
import { Typography, Grid, Stack, TextField, InputAdornment, IconButton, Autocomplete, Popper  } from '@mui/material';  
import { grey } from '@mui/material/colors'; 

import { styled } from '@mui/material/styles';


// components 
import Iconify from '../../../../components/iconify';

import {ViewVillageItem } from './AllSideScreens';
 


  
// import {ViewVillageREQUEST,} from '../../../../redux/viewVillage/viewVillageActions';
 
// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});



export const ViewVillage = memo(({height}) => { 
  // const navigate = useNavigate();  
  // const dispatch = useDispatch(); 
  const inputValue = useRef(''); 


  const HEIGHT = height;
 
  const features = {
  	buttonText: 'View', 
  	backColor: grey[400], 
  	color: grey[900],  
  };

  

  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);

  let viewVillageValue = [];  

  if(allDataOnceReducerValue.user){
    viewVillageValue = allDataOnceReducerValue.user.allVillages;     
  }


  /* const viewVillageReducerValue = useSelector(state => state.viewVillageReducerValue); 

  let viewVillageValue = [];  

  if(viewVillageReducerValue.user){
    viewVillageValue = viewVillageReducerValue.user.message;     
  } */
 


  /* const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) { 
      dispatch(ViewVillageREQUEST({
        token: cookies._token_planetryvilage.token, 
        type: cookies._token_planetryvilage.type, 
      })); 
 
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, dispatch, navigate]); */


  const [searchCategory, setSearchCategory] = useState(''); 



  const filteredCategory = viewVillageValue.filter(
    (file) => 
       (
        (`${file.NewFullName}${' '}${file.Description}${' '}${file.DateCreated}`)
        .toLowerCase()
        .includes(searchCategory.toLowerCase())    
      )  
  );

  /* const handleFilterCategory = a => {
    setSearchCategory(a); 
  }; */

  const handleSearchName = () => {
    setSearchCategory(inputValue.current.value);
    inputValue.current.value = '';
  }


  return (
    <>  
      <Stack spacing={0} sx={{p:1, height:HEIGHT}} >
        <Grid container spacing={1} textAlign="center" justifyContent="center"> 
          <Grid  item xs={12} sm={12} md={12}>
            <Typography variant="body1" color='black'>
              View Village 
            </Typography> 
          </Grid>
          <Grid  item xs={12} sm={12} md={6} sx={{mb:1}}> 

            {/* <TextField fullWidth inputRef={inputValue}  onChange={(e) => {inputValue.current.value = e.target.value} } // value={inputValue}   onChange={(e) => setInputValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end"> 
                    <IconButton color="primary" onClick={handleSearchName}>
                        <Iconify icon="ic:sharp-search" />
                      </IconButton>
                  </InputAdornment>
                ),
              }} 
              name="search" label="search" /> */}

            <Autocomplete 
              autoHighlight 
              popupIcon={null}
              PopperComponent={StyledPopper}
              options={viewVillageValue}
              getOptionLabel={(post) => `${post.NewFullName}${' '}${post.Description}${' '}${post.DateCreated}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField variant="standard"
                  inputRef={inputValue}
                  {...params}
                  placeholder="Search..."
                  onKeyDown={(event) => {
                    if (event.key === 'Enter'){
                      handleSearchName()
                    } 
                  }}
                  InputProps={{
                    style: { color: 'black' },
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                       <IconButton color="primary" onClick={handleSearchName}>
                        <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'black' }} />
                       </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            /> 
          </Grid>
         </Grid>
         
        <Grid container spacing={1} sx={{pb:1}}  justifyContent='center' textAlign='center'> 
          {filteredCategory.map((item, key) => (
            <ViewVillageItem key={key} item={item} features={features}/>
          ))}
        </Grid> 

      </Stack>
    </>
  );
})



ViewVillage.propTypes = {    
  height: PropTypes.string,     
};

 