 
import { useNavigate, } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Card, Grid, Typography, Stack, Divider, Avatar, Box, IconButton  } from '@mui/material';  
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors'; 
import WestIcon from '@mui/icons-material/West'; 


import {domainName} from '../../../../utils/domain';
// ----------------------------------------------------------------------



export const MyVillages = ({item, features}) => {  
  const navigate = useNavigate();     

  const { 
    Photo,
    NewFullName,  
    City,
    Country,
    Continent,   
    Description, 
    WhoAreYou,
    Id
  } = item;

  const {
  	buttonText, 
  	backColor, 
  	color
  } = features;

  return (  
    <Grid  item xs={12} sm={6} md={4} lg={3}> 
      <Stack textAlign="center" justifyContent="center">
        <IconButton aria-label="back" className="back" sx={{fontSize:13, color:'white'}} onClick={() => navigate('/create/village') }>
          <WestIcon/>   
        </IconButton> 
      </Stack>  

      <Card sx={{
        boxShadow: 0,
        textAlign: 'left',
        color: {color},   
        backgroundColor: backColor, 
        minHeight: '250px'
        // borderRadius:0,
         
      }}>   

        <Stack spacing={1} sx={{ p: 2 }} justifyContent='center' textAlign='center'>
          <Box  sx={{fontSize:14}}>
            <Typography variant="body2">Village: {Description}</Typography >
          </Box> 

          <Box>
            <Avatar sx={{ bgcolor: grey[500], height:40, width:40, m:'auto', p:0 }}  alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} />
          </Box>
           
          <Stack direction="row" alignItems="center" justifyContent='center' textAlign='center'>
            <Stack>
              <Typography variant="body1" >
                The villager{','} {NewFullName} 
              </Typography> 

              <Typography variant="subtitle2" >
                {WhoAreYou}
              </Typography> 

              <Typography variant="subtitle2" sx={{fontSize:12}}>
                from {City}{','} {Country}{','} {Continent}
              </Typography>  
 

              <Divider sx={{backgroundColor:'black', m:1, }} />  
              
              <Box>
                <LoadingButton sx={{width:50, p:0}} variant="contained" className="notify" onClick={() => {navigate(`${"/view/my/village/"}${Id}`, { replace: true });}}>
                  {buttonText}
                </LoadingButton> 

                <LoadingButton sx={{width:50, p:0, ml:1}} variant="contained" onClick={() => {navigate(`${"/view/my/village/upload/file/"}${Id}`, { replace: true });}}>
                  Upload  
                </LoadingButton>  
              </Box> 
            </Stack>
          </Stack> 
        </Stack>

      </Card>
    </Grid> 
  );
}


MyVillages.propTypes = {    
  item: PropTypes.object, 
  features: PropTypes.object, 
};
