

import ViewOneCompetitionVideoAudioTypes from './viewOneCompetitionVideoAudioTypes'


export const ViewOneCompetitionVideoAudioREQUEST = (item) => ({
    type: ViewOneCompetitionVideoAudioTypes.View_One_Competition_Video_Audio_REQUEST,
    payload: item
}) 
 

export const ViewOneCompetitionVideoAudioRESET = item => ({
    type: ViewOneCompetitionVideoAudioTypes.View_One_Competition_Video_Audio_RESET,
    payload: item
}) 