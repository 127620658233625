

import PropTypes from 'prop-types';

// mui
import { FormLabel, RadioGroup, FormControlLabel, Radio  } from '@mui/material'; 


// ----------------------------------------------------------------------
 
ExciteRadioGroup.propTypes = {
  handleExcited: PropTypes.func, 
  excited: PropTypes.string, 
};

export default function ExciteRadioGroup({handleExcited, excited}) {  
   
  return (
    <> 
        <FormLabel id="demo-radio-buttons-group-label">Excite the world to watch your video</FormLabel>
        <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Are you left open-mouthed by this video?"
            name="radio-buttons-group"
            value={excited}
            onChange={handleExcited}
        >
            <FormControlLabel value="Are you left open-mouthed by this video?" control={<Radio />} label="Are you left open-mouthed by this video?" />
            <FormControlLabel value="Are you excessively astonished by this video?" control={<Radio />} label="Are you excessively astonished by this video?" />
            <FormControlLabel value="Are you excited to watch my video?" control={<Radio />} label="Are you excited to watch my video?" />
            <FormControlLabel value="Are you perplexed by my video?" control={<Radio />} label="Are you perplexed by my video?" />
            <FormControlLabel value="Are you stupefied by my video?" control={<Radio />} label="Are you stupefied by my video?" />
            <FormControlLabel value="Are you flabbergasted by this video?" control={<Radio />} label="Are you flabbergasted by this video?" />
            <FormControlLabel value="Is this video extremely amazing?" control={<Radio />} label="Is this video extremely amazing?" />
            <FormControlLabel value="Is this video extremely astonishing?" control={<Radio />} label="Is this video extremely astonishing?" /> 
        </RadioGroup>
    </>
  );
}

