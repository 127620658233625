

import {useState} from 'react'; 
import PropTypes from 'prop-types';

import {InputLabel, MenuItem, FormControl, Select, Card, Grid} from '@mui/material';

 
// ---------------------------------------------------------------------- 
CategoryFeast.propTypes = {
  handleSelectedCard: PropTypes.func, 
  backArrow: PropTypes.func, 
  notificationCard: PropTypes.bool,
};

export default function CategoryFeast({handleSelectedCard, backArrow, notificationCard}) {
  const [division, setDivision] = useState('Birthday');

  const updateSelectCard = (category) => {
  	handleSelectedCard(category);
  }

  const handleChange = (event) => {
    setDivision(event.target.value); 
    updateSelectCard(event.target.value);

    if(!notificationCard) backArrow();
  };

  return (
   <Grid  item xs={12} sm={12} md={6.01}>
    <Card sx={{ mt: 0.3, borderRadius:{xs:0, sm:0, md:2}, }}>
      <FormControl sx={{m:1,  }}>
        <InputLabel id="demo-simple-select-autowidth-label">Select a card</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={division}
          onChange={handleChange}
          autoWidth
          label="Select a card"
          sx={{height:'25px'}}
        >  
          <MenuItem value={'Birthday'}>Create a Birthday Card</MenuItem>
          <MenuItem value={'Wedding'}>Create a Wedding Card</MenuItem>
          <MenuItem value={'Valentine'}>Create a Valentines Day Card</MenuItem> 
        </Select>
      </FormControl>
    </Card>
   </Grid>
  );
}