import {memo } from 'react'
import PropTypes from 'prop-types';
import {Card} from '@mui/material';    

// hooks
import useResponsive from '../../../../hooks/useResponsive';

// utils
import {useWindowDimensions} from '../../../../utils/otherFunctions';


import {BusinessCards, AllDreams, ViewVillage} from './AllSideScreens';
  
// const ResizeFullScreen = 60; 



export const SideScreen = memo(({businessCardDetails, menuBool}) => { 
  const { width, height } = useWindowDimensions();
  const isDesktop = useResponsive('up', 'lg');   

  const {
    businessCardBool, 
    allDreamsBool,
    viewVillageBool, 
  } = menuBool; 
 
 
  const HEIGHT = isDesktop? height - 30 : height;

  const HEIGHTInner = isDesktop? `${HEIGHT - 140}px` : `${HEIGHT - 50}px`;

  const POSITION = isDesktop?'fixed' : 'relative';
  
  // maxWidth: 680
  const MAXwidth = isDesktop? width / 2 - 60 : 680;


  return (  
    <Card sx={{maxWidth: {md:'100%', lg:MAXwidth}, position:POSITION, maxHeight:HEIGHT, width: {md:'100%', lg:MAXwidth}, mt:{xs:0, sm:0, md:5}, borderRadius:{xs:0, sm:1, md:1}, overflowY:'scroll'}}> 

      {allDreamsBool && (<AllDreams/>)}

      {viewVillageBool && (<ViewVillage/>)}

      {businessCardBool && (<BusinessCards height={HEIGHTInner} businessCardDetails={businessCardDetails}/>)}

       
    </Card> 
  );
})


SideScreen.propTypes = {    
  businessCardDetails: PropTypes.arrayOf(PropTypes.object), 
  menuBool: PropTypes.object, 
};
 