

import { Helmet } from 'react-helmet-async';  
import { useNavigate, } from 'react-router-dom';
// mui
import { Container, Grid, Stack, IconButton } from '@mui/material';   
import WestIcon from '@mui/icons-material/West'; 


// sections 
import {   
  CreateVideoAudioPhotoVillage,
} from '../../../sections/@users/villageUser';


// ----------------------------------------------------------------------

export default function CreateVideoAudioPhotoVillagePage() { 
  const navigate = useNavigate();  

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Create a village video / audio </title>
      </Helmet>
      
      <Container maxWidth="xl" sx={{mt:1}}>
        <Stack textAlign="center" justifyContent="center">
          <IconButton aria-label="back" sx={{fontSize:13, color:'white'}} onClick={() => navigate('/create/village') }>
            <WestIcon/>   
          </IconButton> 
        </Stack> 

        <Grid container spacing={1} sx={{color: 'black', textAlign:'center', justifyContent:'center'}}> 
          <CreateVideoAudioPhotoVillage/> 
        </Grid>

        
      </Container> 
    </>
  );
}
