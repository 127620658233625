
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import UserDetailsActionTypes from '../userDetails/userDetailsTypes'; 

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}/u/user/details`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            token: user.token,
            type: user.type,
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaUserDetails({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: UserDetailsActionTypes.User_Details_SUCCESS, response });
    } catch (error) {
        yield put({ type: UserDetailsActionTypes.User_Details_FAILURE, error: error.message });
    }
}

export function* sagaUserDetailsScreen() {
    yield takeLatest(UserDetailsActionTypes.User_Details_REQUEST, sagaUserDetails);
}
