
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import PasswordActivationActionTypes from '../passwordActivation/passwordActivationTypes'; 

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}/u/forgot/password/activation`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            password: user.password,
            emailVerificationId: user.emailVerificationId,
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaPasswordActivation({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: PasswordActivationActionTypes.Password_Activation_SUCCESS, response });
    } catch (error) {
        yield put({ type: PasswordActivationActionTypes.Password_Activation_FAILURE, error: error.message });
    }
}

export function* sagaPasswordActivationScreen() {
    yield takeLatest(PasswordActivationActionTypes.Password_Activation_REQUEST, sagaPasswordActivation);
}
