 
import { Helmet } from 'react-helmet-async';  
// mui
import { Container, Grid, Card } from '@mui/material';  


// hooks
import useResponsive from '../../hooks/useResponsive';

// utils
import {useWindowDimensions} from '../../utils/otherFunctions';


// sections 
import {  
  AllDreams, 
} from '../../sections/@users/homeUser';

 
// ----------------------------------------------------------------------

export default function AllDreamsPage() {  
  const { width, height } = useWindowDimensions();
  const isDesktop = useResponsive('up', 'lg');   
 
 
  const HEIGHT = isDesktop? height - 30 : height; 

  const POSITION = isDesktop?'fixed' : 'relative';
   
  const MAXwidth = isDesktop? width / 2 - 60 : 680; 


  return (
    <>
      <Helmet>
        <title> PlanetryVilage | What is my dream? </title>
      </Helmet>
      
      <Container maxWidth="xl"  sx={{p:0}}>  

        <Grid container spacing={1} sx={{color: 'black', textAlign:'center', justifyContent:'center'}}>

          <Card sx={{maxWidth: MAXwidth, position:POSITION, maxHeight:HEIGHT, width: {md:MAXwidth}, mt:{xs:2, sm:2, md:0}, borderRadius:{xs:0, sm:0, md:1}, overflowY:'scroll'}}> 
            <Grid item xs={12} sm={12} md={12}> 
              <AllDreams/> 
            </Grid>
          </Card> 

        </Grid> 
        
      </Container> 
    </>
  );
}
