
import { useCookies  } from 'react-cookie'; 
import { useDispatch,  } from 'react-redux';

import {useState, useEffect, useRef, } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles'; 
import { Box, Stack, AppBar, Toolbar, Popover, Typography, ListItemButton, Tooltip  } from '@mui/material'; 


// icons
import { Icon } from '@iconify/react';
 


// 
// import AccountPopover from './AccountPopover';
// import NotificationsPopover from './NotificationsPopover';  
import DreamsPopover from './DreamsPopover';
import SongVirtualAssistantPopover from './SongVirtualAssistantPopover';





/* import {BusinessCardREQUEST,} from '../../redux/businessCard/businessCardActions';  
import {ViewVideoPhotoREQUEST,} from '../../redux/viewVideoPhoto/viewVideoPhotoActions'; 
import {ViewVillageREQUEST,} from '../../redux/viewVillage/viewVillageActions'; */
 
import {AllDataOnceREQUEST,} from '../../../redux/allDataOnce/allDataOnceActions'; 


// -----------------------------------------------------------------
 
import navConfig from '../nav/config';
import { StyledNavItem, StyledNavItemIcon } from '../../../components/nav-section/styles';
 
// ----------------------------------------------------------------------
 
// const NAV_WIDTH = 280;

// const HEADER_MOBILE = 64;

// const HEADER_DESKTOP = 92;

const NAV_WIDTH = 60;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 60;

const StyledRoot = styled(AppBar)(({ theme }) => ({  
  boxShadow: 10,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  }, 
  backgroundColor: 'white'
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  }, 
  margin:0, padding:0
}));
 

// ----------------------------------------------------------------------

 
export default function Header( ) {   
  const navigate = useNavigate();  
  const dispatch = useDispatch();  


  const [cookies, ] = useCookies(['_token_planetryvilage']); 
 
  const i = useRef(0); // help to stop sending a dispatch everytime a component changes
  useEffect(() => {
    if(i.current === 0){
      if(cookies._token_planetryvilage !== undefined) {  
        dispatch(AllDataOnceREQUEST({
          token: cookies._token_planetryvilage.token, 
          type: cookies._token_planetryvilage.type
        })); 

        
         
      /* dispatch(BusinessCardREQUEST({
        token: cookies._token_planetryvilage.token, 
        type: cookies._token_planetryvilage.type
      }));

      dispatch(ViewVideoPhotoREQUEST({
        token: cookies._token_planetryvilage.token, 
        type: cookies._token_planetryvilage.type
      }));

      dispatch(ViewVillageREQUEST({
        token: cookies._token_planetryvilage.token, 
        type: cookies._token_planetryvilage.type, 
      })); */
 
      } else {
        navigate('/login', { replace: true });   
      } 
    }
    i.current += 1;
  }, [dispatch, navigate, cookies._token_planetryvilage]); 


 
  return ( 
    <StyledRoot>
      <StyledToolbar>  

        {navConfig.map((item) => ( 
          item.title === 'Create'? (<NavCreateItem key={item.title} item={item} />):
          item.title === 'Magazines Wishes'? (<NavMagazineWishesItem key={item.title} item={item} />):
          (<NavItem key={item.title} item={item} />)
        ))}
 
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        > 
          <DreamsPopover />
          {/* <NotificationsPopover /> */} 
          <SongVirtualAssistantPopover />
        </Stack>
      </StyledToolbar>  
    </StyledRoot> 
  );
}



// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon } = item;   

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
        display: {lg: 'none'}, 
      }}
    > 

      <Tooltip placement="right-start"> 
        <Stack sx={{textAlign:'center', justifyContent:'center'}}>
          <StyledNavItemIcon> 
            {icon && icon}   
          </StyledNavItemIcon>
          <Typography sx={{fontSize:'10px', color:'black'}}>{title === 'Magazines and Wishes'? 'Wishes':title === 'Competition'? 'Competition':title === 'Home'?'Home':'' }</Typography>
          <Typography sx={{fontSize:'10px', color:'black'}}>{title === 'Magazines and Wishes'&&'Magazines'}</Typography>
        </Stack>
      </Tooltip> 
      
 
    </StyledNavItem> 
  );
}




NavCreateItem.propTypes = {
  item: PropTypes.object,
}; 

function NavCreateItem({ item }) {
  const navigate = useNavigate();   

  const token = useRef(null); 
  const type = useRef(null);

  const [typeCurrent, setTypeCurrent] = useState('');
 


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type; 
      setTypeCurrent(type.current);
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 
  


  const textTitle = typeCurrent === 'Elderly People'? 'an Old School Card':
                    typeCurrent === 'Investor'? 'an investor card':
                    (typeCurrent === 'Organisation Owner' || 
                      typeCurrent === 'Shop Owner' || 
                      typeCurrent === 'Patient')? 'a business card':
                    typeCurrent === 'New Generation'? 'a universal card': 
                    typeCurrent === 'Pupil'? 'a school card': 
                    'a student card'; 


  const { title, path, icon } = item;    
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <StyledNavItem
      // component={RouterLink}
      // to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold', 
        },
        display: {lg: 'none'},  
      }}
    > 

      <Tooltip placement="right-start"  onClick={handleOpen}> 
        <Stack sx={{textAlign:'center', justifyContent:'center'}}>
          <StyledNavItemIcon> 
            {icon && icon}  
          </StyledNavItemIcon>
          <Typography sx={{fontSize:'10px', color:'black'}}>{title && title}</Typography> 
        </Stack>
      </Tooltip> 
       
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            // mt: 1.5,
            mt: 5,
            ml: 0.75,
            // width: 180,
            width: 300,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack> 
          <ListItemButton component={RouterLink} onClick={handleClose}>
            <Box component={Icon} icon='material-symbols:close' sx={{ width: 25, height: 25, ml:20 }} /> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.feastCard} onClick={handleClose}> 
            <Box component={Icon} icon='fluent:gift-card-add-20-regular' sx={{ width: 50, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Create a birthday / wedding / {`${"Valentine's Day"}`} card</Typography> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.eventCard} onClick={handleClose}> 
            <Box component={Icon} icon='mdi:cards-variant' sx={{ width: 50, height: 30, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Create a new week / easter / christmas / new year card</Typography> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.magazine} onClick={handleClose}> 
            <Box component={Icon} icon='grommet-icons:article' sx={{ width: 25, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Create a magazine</Typography> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.businessCard} onClick={handleClose}> 
            <Box component={Icon} icon='material-symbols:add-card' sx={{ width: 25, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Create {textTitle}</Typography> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.photoVideo} onClick={handleClose}> 
            <Box component={Icon} icon='material-symbols:add-a-photo-rounded' sx={{ width: 25, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Upload a photo / video / audio </Typography> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.tvChannel} onClick={handleClose}> 
            <Box component={Icon} icon='material-symbols:reset-tv-outline' sx={{ width: 25, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Create a TV channel</Typography> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.conferenceRoom} onClick={handleClose}> 
            <Box component={Icon} icon='mdi:teleconference' sx={{ width: 25, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Create a conference room</Typography> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.village} onClick={handleClose}> 
            <Box component={Icon} icon='fontisto:holiday-village' sx={{ width: 25, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Create a village</Typography> 
          </ListItemButton>
        </Stack>
      </Popover>
 
    </StyledNavItem> 
  );
}







NavMagazineWishesItem.propTypes = {
  item: PropTypes.object,
};

function NavMagazineWishesItem({ item }) {
  const { title, path, icon } = item;    
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <StyledNavItem
      // component={RouterLink}
      // to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold', 
        },
        display: {lg: 'none'}, 
        m:0,
        p:0
      }}
    > 

      {/* <Tooltip title={title} placement="right-start"  onClick={handleOpen}> 
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      </Tooltip> */}

      <Tooltip placement="right-start"  onClick={handleOpen}> 
        <Stack sx={{textAlign:'center', justifyContent:'center'}}>
          <StyledNavItemIcon> 
            {icon && icon}  
          </StyledNavItemIcon>
          <Typography sx={{fontSize:'9px', color:'black'}}>{title === 'Magazines Wishes'? 'Wishes':'Wishes'}</Typography> 
        </Stack>
      </Tooltip> 
       
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            // mt: 1.5,
            mt: 5,
            ml: 0.75,
            // width: 180,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack> 
          <ListItemButton component={RouterLink} onClick={handleClose}>
            <Box component={Icon} icon='material-symbols:close' sx={{ width: 50, height: 25, ml:16}} /> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.eventCard} onClick={handleClose}> 
            <Box component={Icon} icon='ic:baseline-card-giftcard' sx={{ width: 25, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Event Cards</Typography> 
          </ListItemButton> 
          
          <ListItemButton component={RouterLink} to={path.wishesSent} onClick={handleClose}> 
            <Box component={Icon} icon='ic:twotone-call-made' sx={{ width: 25, height: 30, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Wishes Sent</Typography> 
          </ListItemButton>
          
          <ListItemButton component={RouterLink} to={path.wishesReceived} onClick={handleClose}> 
            <Box component={Icon} icon='mdi:call-received' sx={{ width: 25, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Wishes Received</Typography> 
          </ListItemButton> 
          
          <ListItemButton component={RouterLink} to={path.magazine} onClick={handleClose}> 
            <Box component={Icon} icon='grommet-icons:article' sx={{ width: 25, height: 25, mr:1}} /> 
            <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Magazines</Typography> 
          </ListItemButton> 
        </Stack>
      </Popover>
 
    </StyledNavItem> 
  );
}

