 
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
// @mui
import { Card, Grid, Box, Typography, Stack, IconButton } from '@mui/material';  
// import { grey } from '@mui/material/colors';



import {useWindowDimensions} from '../../../utils/otherFunctions';
import {domainName} from '../../../utils/domain';
// ----------------------------------------------------------------------



export const AllPromoters = ({item}) => {  
  const {Id, Description, NewFirstName, NewLastName, Continent, Country, City, Photo } = item;  
  const { width } = useWindowDimensions();

  return (  
    <Grid key={Id}  item xs={12} sm={6} md={6} > 
     <Card sx={{
        p: 1, 
        boxShadow: 0,
        textAlign: 'left',
        // color: 'white', 
        // bgcolor: grey[700], 
        color: 'black', 
        bgcolor: 'white',   
        minHeight:165,
         
      }}> 

        <Grid container spacing={1}  textAlign='center' justifyContent='center'>
          <Grid item xs={12} sm={12} md={8}  >
            <Stack  spacing={1}>  
              <Typography variant="subtitle1" sx={{fontSize:{xs:12, sm:12, md:14}}}>
                The promoter{','} {NewFirstName}{' '}{NewLastName} {','} is from
              </Typography> 

              <Typography variant="subtitle2" sx={{fontSize:{xs:12, sm:12, md:14}}}>
                {Continent}{','} {Country}{','} {City}
              </Typography> 

              <Box className="notify" component={RouterLink} to={`/competition/${Description}/${Id}`}  sx={{fontSize:14, textDecoration: 'none', color:'blue'}}>
                <Typography variant="body2"> 
                    <IconButton aria-label="view a specific competition" sx={{fontSize:13, color:'blue'}}>
                        Continue <TrendingFlatIcon/>
                    </IconButton>
                </Typography >
                {Description}
              </Box>  

              <Box sx={{pl:{xs:`${width/2.5}px`, sm:`${width/5.5}px`}, display:{xs:'block', sm:'block', lg:'none'}}}>
                <img src={`${domainName}/EddyDreams/Home/images/${Photo}`} alt='' style={{width:50, }}/>
              </Box>
            </Stack>
          </Grid>
            
          <Grid item xs={12} sm={12} md={4}  sx={{display:{lg: 'block', sm:'none', xs:'none'}}}> 
            <Box  component="img"
              sx={{ 
                height: 80,
                width: { xs: 'auto', sm: 'auto',md: 'auto' },
                maxHeight: { xs: 80, sm: 80, md: 80 },
                maxWidth: { xs: 'auto', sm: 'auto', md: 'auto' },
                borderRadius: '10px', 
              }}

              alt=""
              src={`${domainName}/EddyDreams/Home/images/${Photo}`}
            />
          </Grid> 
        </Grid>
      </Card> 
    </Grid> 
  );
}


AllPromoters.propTypes = {    
  item: PropTypes.object,     
};
