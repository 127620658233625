

import ViewOneMagazineVideoActionTypes from './viewOneMagazineVideoTypes'


export const ViewOneMagazineVideoREQUEST = (item) => ({
    type: ViewOneMagazineVideoActionTypes.View_One_Magazine_Video_REQUEST,
    payload: item
}) 
 

export const ViewOneMagazineVideoRESET = item => ({
    type: ViewOneMagazineVideoActionTypes.View_One_Magazine_Video_RESET,
    payload: item
}) 