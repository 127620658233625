
import { useState, memo, } from 'react';      
import axios from 'axios'; 
import PropTypes from 'prop-types';   

// @mui
import { Stack, TextField, Typography, Card, Grid, } from '@mui/material';
import { LoadingButton } from '@mui/lab';  
  
 


import {domainName} from '../../../../utils/domain';


// ---------------------------------------------------------------------- 

export const UpdateVillage = memo(({item}) => {  

  const {      
    Description, 
    WhoAreYou,
    Id
  } = item;   
 

  const [error, setError] = useState('');
  const [villageName, setDescription] = useState(Description);
  const [personality, setWhoAreYou] = useState(WhoAreYou);

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);

    setError('');
  }
 
  const handleChangeWhoAreYou = (e) => {
    setWhoAreYou(e.target.value);

    setError('');
  }


  /*  Begin upload */ 
  const urlLocalRequest = `${domainName}/u/update/village`;

  const upload = async () => {  
    if(await (villageName).trim() === ''){
      setError('The village name is required');
      return;
    }   
    if(await (personality).trim() === ''){
      setError('The personality is required');
      return;
    } 
 
    const Description_ = await villageName;
    const WhoAreYou_ = await personality;
    const Id_ = await Id;
 
    const resp = await sendFileData(Id_, Description_, WhoAreYou_, urlLocalRequest);
 
    const jsonResponse = await resp.data; 
    const jsres = await jsonResponse.message; 
    setError(jsres); 
  } 
  

  const sendFileData = (Id_, Description_, WhoAreYou_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {  
        Id: Id_,
        Description: Description_,
        WhoAreYou: WhoAreYou_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  } 
  /*  End upload */ 
 

  return ( 
    <> 
    <Grid  item xs={12} sm={12} md={4} lg={3}>  

     <Card > 
        <Stack spacing={3} sx={{p:1, }}>
          <Typography variant="body1">
            Update a Village
          </Typography> 

          <TextField name="Description" value={villageName} onChange={handleChangeDescription} label="What is the village name?" inputProps={{ maxLength: 20 }} />

          <TextField name="WhoAreYou" value={personality} onChange={handleChangeWhoAreYou} label="Who are You?" inputProps={{ maxLength: 20 }} />
           
          <Typography variant='subtitle2' color='red'>{error}</Typography>

          <LoadingButton fullWidth size="large"  title='button Village' variant="contained" onClick={upload}> 
            Update
          </LoadingButton> 
        </Stack>  
     </Card>
    </Grid>
    </>
  );
})


UpdateVillage.propTypes = {    
  item: PropTypes.object,  
};
  
