

// ----------------------------------------------------------------------

const weekBackgroundDetails = [
  {
  	id: 1, 
    back: 'week.gif' 
   },  

  {
  	id: 2, 
    back: 'weekb.gif'
   },  
   
  {
  	id: 3, 
    back: 'weekc.gif'
   },  
   
  {
  	id: 4, 
    back: 'weekd.gif'
   },  
   
  {
  	id: 5, 
    back: 'weeke.gif'
   },  
   
  {
  	id: 6, 
    back: 'weekf.gif'
   },  
   
  {
  	id: 7, 
    back: 'weekg.gif'
   },  
   
  {
  	id: 8, 
    back: 'weekh.gif'
   },  
   
  {
  	id: 9, 
    back: 'weeki.gif'
   },  
   
  {
  	id: 10, 
    back: 'weekj.gif'
   },  
   
  {
  	id: 11, 
    back: 'week1.jpg'
   },  
   
  {
  	id: 12, 
    back: 'week2.jpg'
   },  
   
  {
  	id: 13, 
    back: 'week3.jpg'
   },  
   
  {
  	id: 14, 
    back: 'week4.jpg'
   },  
   
  {
  	id: 15, 
    back: 'week5.jpg'
   },  
   
  {
  	id: 16, 
    back: 'week6.jpg'
   },  
   
  {
  	id: 17, 
    back: 'week7.jpg'
   },  
   
  {
  	id: 18, 
    back: 'week8.jpg'
   },  
   
  {
  	id: 19, 
    back: 'week9.jpg'
   },  
   
  {
  	id: 20, 
    back: 'week10.jpg'
   },   
   
  {
  	id: 21, 
    back: 'week11.jpg'
   },   
   
  {
  	id: 22, 
    back: 'week12.jpg'
   },   
   
  {
  	id: 23, 
    back: 'week13.jpg'
   },   
   
  {
  	id: 24, 
    back: 'week14.jpg'
   },   
   
  {
  	id: 25, 
    back: 'week15.jpg'
   },   
   
  {
  	id: 26, 
    back: 'week16.jpg'
   },   
   
  {
  	id: 27, 
    back: 'week17.jpg'
   },   
   
  {
  	id: 28, 
    back: 'week18.jpg'
   },   
   
  {
  	id: 29, 
    back: 'week19.jpg'
   },   
   
  {
  	id: 30, 
    back: 'week20.jpg'
   },   
 
];

export default weekBackgroundDetails;
