import {useEffect, useRef,} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography, Stack, Avatar} from '@mui/material'; 

import { grey } from '@mui/material/colors';

import {playCurrentVideoPauseOther} from '../../../../utils/otherFunctions';



import {domainName} from '../../../../utils/domain';



export const Page3 = ({page3Features, BackgroundColor}) => {  

  const { 
    height, 
    tagHtml, 
    photoHeight, 
    photoWidth,   
    borderRadius,
    categoryDivision,
    Video,
    Photo,
    NewFullName,
    City,
    Country,
    Continent,
    eventCardBackgroundName,
    eventOrWish,
    receiver
  } = page3Features; 

     
  const backImage = `${domainName}${'/EddyDreams/gifs/'}${eventCardBackgroundName}`;   

  const pageScroll = {   
    textAlign: 'center', 
  };  


  const fileId = useRef();

  const playFileOnTouch = ()=> {
    fileId.current.play(); 
  }
  
  
  useEffect(() => {
    playCurrentVideoPauseOther(); 
  });

  
  /* beginning define an observer to make the video load progressively */
  /* const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting)
  } */

  const callbackFunction = () => { 
  }
  
  const options = useRef({});

  options.current = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  }

  useEffect(() => {
    const fileIdx = fileId.current
    const lazyVideoObserver = new IntersectionObserver(callbackFunction, options.current);
    if(fileIdx) lazyVideoObserver.observe(fileIdx);

    return () => {
      if(fileIdx) lazyVideoObserver.unobserve(fileIdx);
    }
  }, [fileId, options]);
  /* end define an observer to make the video load progressively */



  return (   
      <>
        <Grid container spacing={1} sx={{height:{height}, backgroundColor:'white', color:BackgroundColor, fontStyle:'italic', backgroundImage:`url(${backImage})`, backgroundSize: 'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} > 
          
          <Grid item xs={12} sm={12} md={12}>  
            <div style={pageScroll}>
            <Stack direction="row" spacing={1}  justifyContent="center">
              <Typography variant={tagHtml}  sx={{fontSize:{xs:25, sm:25, md:40}, font:`0 'Lovers Quarrel'`}}>
                {categoryDivision==='New Week'?'Happy New Week!':
                categoryDivision==='New Month'?'Happy New Month!':
                categoryDivision==='Halloween'?'Happy Halloween!':
                categoryDivision==='Thanksgiving'?'Happy Thanksgiving!':
                categoryDivision==='Easter'?'Happy Easter!':
                categoryDivision==='Christmas'?'Merry Christmas!':
                categoryDivision==='New Year'?'Happy New Year!':
                categoryDivision==='Birthday'?'Happy Birthday':
                categoryDivision==='Wedding'?'Happy Wedding Anniversary':
                categoryDivision==='Valentine'?"Happy Valentine's Day":
                'Have a good time'}
              </Typography>

              <Typography variant={tagHtml}  sx={{fontSize:{xs:25, sm:25, md:40}, font:`0 'Lovers Quarrel'`}}> 
                {eventOrWish === 'EVENT'? '':receiver }
              </Typography>
            </Stack>
            </div>  
          </Grid>  


          <Grid item xs={12} sm={12} md={12} sx={{boxShadow:10, borderStyle: 'solid', borderColor:'grey', borderRadius:'10px'}}>    
            <video ref={fileId} controls controlsList="nodownload" 
              style={{height: photoHeight,
                width: photoWidth,
                maxHeight: { xs: photoHeight, md: photoHeight },
                maxWidth: { xs: photoWidth, md: photoWidth },
                borderRadius: {borderRadius}, 
                objectFit:'cover' 
              }}
              poster="/assets/images/planetryvilage/video-to-lazy-load-1x.jpg"
              className="play-video" 
              
              onClick={playCurrentVideoPauseOther}

              onTouchStart={playFileOnTouch}

              onMouseOver={playFileOnTouch}

              onFocus={ () => null }  

              preload="none" 
              autoPlay loop playsInline   
            >
              <source src={`${domainName}/EddyDreams/otherFiles/videos/${Video}`} type="video/webm"/>
              <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/>
            </video>
          </Grid> 

          <Grid item xs={12} sm={12} md={12}> 
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={1}>
                <Avatar sx={{ bgcolor: grey[500], height:40, width:40 }} aria-label="profile photo" alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} />
                <Typography className="notranslate" variant={tagHtml} sx={{fontSize:{xs:30, sm:30, md:40}, font:`0 'Lovers Quarrel'`}}> 
                  {NewFullName}
                </Typography>
              </Stack>
            
              <Stack direction="row" spacing={1}> 
                <Typography variant={tagHtml} sx={{fontSize:{xs:30, sm:30, md:40}, font:`0 'Lovers Quarrel'`}}>
                  {City}, {Country}, {Continent} 
                </Typography> 
              </Stack>
            </Stack> 
          </Grid>  
        </Grid>
      </>   
  );
}



Page3.propTypes = {    
  page3Features: PropTypes.object,  
  BackgroundColor:  PropTypes.string, 
};  

// export default memo(Page3);