
const ViewOneMagazineVideoActionTypes = { 
    View_One_Magazine_Video_REQUEST: 'View_One_Magazine_Video_REQUEST',
    View_One_Magazine_Video_SUCCESS:'View_One_Magazine_Video_SUCCESS',
    View_One_Magazine_Video_FAILURE: 'View_One_Magazine_Video_FAILURE', 
    View_One_Magazine_Video_RESET: 'View_One_Magazine_Video_RESET', 
}
 
export default ViewOneMagazineVideoActionTypes

  

 