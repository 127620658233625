
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import ViewOneMyVillageVideoAudioActionTypes from '../viewOneMyVillageVideoAudio/viewOneMyVillageVideoAudioTypes'

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}/u/view/my/village/video/audio/single`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            token: user.token,
            type: user.type,
            sessionIdentifier: user.sessionIdentifier,
            sessionVideoAudioId: user.sessionVideoAudioId,
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaViewOneMyVillageVideoAudio({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: ViewOneMyVillageVideoAudioActionTypes.View_One_My_Village_Video_Audio_SUCCESS, response });
    } catch (error) {
        yield put({ type: ViewOneMyVillageVideoAudioActionTypes.View_One_My_Village_Video_Audio_FAILURE, error: error.message });
    }
}

export function* sagaViewOneMyVillageVideoAudioScreen() {
    yield takeLatest(ViewOneMyVillageVideoAudioActionTypes.View_One_My_Village_Video_Audio_REQUEST, sagaViewOneMyVillageVideoAudio);
}
