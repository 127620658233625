
import { useEffect, useRef, useCallback, memo  } from 'react';  
import { useCookies  } from 'react-cookie';
import { useNavigate,  } from 'react-router-dom'; 
import { useDispatch, useSelector, } from 'react-redux';
import PropTypes from 'prop-types';

import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import CloseIcon from '@mui/icons-material/Close'; 

// @mui 
import { Popover, IconButton, Typography, Divider, Grid } from '@mui/material'; 



import { fShortenNumber } from '../../../../utils/formatNumber'; 

import {UserInfoREQUEST,} from '../../../../redux/userInfo/userInfoActions';  
 
// ----------------------------------------------------------------------


export const UserInfo = memo(({openUserInfo, handleCloseUserInfo, magazineId}) => {   
  
  const dispatch = useDispatch();  
  const navigate = useNavigate();
  const _token = useRef(null); 
  const _type = useRef(null);



  let _numberTrackees = 0; 
  let _numberTrackers = 0; 
  let _userInfoTrackersTrackeesFiles = 0; 
 
  const userInfoReducerValue = useSelector(state => state.userInfoReducerValue);

  if(userInfoReducerValue.user){
    const userInfoValue = userInfoReducerValue.user;  
    _numberTrackees = userInfoValue.numberTrackees; 
    _numberTrackers = userInfoValue.numberTrackers; 
    _userInfoTrackersTrackeesFiles = userInfoValue.userInfoTrackersTrackeesFiles; 
  } 
  


  const handleUserInfo = useCallback( async (aToken, aType) => {
    const token = await aToken;
    const type = await aType;
    const userDivision = await 'Magazines';
    const vId = await magazineId; 

    await dispatch(UserInfoREQUEST({token, type, userDivision, vId}));
  }, [dispatch, magazineId]);
  

  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
        _token.current = cookies._token_planetryvilage.token; 
        _type.current = cookies._token_planetryvilage.type;

        handleUserInfo(_token.current, _type.current); 
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate, handleUserInfo, ]); 

  


  return (
    <> 

      <Popover
        open={Boolean(openUserInfo)}
        anchorEl={openUserInfo}
        onClose={handleCloseUserInfo}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          sx: {
            // p: 1,
            mt: 1.5,
            // ml: 0.75,
            width: 300,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            }, 
          },
        }}
      > 
        <Grid container spacing={0} > 
          <Grid  item xs={12} sm={12} md={12}> 
            <Grid container spacing={0} textAlign='right'> 
              <Grid  item xs={12} sm={12} md={12}>
                <IconButton aria-label="close"  onClick={handleCloseUserInfo}>
                  <CloseIcon sx={{fontSize:{xs:20, md:20}, color: 'black'}} />   
                </IconButton>  
                <Divider sx={{ borderStyle: 'dashed' }} />
              </Grid>
            </Grid>
          </Grid>  
          
          <Grid  item xs={12} sm={12} md={12}>
            <Typography variant='button' sx={{ml:1}}>User Information</Typography>
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
          
          <Grid  item xs={12} sm={12} md={12}>
            <IconButton aria-label="all" sx={{fontSize:{xs:13, md:15}, }} onClick={() => {handleCloseUserInfo();}}>
              <DirectionsRunIcon sx={{fontSize:{xs:15, md:20}}} />  
              <Typography variant='button' sx={{ml:1}}> 
                {_numberTrackers === 0 ? '0 tracker':
                 _numberTrackers === 1? '1 tracker':
                 `${fShortenNumber(_numberTrackers)} ${' trackers'}`}
              </Typography>
            </IconButton>
          </Grid>
          
          <Grid  item xs={12} sm={12} md={12}>
            <IconButton aria-label="all" sx={{fontSize:{xs:13, md:15},}} onClick={() => {handleCloseUserInfo();}}>
              <FollowTheSignsIcon sx={{fontSize:{xs:15, md:20}}} />  
              <Typography variant='button' sx={{ml:1}}>
                {_numberTrackees === 0 ? '0 trackee':
                 _numberTrackees === 1? '1 trackee':
                 `${fShortenNumber(_numberTrackees)} ${' trackees'}`}
              </Typography>
            </IconButton>
          </Grid>
          
          <Grid  item xs={12} sm={12} md={12}>
            <IconButton aria-label="all" sx={{fontSize:{xs:13, md:15}}} onClick={() => {handleCloseUserInfo();}}>
              <CloudCircleIcon sx={{fontSize:{xs:15, md:20}}} />  
              <Typography variant='button' sx={{ml:1}}> 
                {_userInfoTrackersTrackeesFiles === 0 ? '0 file':
                 _userInfoTrackersTrackeesFiles === 1? '1 file':
                 `${fShortenNumber(_userInfoTrackersTrackeesFiles)} ${' files'}`} 
              </Typography>
            </IconButton>
          </Grid>     
       
        </Grid> 
      </Popover>
    </>
  );
})


UserInfo.propTypes = {     
  openUserInfo: PropTypes.any, 
  handleCloseUserInfo: PropTypes.func, 
  magazineId: PropTypes.number, 
};
 