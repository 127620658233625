
import {useState, memo} from 'react';
import PropTypes from 'prop-types';
 

import HTMLFlipBook from "react-pageflip"; 
import {Box, Avatar, } from '@mui/material'; 

// icons
import { Icon } from '@iconify/react';  

import { grey } from '@mui/material/colors';

// hooks
import useResponsive from '../../../../hooks/useResponsive';

// utils
import {useWindowDimensions} from '../../../../utils/otherFunctions';
   

import {Page1, Page2, Page3, Page4} from './AllPages';
 

import {domainName} from '../../../../utils/domain';

// const ResizeScreen = 180;
// const ResizeFullScreen = 50;
const ResizeFullScreen = 60;


export const CardFullScreen = memo(({wishItem, eventOrWish, wishOption}) => {
  const [BackgroundColor, setBackgroundColor] = useState('white');   
  const multipleColor = ["black", "blue", "white", "red", "gray", "green"]; 
   

  const changeColor = () => {
    /* if (BackgroundColor === 'blue') {
      setBackgroundColor('white')  
    } else {
      setBackgroundColor('blue');
    } */

    const random = Math.floor(Math.random() * multipleColor.length);
    setBackgroundColor(multipleColor[random]); 
  }

  /* const {
    numberOfLikes,
    numberOfViews,
    numberOfComments,
    message,
    excited, 
    category,
    fileURL,
    photoURL,
    backgroundPhotoURL,
    fullName,
    city,
    country,
    continent,
    receiver
  } = wishItem; */

  const {
    City,
    Continent,
    Country,
    // DateCreated,
    // DateCreated2,
    FeastCardsId,
    Excited,
    // Id,
    Message,
    // Message2,
    NewFullName,
    NewFullName2,
    NumberComments,
    NumberLikes,
    NumberViews,
    Photo,
    Video,
    categoryDivision,
    eventCardBackgroundName,
     
  }  = wishItem
 
 

  const { height, width } = useWindowDimensions();
  const isDesktop = useResponsive('up', 'lg'); 
 
  const WIDTH = isDesktop? ((width/2) - ResizeFullScreen) : (width-20);
  const HEIGHT = height - 50;


  const page1Features = isDesktop ? { 
      height: HEIGHT, 
      FeastCardsId,
      NumberLikes,
      NumberViews,
      NumberComments,
      eventCardBackgroundName,
      wishOption
  } : { 
      height: HEIGHT, 
      FeastCardsId,
      NumberLikes,
      NumberViews,
      NumberComments,
      eventCardBackgroundName,
      wishOption
  };  


  const page2Features = isDesktop ? {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'h6',
      tagHtmlSecond: 'h3',
      photoHeight: HEIGHT *2/3,
      photoWidth: WIDTH  - 15,
      textHeight: HEIGHT *2/3,
      borderRadius:'10px',
      Message,
      Excited,
      eventCardBackgroundName
  } : {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT *2/3,
      photoWidth: WIDTH  - 10,
      textHeight: HEIGHT *2/3,
      borderRadius:'10px',
      Message,
      Excited,
      eventCardBackgroundName
  };


  const page3Features = isDesktop ? {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'h4',
      photoHeight: HEIGHT *2/3 - 20,
      photoWidth: WIDTH  - 15,
      textHeight: '150px',
      borderRadius:'10px',
      categoryDivision,
      Video,
      Photo,
      eventCardBackgroundName,
      NewFullName,
      City,
      Country,
      Continent, 
      eventOrWish,
      NewFullName2
  } : {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'subtitle2',
      photoHeight: HEIGHT *2/3 - 20,
      photoWidth: WIDTH  - 10,
      textHeight: '100px',
      borderRadius:'10px',
      categoryDivision,
      Video,
      Photo,
      eventCardBackgroundName,
      NewFullName,
      City,
      Country,
      Continent, 
      eventOrWish,
      NewFullName2
  };
 
  const page4Features = isDesktop ? {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT / 2,
      photoWidth: WIDTH / 2 - 15,
      textHeight: `${HEIGHT/3}px`,
      borderRadius:'10px',
      eventCardBackgroundName
  } : {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT / 2,
      photoWidth: WIDTH / 2 - 15,
      textHeight: `${HEIGHT/3}px`,
      borderRadius:'10px',
      eventCardBackgroundName
  };
  

  return (  
    <HTMLFlipBook width={WIDTH}  height={HEIGHT}  className="showItem">
      <div> 
        <div className='firstLast'> 
          <h4> 
            Click the right corner to read this {eventOrWish === 'EVENT'?'event': 'wish '} card
          </h4>

          <Box component={Icon} icon='mdi:hand-tap' sx={{ width:1, height: '100px', ml:'80px'}} onClick={changeColor}/> 

          <p>This {eventOrWish === 'EVENT'?'event': 'wish '} card was written by</p> 
          <Avatar sx={{ bgcolor: grey[500], height:60, width:60, ml:{xs: WIDTH/19, sm: WIDTH/17.5, md: WIDTH/17.5} }} aria-label="profile photo" alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} />
          <p className="notranslate">{NewFullName}</p> 
        </div>
      </div>
      
      <div data-density='hard'>
        <Page1 page1Features={page1Features} BackgroundColor={BackgroundColor} />
      </div>
          
      <div data-density='hard'> 
        <Page2 page2Features={page2Features} BackgroundColor={BackgroundColor} />
      </div>
       
      <div data-density='hard'> 
        <Page3 page3Features={page3Features} BackgroundColor={BackgroundColor} />
      </div>
       
      <div data-density='hard'> 
        <Page4 page4Features={page4Features} />
      </div> 
 
      <div> 
        <div className='firstLast'>
          <h3>Click the left corner to read the previous pages</h3>
          <Box component={Icon} icon='mdi:hand-tap' sx={{ width:1, height: '100px', pr:'100px'}} onClick={changeColor}/> 
          <p>This {eventOrWish === 'EVENT'?'event': 'wish '} card was written by</p> 
          <Avatar sx={{ bgcolor: grey[500], height:60, width:60, ml:{xs: WIDTH/19, sm: WIDTH/17.5, md: WIDTH/17.5} }} aria-label="profile photo" alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} />
          <p className="notranslate">{NewFullName}</p>
        </div>
      </div>
    </HTMLFlipBook> 
  );
})




CardFullScreen.propTypes = {    
  wishItem: PropTypes.object, 
  eventOrWish: PropTypes.string,
  wishOption: PropTypes.string,
}; 
 