
import ViewOneVideoPhotoActionTypes from './viewOneVideoPhotoTypes'

const INITIAL_STATE = {
    loading : false,
    user : null,
    error : null
}

export const viewOneVideoPhotoReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) { 
        case ViewOneVideoPhotoActionTypes.View_Video_Photo_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ViewOneVideoPhotoActionTypes.View_Video_Photo_SUCCESS:
            return {
                ...state,
                user: action.response,
                loading: false,
            };

        case ViewOneVideoPhotoActionTypes.View_Video_Photo_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            }; 

        case ViewOneVideoPhotoActionTypes.View_Video_Photo_RESET:
            return {
                ...state,
                error: null,
                loading: false,
                user : null,
            };
        default:
            return state;
    }
}
