import {useEffect, useRef, memo, } from 'react';
import { useNavigate,  } from 'react-router-dom'; 
import {Box, Card, CardHeader, CardContent, CardActions, Avatar, IconButton, Typography,  } from '@mui/material';
     
import { grey } from '@mui/material/colors';
   
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat'; 
 
import PropTypes from 'prop-types';

 

// utils
import { fShortenNumber } from '../../../../utils/formatNumber'; 
import {lowerCaseAllWordsExceptFirstLetters} from '../../../../utils/formatWord';

import {useWindowDimensions} from '../../../../utils/otherFunctions';
 

// hooks
import useResponsive from '../../../../hooks/useResponsive'; 


import {domainName} from '../../../../utils/domain';
 

 

// ----------------------------------------------------------------------------



export const PhotoOnly = memo(({keyProp, cardItem}) => {   
  const navigate = useNavigate();   
  const { height } = useWindowDimensions();
  const isDesktop = useResponsive('up', 'lg'); 
  
  // const HEIGHT = 350;
  // const HEIGHT = isDesktop? height*2/3 : height*2/3;
  const HEIGHT = isDesktop? height/4 : height*2/3 - 20;  
  
  const {
    Description,  
    categoryDivision,
    DateCreated, 
    NumberLikes, 
    NumberViews, 
    NumberComments, 
    fileTypeDivision, 
    VideoAudioPhoto, 
    Photo,
    NewFullName, 
    uservideoaudiophotoId
  } = cardItem;

  
  const titleTV = fileTypeDivision === "Video" ? `${lowerCaseAllWordsExceptFirstLetters(NewFullName)} ${'TV'}`:
                  fileTypeDivision === "Audio" ? `${lowerCaseAllWordsExceptFirstLetters(NewFullName)} ${'Radio'}`:
                  `${lowerCaseAllWordsExceptFirstLetters(NewFullName)}`;


  const viewSingleFile = () => {
    navigate(`/home/${uservideoaudiophotoId}`, { replace: true });
  }
 


  
  /* beginning define an observer to make the photo load progressively */
  /* const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting)
  } */
  const refPhoto = useRef();

  const callbackFunction2 = () => { 
  }
  
  const options2 = useRef({});

  options2.current = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  }

  useEffect(() => {
    const fileIdx = refPhoto.current
    const lazyVideoObserver = new IntersectionObserver(callbackFunction2, options2.current);
    if(fileIdx) lazyVideoObserver.observe(fileIdx);  

    return () => {
      if(fileIdx) lazyVideoObserver.unobserve(fileIdx);
    }
  }, [refPhoto, options2]);
  /* end define an observer to make the photo load progressively */


  
 

  

  return (  
    <Card className="showItem" sx={{ maxWidth: 680, mb:1, borderRadius:{xs:0, sm:1, md:1}, p:0, m:'auto'  }} onClick={viewSingleFile}> 
       
      <Box  component="img" ref={refPhoto}
        sx={{ 
          height: HEIGHT,
          width: { xs: '100%', sm: '100%', md: '100%' },
          // maxHeight: { xs: HEIGHT, md: HEIGHT },
          maxHeight: { xs: 'auto', md: 'auto' },
          maxWidth: { xs: '100%', sm: '100%', md: '100%'  }, 
          m:'auto',
          p:0, 
          objectFit:'cover'
        }}
        className={`${"show picture "}${keyProp}`}
        loading="lazy"

        alt="photo"   
        src={`${domainName}/EddyDreams/otherFiles/images/${VideoAudioPhoto}`}
      />   

      {/* <picture>
        <source media="(min-width: 800px)" srcSet={`${domainName}/EddyDreams/otherFiles/images/${VideoAudioPhoto}`}/>
        <img ref={refPhoto}
          style={{height: HEIGHT, width:'100%', objectFit:'cover'}}
          alt="user"   
          src="/assets/images/planetryvilage/image-to-lazy-load-1x.jpg"
          loading="lazy"
          className="lazy"

        />
      </picture> */}



      <CardActions sx={{justifyContent:'center'}}>
        <IconButton aria-label="add to favorites" sx={{fontSize:{xs:13, md:15}, color:"black", }} >
          {fShortenNumber(NumberLikes)} <FavoriteBorderIcon sx={{fontSize:{xs:20, md:25}, }} />
        </IconButton>
        <IconButton aria-label="views" sx={{fontSize:{xs:13, md:15}, color:"black"}}>
          {fShortenNumber(NumberViews)} <VisibilityIcon  sx={{fontSize:{xs:20, md:25}, color:"black"}}/>
        </IconButton> 

        <IconButton aria-label="comment" sx={{fontSize:{xs:13, md:15}, color:"black"}}  >
          {fShortenNumber(NumberComments)} <ChatIcon  sx={{fontSize:{xs:20, md:25}, color:"black"}}/>
        </IconButton>  
      </CardActions> 

       
      <CardHeader  sx={{pt:0}}
        avatar={
          <Avatar sx={{ bgcolor: grey[500] }} alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} /> 
        } 

        titleTypographyProps={{
          fontSize: 15,
          noWrap: true
        }}

        classes={{
          title: "notranslate"
        }}

        title={titleTV} 
        subheader={DateCreated}

      /> 
    
      <CardContent>
        <Typography variant="body1" color="black" noWrap>
          {categoryDivision}{': '}{Description} 
        </Typography>
      </CardContent>
       
    </Card> 
    
  );
})


 
PhotoOnly.propTypes = {
  cardItem: PropTypes.object,
  keyProp: PropTypes.any,
};
 