
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import ForgottenPasswordActionTypes from '../password/forgottenPasswordTypes'; 

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}/u/forgot/password`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            email: user.email,
            hostname: user.hostname,
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaForgottenPassword({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: ForgottenPasswordActionTypes.Forgotten_Password_SUCCESS, response });
    } catch (error) {
        yield put({ type: ForgottenPasswordActionTypes.Forgotten_Password_FAILURE, error: error.message });
    }
}

export function* sagaForgottenPasswordScreen() {
    yield takeLatest(ForgottenPasswordActionTypes.Forgotten_Password_REQUEST, sagaForgottenPassword);
}
