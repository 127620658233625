import TvRadioStreamingActionTypes from './tvRadioStreamingTypes'


export const TvRadioStreamingREQUEST = (item) => ({
    type: TvRadioStreamingActionTypes.Tv_Radio_Streaming_REQUEST,
    payload: item
}) 


export const TvRadioStreamingRESET = item => ({
    type: TvRadioStreamingActionTypes.Tv_Radio_Streaming_RESET,
    payload: item
}) 

