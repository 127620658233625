
const ViewOneEventCardVideoActionTypes = { 
    View_One_Event_Card_Video_REQUEST: 'View_One_Event_Card_Video_REQUEST',
    View_One_Event_Card_Video_SUCCESS:'View_One_Event_Card_Video_SUCCESS',
    View_One_Event_Card_Video_FAILURE: 'View_One_Event_Card_Video_FAILURE', 
    View_One_Event_Card_Video_RESET: 'View_One_Event_Card_Video_RESET', 
}
 
export default ViewOneEventCardVideoActionTypes

  

 