

import ViewOneVillageVideoAudioTypes from './viewOneVillageVideoAudioTypes'


export const ViewOneVillageVideoAudioREQUEST = (item) => ({
    type: ViewOneVillageVideoAudioTypes.View_One_Village_Video_Audio_REQUEST,
    payload: item
}) 
 

export const ViewOneVillageVideoAudioRESET = item => ({
    type: ViewOneVillageVideoAudioTypes.View_One_Village_Video_Audio_RESET,
    payload: item
}) 