import {useEffect, useRef, } from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@mui/material'; 
 

import {playCurrentVideoPauseOther} from '../../../../utils/otherFunctions';



import {domainName} from '../../../../utils/domain'; 

// -----------------------------------------------------------
 

export const Page5 = ({page5Features}) => {  
  
  const { 
    height, 
    tagHtml, 
    photoHeight, 
    photoWidth, 
    textHeight,
    // borderRadius,
    Content1Page5,
    Video1Page5
  } = page5Features; 

  const pageScroll = {
    overflowY: 'scroll', 
    height:textHeight, 
  };  


  const fileId = useRef();

  const playFileOnTouch = ()=> {
    fileId.current.play(); 
  }
  
  
  useEffect(() => {
    playCurrentVideoPauseOther(); 
  });

  /* beginning define an observer to make the video load progressively */
  /* const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting)
  } */

  const callbackFunction = () => { 
  }
  
  const options = useRef({});

  options.current = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0
  }

  useEffect(() => {
    const fileIdx = fileId.current
    const lazyVideoObserver = new IntersectionObserver(callbackFunction, options.current);
    if(fileIdx) lazyVideoObserver.observe(fileIdx);

    return () => {
      if(fileIdx) lazyVideoObserver.unobserve(fileIdx);
    }
  }, [fileId, options]);
  /* end define an observer to make the video load progressively */

  

  return (   
      <>
        <Grid container spacing={1} sx={{height:{height}, backgroundColor:'white'}} > 
          <Grid item xs={12} sm={12} md={12}>    
            <video ref={fileId} controls controlsList="nodownload" 
              style={{height: photoHeight,
                width: photoWidth,
                maxHeight: { xs: photoHeight, md: photoHeight },
                maxWidth: { xs: photoWidth, md: photoWidth },
                borderRadius: 10, 
                objectFit:'cover'
              }}
              poster="/assets/images/planetryvilage/video-to-lazy-load-1x.jpg"
              className="play-video"
              onClick={playCurrentVideoPauseOther} 

              onTouchStart={playFileOnTouch}

              onMouseOver={playFileOnTouch}

              onFocus={ () => null }  

              preload="none" 
              autoPlay loop playsInline   
            >
              <source src={`${domainName}${'/EddyDreams/otherFiles/videos/'}${Video1Page5}`} type="video/webm"/>
              <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/>
            </video>
          </Grid> 

          <Grid item xs={12} sm={12} md={12}> 
            <div style={pageScroll}>
              <Typography variant={tagHtml} sx={{fontSize:{xs:26, sm:26, md:35}, font:`50px 'Lovers Quarrel'`}}>
                {Content1Page5} 
              </Typography>
            </div>
          </Grid>  
        </Grid>
      </>   
  );
}


Page5.propTypes = {    
  page5Features: PropTypes.object, 
}; 
 