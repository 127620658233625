  
import { useState, useRef, useEffect, } from 'react';  
import { useNavigate, } from 'react-router-dom';  
import { useCookies  } from 'react-cookie';  
import axios from 'axios'; 
import { useSpeechRecognition } from "react-speech-recognition";

// mui
import { Stack, Typography, Grid, TextField, Card, Divider, IconButton, LinearProgress, FormLabel   } from '@mui/material';  

import { LoadingButton } from '@mui/lab'; 
import PhotoCamera from '@mui/icons-material/PhotoCamera'; 
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

import {CategoryMenuItem} from './AllCreatedFiles';


import {isFileAnImage, isFileAnAudio, isFileAVideo, fileDuration_} from '../../../utils/otherFunctions';


import {domainName} from '../../../utils/domain';
    
    
import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, 
     } from '../../../utils/translateFunctions';


import { cloud99, cloud100 } from '../../../utils/automateCreateVideoFunctions';


import { cloud6, cloud55, cloud179, cloud53, cloud51, cloud15, cloud149, cloud169, cloud173, cloud7,
         cloud153, cloud175, cloud165, cloud167, cloud171, cloud161, cloud177, cloud155, cloud151, cloud163, cloud157,
         cloud181, cloud183, cloud186, cloud187 } from '../../../utils/automateAllCommonFunctions';


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------

export default function CreateVideoAudioPhoto() {    
  const navigate = useNavigate();   

  const token = useRef(null); 
  const type = useRef(null);


  const [cookies, ] = useCookies(['_token_planetryvilage']);     
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 


  const [files, setFiles] = useState([]);  
  const [maxMinute, setMaxMinute] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);   
  const [preview, setPreview] = useState({imageURL:'', audioURL: '', videoURL: ''});
  const [fileTypeCheck, setFileTypeCheck] = useState({imageFile:false, audioFile:false, videoFile:false});
  const [selectedCategory, setSelectedCategory] = useState('Birthday');
  const [message, setMessage] = useState(''); 

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);

    setMaxMinute('');
  }
 
   
  const handleSelectCategory = (a) => {
    setSelectedCategory(a); 
  }



  /*  Begin upload */
  let urlLocalComplete; 
  let urlLocalData;
  let urlLink2;
  let urlLink;
  let options;
  let EndPoints;
  let fileCategory;
  const urlLocalRequest = `${domainName}/u/upload/photo/video/audio`;
  const TFile = [];
  let numberOfFiles = 0; 


  const generateUrlFile = (urlLink_) => {
    urlLink2 = `${urlLink_}${'-data'}`;
    urlLink = `${urlLink_}${'-complete'}`;

    return {
      urlLink2,
      urlLink
    };
  }


  const upload = async () => {  
    if(await (message).trim() === ''){
      setMaxMinute('The description is required');
      return;
    }  

    if(await files.length === 0){
      setMaxMinute('Select a file');
      return;
    }
 
 

    TFile[0] = files;
    numberOfFiles = await files.length;

    if(fileTypeCheck.imageFile) {  
      fileCategory = 'images';
    } else 
    if(fileTypeCheck.audioFile) { 
      fileCategory = 'audios';
    } else 
    if (fileTypeCheck.videoFile) { 
      fileCategory = 'videos';
    }
 

    urlLink2 = await generateUrlFile(urlLocalRequest).urlLink2;

    urlLink = await generateUrlFile(urlLocalRequest).urlLink;

    urlLocalComplete = await urlLink;
    urlLocalData = await urlLink2;


    EndPoints = await {
      UPLOAD: urlLocalComplete,
      UPLOAD_DATA: urlLocalData,
      UPLOAD_REQUEST: urlLocalRequest
    }
 

    options = await {
      url: EndPoints.UPLOAD,
      startingByte: 0,
      // chunkSize: 100000,
      chunkSize: 1000000,
      fileId: '',
      arrayId: ''
    };
 

    uploadTrigger();  
  } 


  const uploadTrigger = async () => {  
    const optionResult = await uploadCreateDirectory();

    const optionResultArrayIdLength = await optionResult.arrayId.length;
    const filesLength = await numberOfFiles;

    if (await (optionResultArrayIdLength > 0)) {
      for (let z = 0; z < filesLength; z+=1) {
        // const files_ = TFile[0].files[z];
        const files_ = (TFile[0])[z];
        if (files_) {   
          uploadExecution(files_, z);
        }
      }
    }
  }


  const uploadCreateDirectory = async () => {
    const audioVideoPhotoDescription_ = await message; 

    const resp = await sendFile(numberOfFiles, fileCategory, EndPoints.UPLOAD_REQUEST);

    try {
      const jsonResponse = await resp.data;   

      if (await (resp.status === 400)) {
        await console.log('An error occured in the directory');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      }  


      options = await {
        ...options,
        ...jsonResponse
      };

      const respData = await sendFileData(options.arrayId, audioVideoPhotoDescription_, token.current, type.current, selectedCategory, fileCategory, EndPoints.UPLOAD_DATA);
       
      if (await (respData.status === 400)) {
        await console.log('Missing file and content');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      } 

      return options; 
    } catch (err) {
      console.error('failed to read file: ', err);

      options = await {
        ...options,
        ...{
          arrayId: []
        }
      };
      return options;
    } 
  }


  const uploadFileChunks = async (file, options, i) => {
    let options_;

    if (await isFileAnImage(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          // chunkSize: 1000 
          chunkSize: 100000 
        }
      };
    } else
    if (await isFileAnAudio(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          // chunkSize: 10000
          chunkSize: 100000 
        }
      };
    } else {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        }
      };
    }
    await sendFileChunks(file, options_.startingByte, options_.chunkSize, options_, i);

  }


  const sendFileChunks = async (file, startingByte, endingByte_, options_, i) => {
    const fileSize = await file.size;
    let endingByte = await endingByte_;

    const formData = await new FormData();
    const xhr = await new XMLHttpRequest();

    if (fileSize < endingByte) {
      endingByte = await fileSize;
    }
 

    if (endingByte < fileSize) {
      xhr.onreadystatechange = async () => {
        if (await (xhr.readyState === XMLHttpRequest.DONE)) {
          const percentageUploaded = await (endingByte / fileSize) * 100;  

          await setMaxMinute('Upload in progress');
          await setProgressPercentage(parseInt(percentageUploaded, 10)); 

          await sendFileChunks(file, startingByte + options_.chunkSize, startingByte + (options_.chunkSize * 2), options_, i);
        }
      }
    } else { 
      await setProgressPercentage(0);

      await setMessage('');

      await setPreview({
        preview, 
        ...{
          imageURL: '', 
          audioURL: '', 
          videoURL: ''
        }
      });

      await setFiles([]);
      await setMaxMinute(''); 
    }


    const chunk = await file.slice(startingByte, endingByte);

    await formData.append('chunk', chunk, file.name);
    await formData.append('startingByte', startingByte);
    await formData.append('endingByte', endingByte);
    await formData.append('fileId', options_.fileId);
    await formData.append('X-fileCategory', fileCategory);

    await xhr.open('POST', options_.url, true);
    await xhr.setRequestHeader('X-File-Id', options_.fileId);
    await xhr.setRequestHeader('X-File-Size', fileSize);
    await xhr.setRequestHeader('X-startingByte', startingByte);
    await xhr.setRequestHeader('X-endingByte', endingByte);
    await xhr.setRequestHeader('X-fileCategory', fileCategory);

    await xhr.send(formData);
  }


  const sendFile = (numberOfFiles_, fileCategory_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        numberOfFiles: numberOfFiles_, 
        fileCategory: fileCategory_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }


  const sendFileData = (fileData, audioVideoPhotoDescription_, token_, type_, selectedCategory, fileCategory_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        fileData_: fileData,
        Description: audioVideoPhotoDescription_,
        token: token_,
        type: type_,
        DivisionName: selectedCategory,
        fileCategory: fileCategory_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }



  const uploadExecution = async (files_, i) => {
    let newFile;

    if (await isFileAnImage(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.jpg');
    } else 
    if (await isFileAnAudio(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.wav');
    } else {
      newFile = await renameFile(files_, 'planetryvilage.webm');
    }

    if (!newFile) {
      await console.log("You must first select the file to upload it");
    } else {
      try {
        await uploadFileChunks(newFile, options, i);
      } catch (err) {
        console.error('failed to read file: ', err);
      }
    }
  }


  function renameFile (originalFile_, newName_) {
    return new File([originalFile_], newName_, {
      type: originalFile_.type,
      lastModified: originalFile_.lastModified,
    });
  }
  /*  End upload */




  const resetAllFile = () => {
  	setFileTypeCheck({
  		fileTypeCheck, 
  		...{
  			imageFile:false, 
  			audioFile:false, 
  			videoFile:false
  		}
  	});

  	setPreview({
  		preview, 
  		...{
  			imageURL: '', 
  			audioURL: '', 
  			videoURL: ''
  		}
  	});

  	setFiles([]);
  	setMaxMinute('Max 2 minutes');
  }


 

  // check if a file is an image or an audio or a video
  const fileChecker = async (file) => {
  	if (await isFileAnImage(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:true, 
  				audioFile:false, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: URL.createObjectURL(file), 
  				audioURL: '', 
  				videoURL: ''
  			}
  		});
  	} else 
  	if(await isFileAnAudio(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:true, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: URL.createObjectURL(file), 
  				videoURL: ''
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else
  	if(await isFileAVideo(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:false, 
  				videoFile:true
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: '', 
  				videoURL: URL.createObjectURL(file)
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else{
  		// the file is nor an image nor an audio nor a video.  Reset everything
  		resetAllFile();
  	}
  }
   
  const handleFilesChange = (e) => {
   	if (e.target.files) {
      setFiles(e.target.files); 
      fileChecker(e.target.files[0]);  
    }   
  }; 





  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
    
  const inputElement = useRef();
  const triggerUploadClick = useRef();

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {  
        let description;     

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));   
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t); 

          
          /* add a description */
          if (await stringComparison([cloud99.a], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            description = await tm;

            await setMessage(description);

            speech.text = await transMany__('The description has been updated') || 'The description has been updated';
            speechSynth.speak(speech);
          } else {
            description = await '';
          }

          
          /* select a file */
          if (await stringComparison([cloud100.a, cloud100.b, cloud100.c, cloud100.d, cloud100.e, cloud100.f, cloud100.g, cloud100.h, cloud100.i, cloud100.j, cloud100.k], t)) {
            await inputElement.current.click();  

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech);
          }

          /* trigger an upload */
          if (await stringComparison([cloud6.a, cloud6.b, cloud6.c, cloud6.d, cloud6.e, cloud6.f, cloud6.g, cloud6.h, cloud6.i], t)) {
            await triggerUploadClick.current.click();

            speech.text = await transMany__('The save has been triggered') || 'The save has been triggered';
            speechSynth.speak(speech);
          }



          /* beginning select a category */
          if (await stringComparison([cloud55.a, cloud55.b], t)) {  
            await handleSelectCategory('Birthday'); 

            speech.text = await transMany__('The birthday celebration is selected') || 'The birthday celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud179.a, cloud179.b, cloud179.c, cloud179.d], t)) {  
            await handleSelectCategory('Wedding'); 

            speech.text = await transMany__('The wedding celebration is selected') || 'The wedding celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud53.a, cloud53.b], t)) {  
            await handleSelectCategory('Easter'); 

            speech.text = await transMany__('The easter celebration is selected') || 'The easter celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud51.a, cloud51.b], t)) {  
            await handleSelectCategory('Christmas'); 

            speech.text = await transMany__('The christmas celebration is selected') || 'The christmas celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud15.a, cloud15.b], t)) {  
            await handleSelectCategory('New Year'); 

            speech.text = await transMany__('The new year celebration is selected') || 'The new year celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud149.a, cloud149.b, cloud149.c, cloud149.d, cloud149.e], t)) {  
            await handleSelectCategory('Collective Intelligence'); 

            speech.text = await transMany__('The creativity and imagination is selected') || 'The creativity and imagination is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud169.a, cloud169.b, cloud169.c, cloud169.d, cloud169.e, cloud169.f, cloud169.g, cloud169.h, cloud169.i], t)) {  
            await handleSelectCategory('Motivational Speech'); 

            speech.text = await transMany__('The motivational speech is selected') || 'The motivational speech is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud173.a, cloud173.b, cloud173.c, cloud173.d, cloud173.e, cloud173.f, cloud173.g, cloud173.h], t)) {  
            await handleSelectCategory('Reality TV'); 

            speech.text = await transMany__('The reality tv is selected') || 'The reality tv is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud7.a, cloud7.b, cloud7.c, cloud7.d], t)) {  
            await handleSelectCategory('Life Experience'); 

            speech.text = await transMany__('The life experience is selected') || 'The life experience is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud153.a, cloud153.b, cloud153.c, cloud153.d, cloud153.e, cloud153.f], t)) {  
            await handleSelectCategory('Dancers'); 

            speech.text = await transMany__('The dance is selected') || 'The dance is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud175.a, cloud175.b, cloud175.c, cloud175.d, cloud175.e, cloud175.f, cloud175.g], t)) {  
            await handleSelectCategory('Singers'); 

            speech.text = await transMany__('The song is selected') || 'The song is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud165.a, cloud165.b, cloud165.c, cloud165.d, cloud165.e, cloud165.f, cloud165.g, cloud165.h, cloud165.i, cloud165.j], t)) {  
            await handleSelectCategory('Hairstyle'); 

            speech.text = await transMany__('The hairstyle is selected') || 'The hairstyle is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud167.a, cloud167.b, cloud167.c, cloud167.d, cloud167.e, cloud167.f, cloud167.g, cloud167.h], t)) {  
            await handleSelectCategory('Make-Up'); 

            speech.text = await transMany__('The make-up is selected') || 'The make-up is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud171.a, cloud171.b, cloud171.c, cloud171.d, cloud171.e, cloud171.f, cloud171.g, cloud171.h, cloud171.i, cloud171.j, cloud171.k], t)) {  
            await handleSelectCategory('Outfit'); 

            speech.text = await transMany__('The outfit is selected') || 'The outfit is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud161.a, cloud161.b, cloud161.c, cloud161.d, cloud161.e, cloud161.f, cloud161.g, cloud161.h, cloud161.i], t)) {  
            await handleSelectCategory('Gossip'); 

            speech.text = await transMany__('The gossip is selected') || 'The gossip is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud177.a, cloud177.b, cloud177.c, cloud177.d, cloud177.e, cloud177.f, cloud177.g, cloud177.h, cloud177.i, cloud177.j, cloud177.k, cloud177.l], t)) {  
            await handleSelectCategory('Talking Non-Stop'); 

            speech.text = await transMany__('Talking non-stop is selected') || 'Talking non-stop is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud155.a, cloud155.b, cloud155.c, cloud155.d, cloud155.e, cloud155.f], t)) {  
            await handleSelectCategory('Drawing'); 

            speech.text = await transMany__('The drawing is selected') || 'The drawing is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud151.a, cloud151.b, cloud151.c, cloud151.d], t)) {  
            await handleSelectCategory('Cooking'); 

            speech.text = await transMany__('The cooking is selected') || 'The cooking is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud163.a, cloud163.b, cloud163.c, cloud163.d, cloud163.e], t)) {  
            await handleSelectCategory('Gym'); 

            speech.text = await transMany__('The gym is selected') || 'The gym is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud157.a, cloud157.b, cloud157.c, cloud157.d], t)) {  
            await handleSelectCategory('Freestyle'); 

            speech.text = await transMany__('The freestyle is selected') || 'The freestyle is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud181.a, cloud181.b, cloud181.c, cloud181.d, cloud181.e, cloud181.f, cloud181.g, cloud181.h, cloud181.i], t)) {  
            await handleSelectCategory('Advertising'); 

            speech.text = await transMany__('The invention showcase is selected') || 'The invention showcase is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud183.a, cloud183.b, cloud183.c, cloud183.d], t)) {  
            await handleSelectCategory('Opportunity Zones'); 

            speech.text = await transMany__('The opportunity zone is selected') || 'The opportunity zone is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud186.a, cloud186.b], t)) {  
            await handleSelectCategory('Anniversary'); 

            speech.text = await transMany__('The anniversary celebration is selected') || 'The anniversary celebration is selected';
            speechSynth.speak(speech); 
          }

          if (await stringComparison([cloud187.a, cloud187.b, cloud187.c, cloud187.d, cloud187.e], t)) {  
            await handleSelectCategory('Help'); 

            speech.text = await transMany__('The help is selected') || 'The help is selected';
            speechSynth.speak(speech); 
          }
          /* end select a category */  

        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, selectedCategory]);

 
  /* End Michael */  
 
  
  return (
    <>   
      <Grid  item xs={12} sm={12} md={8} lg={6}>
        <Card >
          <Stack spacing={1} sx={{p:1, textAlign:'center', justifyContent:'center'}}>
            <Typography variant="string">
              Upload a photo / video / audio  
            </Typography>

            <Divider/> 

            <CategoryMenuItem handleSelectCategory={handleSelectCategory} selectedCategory={selectedCategory}/>

            <TextField name="Description" value={message} onChange={handleChangeMessage} label="Description" />

            <FormLabel id="selct-a-file">select a file</FormLabel>
            <IconButton color="primary" aria-label="upload picture video audio"  title='select a video or audio or photo' component="label">
              <input hidden accept="*" type="file" onChange={handleFilesChange} ref={inputElement} />
              <PhotoCamera sx={{fontSize:{xs:30, md:40}}} /> 
              <VideoCameraBackIcon sx={{fontSize:{xs:30, md:40}}} /> 
              <AudiotrackIcon sx={{fontSize:{xs:30, md:40}}} /> 
            </IconButton>
                
            {!!progressPercentage && 
            ( <>
                <LinearProgress variant="determinate" value={progressPercentage}/>
                <Typography variant="body2" color="text.secondary">
                  {`${Math.round(progressPercentage)}%`}
                </Typography> 
              </>
            )} 
                
            {(!!files.length) &&  
            (<>
              {fileTypeCheck.imageFile && <img src={preview.imageURL} alt='' style={{maxWidth: '250px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}

              {fileTypeCheck.videoFile && <video controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                loop 
                src={preview.videoURL}
              ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></video>}
                

              {fileTypeCheck.audioFile && <audio controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                loop 
                src={preview.audioURL}
              ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></audio>}              
            </>)}
            

            <Typography variant='subtitle2' color='red'>{maxMinute}</Typography>
            
            <LoadingButton fullWidth size="large"  type="button"  title='Share' variant="contained" ref={triggerUploadClick}  onClick={upload}> 
              Upload
            </LoadingButton> 
              
          </Stack>
          </Card> 
        </Grid>
    </>
  );
}
