import ForgottenPasswordActionTypes from './forgottenPasswordTypes'

const INITIAL_STATE = {
    loading : false,
    user : null,
    error : null
}

export const forgottenPasswordReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) { 
        case ForgottenPasswordActionTypes.Forgotten_Password_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ForgottenPasswordActionTypes.Forgotten_Password_SUCCESS:
            return {
                ...state,
                user: action.response,
                loading: false,
            };

        case ForgottenPasswordActionTypes.Forgotten_Password_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

        case ForgottenPasswordActionTypes.Forgotten_Password_RESET:
            return {
                ...state,
                error: null,
                loading: false,
                user : null,
            };
        default:
            return state;
    }
}
