
import { useNavigate, } from 'react-router-dom';
// @mui
import {
  Box, 
  IconButton, 
  Stack,
  Typography
} from '@mui/material';


// icons
import { Icon } from '@iconify/react';



// ----------------------------------------------------------------------
 
export default function SongVirtualAssistantPopover() {   
  const navigate = useNavigate();   


  return (
    <> 
      <Stack sx={{textAlign:'center', justifyContent:'center', ml:1, }}>
        <IconButton color='primary' sx={{width:35, height:15}} onClick={() => {navigate('/play/song/using/michael', { replace: true });}}>   
          {/* <Iconify icon="game-icons:love-song" width={24} height={24} color='red'/> */}
          <Box component={Icon} icon='game-icons:love-song'  color='blue' /> 
        </IconButton>
        <Typography sx={{fontSize:'11px', color:'black'}}>{`${'Play Music'}`}</Typography>
      </Stack> 

       
    </>
  );
}

// ----------------------------------------------------------------------

