 
import PropTypes from 'prop-types';
import {Grid, Typography} from '@mui/material'; 


import {domainName} from '../../../../utils/domain';


export default function Page2 ({page2Features, BackgroundColor}) {  

  const { 
    height, 
    tagHtml, 
    tagHtmlSecond, 
    textHeight,
    Message,
    Excited,
    eventCardBackgroundName
  } = page2Features; 


  const backImage = `${domainName}${'/EddyDreams/gifs/'}${eventCardBackgroundName}`;   
  
  const pageScroll = {
    overflowY: 'scroll',  
    textAlign: 'center',
    maxHeight: textHeight,  
  };  

  return (   
      <>
        <Grid container spacing={1} sx={{height:{height}, fontSize:{xs:35, sm:35, md:35}, font:`50px 'Lovers Quarrel'`, backgroundColor:'white', color:BackgroundColor, fontStyle:'italic', backgroundImage:`url(${backImage})`, backgroundSize: 'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} > 
          <Grid item xs={12} sm={12} md={12}>  
            <div style={pageScroll}>
              <Typography variant={tagHtml} sx={{fontSize:{xs:35, sm:35, md:50}, font:`0 'Lovers Quarrel'`}}>
                {Message}
              </Typography>
            </div>
          </Grid> 

          <Grid item xs={12} sm={12} md={12}> 
            <div style={pageScroll}>
              <Typography variant={tagHtmlSecond} sx={{fontSize:{xs:35, sm:35, md:40}, font:`0 'Lovers Quarrel'`}}>
                {Excited}
              </Typography>
            </div>
          </Grid>  
        </Grid>
      </>   
  );
}



Page2.propTypes = {    
  page2Features: PropTypes.object, 
  BackgroundColor:  PropTypes.string,
};  
 