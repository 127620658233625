
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import ReportAbuseActionTypes from '../reportAbuse/reportAbuseTypes'; 

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}/u/report/abuse`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            token: user.token, 
            type: user.type,
            vId: user.vId,
            userDivision: user.userDivision,
            abuseTypeName: user.abuseTypeName
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaReportAbuse({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: ReportAbuseActionTypes.Report_Abuse_SUCCESS, response });
    } catch (error) {
        yield put({ type: ReportAbuseActionTypes.Report_Abuse_FAILURE, error: error.message });
    }
}

export function* sagaReportAbuseScreen() {
    yield takeLatest(ReportAbuseActionTypes.Report_Abuse_REQUEST, sagaReportAbuse);
}
