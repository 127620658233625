
import { Helmet } from 'react-helmet-async';  
import { useSelector  } from 'react-redux'; 
// mui
import { Container, Typography, Grid, } from '@mui/material';  



// sections
import {  
  AllPromoters,
} from '../../sections/@users/competitionUser';

// mock
// import competitionDetails from '../../_mock/competitionDetails'; 


// ----------------------------------------------------------------------

export default function CompetitionPage() {   
  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);

  let allCompetitionsValue = []; 

  if(allDataOnceReducerValue.user){
    allCompetitionsValue = allDataOnceReducerValue.user.allCompetitions; 
  } 

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Competition </title>
      </Helmet>

      <Container maxWidth="xl" sx={{p:0}}>
        {/* <Typography variant="h4" sx={{ color:'white' }} fontSize="14px">
          Competition
        </Typography> */} 

        
        {/* <Card sx={{shadowBox:20, borderRadius:{xs:0, sm:0, md:'10px'}}}> */}
        <Grid container spacing={1} sx={{p:2}}  textAlign='center' justifyContent='center'> 
          <Grid  item xs={12} sm={12} md={12} >
          <Typography variant="h4" sx={{ color:'white' }} fontSize="14px">
          Competition
        </Typography>
          </Grid>

          {allCompetitionsValue.map((item, key) => ( 
            <AllPromoters key={key} item={item} />
          ))}
        </Grid>
        {/* </Card> */}
        
      </Container>
    </>
  );
}
