 
import { useState, useEffect, useRef, useCallback } from 'react';
import { Outlet, useNavigate, useLocation  } from 'react-router-dom'; 
import { useCookies  } from 'react-cookie'; 
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
 
// import { useDispatch,  } from 'react-redux';


// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import {Nav} from './nav';

// component 
import {StopMichaelwidget} from './StopMichaelwidget';
 

// util  
import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, transMany, firstElementOfString, 
         removeFirstElementFromString, stringComparison, cloud, cloud13, cloud63, cloud66, cloud67, cloud83, cloud198, 
         cloud301, cloud302, cloud303, cloud304, cloud305, cloud306, cloud307, cloud308, cloud309, cloud310, cloud311,
         cloud312, cloud313,
         scrollDownMain, scrollUpMain, getIntoSpecificArea, getIntoSpecificVideo, 
     } from '../../utils/translateFunctions';



import { cloud125aa, cloud127aa, cloud119a, cloud123aa} from '../../utils/automateCreateVillageFunctions';


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64; 
// const APP_BAR_DESKTOP = 92;

const APP_BAR_MOBILE = 40; 
const APP_BAR_DESKTOP = 60;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden', 
  backgroundColor:'black',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),   // paddingLeft:0, paddingRight:0,  margin:0,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
  },
}));



// ----------------------------------------------------------------------

export default function DashboardLayout() { 
  const [open, setOpen] = useState(false); 
  const {pathname} = useLocation(); 
  

  // const [show, setShow] = useState(true);

  /* const [y, setY] = useState(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  });

  const handleNavigation = (e) => {
    const window = e.currentTarget; 
    if (y > window.scrollY) {
      setShow(true);
    } else if (y < window.scrollY) {
      setShow(false);
    }
    setY(window.scrollY); 
  }; */

  




  const navigate = useNavigate();   

  const [typeCurrent, setTypeCurrent] = useState('');
 


  // const [cookies, ] = useCookies(['_token_planetryvilage']);  
  const [cookies, setCookie, removeCookie ] = useCookies(['_token_planetryvilage']);

  const logOut = useCallback(() => {
    if(cookies._token_planetryvilage !== undefined) {
      setCookie('_token_planetryvilage', undefined, {maxAge: 0, }); 
      removeCookie('_token_planetryvilage');
      navigate('/login', { replace: true });
    }
  }, [cookies, setCookie, removeCookie, navigate]); 



 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {   
      setTypeCurrent(cookies._token_planetryvilage.type);
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 

  
   
  useEffect(() => {
    // disable right click
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    return () => { // return a cleanup function to unregister our function since its gonna run multiple times
      document.removeEventListener('contextmenu', (e) => {
        e.preventDefault();
      });
    };
  });
 



  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    isMicrophoneAvailable
  } = useSpeechRecognition(); 
  
  const scrollMain = useRef(0);     

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {     

        // const tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          const t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  
          const removeFirstElementFromString_ = await removeFirstElementFromString(t);  


          // log out 
          if (await stringComparison([cloud313.a, cloud313.b, cloud313.c, cloud313.d, cloud313.e, cloud313.f, cloud313.g, cloud313.h, cloud313.i, cloud313.j, cloud313.k, cloud313.l, cloud313.m, cloud313.n, cloud313.o], t)) {
            await logOut(); 
          } 
          
          // trigger a specific area
          if (await stringComparison(['part'], firstElementOfString_)) { 
            const part = await removeFirstElementFromString_; 

            await getIntoSpecificArea(part);
          }


          // play a specific video in a wish card
          if (await stringComparison([cloud198.a, cloud198.b], firstElementOfString_)) {
            const play = await removeFirstElementFromString_;
            await getIntoSpecificVideo(play);
          } 

          
          // back or return
          if (await stringComparison(['back', 'bag', 'go back', 'goes back'], t)) { 
            const a = await  document.getElementsByClassName('back')[0];

            if (await (typeof(a) !== 'undefined' && a !== null)) {
              await a.click();
            } 
          } 

          if (await stringComparison(['return'], t)) { 
            const a = await  document.getElementsByClassName('return')[0];

            if (await (typeof(a) !== 'undefined' && a !== null)) {
              await a.click();
            } 
          } 

          /* navigate to the homepage */
          if (await stringComparison([cloud301.a, cloud301.b, cloud301.c, cloud301.d, cloud301.e, cloud301.f], t)) {
            navigate('/home', { replace: true }); 
          }

          /* navigate to my dream */
          if (await stringComparison(['my dream'], t)) {
            navigate('/dream', { replace: true }); 
          }

          /* navigate to play music */
          if (await stringComparison(['play music', 'play the music',  'plays music', 'plays the music',   'played music', 'played the music'], t)) {
            if(await !(pathname.includes('play/song/using/michael'))) {
              navigate('/play/song/using/michael', { replace: true });  
            }
          }

          /* change password */
          if (await stringComparison([cloud302.a, cloud302.b, cloud302.c, cloud302.d, cloud302.e, cloud302.f, cloud302.g, cloud302.h, cloud302.i], t)) {
            navigate('/update/password', { replace: true });
          }

          /* update profile content */
          if (await stringComparison([cloud303.a, cloud303.b, cloud303.c, cloud303.d, cloud303.e, cloud303.f, cloud303.a1, cloud303.a2, cloud303.a3, cloud303.a4, cloud303.a5, cloud303.a6, cloud303.a7, cloud303.a8, cloud303.a9, cloud303.a10, cloud303.a11, cloud303.a12], t)) {
            navigate('/update/profile/content', { replace: true });
          }

          /* update profile picture */
          if (await stringComparison([cloud304.a, cloud304.b, cloud304.c, cloud304.d, cloud304.e, cloud304.f, cloud304.a1, cloud304.a2, cloud304.a3, cloud304.a4, cloud304.a5, cloud304.a6, cloud304.a7, cloud304.a8, cloud304.a9, cloud304.a10, cloud304.a11, cloud304.a12, cloud304.a13, cloud304.a14, cloud304.a15, cloud304.a16, cloud304.a17, cloud304.a18, cloud304.a19, cloud304.a20, cloud304.a21, cloud304.a22, cloud304.a23, cloud304.a24, cloud304.a25, cloud304.a26, cloud304.a27, cloud304.a28, cloud304.a29, cloud304.a30, cloud304.a31, cloud304.a32, cloud304.a33, cloud304.a34, cloud304.a35, cloud304.a36, cloud304.a37, cloud304.a38, cloud304.a39, cloud304.a40, cloud304.a41, cloud304.a42, cloud304.a43, cloud304.a44, cloud304.a45, cloud304.a46, cloud304.a47, cloud304.a48], t)) {
            navigate('/update/profile/photo', { replace: true });
          }
     

          /* navigate to magazines */
          if (await stringComparison([cloud305.a, cloud305.b, cloud305.c, cloud305.d], t)) {
            navigate('/magazine', { replace: true });
          }


          /* wishes sent */
          if (await stringComparison(['wishes sent', 'wishes send', 'wishes that are sent', 'wishes which are sent',   'the wishes sent', 'the wishes send', 'the wishes that are sent', 'the wishes which are sent'], t)) {
            navigate('/wishes/sent', { replace: true });
          }


          /* wishes received */
          if (await stringComparison(['wishes received', 'wishes receive', 'wishes that are received', 'wishes which are received',    'the wishes received', 'the wishes receive', 'the wishes that are received', 'the wishes which are received'], t)) {
            navigate('/wishes/received', { replace: true });
          }


          /* event cards */
          if (await stringComparison(['the wishes of new year', 'the new year wishes', 'christmas card', 'christmas cards', 'the christmas card', 'the christmas cards', cloud306.a, cloud306.b, cloud306.c, cloud306.d, cloud306.e, cloud306.f, cloud306.g, cloud306.h, cloud306.i, cloud306.j, cloud306.k, cloud306.l, cloud306.m, cloud306.n, cloud306.o, cloud306.p], t)) {
            navigate('/event/card', { replace: true });
          }


          /* navigate to update a village */
          if (await stringComparison([cloud125aa.a, cloud125aa.b, cloud125aa.c, cloud125aa.d, cloud125aa.a1, cloud125aa.a2, cloud125aa.a3, cloud125aa.a4, cloud125aa.a5, cloud125aa.a6, cloud125aa.a7, cloud125aa.a8, cloud125aa.a9, cloud125aa.a10, cloud125aa.a11, cloud125aa.a12, cloud125aa.a13, cloud125aa.a14, cloud125aa.a15], t)) {
            navigate('/update/village', { replace: true }); 
          }

          /* my village */
          if (await stringComparison([cloud127aa.a1, cloud127aa.a2, cloud127aa.a3, cloud127aa.a4, cloud127aa.a5, cloud127aa.a6, cloud127aa.a7, cloud127aa.a8, cloud127aa.a9, cloud127aa.a10, cloud127aa.a11, cloud127aa.a12], t)) {
            navigate('/my/villages', { replace: true }); 
          }

          /* delete a village */
          if (await stringComparison([cloud119a.a1, cloud119a.a2, cloud119a.a3, cloud119a.a4, cloud119a.a5, cloud119a.a6, cloud119a.a7, cloud119a.a8, cloud119a.a9, cloud119a.a10, cloud119a.a11, cloud119a.a12, cloud119a.a13, cloud119a.a14, cloud119a.a15, cloud119a.a16, cloud119a.a17, cloud119a.a18, cloud119a.a19, cloud119a.a20, cloud119a.a21, cloud119a.a22, cloud119a.a23, cloud119a.a24, cloud119a.a25, cloud119a.a26, cloud119a.a27, cloud119a.a28, cloud119a.a29, cloud119a.a30, cloud119a.a31, cloud119a.a32, cloud119a.a33, cloud119a.a34, cloud119a.a35, cloud119a.a36, cloud119a.a37, cloud119a.a38, cloud119a.a39, cloud119a.a40, cloud119a.a41, cloud119a.a42, cloud119a.a43, cloud119a.a44, cloud119a.a45, cloud119a.a46, cloud119a.a47, cloud119a.a48, cloud119a.b1, cloud119a.b2, cloud119a.b3, cloud119a.b4, cloud119a.b5, cloud119a.b6, cloud119a.b7, cloud119a.b8, cloud119a.b9, cloud119a.b10, cloud119a.b11, cloud119a.b12, cloud119a.b13, cloud119a.b14, cloud119a.b15, cloud119a.b16, cloud119a.b17, cloud119a.b18, cloud119a.b19, cloud119a.b20, cloud119a.b21, cloud119a.b22, cloud119a.b23, cloud119a.b24, cloud119a.b25, cloud119a.b26, cloud119a.b27, cloud119a.b28, cloud119a.b29, cloud119a.b30, cloud119a.b31, cloud119a.b32, cloud119a.b33, cloud119a.b34, cloud119a.b35, cloud119a.b36, cloud119a.b37, cloud119a.b38, cloud119a.b39, cloud119a.b40, cloud119a.b41, cloud119a.b42, cloud119a.b43, cloud119a.b44, cloud119a.b45, cloud119a.b46, cloud119a.b47, cloud119a.b48], t)) {
            navigate('/delete/village', { replace: true });
          }

          /* create a village */
          if (await stringComparison([cloud123aa.a, cloud123aa.b, cloud123aa.c, cloud123aa.d, cloud123aa.e, cloud123aa.f, cloud123aa.g, cloud123aa.h, cloud123aa.i, cloud123aa.j, cloud123aa.k, cloud123aa.l], t)) {
            navigate('/create/village', { replace: true });
          }
  
          
          /* beginning down up stop scrollbar */
          if (await stringComparison([cloud67.a, cloud67.b, cloud67.c, cloud67.d, cloud67.e], t)) { 
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollDownMain(window);
          }

          if (await stringComparison([cloud66.a, cloud66.b, cloud66.c, cloud66.d, cloud66.e], t)) {  
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollUpMain(window);
          }

          if (await stringComparison([cloud63.a, cloud63.b, cloud63.c], t)) {  
            await clearInterval(scrollMain.current);
          }
          /* end down up stop scrollbar */


          /* Michael what's your name */
          if (await stringComparison([cloud13.a, cloud13.b, cloud13.c, cloud13.d, cloud13.a2, cloud13.b2, cloud13.c2, cloud13.d2], t)) {
            speech.text = await transMany__(cloud13.e) || cloud13.e;
            await speechSynth.speak(speech); 
          } 
          
          /* Michael where do you live */
          if (await stringComparison([cloud.a, cloud.b, cloud.c, cloud.d, cloud.a2, cloud.b2, cloud.c2, cloud.d2, cloud.a3, cloud.b3, cloud.c3, cloud.d3, cloud.d4, cloud.d5], t)) {
            speech.text = await transMany__(cloud.e) || cloud.e;
            await speechSynth.speak(speech); 
          } 


          /* navigate to create a magazine */
          if (await stringComparison(['create a magazine', 'create magazine', 'create the magazine',   'creates a magazine', 'creates magazine', 'creates the magazine',   'created a magazine', 'created magazine', 'created the magazine'], t)) {
            navigate('/create/magazine', { replace: true });
          }


          /* navigate to create a card */
          if (await stringComparison(['create-a-card', 'create a card', 'create a mapping',   'creates a card', 'creates a mapping',   'created a card', 'created a mapping'], t)) {  
            navigate('/create/business/card', { replace: true });
          }

          
          // navigate to create a tv channel
          if (await stringComparison([cloud310.a, cloud310.b, cloud310.c, cloud310.d], t)) {
            navigate('/create/tv/channel', { replace: true });
          }

          // navigate to tv streaming
          if (await stringComparison([cloud311.a, cloud311.b, cloud311.c, cloud311.d], t)) {
            navigate('/tv/streaming', { replace: true });
          }

          // navigate to tv watcher
          if (await stringComparison([cloud312.a, cloud312.b, cloud312.c, cloud312.d, cloud312.e, cloud312.f, cloud312.g], t)) {
            navigate('/tv/watcher', { replace: true });
          }


          // navigate to create a conference room 
          if (await stringComparison(['create a conference room', 'create a conference', 'create conference room', 'create conference',    'creates a conference room', 'creates a conference', 'creates conference room', 'creates conference',     'created a conference room', 'created a conference', 'created conference room', 'created conference'], t)) {
            navigate('/create/conference/room', { replace: true });
          }


          // navigate to join a conference room 
          if (await stringComparison(['join a conference room', 'join a conference', 'join conference room', 'join conference',    'joins a conference room', 'joins a conference', 'joins conference room', 'joins conference',     'joined a conference room', 'joined a conference', 'joined conference room', 'joined conference'], t)) {
            navigate('/join/conference', { replace: true });
          }



          // navigate to competition 
          if (await stringComparison([ 'competition', 'the competition'], t)) {
            navigate('/competition', { replace: true });
          }



          /* beginning navigate to upload photo video audio */
          // upload photo
          if (await stringComparison([cloud307.a, cloud307.b, cloud307.c, cloud307.d, cloud307.e, cloud307.f, cloud307.g, cloud307.h, cloud307.i, cloud307.j, cloud307.k, cloud307.l, cloud307.m, cloud307.n, cloud307.o, cloud307.p, cloud307.q, cloud307.r, cloud307.s, cloud307.t, cloud307.u, cloud307.v, cloud307.w, cloud307.a2, cloud307.a3, cloud307.a4, cloud307.a5, cloud307.a6, cloud307.a7, cloud307.a8, cloud307.a9, cloud307.a10, cloud307.a11, cloud307.a12, cloud307.a13, cloud307.a14, cloud307.a15, cloud307.a16, cloud307.a17, cloud307.a18, cloud307.a19, cloud307.a20, cloud307.a21, cloud307.a22, cloud307.a23, cloud307.a24, cloud307.a25, cloud307.aa, cloud307.ba, cloud307.ca, cloud307.da, cloud307.ea, cloud307.fa, cloud307.ga, cloud307.ha, cloud307.ia, cloud307.ja, cloud307.ka, cloud307.la, cloud307.ma, cloud307.na, cloud307.oa, cloud307.pa, cloud307.qa, cloud307.ra, cloud307.sa, cloud307.ta, cloud307.ua, cloud307.va, cloud307.wa, cloud307.a2a, cloud307.a3a, cloud307.a4a, cloud307.a5a, cloud307.a6a, cloud307.a7a, cloud307.a8a, cloud307.a9a, cloud307.a10a, cloud307.a11a, cloud307.a12a, cloud307.a13a, cloud307.a14a, cloud307.a15a, cloud307.a16a, cloud307.a17a, cloud307.a18a, cloud307.a19a, cloud307.a20a, cloud307.a21a, cloud307.a22a, cloud307.a23a, cloud307.a24a, cloud307.a25a], t)) {
            if(await !(pathname.includes('upload/photo/video/audio'))) {
              navigate('/upload/photo/video/audio', { replace: true }); 
            }
          }

          // upload audio
          if (await stringComparison([cloud308.a, cloud308.b, cloud308.c, cloud308.d, cloud308.e, cloud308.f, cloud308.g, cloud308.h, cloud308.i, cloud308.j, cloud308.k, cloud308.l, cloud308.m, cloud308.n, cloud308.o, cloud308.aa, cloud308.ba, cloud308.ca, cloud308.da, cloud308.ea, cloud308.fa, cloud308.ga, cloud308.ha, cloud308.ia, cloud308.ja, cloud308.ka, cloud308.la, cloud308.ma, cloud308.na, cloud308.oa], t)) {
            if(await !(pathname.includes('upload/photo/video/audio'))) {
              navigate('/upload/photo/video/audio', { replace: true }); 
            }
          }

          // upload video
          if (await stringComparison([cloud309.a, cloud309.b, cloud309.c, cloud309.d, cloud309.e, cloud309.f, cloud309.g, cloud309.h, cloud309.i, cloud309.j, cloud309.k, cloud309.l, cloud309.m, cloud309.n, cloud309.o, cloud309.aa, cloud309.ba, cloud309.ca, cloud309.da, cloud309.ea, cloud309.fa, cloud309.ga, cloud309.ha, cloud309.ia, cloud309.ja, cloud309.ka, cloud309.la, cloud309.ma, cloud309.na, cloud309.oa], t)) {
            if(await !(pathname.includes('upload/photo/video/audio'))) {
              navigate('/upload/photo/video/audio', { replace: true }); 
            }
          }
          /* end navigate to upload photo video audio */


          
          /* beginning navigate to create a feast card */
          // navigate to create a birthday card
          if (await stringComparison(['create a birthday card', 'create birthday card',   'creates a birthday card', 'creates birthday card',   'created a birthday card', 'created birthday card'], t)) {
            if(await !(pathname.includes('feast'))) {
              navigate('/create/feast/card', { replace: true }); 
            }
          }


          // navigate to create a wedding card
          if (await stringComparison(['create a wedding card', 'create wedding card',   'creates a wedding card', 'creates wedding card',   'created a wedding card', 'created wedding card'], t)) {
            if(await !(pathname.includes('feast'))) {
              navigate('/create/feast/card', { replace: true }); 
            }
          }


          // navigate to create a valentine card 
          if (await stringComparison(['create a valentine card', 'create valentine card', 'create a valentines day card', "create a valentine's day card",   'creates a valentine card', 'creates valentine card', 'creates a valentines day card', "creates a valentine's day card",    'created a valentine card', 'created valentine card', 'created a valentines day card', "created a valentine's day card"], t)) {
            if(await !(pathname.includes('feast'))) {
              navigate('/create/feast/card', { replace: true }); 
            }
          }
          /* end navigate to create a feast card */
           


          /* beginning navigate to create an event card */
          // navigate to create a new week card
          if (await stringComparison(['create a new week card', 'create new week card',   'creates a new week card', 'creates new week card',  'created a new week card', 'created new week card'], t)) {
            if(await !(pathname.includes('event'))) {
              navigate('/create/event/card', { replace: true }); 
            }
          }
          
          // navigate to create a new month card
          if (await stringComparison(['create a new month card', 'create new month card',   'creates a new month card', 'creates new month card',   'created a new month card', 'created new month card'], t)) {
            if(await !(pathname.includes('event'))) {
              navigate('/create/event/card', { replace: true }); 
            }
          }
          
          // navigate to create an easter card
          if (await stringComparison(['create an easter card', 'create easter card',   'creates an easter card', 'creates easter card',    'created an easter card', 'created easter card'], t)) {
            if(await !(pathname.includes('event'))) {
              navigate('/create/event/card', { replace: true }); 
            }
          }
          
          // navigate to create a halloween card
          if (await stringComparison(['create a halloween card', 'create halloween card',   'creates a halloween card', 'creates halloween card',   'created a halloween card', 'created halloween card'], t)) {
            if(await !(pathname.includes('event'))) {
              navigate('/create/event/card', { replace: true }); 
            }
          }
          
          // navigate to create a Thanksgiving card
          if (await stringComparison(['create a thanksgiving card', 'create thanksgiving card',   'creates a thanksgiving card', 'creates thanksgiving card',  'created a thanksgiving card', 'created thanksgiving card'], t)) {
            if(await !(pathname.includes('event'))) {
              navigate('/create/event/card', { replace: true }); 
            }
          }
          
          // navigate to create a Christmas card
          if (await stringComparison(['create a christmas card', 'create christmas card',    'creates a christmas card', 'creates christmas card',   'created a christmas card', 'created christmas card'], t)) {
            if(await !(pathname.includes('event'))) {
              navigate('/create/event/card', { replace: true }); 
            } 
          }
          
          // navigate to create a New Year card
          if (await stringComparison(['create a new year card', 'create new year card',   'creates a new year card', 'creates new year card',  'created a new year card', 'created new year card'], t)) {
            if(await !(pathname.includes('event'))) {
              navigate('/create/event/card', { replace: true }); 
            } 
          }
          /* end navigate to create an event card */

        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, pathname, logOut]);

 
  /* End Michael */  

  const listenContinuously = () => {  
    if (isMicrophoneAvailable && SpeechRecognition.browserSupportsSpeechRecognition()) {
      SpeechRecognition.startListening({
        continuous: true,
        language: newLang(),
      });
    }
  } 
   
   
  useEffect(() => { 
    listenContinuously(); 
  });
  
  const changeContinuityStop = () => {

    if(listening) {  
      SpeechRecognition.stopListening();
    } else { 
      listenContinuously();
    }
  } 
 

  return ( 
    typeCurrent ? (<StyledRoot> 
      {/* {show  && <Header onOpenNav={() => setOpen(true)} />} */}

      <Header onOpenNav={() => setOpen(true)} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} />   

      <Main>
        <Outlet />  
      </Main>   

      <StopMichaelwidget changeContinuityStop={changeContinuityStop}/>

 
    </StyledRoot>): null
  );
}


 