

// ----------------------------------------------------------------------

const seasonHistoryLinks = [
  { 
	category: 'View', 
    URL: '/compete/season/' 
   },   
 
];

export default seasonHistoryLinks;
