// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------



const icon = (name) => <Iconify icon={name} sx={{ width: 1, height: 1 }} />;

const navConfig = [ 
  {
    title: 'Home',
    path: '/home',
    icon: icon('mdi:house'),
  }, 
  /* {
    title: 'Magazines and Wishes',
    path: '/magazine/wishes',
    icon: icon('fluent:gift-card-24-regular'),
  }, */

  {
    title: 'Magazines Wishes',
    path: {
           eventCard:'/event/card',
           wishesSent:'/wishes/sent',
           wishesReceived:'/wishes/received',
           magazine:'/magazine', 
           
         },
    icon: icon('fluent:gift-card-24-regular'),
  },

  {
    title: 'Create', 
    path: {
           feastCard:'/create/feast/card',
           eventCard:'/create/event/card',
           magazine:'/create/magazine',
           businessCard:'/create/business/card',
           photoVideo:'/upload/photo/video/audio',
           tvChannel:'/create/tv/channel',
           conferenceRoom:'/create/conference/room',
           village:'/create/village',
           
         },
    icon: icon('gridicons:create'),
  },  

  {
    title: 'Competition',
    path: '/competition',
    icon: icon('game-icons:trophy-cup'),
  },  
];

export default navConfig;  

/* 
import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  }, 
  {
    title: 'home',
    path: '/dashboard/home',
    icon: icon('ic_home'),
  },
  {
    title: 'courses',
    path: '/dashboard/course',
    icon: icon('ic_course'),
  },
  {
    title: 'gallery',
    path: '/dashboard/gallery',
    icon: icon('ic_gallery'),
  },
  {
    title: 'events',
    path: '/dashboard/event',
    icon: icon('ic_event'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'product',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: icon('ic_blog'),
  },
  {
    title: 'login',
    path: '/login',
    icon: icon('ic_lock'),
  },
  {
    title: 'Not found',
    path: '/404',
    icon: icon('ic_disabled'),
  },
];

export default navConfig; */
