
export const cloud112 = {
    a: 'description',
    b: 'date',
    c: 'start',
    d: 'end',
    e: 'and',
    f: 'notify',
    g: 'channel'
};


export const cloud112a = {
    a: 'date',
    b: 'did',
    c: 'dates',
    d: 'dated',
    e: 'day'
};

export const cloud112b = {
    a: 'start',
    b: 'started',
    c: 'starting',
    d: 'starts',
    e: 'beginning',
    f: 'departure',
    g: 'begin',
    h: 'begins',
    i: 'opening'
};

export const cloud112c = {
    a: 'end',
    b: 'and',
    c: 'ends',
    d: 'ending',
    e: 'ended',
    f: 'term',
    g: 'finish',
    h: 'finishes',
    i: 'finishing',
    j: 'limit',
    k: 'limits',
    l: 'limited',
    m: 'limiting'
};
 

export const cloud112d = {
    a: 'notify',
    b: 'notifies',
    c: 'notified',
    d: 'notifying',
    e: 'send',
    f: 'sent',
    g: 'share',
    h: 'shares',
    i: 'shared',
    j: 'sends'
}

export const cloud112e = {
    a: 'channel',
    b: 'chain',
    c: 'title',
    d: 'name',
    e: 'named'
}


export const cloud6 = {
    a: 'save',
    b: 'saves',
    c: 'saved',
    d: 'share',
    e: 'shares',
    f: 'shared',
    g: 'send',
    h: 'sends',
    i: 'sent'
};




export const cloud7 = {
    a: 'life experience',
    b: 'live experience',
    c: 'the life experience',
    d: 'the experience of life'
}; 


export const cloud15 = {
    a: 'new year celebration',
    b: 'the new year celebration'
};
 


export const cloud51 = {
    a: 'christmas celebration',
    b: 'the christmas celebration'
};
 

export const cloud53 = {
    a: 'easter celebration',
    b: 'the easter celebration'
};
 

export const cloud55 = {
    a: 'birthday celebration',
    b: 'the birthday celebration'
};
 

export const cloud149 = {
    a: 'creativity and imagination',
    b: 'creativity',
    c: 'imagination',
    d: 'the imagination',
    e: 'the creativity'
};
 

export const cloud151 = {
    a: 'cook',
    b: 'cooks',
    c: 'cooking',
    d: 'the cook'
};
 

export const cloud153 = {
    a: 'dance',
    b: 'dancers',
    c: 'dancer',
    d: 'dancing',
    e: 'the dance',
    f: 'the dancers'
};
 

export const cloud155 = {
    a: 'drawing',
    b: 'craft',
    c: 'art and craft',
    d: 'arts',
    e: 'the drawing',
    f: 'the craft'
};
 

export const cloud157 = {
    a: 'freestyle',
    b: 'free',
    c: 'free.',
    d: 'the freestyle'
};
 

export const cloud161 = {
    a: 'gossip',
    b: 'gossiping',
    c: 'gossips',
    d: 'the gossip',
    e: 'chat',
    f: 'chatting',
    g: 'the chatter',
    h: 'cancan',
    i: 'chatter'
};
 

export const cloud163 = {
    a: 'gym',
    b: 'gymnastics',
    c: 'sports',
    d: 'the gym',
    e: 'the gymnastics'
};
 

export const cloud165 = {
    a: 'hairstyle',
    b: 'haircut',
    c: 'hairstylist',
    d: 'haircutting',
    e: 'the hairdresser',
    f: 'hairdresser',
    g: 'the barber',
    h: 'barber',
    i: 'the haircut',
    j: 'the hairstyle'
};
 

export const cloud167 = {
    a: 'makeup',
    b: 'beauty',
    c: 'beautiful',
    d: 'beautify', 
    e: 'the makeup',
    f: 'the beauty',
    g: 'beautiful',
    h: 'beautify', 
};
 

export const cloud169 = {
    a: 'motivational speech',
    b: 'motivational speaker',
    c: 'motivation',
    d: 'motivate',
    e: 'the motivational speech',
    f: 'the motivational speaker',
    g: 'the motivation',
    h: 'motivates',
    i: 'motivated'
};
 

export const cloud171 = {
    a: 'outfit',
    b: 'top model',
    c: 'dress code',
    d: 'swagger',
    e: 'dummy',
    f: 'the dummy',
    g: 'supermodel',
    h: 'the supermodel',
    i: 'the outfit',
    j: 'the top model',
    k: 'the dress code'
};
 

export const cloud173 = {
    a: 'reality tv',
    b: 'reality',
    c: '1tv1',
    d: '1television1',
    e: 'the reality tv',
    f: 'the reality',
    g: '1the tv1',
    h: '1the television1'
};
 

export const cloud175 = {
    a: 'singers',
    b: 'the singers',
    c: 'singing',
    d: 'sing',
    e: 'sings',
    f: 'singer',
    g: 'the singer'
};
 

export const cloud177 = {
    a: 'talking non-stop',
    b: 'talking',
    c: 'non-stop',
    d: 'talk',
    e: 'non stop',
    f: 'talking nonstop',
    g: 'nonstop',
    h: 'talk nonstop',
    i: 'chatting nonstop',
    j: 'talks nonstop',
    k: 'chats nonstop',
    l: 'chatted nonstop'
};
 

export const cloud179 = {
    a: 'wedding celebration',
    b: 'wedding',
    c: 'wedding celebration.',
    d: 'wedding.'
};
 

export const cloud181 = {
    a: 'invention',
    b: 'new invention',
    c: 'the invention',
    d: 'the new invention',
    e: 'new inventions',
    f: 'the new inventions',
    g: 'inventions',
    h: 'the inventions',
    i: 'invention'
};
 


export const cloud183 = {
    a: 'opportunity zones',
    b: 'opportunity zone',
    c: 'the opportunity zone',
    d: 'the opportunity zones'
}; 



export const cloud185 = {
    a: 'study',
    b: 'studies',
    c: 'the study',
    d: 'the study rooms',
    e: 'study hall',
    f: 'study halls',
    g: 'the studies',
    h: 'the study hall',
    i: 'the study halls'
};
 


export const cloud186 = {
    a: 'anniversary celebration',
    b: 'the anniversary celebration'
};


export const cloud187 = {
    a: 'assistance',
    b: 'assist',
    c: 'assistant',
    d: 'assisting',
    e: 'help',
};


export const cloud57 = {
    a: 'universal card',
    b: 'universal cards',
    c: 'card universal',
    d: 'cards universal',
    e: 'maps universal',
    f: 'map universal',
    g: 'universal map',
    h: 'universal maps',
    i: 'the universal card',
    j: 'the universal cards',
    k: 'the card universal',
    l: 'the cards universal',
    m: 'the maps universal',
    n: 'the map universal',
    o: 'the universal map',
    p: 'the universal maps'
};

export const cloud58 = {
    a: 'old school card',
    b: 'old school cards',
    c: 'card old school',
    d: 'cards old school',
    e: 'maps old school',
    f: 'map old school',
    g: 'old school map',
    h: 'old school maps',
    i: 'the old school card',
    j: 'the old school cards',
    k: 'the card old school',
    l: 'the cards old school',
    m: 'the maps old school',
    n: 'the map old school',
    o: 'the old school map',
    p: 'the old school maps'
};


export const cloud59 = {
    a: 'business card',
    b: 'business cards',
    c: 'card business',
    d: 'cards business',
    e: 'maps business',
    f: 'map business',
    g: 'business map',
    h: 'business maps',
    i: 'the business card',
    j: 'the business cards',
    k: 'the card business',
    l: 'the cards business',
    m: 'the maps business',
    n: 'the map business',
    o: 'the business map',
    p: 'the business maps'
};


export const cloud60 = {
    a: 'school card',
    b: 'school cards',
    c: 'card school',
    d: 'cards school',
    e: 'maps school',
    f: 'map school',
    g: 'school map',
    h: 'school maps',
    i: 'the school card',
    j: 'the school cards',
    k: 'the card school',
    l: 'the cards school',
    m: 'the maps school',
    n: 'the map school',
    o: 'the school map',
    p: 'the school maps'
};


export const cloud61 = {
    a: 'student card',
    b: 'student cards',
    c: 'card student',
    d: 'cards student',
    e: 'maps student',
    f: 'map student',
    g: 'student map',
    h: 'student maps',
    i: 'the student card',
    j: 'the student cards',
    k: 'the card student',
    l: 'the cards student',
    m: 'the maps student',
    n: 'the map student',
    o: 'the student map',
    p: 'the student maps'
};