import {useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';

// mui
import {Typography, Grid, ListItemButton, Box,  } from '@mui/material'; 

// icons
import { Icon } from '@iconify/react';

import {playCurrentVideoPauseOther, playCurrentAudioPauseOther} from '../../../../utils/otherFunctions'; 



import {domainName} from '../../../../utils/domain';

// -----------------------------------------------------



export const BusinessCardContent = memo(({content, height}) => {
  const HEIGHT = height;
  const backgroundPhotoURL = 'product7.gif';   

  const {  
    Video, 
    Phone,
    Website1, 
    Website2, 
    Photo,
    NewFullName, 
    Address,
    City,
    Country,
    Continent
  } = content;

  const {   
    ProfessionOrLevel,
    CompanyNameOrSchool, 
  } = {ProfessionOrLevel: content.Profession_Or_Level, CompanyNameOrSchool: content.CompanyName_Or_School};

  const handleVideoAudio = async () => {  
    await playCurrentVideoPauseOther();
    await playCurrentAudioPauseOther();
  }; 


  const fileId = useRef();

  const playFileOnTouch = ()=> {
    fileId.current.play(); 
  }
  
  
  useEffect(() => {
    handleVideoAudio(); 
  });
   

  return (  
      <Grid container spacing={1} sx={{height:HEIGHT, overflowY: 'scroll',}}>  

        <Grid item xs={12} sm={12} md={12}> 
          <Grid container spacing={1}  alignItems="center" justifyContent="center" >
            <Grid item xs={12} sm={12} md={6}> 
              <video ref={fileId} controls controlsList="nodownload" style={{width: '100%', objectFit:'cover', }}
                poster="/assets/images/planetryvilage/video-to-lazy-load-1x.jpg"
                onClick={handleVideoAudio} 
                onTouchStart={playFileOnTouch}
                onMouseOver={playFileOnTouch} 
                onFocus={ () => null }

                preload="none" 
                autoPlay loop playsInline  
              >
                <source src={`${domainName}/EddyDreams/otherFiles/videos/${Video}`} type="video/webm"/>
                <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/>
              </video>
            </Grid> 
            <Grid item xs={12} sm={12} md={6}>   
              <ListItemButton component={RouterLink}>
                <Box component={Icon} icon='ic:baseline-phone-forwarded' sx={{ width: 20, height: 20, mr:1 }} /> 
                <Typography>{Phone}</Typography> 
              </ListItemButton> 

              <ListItemButton component={RouterLink}   onClick={() => {window.open(Website1, '_blank')}}>
                <Box component={Icon} icon='emojione-monotone:spider-web' sx={{ width: 20, height: 20, mr:1 }} /> 
                <Typography noWrap>{Website1}</Typography> 
              </ListItemButton>    

              <ListItemButton component={RouterLink}   onClick={() => {window.open(Website2, '_blank')}}>
                <Box component={Icon} icon='game-icons:spider-web' sx={{ width: 20, height: 20, mr:1 }} /> 
                <Typography noWrap>{Website2}</Typography> 
              </ListItemButton>   
            </Grid> 
          </Grid>
        </Grid> 

        <Grid item xs={12} sm={12} md={12} sx={{backgroundColor:'white', color:'white', backgroundImage:`url(/assets/images/planetryvilage/${backgroundPhotoURL})`, backgroundSize: 'cover'}}> 
          <Grid container spacing={1}  alignItems="center" justifyContent="center" >
            <Grid item xs={12} sm={12} md={6}> 
              <Box  component="img"
                sx={{  
                  height: 160,
                  width: { xs: 'auto', md: 'auto' },
                  maxHeight: { xs: 160, md: 160 }, 
                  objectFit:'cover' 
                }} 
                loading="lazy"

                alt="photo" 
                src={`${domainName}/EddyDreams/Home/images/${Photo}`}
              />
            </Grid> 
            <Grid item xs={12} sm={12} md={6}>   
              <ListItemButton component={RouterLink}>
                <Box component={Icon} icon='openmoji:european-name-badge' sx={{ width: 40, height: 40, mr:1 }} /> 
                <Typography>{NewFullName}</Typography> 
              </ListItemButton>
  
              <ListItemButton component={RouterLink}>
                <Box component={Icon} icon='eos-icons:cluster-role' sx={{ width: 30, height: 30, mr:1 }} /> 
                <Typography>{ProfessionOrLevel}</Typography> 
              </ListItemButton> 
            </Grid> 
          </Grid>
        </Grid> 

        <Grid item xs={12} sm={12} md={12}> 
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6}> 
              <ListItemButton component={RouterLink}>
                <Box component={Icon} icon='emojione-v1:classical-building' sx={{ width: 20, height: 20, mr:1 }} /> 
                <Typography>{CompanyNameOrSchool}</Typography> 
              </ListItemButton>
  
              <ListItemButton component={RouterLink}>
                <Box component={Icon} icon='fluent:globe-location-20-regular' sx={{ width: 20, height: 20, mr:1 }} /> 
                <Typography>{Address}</Typography> 
              </ListItemButton> 
            </Grid> 
            <Grid item xs={12} sm={12} md={6}>  
              <ListItemButton component={RouterLink}>
                <Box component={Icon} icon='emojione-monotone:cityscape' sx={{ width: 20, height: 20, mr:1 }} /> 
                <Typography>{City}</Typography> 
              </ListItemButton>
  
              <ListItemButton component={RouterLink}>
                <Box component={Icon} icon='mdi:city-variant-outline' sx={{ width: 20, height: 20, mr:1 }} /> 
                <Typography>{Country}</Typography> 
              </ListItemButton> 
  
              <ListItemButton component={RouterLink}>
                <Box component={Icon} icon='gis:earth-gear' sx={{ width: 20, height: 20, mr:1 }} /> 
                <Typography>{Continent}</Typography> 
              </ListItemButton> 
            </Grid> 
          </Grid>
        </Grid> 
      </Grid>   
  );
})

BusinessCardContent.propTypes = {  
  content: PropTypes.object,
  height: PropTypes.string,
  
};
 