

// ----------------------------------------------------------------------

const yearBackgroundDetails = [
  {
  	id: 1, 
    back: 'product29.gif'
   },  

  {
  	id: 2, 
    back: 'product29b.gif'
   },  
   
  {
  	id: 3, 
    back: 'product29c.gif'
   },  
   
  {
  	id: 4, 
    back: 'product29d.gif'
   },  
   
  {
  	id: 5, 
    back: 'product29e.gif'
   },  
   
  {
  	id: 6, 
    back: 'product29f.gif'
   },  
   
  {
  	id: 7, 
    back: 'product29g.gif'
   },  
   
  {
  	id: 8, 
    back: 'product29h.gif'
   },  
   
  {
  	id: 9, 
    back: 'product29i.gif'
   },  
   
  {
  	id: 10, 
    back: 'product29j.gif'
   },  
   
  {
  	id: 11, 
    back: 'year1.jpg'
   },  
   
  {
  	id: 12, 
    back: 'year2.jpg'
   },  
   
  {
  	id: 13, 
    back: 'year3.jpg'
   },  
   
  {
  	id: 14, 
    back: 'year4.jpg'
   },  
   
  {
  	id: 15, 
    back: 'year5.jpg'
   },  
   
  {
  	id: 16, 
    back: 'year6.jpg'
   },  
   
  {
  	id: 17, 
    back: 'year7.jpg'
   },  
   
  {
  	id: 18, 
    back: 'year8.jpg'
   },  
   
  {
  	id: 19, 
    back: 'year9.jpg'
   },  
   
  {
  	id: 20, 
    back: 'year10.jpg'
   },   
   
  {
  	id: 21, 
    back: 'year11.jpg'
   },   
   
  {
  	id: 22, 
    back: 'year12.jpg'
   },   
   
  {
  	id: 23, 
    back: 'year13.jpg'
   },   
   
  {
  	id: 24, 
    back: 'year14.jpg'
   },   
   
  {
  	id: 25, 
    back: 'year15.jpg'
   },   
   
  {
  	id: 26, 
    back: 'year16.jpg'
   },   
   
  {
  	id: 27, 
    back: 'year17.jpg'
   },   
   
  {
  	id: 28, 
    back: 'year18.jpg'
   },   
   
  {
  	id: 29, 
    back: 'year19.jpg'
   },   
   
  {
  	id: 30, 
    back: 'year20.jpg'
   },   
 
];

export default yearBackgroundDetails;
