 
import { NavLink as RouterLink, } from 'react-router-dom';
import PropTypes from 'prop-types'; 
// @mui
import { Card, Grid, Box, Typography, Stack, IconButton } from '@mui/material';  
import VisibilityIcon from '@mui/icons-material/Visibility';


// ----------------------------------------------------------------------


export const SeasonHistory = ({item, competitionId, seasonHistoryLinks}) => {   
  const {
  	seasonId, 
  	seasonTypeName,
  } = item;

  return (  
    <Grid   item xs={12} sm={12} md={4} >  
        <Card sx={{
        	p: 1, 
            boxShadow: 0,
            textAlign: 'left', 
            minHeight:125,  
        }}
        > 
            <Stack  spacing={1} textAlign="center" justifyContent="center">  
                <Typography variant="subtitle1" sx={{fontSize:{xs:13, sm:13, md:15}, }}>
                    {seasonTypeName} 
                </Typography>    
              
                {seasonHistoryLinks.map((item, key) => (
                <Box component={RouterLink} to={`${item.URL}${competitionId}${'/'}${seasonId}`} key={key}  sx={{textDecoration:'none'}}>
                    <IconButton aria-label="Season history" sx={{fontSize:13, color:'blue'}}>
                        <Typography variant="body2" > {item.category}</Typography >  <VisibilityIcon sx={{ml:1,}}/>
                    </IconButton>
                </Box> 
                ))}
            </Stack>  
        </Card> 
    </Grid> 
  );
}


SeasonHistory.propTypes = {    
  item: PropTypes.object,        
  competitionId: PropTypes.string,  
  seasonHistoryLinks:   PropTypes.arrayOf(PropTypes.object),
};
