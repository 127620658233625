import LoginActionTypes from './loginTypes'


export const LogInREQUEST = (item) => ({
    type: LoginActionTypes.LOG_IN_REQUEST,
    payload: item
}) 


export const LogInRESET = item => ({
    type: LoginActionTypes.LOG_IN_RESET,
    payload: item
}) 