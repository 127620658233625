import { useState, useEffect, useRef, useCallback } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

// @mui
import { Stack, TextField, Card, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab'; 

// icons
import { Icon } from '@iconify/react'; 

import {ForgottenPasswordREQUEST, ForgottenPasswordRESET} from '../../../redux/password/forgottenPasswordActions'; 


import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud, cloud13, cloud63,
       cloud66, cloud67, cloud83, cloud85, cloud86, cloud88, cloud89__, restructureEmail,
       scrollDownMain, scrollUpMain} from '../../../utils/translateFunctions';
 
 

const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------

export default function ForgottenPasswordForm() { 
  const navigate = useNavigate(); 
  const dispatch = useDispatch(); 


  const forgottenPasswordValue = useSelector(state => state.forgottenPasswordValue);

  
  const [error, setError] = useState("");  
  const [initializeForgottenPassword, setInitializeForgottenPassword] = useState({email:'', hostname:`${window.location.hostname}`});
  

  useEffect(() => {  
    if(forgottenPasswordValue.user){
      if(forgottenPasswordValue.user.message === "An error occured!"){
        setError("An error occured!"); 
      } 
      else 
      if(forgottenPasswordValue.user.message === "This email address is not recognized."){
        setError("This email address is not recognized."); 
      }else 
      if(forgottenPasswordValue.user.message === "It has been successfully completed. Can you check your email?"){
        setError("It has been successfully completed. Can you check on your inbox?"); 
      } 

      dispatch(ForgottenPasswordRESET(null));
    } 
  },[forgottenPasswordValue.user, dispatch]);  




  const handleClick = useCallback(async () => { 
    if(await (initializeForgottenPassword.email).trim() === ''){
      setError('The email is required');
      return;
    } 

    if(await (initializeForgottenPassword.hostname).trim() === ''){
      setError('The hostname is required');
      return;
    }

    dispatch(ForgottenPasswordREQUEST(initializeForgottenPassword));
  }, [dispatch, initializeForgottenPassword]);



  const onChangeEmail = (e) => {
    setInitializeForgottenPassword({
      initializeForgottenPassword, 
      ...{
        email: e.target.value, 
        hostname: initializeForgottenPassword.hostname, 
      }
    });

    setError('');
  }





  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    isMicrophoneAvailable
  } = useSpeechRecognition(); 
  
  const scrollMain = useRef(0); 

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {     
        let email;  

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));     
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_); 


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;
          const firstElementOfString_ = await firstElementOfString(t); 

          
          /* navigate to login page */
          if (await stringComparison([cloud85.a, cloud85.b, cloud85.c, cloud85.d, cloud85.e, cloud85.f, cloud85.g, cloud85.h, cloud85.i], t)) {
            navigate('/login', { replace: true }); 
          }
          
          /* navigate to register page */
          if (await stringComparison([cloud86.a, cloud86.b, cloud86.c, cloud86.d, cloud86.e, cloud86.f, cloud86.g, cloud86.h, cloud86.i, cloud86.j, cloud86.k, cloud86.l, cloud86.m, cloud86.n], t)) {
            navigate('/register', { replace: true });
          }

          if (await stringComparison([cloud89__.a], firstElementOfString_)) {
            t = await firstElementOfString_; 

            const a3 = await transMany__(`${'4 '}${firstElementOfString_}`) || `${'4 '}${firstElementOfString_}`;
            const a = await a3.replace("4 ", "");

            if (await (a.match(/(\w+)/g).length === 1)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            }

            email = await tm;
          } else {
            email = await '';
          }

 
          
          /* set email */
          if (await stringComparison([cloud89__.a], t)) {    
            await setInitializeForgottenPassword({
              initializeForgottenPassword, 
              ...{
                email: restructureEmail(email), 
                hostname: initializeForgottenPassword.hostname,
              }
            });

            await setError('');

            speech.text = await transMany__('The email has been updated') || 'The email has been updated';
            speechSynth.speak(speech);
          }
           
           
          
          /* send the form */
          if (await stringComparison([cloud88.a, cloud88.b, cloud88.c, cloud88.d, cloud88.e, cloud88.f, cloud88.g, cloud88.h, cloud88.i, cloud88.j], t)) {
            await handleClick();  
          }

          
          /* beginning down up stop scrollbar */
          if (await stringComparison([cloud67.a, cloud67.b, cloud67.c], t)) { 
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollDownMain(window);
          }

          if (await stringComparison([cloud66.a, cloud66.b, cloud66.c], t)) {  
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollUpMain(window);
          }

          if (await stringComparison([cloud63.a, cloud63.b, cloud63.c], t)) {  
            await clearInterval(scrollMain.current);
          }
          /* end down up stop scrollbar */


          /* Michael what's your name */
          if (await stringComparison([cloud13.a, cloud13.b, cloud13.c, cloud13.d, cloud13.a2, cloud13.b2, cloud13.c2, cloud13.d2], t)) {
            speech.text = await transMany__(cloud13.e) || cloud13.e;
            await speechSynth.speak(speech); 
          }
          
          /* Michael where do you live */
          if (await stringComparison([cloud.a, cloud.b, cloud.c, cloud.d, cloud.a2, cloud.b2, cloud.c2, cloud.d2, cloud.a3, cloud.b3, cloud.c3, cloud.d3, cloud.d4, cloud.d5], t)) {
            speech.text = await transMany__(cloud.e) || cloud.e;
            await speechSynth.speak(speech); 
          } 
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, initializeForgottenPassword, handleClick, navigate]);


  const listenContinuously = () => {
    if (isMicrophoneAvailable && SpeechRecognition.browserSupportsSpeechRecognition()) {
      SpeechRecognition.startListening({
        continuous: true,
        language: newLang(),
      });
    }
  }; 
   
  
  useEffect(() => {
    listenContinuously();
  })

  const changeContinuityStop = () => {

    if(listening) {
      SpeechRecognition.stopListening();
    } else {
      listenContinuously();
    } 
  }
  /* End Michael */  

 
  return (
    <>
     <Card sx={{p:2, boxShadow:10, }}>
      <Stack sx={{ my: 2 }}>
        <Box component={Icon} icon='game-icons:robot-golem' onClick={changeContinuityStop} sx={{ width: {xs:30, md:30}, height: {xs:30, md:30}, cursor:'pointer', m:1 }} />  

        <TextField  value={initializeForgottenPassword.email} onChange ={onChangeEmail} name="email" label="Email address" /> 

        <Typography variant='subtitle2' color='red'>{error}</Typography>
      </Stack> 

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Send
      </LoadingButton> 
     </Card>
    </>
  );
}
