 
import PropTypes from 'prop-types';
import {Grid, Typography, Avatar, Stack} from '@mui/material'; 

import { grey } from '@mui/material/colors';



import {domainName} from '../../../../utils/domain'; 

// -----------------------------------------------------------


export const Page6 = ({page6Features, BackgroundColor}) => {  

  const { 
    height, 
    tagHtml, 
    Photo1Page5,
    Photo,
    Continent,
    Country,
    City,
    NewFullName 
  } = page6Features; 

  const pageScroll = {    
    textAlign: 'center',
    fontStyle: 'italic', 
  }; 

  
  const backImage = `${domainName}${'/EddyDreams/otherFiles/images/'}${Photo1Page5}`;  

  return (   
      <>
        <Grid container spacing={1} sx={{height:{height}, backgroundColor:'white', color:BackgroundColor, backgroundImage:`url(${backImage})`, backgroundSize: 'cover'}} > 

          <Grid item xs={12} sm={12} md={12}> 
            <div style={pageScroll}>
              <Typography variant={tagHtml} sx={{mt:15}} sx={{fontSize:{xs:26, sm:26, md:50}, font:`50px 'Lovers Quarrel'`}}>
                Written 
              </Typography>
              <Typography variant={tagHtml} sx={{fontSize:{xs:26, sm:26, md:50}, font:`50px 'Lovers Quarrel'`}}>
                by 
              </Typography>
              <Typography className="notranslate" variant={tagHtml} sx={{fontSize:{xs:26, sm:26, md:50}, font:`50px 'Lovers Quarrel'`}}>
                 {NewFullName}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>  
            <Stack direction="row" spacing={2}>
              <Avatar sx={{ bgcolor: grey[500], height:60, width:60 }} aria-label="profile photo" alt='' src={`${domainName}${'/EddyDreams/Home/images/'}${Photo}`} />

              <Typography variant={tagHtml} sx={{fontSize:{xs:26, sm:26, md:50}, font:`50px 'Lovers Quarrel'`}}> 
                {City}, {Country}, {Continent}
              </Typography>
            </Stack>
          </Grid> 

        </Grid>
      </>   
  );
}


Page6.propTypes = {    
  page6Features: PropTypes.object, 
  BackgroundColor:  PropTypes.string,  
};  
 