
import { useState, useRef, useEffect, memo} from 'react';  
import { useNavigate, } from 'react-router-dom';  
import { useCookies  } from 'react-cookie';  
import axios from 'axios'; 
import PropTypes from 'prop-types';      

// @mui
import { Stack, TextField, Typography, Card, Grid, } from '@mui/material';
import { LoadingButton } from '@mui/lab';   
  
 


import {domainName} from '../../../../utils/domain';


// ---------------------------------------------------------------------- 

export const DeleteVillage = memo(({item}) => {   
  const navigate = useNavigate();   

  const token = useRef(null); 
  const type = useRef(null);


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 



  const {      
    Description, 
    WhoAreYou,
    Id
  } = item;   


  const [error, setError] = useState('');
  const [villageName, setDescription] = useState(Description);
  const [personality, setWhoAreYou] = useState(WhoAreYou);

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);

    setError('');
  }
 
  const handleChangeWhoAreYou = (e) => {
    setWhoAreYou(e.target.value);

    setError('');
  }


  /*  Begin upload */ 
  const urlLocalRequest = `${domainName}/u/delete/village`;

  const upload = async () => {  
    if(await (villageName).trim() === ''){
      setError('The village name is required');
      return;
    }   
    if(await (personality).trim() === ''){
      setError('The personality is required');
      return;
    } 
  
    const Id_ = await Id;
    const token_ = await token.current;
    const type_ = await type.current;
 
    const resp = await sendFileData(Id_, token_, type_, urlLocalRequest);
 
    const jsonResponse = await resp.data; 
    const jsres = await jsonResponse.message; 
    setError(jsres); 
  } 
 

  const sendFileData = (Id_, token_, type_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {  
        Id: Id_,
        token: token_,
        type: type_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  } 
  /*  End upload */ 


 

  return ( 
    <> 
    <Grid  item xs={12} sm={12} md={4} lg={3}> 
     <Card > 
        <Stack spacing={3} sx={{p:1, }}>
          <Typography variant="body1">
            Delete a Village
          </Typography> 

          <TextField name="Description" value={villageName} onChange={handleChangeDescription} label="What is the village name?" />

          <TextField name="WhoAreYou" value={personality} onChange={handleChangeWhoAreYou} label="Who are You?" />
           
          <Typography variant='subtitle2' color='red'>{error}</Typography>

          <LoadingButton fullWidth size="large"  title='button Village' variant="contained" onClick={upload}> 
            Delete
          </LoadingButton> 
        </Stack>  
     </Card>
    </Grid>
    </>
  );
})


DeleteVillage.propTypes = {    
  item: PropTypes.object,  
};

