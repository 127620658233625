
import PropTypes from 'prop-types';

// mui
import { FormLabel, IconButton, Typography, LinearProgress  } from '@mui/material'; 
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack'; 


// ----------------------------------------------------------------------
 
VideoSelection.propTypes = {
  handleFilesChange: PropTypes.func, 
  progressPercentage: PropTypes.number, 
  files: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object, 
  ]), 
  fileTypeCheck: PropTypes.object,
  preview: PropTypes.object,  
  inputElement: PropTypes.any,
};

export default function VideoSelection({inputElement, handleFilesChange, progressPercentage, files, fileTypeCheck, preview}) {  
   
  return (
    <> 
        <FormLabel id="select-a-video">select a video</FormLabel>
        <IconButton color="primary" aria-label="upload picture"   title='select a video' component="label">
            <input hidden accept="video/*" type="file" ref={inputElement} onChange={handleFilesChange} />
            <VideoCameraBackIcon sx={{fontSize:{xs:30, md:40},}} />  
        </IconButton>
                
        {!!progressPercentage && 
           (<>
                <LinearProgress variant="determinate" value={progressPercentage}/>
                <Typography variant="body2" color="text.secondary">
                    {`${Math.round(progressPercentage)}%`}
                </Typography> 
            </>)
        }


        {files && files.length !== 0 &&  
            (<>
                {fileTypeCheck.imageFile && <img src={preview.imageURL} alt='' style={{maxWidth: '250px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}

                {fileTypeCheck.videoFile && <video controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                    loop 
                    src={preview.videoURL}
                ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></video>}
                

                {fileTypeCheck.audioFile && <audio controls controlsList="nodownload" style={{width: '100%', maxHeight: '150px'}}
                    loop 
                    src={preview.audioURL}
                ><track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/></audio>}
            </>)
        } 
    </>
  );
}

