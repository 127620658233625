
export const lowerCaseAllWordsExceptFirstLetters = string =>
 string.replaceAll(/\S*/g, word =>
  `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
 );


export const reverseString = (str) => { 
  const strin = str.split("/").reverse().join("/");  
  return strin; 
}


export const reverseArray = (array1) => { 
  const reversed = [...array1].reverse() 
  return reversed; 
}

export const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes, seconds] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
    }

    minutes = parseInt(minutes, 10);
    seconds = parseInt(seconds, 10);

    return `${hours}:${minutes}:${seconds}`;
}


export const convertISO8601DateToNormalDate = (endDate) => { 
    let dd = new Date(endDate);

    dd = new Date(dd);

    const {
        y,
        m,
        d
    } = {
        y: dd.getFullYear(),
        m: (`${"0"}${(dd.getMonth() + 1)}`).slice(-2),
        d: (`${"0"}${dd.getDate()}`).slice(-2)
    };
    return `${y}${'-'}${d}${'-'}${m}`;   
}



export const convertISO8601DateToNormalTime = (endTime) => {
    let dd = new Date(endTime);

    dd = new Date(dd);

    const {
        h,
        m,
        s
    } = {
        h: (`${"0"}${dd.getHours()}`).slice(-2),
        m: (`${"0"}${dd.getMinutes()}`).slice(-2),
        s: (`${"0"}${dd.getSeconds()}`).slice(-2)
    }; 

    return `${h}${':'}${m}${':'}${s}`; 
}


/* return true if DateOnline greater than or equal to DateNow */
export function dateOnlineDateNow (DateOnline, DateNow) {
    return DateOnline > DateNow || DateOnline === DateNow;
}


/* return the current date in the format YYYY-MM-DD */
export const dateNow = () => {
    const dd = new Date();
    const {
        y,
        m,
        d
    } = {
        y: dd.getFullYear(),
        m: (`${"0"}${(dd.getMonth() + 1)}`).slice(-2),
        d: (`${"0"}${dd.getDate()}`).slice(-2)
    };


    return `${y}${"-"}${d}${"-"}${m}`; 
}




/* return true if TimeFrom less than TimeTo */
export const dateTimeFromDateTimeTo = (TimeFrom, TimeTo) => {  
    const str1 =  TimeFrom.split(':');
    const str2 =  TimeTo.split(':');

    const totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0], 10);
    const totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[0], 10);
 
    return totalSeconds1 < totalSeconds2;
}