

import ViewOneMyVillageVideoAudioTypes from './viewOneMyVillageVideoAudioTypes'


export const ViewOneMyVillageVideoAudioREQUEST = (item) => ({
    type: ViewOneMyVillageVideoAudioTypes.View_One_My_Village_Video_Audio_REQUEST,
    payload: item
}) 
 

export const ViewOneMyVillageVideoAudioRESET = item => ({
    type: ViewOneMyVillageVideoAudioTypes.View_One_My_Village_Video_Audio_RESET,
    payload: item
}) 