import PasswordActivationActionTypes from './passwordActivationTypes'


export const PasswordActivationREQUEST = (item) => ({
    type: PasswordActivationActionTypes.Password_Activation_REQUEST,
    payload: item
}) 


export const PasswordActivationRESET = item => ({
    type: PasswordActivationActionTypes.Password_Activation_RESET,
    payload: item
}) 


