

import ViewOneFeastCardVideoActionTypes from './viewOneFeastCardVideoTypes'


export const ViewOneFeastCardVideoREQUEST = (item) => ({
    type: ViewOneFeastCardVideoActionTypes.View_One_Feast_Card_Video_REQUEST,
    payload: item
}) 
 

export const ViewOneFeastCardVideoRESET = item => ({
    type: ViewOneFeastCardVideoActionTypes.View_One_Feast_Card_Video_RESET,
    payload: item
}) 