// import {Suspense } from "react";

// routes 
import Router from './routes';
// theme
import ThemeProvider from './theme'; 
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';


// ----------------------------------------------------------------------


 
export default function App() {
  return ( 
    <ThemeProvider> 
      <ScrollToTop /> 
      <StyledChart /> 

      <Router /> 

    </ThemeProvider> 
  );
}
 


/*
export default function App() {
  return (
  	<Suspense fallback={<img style={{padding:0, margin:'auto',}}  src={`/assets/icons/loadingIcon.gif`} alt="loading icon" />}> 
    <ThemeProvider>  
      <StyledChart /> 

      <Router /> 

    </ThemeProvider>
    </Suspense>
  );
}
*/


