import ForgottenPasswordActionTypes from './forgottenPasswordTypes'


export const ForgottenPasswordREQUEST = (item) => ({
    type: ForgottenPasswordActionTypes.Forgotten_Password_REQUEST,
    payload: item
}) 


export const ForgottenPasswordRESET = item => ({
    type: ForgottenPasswordActionTypes.Forgotten_Password_RESET,
    payload: item
}) 