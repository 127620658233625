


export const cloud100 = {
    a: 'select',
    b: 'select a file',
    c: 'select file',
    d: 'selects a file',
    e: 'selects file',
    f: 'selected a file',
    g: 'selected file',
    h: 'selects',
    i: 'selected',
    j: 'selection',
    k: 'collection'
};

export const cloud101 = {
    a: 'save',
    b: 'share',
    c: 'send',
    d: 'save.',
    e: 'share.',
    f: 'send.', 
};


export const cloud123 = {
    a: 'description'
}


export const cloud124 = {
    a: 'one',
    b: '1',
    c: 'eh'
}

export const cloud125 = {
    a: 'two',
    b: 'to',
    c: '2'
}

export const cloud126 = {
    a: 'three',
    b: '3',
    c: 'tree'
}

export const cloud127 = {
    a: 'for',
    b: '4',
    c: 'four'
}

export const cloud128 = {
    a: 'five',
    b: '5'
}

export const cloud129 = {
    a: 'six',
    b: '6'
}

export const cloud130 = {
    a: 'seven',
    b: '7',
    c: 'seth'
}

export const cloud131 = {
    a: 'eight',
    b: '8'
}

export const cloud148 = {
    a: 'part'
}

export const cloud197 = {
	a: 'image',
	b: 'picture',
	c: 'images',
	d: 'pictures',
	e: 'photo',
	f: 'photos',
	g: 'photograph',
	h: 'photographs'
};


export const findBackgroundTemplate = (arrayObject, id_) => { 
    const backDetails = arrayObject.filter(obj => obj.id === id_);
    return backDetails[0].back;
}


export const getIntoSpecificArea = async (a_) => {
    const a = await parseInt(a_, 10) - 1;

    const notify = await document.getElementsByClassName("notify");

    if (await (typeof(notify[a]) !== 'undefined' && notify[a] !== null)) {
        notify[a].click();
    }
}