import UserInfoActionTypes from './userInfoTypes'


export const UserInfoREQUEST = (item) => ({
    type: UserInfoActionTypes.User_Info_REQUEST,
    payload: item
}) 


export const UserInfoRESET = item => ({
    type: UserInfoActionTypes.User_Info_RESET,
    payload: item
}) 

