
import { useState, memo, } from 'react'; 
import PropTypes from 'prop-types';
import axios from 'axios';  

// @mui
import { Card, Grid, Typography, Stack, Divider, Avatar  } from '@mui/material';  
import { LoadingButton } from '@mui/lab'; 
import { grey } from '@mui/material/colors';


import {domainName} from '../../../utils/domain';
// ----------------------------------------------------------------------


export const VotesHistory = memo(({item, features, token, type, competitionId, seasonId}) => {  
  const { 
  	Photo,
    NewFullName,  
    City,
    Country,
    Continent,
    DateCreated,
    TimeCreated2,
    NumberLikes,
    RoomId 
  } = item;
  
  const [error, setError] = useState('');
  const [numberVotes, setNumberVotes] = useState(NumberLikes)


  const { 
  	buttonText,
  	backColor, 
  	color
  } = features;

 


  /*  Begin upload */ 
  const urlLocalRequest = `${domainName}/u/vote/history/save`;

  const upload = async () => {    
    const setBreakingNewsIdentifier_ = await competitionId;
    const seasonIdentifier_ = await seasonId;
    const RoomId_ = await RoomId;
    const token_ = await token;
    const type_ = await type;
 
    const resp = await sendFileData(token_, type_, RoomId_, seasonIdentifier_, setBreakingNewsIdentifier_, urlLocalRequest);
 
    const jsonResponse = await resp.data; 
    const jsres = await jsonResponse.message; 

    if(jsres === 'Your vote has been received') {
      setError(jsres);
      setNumberVotes(numberVotes + 1);
    } else {
      setError(jsres);
    } 
  } 
 

  const sendFileData = (token_, type_, RoomId_, seasonIdentifier_, setBreakingNewsIdentifier_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {   
        token: token_,
        type: type_,
        roomId: RoomId_,
        seasonIdentifier: seasonIdentifier_,
        setBreakingNewsIdentifier: setBreakingNewsIdentifier_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  } 
  /*  End upload */ 



  return (  
    <Grid  item xs={12} sm={6} md={6} >  

      <Card sx={{m:1, backgroundColor: backColor, }}>
        <Typography variant="subtitle" color={color} fontSize="14px">
            Votes History
        </Typography> 
      </Card> 

      <Card sx={{
        boxShadow: 0,
        textAlign: 'left',
        color: {color},   
        backgroundColor: backColor, 
        minHeight:'300px',
        // borderRadius:'3px',
         
      }}>  

        <Stack spacing={1} sx={{ p: 2 }}> 
            <Stack direction="column" alignItems="center"> 
                <Typography variant='subtitle2' color='white'>{error}</Typography>

                <Avatar sx={{ bgcolor: grey[500], height:{xs:40, sm:40, md:50}, width:{xs:40, sm:40, md:50}, mr:1 }}  alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} />
                 
                <Typography variant="subtitle1" sx={{fontSize:14}}>
                    I am {NewFullName}{','} the competitor{','}
                </Typography>

                <Typography variant="subtitle1" sx={{fontSize:14}}>
                    from {City}{','}  {Country}{','} {Continent}
                </Typography>  
            </Stack> 

            <Divider sx={{backgroundColor:'black', m:1, }} /> 

            <Stack direction="column" alignItems="center" >   
                <Typography variant="subtitle1" sx={{fontSize:14, color:'white'}}>
                    must be voted by {DateCreated} {TimeCreated2}
                </Typography> 

                <Typography variant="subtitle1" sx={{fontSize:14}}>
                    {numberVotes} votes 
                </Typography> 

                <Divider sx={{backgroundColor:'black', m:1, }} />

                <LoadingButton  sx={{width:100, p:0}}  variant="contained" onClick={upload}>
                    {buttonText}
                </LoadingButton> 
            </Stack>
        </Stack>
      </Card>
    </Grid> 
  );
})



VotesHistory.propTypes = {    
  item: PropTypes.object, 
  features: PropTypes.object, 
  token: PropTypes.string, 
  type: PropTypes.string, 
  competitionId: PropTypes.string, 
  seasonId: PropTypes.string, 
};
