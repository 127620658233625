
import { useEffect, useRef, useState, useMemo } from 'react';  
import { useCookies  } from 'react-cookie';
import { useNavigate,  } from 'react-router-dom'; 
import { useSpeechRecognition } from "react-speech-recognition";
import YouTube from 'react-youtube'; 
import Dailymotion from 'react-dailymotion';

import { Card, IconButton, InputAdornment, TextField} from '@mui/material'; 

import io from 'socket.io-client';
 
 
import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud, cloud13, cloud63,
       cloud66, cloud67, cloud82, cloud83, cloud84, scrollDownMain, scrollUpMain, stringInclude, sleep} from '../../../../utils/translateFunctions';


import { cloud2, cloud3, cloud4, cloud5, cloud6, cloud7, cloud9, cloud11, cloud12, cloud74, cloud75, cloud76, 
      cloud77, cloud78, DMPause, DMPlay, YTPause, YTPlay } from '../../../../utils/automateMusicFunctions'; 
 


// components  
import Iconify from '../../../../components/iconify';

// hooks
import useResponsive from '../../../../hooks/useResponsive';

// utils
import {useWindowDimensions} from '../../../../utils/otherFunctions';

import {domainName} from '../../../../utils/domain';

const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;

// ----------------------------------------------------------
export default function PlaySongWithMichael() { 
  const navigate = useNavigate();    
  const socket = useMemo(() => io(`${domainName}`), []); 

  const token = useRef(null); 
  const type = useRef(null); 

  const [iframeValue, setIframeValue] = useState('');  
  const [videoId, setVideoId] = useState(null);
  const [videoId2, setVideoId2] = useState(null); 
  const [config, setConfig] = useState({}); 
  const [vol, setVolume] = useState(1);
  const [vol2, setVolume2] = useState(1);

  const displayIframeOrYoutube = useRef({iframe: true, other:false, daily: false});
  const player = useRef([]);
  const player2 = useRef([]);
  const streamingServiceType = useRef('');
  const inputValue = useRef(''); 

  const { height } = useWindowDimensions();
  const isDesktop = useResponsive('up', 'lg');   
 
 
  const HEIGHT = isDesktop? height - 30 : height;

  const HEIGHTInner = isDesktop? `${HEIGHT - 50}px` : `${HEIGHT - 60}px`;
 

  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
        token.current = cookies._token_planetryvilage.token; 
        type.current = cookies._token_planetryvilage.type; 
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 
 
 

  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition();  

  const scrollMain = useRef(0);

  function onPlayerReady_(event) { 
    player.current = [];
    player.current = [].concat(event.target, player.current);
  }

  function onPlayerReady2(event) { 
    player2.current = [];
    player2.current = [].concat(event.target, player2.current);
  }
  

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {   
        const erase__ = await type.current;
        const erase2__ = await token.current; 

        let firstElementOfString__; let messageE;
        const tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(tm);    
        
        resetTranscript();  


        const firstElementOfString_ = await firstElementOfString(t_);
        let removeFirstElementFromString_ = await removeFirstElementFromString(t_); 
        // const  t = await removeFirstElementFromString_;


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString_)) {
            
          firstElementOfString__ = await firstElementOfString(removeFirstElementFromString_);   
          
          /* play a radio channel */
          if (await stringComparison([cloud12.a, cloud12.b], firstElementOfString__) && await stringInclude(removeFirstElementFromString_, cloud76.b)) {
            speech.text = await transMany__(cloud74.a) || cloud74.a;
            await speechSynth.speak(speech); 
 
            const newString = await removeFirstElementFromString(removeFirstElementFromString(tm));  

            if (await (firstElementOfString__ === 'plays')) {
              firstElementOfString__ = await 'play';
            }

            removeFirstElementFromString_ = await `${firstElementOfString__}${' '}${newString}`;

            await socket.emit(cloud78.a, {
              option1: firstElementOfString__,
              option2: removeFirstElementFromString_,
              option3: erase2__,
              option4: erase__,
              option5: "radio"
            });

            await socket.on(cloud76.a, async (message_) => { 
              displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

              const message = await message_;

              speech.text = await transMany__(message.b) || message.b;
              await speechSynth.speak(speech);

              speech.text = await transMany__(message.c) || message.c;
              await speechSynth.speak(speech);

              speech.text = '';
              await speechSynth.speak(speech);

              await setIframeValue(message.a);
            });
          } else /* play a tv channel */
          if (await stringComparison([cloud12.a, cloud12.b], firstElementOfString__) && await (stringInclude(removeFirstElementFromString_, cloud77.b) || stringInclude(removeFirstElementFromString_, "television"))) {
            speech.text = await transMany__(cloud74.a) || cloud74.a;
            await speechSynth.speak(speech); 

            const newString = await removeFirstElementFromString(removeFirstElementFromString(tm));

            if (await (firstElementOfString__ === 'plays')) {
              firstElementOfString__ = await 'play';
            }

            removeFirstElementFromString_ = await `${firstElementOfString__}${' '}${newString}`;

            await socket.emit(cloud78.a, {
              option1: firstElementOfString__,
              option2: removeFirstElementFromString_,
              option3: erase2__,
              option4: erase__
            });

            await socket.on(cloud77.a, async (message_) => { 
              displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

              const message = await message_;

              speech.text = await transMany__(message.b) || message.b;
              await speechSynth.speak(speech);

              speech.text = await transMany__(message.c) || message.c;
              await speechSynth.speak(speech);

              speech.text = '';
              await speechSynth.speak(speech);

              await setIframeValue(message.a);
            });
          } else
          if (await stringComparison([cloud12.a, cloud12.b], firstElementOfString__)) {
            speech.text = await transMany__(cloud74.a) || cloud74.a;
            await speechSynth.speak(speech); 

            const newString = await removeFirstElementFromString(removeFirstElementFromString(tm));

            removeFirstElementFromString_ = await `${firstElementOfString__}${' '}${newString}`;

            await socket.emit(cloud11.a, {
              option1: firstElementOfString__,
              option2: removeFirstElementFromString_,
              option3: erase2__,
              option4: erase__
            });   

            await socket.on(cloud11.a, async (message_) => {  
              const message = await message_;
              messageE = await message_.e;       
              streamingServiceType.current = await messageE;   
              const messageF = await message.f;
              const messageC = await message.c;
              
              /* play the music on Amazon */
              if (await (messageE === cloud3.d)) {  
                displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

                await setIframeValue(`${messageC}?marketplaceId=A1F83G8C2ARO7P&musicTerritory=GB`);
              } else /* play the music on Apple */
              if (await (messageE === cloud3.f)) {   
                displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

                await setIframeValue(`${messageC}?app=music`)
              } else /* play the music on Vimeo */
              if (await (messageE === cloud3.a)) {   
                displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false}; 
                
                await setIframeValue(`${messageC}?autoplay=${message.autoplay}&muted=${message.muted}&loop=${message.loop}&autopause=${message.autopause}&h=${message.h}`)
              } else /* play  the music on DailyMotion */
              if (await (messageE === cloud3.b)) {  
                displayIframeOrYoutube.current = await {iframe:false, other: false, daily: true};

                await setVideoId2(message.c);  
              } else /* play the music on Spotify */
              if (await (messageE === cloud3.e)) {   
                
                displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

                if (await (messageF === cloud9.a)) {   
                  await setIframeValue(messageC);
                } else
                if (await (messageF === cloud9.b)) {  
                  await setIframeValue(messageC);
                } else
                if (await (messageF === cloud9.c)) {   
                  await setIframeValue(messageC);
                } else
                if (await (messageF === cloud9.d)) {   
                  await setIframeValue(messageC);
                }
              } else /* play the music on youtube */
              if (await (message.e === cloud3.c)) {   
                displayIframeOrYoutube.current = await {iframe:false, other: true, daily: false};

                await setVideoId(message.c);

                const config_ = await { 
                  height: HEIGHTInner,
                  width: '100%',
                  videoId: message.c,
                  playerVars: message.d,   
                }
 
                setConfig(config_); 
              }

            }); 
          } else /* pause a video */  
          if (await stringComparison([cloud7.a, cloud7.a1, cloud7.a2], firstElementOfString__) && await stringComparison([cloud7.b, cloud7.c, cloud7.b1, cloud7.c1, cloud7.b2, cloud7.c2, cloud7.c3, cloud7.c4, cloud7.c5, cloud7.c6, cloud7.c7, cloud7.c8], removeFirstElementFromString_)) {
             
            if (await (streamingServiceType.current === cloud3.a)) { 
              // await V_Pause(player);
            } else
            if (await (streamingServiceType.current === cloud3.b)) {  
              await DMPause(player2.current); 
            } else
            if (await (streamingServiceType.current === cloud3.c)) {  
              await YTPause(player.current); 
            }
          } else /* turn up the volume */
          if (await stringComparison([cloud6.a, cloud6.a1, cloud6.a2], firstElementOfString__) && await stringComparison([cloud6.b, cloud6.c, cloud6.b1, cloud6.c1, cloud6.b2, cloud6.c2, cloud6.c3, cloud6.c4, cloud6.c5], removeFirstElementFromString_)) {
              if (await (streamingServiceType.current === cloud3.a)) { 
                // await V_TurnUp(player, vol);
              } else
              if (await (streamingServiceType.current === cloud3.b)) { 
                setVolume2(vol2 + 0.4)
              } else
              if (await (streamingServiceType.current === cloud3.c)) {   
                setVolume(vol + 0.4)
              }
            } else /* turn down the volume */
            if (await stringComparison([cloud5.a, cloud5.a1, cloud5.a2], firstElementOfString__) && await stringComparison([cloud5.b, cloud5.c, cloud5.b1, cloud5.c1, cloud5.b2, cloud5.c2, cloud5.c3, cloud5.c4, cloud5.c5], removeFirstElementFromString_)) {
              if (await (streamingServiceType.current === cloud3.a)) {
                // await V_TurnDown(player, vol);
              } else
              if (await (streamingServiceType.current === cloud3.b)) { 
                setVolume2(vol2 - 0.4)
              } else
              if (await (streamingServiceType.current === cloud3.c)) { 
                setVolume(vol - 0.4)
              }
            } else /* resume a video that was paused */
            if (await stringComparison([cloud4.a, cloud4.a1, cloud4.a2, cloud4.a3, cloud4.a4], firstElementOfString__) && await stringComparison([cloud4.b, cloud4.b1, cloud4.b2, cloud4.b3, cloud4.b4, cloud4.b5, cloud4.b6, cloud4.b7, cloud4.b8, cloud4.b9, cloud4.b10, cloud4.b11], removeFirstElementFromString_)) {
              if (await (streamingServiceType.current === cloud3.a)) {
                // await V_Play(player);
              } else
              if (await (streamingServiceType.current === cloud3.b)) {
                await DMPlay(player2.current); 
              } else
              if (await (streamingServiceType.current === cloud3.c)) {
                await YTPlay(player.current); 
              }
            } else /* remove a video that is currently playing */
            if (await stringComparison([cloud2.a, cloud2.a1, cloud2.a2, cloud2.a3, cloud2.a4, cloud2.a5, cloud2.a6, cloud2.a7, cloud2.a8, 'suppress', 'suppresses', 'suppressed', 'eliminate', 'eliminates', 'eliminated', 'delete', 'deletes', 'deletes'], firstElementOfString__) && await stringComparison([cloud2.b, cloud2.b1, cloud2.b2, cloud2.b3, cloud2.b4, cloud2.b5, cloud2.b6, cloud2.b7, cloud2.b8, cloud2.b9, cloud2.b10, cloud2.b11, cloud2.b12, cloud2.b13, cloud2.b14, cloud2.b15, cloud2.b16, cloud2.b17, cloud2.b18, cloud2.b19, cloud2.b20, cloud2.b21, cloud2.b22, cloud2.b23, cloud2.b24, cloud2.b25, cloud2.b26, 'suppress the video', 'suppresses the video', 'suppressed the video', 'eliminate the video', 'eliminates the video', 'eliminated the video', 'suppress this video', 'suppresses this video', 'suppressed this video', 'eliminate this video', 'eliminates this video', 'eliminated this video', 'delete the video', 'deletes the video', 'deletes the video', 'delete this video', 'deletes this video', 'deletes this video'], removeFirstElementFromString_)) {
              if (await (streamingServiceType.current === cloud3.a)) {
                // await V_Discard(player);
              } else
              if (await (streamingServiceType.current === cloud3.b)) {
                // await DM_Discard(player);
              } else
              if (await (streamingServiceType.current === cloud3.c)) {
                // await YT_Discard(player);
              }
            } else /* michael what's your name */
            if (await stringComparison([cloud.a], firstElementOfString__) && await stringComparison([cloud.b, cloud.c, cloud.d], removeFirstElementFromString_)) {

              speech.text = await transMany__(cloud.e) || cloud.e;
              await speechSynth.speak(speech);

              speech.text = await '';
              await speechSynth.speak(speech);
            } else /* Michael connect to youtube music or any music streaming services (apple music, amazon music, vimeo) */
            if (await (stringComparison([cloud82.a, cloud82.a2, cloud82.a4, cloud82.a6, cloud82.a8, cloud82.a10, cloud82.a12, cloud82.a13, cloud82.a14, cloud82.a15, cloud82.a16, cloud82.a17, cloud82.a24], firstElementOfString__)) && await (stringInclude(removeFirstElementFromString_, cloud82.b) || stringInclude(removeFirstElementFromString_, cloud82.a3) || stringInclude(removeFirstElementFromString_, cloud82.a5) || stringInclude(removeFirstElementFromString_, cloud82.a7) || stringInclude(removeFirstElementFromString_, cloud82.a9) || stringInclude(removeFirstElementFromString_, cloud82.a11) || stringInclude(removeFirstElementFromString_, cloud82.a18) || stringInclude(removeFirstElementFromString_, cloud82.a19) || stringInclude(removeFirstElementFromString_, cloud82.a20) || stringInclude(removeFirstElementFromString_, cloud82.a21) || stringInclude(removeFirstElementFromString_, cloud82.a22) || stringInclude(removeFirstElementFromString_, cloud82.a23) || stringInclude(removeFirstElementFromString_, cloud82.a24)) && await (stringInclude(removeFirstElementFromString_, cloud82.c)|| stringInclude(removeFirstElementFromString_, cloud82.a24) )) {
 
              if (await (firstElementOfString__ === 'tune')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('tune in', 'connect to');
              } else
              if (await (firstElementOfString__ === 'tunes')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('tunes in', 'connect to');
              } else
              if (await (firstElementOfString__ === 'tuned')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('tuned in', 'connect to');
              } else


              if (await (firstElementOfString__ === 'connects')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('connects to', 'connect to');
              } else
              if (await (firstElementOfString__ === 'connecttwo')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('connecttwo', 'connect to');
              } else
              if (await (firstElementOfString__ === 'connected')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('connected to', 'connect to');
              } else


              if (await (firstElementOfString__ === 'sign')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('sign in', 'connect to');
              } else
              if (await (firstElementOfString__ === 'signs')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('signs in', 'connect to');
              } else
              if (await (firstElementOfString__ === 'signed')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('signed in', 'connect to');
              } else


              if (await (firstElementOfString__ === 'log')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('log in', 'connect to');
              } else
              if (await (firstElementOfString__ === 'logs')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('logs in', 'connect to');
              } else
              if (await (firstElementOfString__ === 'logged')) {
                firstElementOfString__ = 'connect';
                removeFirstElementFromString_ = await removeFirstElementFromString_.replace('logged in', 'connect to');
              }

              await socket.emit(cloud82.d, {
                option1: firstElementOfString__,
                option2: removeFirstElementFromString_,
                option3: erase2__,
                option4: erase__
              });

              await socket.on(cloud82.d, async (message_) => {
                const message = await message_;

                speech.text = await transMany__(message.a) || message.a;
                await speechSynth.speak(speech);

                speech.text = await '';
                await speechSynth.speak(speech);

              });
            } else /* Michael search who is Einstein */
            if (await (stringInclude(removeFirstElementFromString_, cloud84.a) || stringInclude(removeFirstElementFromString_, cloud84.b) || stringInclude(removeFirstElementFromString_, cloud84.c) || stringInclude(removeFirstElementFromString_, cloud84.d) || stringInclude(removeFirstElementFromString_, cloud84.e) || stringInclude(removeFirstElementFromString_, cloud84.f) || stringInclude(removeFirstElementFromString_, cloud84.g) || stringInclude(removeFirstElementFromString_, cloud84.h) || stringInclude(removeFirstElementFromString_, cloud84.i) || stringInclude(removeFirstElementFromString_, cloud84.j) || stringInclude(removeFirstElementFromString_, cloud84.k) || stringInclude(removeFirstElementFromString_, cloud84.l))) {
              speech.text = await transMany__(cloud74.a) || cloud74.a;
              await speechSynth.speak(speech); 
 
              speech.text = await '';
              await speechSynth.speak(speech);

              const newString = await removeFirstElementFromString(removeFirstElementFromString(tm));

              removeFirstElementFromString_ = await `${firstElementOfString__}${' '}${newString}`;

              await socket.emit(cloud75.a, {
                option1: firstElementOfString__,
                option2: removeFirstElementFromString_,
                option3: erase2__,
                option4: erase__
              });

              await socket.on(cloud75.a, async (message_) => { 
                displayIframeOrYoutube.current = await {iframe:true, other:false, daily: false};

                const message = await message_; 

                await setIframeValue(message.a);


                speech.text = await transMany__(message.b) || message.b;
                await speechSynth.speak(speech);

                await sleep(10000);

                speech.text = await transMany__(message.c) || message.c;
                await speechSynth.speak(speech);

                speech.text = await '';
                await speechSynth.speak(speech);

              });
            } 
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, socket, HEIGHTInner, vol, vol2]);

 

  
  /* use Michael manually */ 

  const handleSearchName = async () => {
   
    if (inputValue.current.value !== '') {   
      const erase__ = await type.current;
      const erase2__ = await token.current; 

      let firstElementOfString__; let messageE;
      const tm = await removeCommaQuestionMarkFullStopLowerCase(inputValue.current.value);    
      const t_ = await transMany(tm);    
        
      inputValue.current.value = '';  

      const firstElementOfString_ = await firstElementOfString(t_);
      let removeFirstElementFromString_ = await removeFirstElementFromString(t_);
      const  t = await removeFirstElementFromString_;


      if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString_)) {
         
          
        /* beginning down up stop scrollbar */
        if (await stringComparison([cloud67.a, cloud67.b, cloud67.c, cloud67.d, cloud67.e], t)) { 
          await clearInterval(scrollMain.current);
          scrollMain.current = await scrollDownMain(window);
        }

        if (await stringComparison([cloud66.a, cloud66.b, cloud66.c, cloud66.d, cloud66.e], t)) {  
          await clearInterval(scrollMain.current);
          scrollMain.current = await scrollUpMain(window);
        }

        if (await stringComparison([cloud63.a, cloud63.b, cloud63.c], t)) {  
          await clearInterval(scrollMain.current);
        }
        /* end down up stop scrollbar */


        /* Michael what's your name */
        if (await stringComparison([cloud13.a, cloud13.b, cloud13.c, cloud13.d, cloud13.a2, cloud13.b2, cloud13.c2, cloud13.d2], t)) {
          speech.text = await transMany__(cloud13.e) || cloud13.e;
          await speechSynth.speak(speech);  
        }
          
        /* Michael where do you live */
        if (await stringComparison([cloud.a, cloud.b, cloud.c, cloud.d, cloud.a2, cloud.b2, cloud.c2, cloud.d2, cloud.a3, cloud.b3, cloud.c3, cloud.d3, cloud.d4, cloud.d5], t)) {
          speech.text = await transMany__(cloud.e) || cloud.e;
          await speechSynth.speak(speech); 
        } 



        firstElementOfString__ = await firstElementOfString(removeFirstElementFromString_);   
          
        /* play a radio channel */
        if (await stringComparison([cloud12.a, cloud12.b], firstElementOfString__) && await stringInclude(removeFirstElementFromString_, cloud76.b)) {
          speech.text = await transMany__(cloud74.a) || cloud74.a;
          await speechSynth.speak(speech); 
 
          const newString = await removeFirstElementFromString(removeFirstElementFromString(tm));  

          if (await (firstElementOfString__ === 'plays')) {
            firstElementOfString__ = await 'play';
          }

          removeFirstElementFromString_ = await `${firstElementOfString__}${' '}${newString}`;

          await socket.emit(cloud78.a, {
            option1: firstElementOfString__,
            option2: removeFirstElementFromString_,
            option3: erase2__,
            option4: erase__,
            option5: "radio"
          });

          await socket.on(cloud76.a, async (message_) => { 
            displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

            const message = await message_;

            speech.text = await transMany__(message.b) || message.b;
            await speechSynth.speak(speech);

            speech.text = await transMany__(message.c) || message.c;
            await speechSynth.speak(speech);

            speech.text = '';
            await speechSynth.speak(speech);

            await setIframeValue(message.a);
          });
        } else /* play a tv channel */
        if (await stringComparison([cloud12.a, cloud12.b], firstElementOfString__) && await (stringInclude(removeFirstElementFromString_, cloud77.b) || stringInclude(removeFirstElementFromString_, "television"))) {
          speech.text = await transMany__(cloud74.a) || cloud74.a;
          await speechSynth.speak(speech); 

          const newString = await removeFirstElementFromString(removeFirstElementFromString(tm));

          if (await (firstElementOfString__ === 'plays')) {
            firstElementOfString__ = await 'play';
          }

          removeFirstElementFromString_ = await `${firstElementOfString__}${' '}${newString}`;

          await socket.emit(cloud78.a, {
            option1: firstElementOfString__,
            option2: removeFirstElementFromString_,
            option3: erase2__,
            option4: erase__
          });

          await socket.on(cloud77.a, async (message_) => { 
            displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

            const message = await message_;

            speech.text = await transMany__(message.b) || message.b;
            await speechSynth.speak(speech);

            speech.text = await transMany__(message.c) || message.c;
            await speechSynth.speak(speech);

            speech.text = '';
            await speechSynth.speak(speech);

            await setIframeValue(message.a);
          });
        } else
        if (await stringComparison([cloud12.a, cloud12.b], firstElementOfString__)) {
          speech.text = await transMany__(cloud74.a) || cloud74.a;
          await speechSynth.speak(speech); 

          const newString = await removeFirstElementFromString(removeFirstElementFromString(tm));

          removeFirstElementFromString_ = await `${firstElementOfString__}${' '}${newString}`;

          await socket.emit(cloud11.a, {
            option1: firstElementOfString__,
            option2: removeFirstElementFromString_,
            option3: erase2__,
            option4: erase__
          });   

          await socket.on(cloud11.a, async (message_) => {  
            const message = await message_;
            messageE = await message_.e;       
            streamingServiceType.current = await messageE;   
            const messageF = await message.f;
            const messageC = await message.c;
              
            /* play the music on Amazon */
            if (await (messageE === cloud3.d)) {  
              displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

              await setIframeValue(`${messageC}?marketplaceId=A1F83G8C2ARO7P&musicTerritory=GB`);
            } else /* play the music on Apple */
            if (await (messageE === cloud3.f)) {   
              displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

              await setIframeValue(`${messageC}?app=music`)
            } else /* play the music on Vimeo */
            if (await (messageE === cloud3.a)) {   
              displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false}; 
                
              await setIframeValue(`${messageC}?autoplay=${message.autoplay}&muted=${message.muted}&loop=${message.loop}&autopause=${message.autopause}&h=${message.h}`)
            } else /* play  the music on DailyMotion */
            if (await (messageE === cloud3.b)) {  
              displayIframeOrYoutube.current = await {iframe:false, other: false, daily: true};

              await setVideoId2(message.c); 
             
              // await setVideoId('');  
              if(await player.current.length)  await YTPause(player.current); /* stop the music on youtube in manual mode before playing dailymotion */
            } else /* play the music on Spotify */
            if (await (messageE === cloud3.e)) {   
                
              displayIframeOrYoutube.current = await {iframe:true, other: false, daily: false};

              if (await (messageF === cloud9.a)) {   
                await setIframeValue(messageC);
              } else
              if (await (messageF === cloud9.b)) {  
                await setIframeValue(messageC);
              } else
              if (await (messageF === cloud9.c)) {   
                await setIframeValue(messageC);
              } else
              if (await (messageF === cloud9.d)) {   
                await setIframeValue(messageC);
              }
            } else /* play the music on youtube */
            if (await (message.e === cloud3.c)) {   
              displayIframeOrYoutube.current = await {iframe:false, other: true, daily: false};

              await setVideoId(message.c); 

              const config_ = await { 
                height: HEIGHTInner,
                width: '100%',
                videoId: message.c,
                playerVars: message.d,   
              }
 
              setConfig(config_); 

              if(await player2.current.length) await DMPause(player2.current); /* stop the music on dailymotion before playing youtube */
            }

          }); 
        } else /* pause a video */  
        if (await stringComparison([cloud7.a, cloud7.a1, cloud7.a2], firstElementOfString__) && await stringComparison([cloud7.b, cloud7.c, cloud7.b1, cloud7.c1, cloud7.b2, cloud7.c2, cloud7.c3, cloud7.c4, cloud7.c5, cloud7.c6, cloud7.c7, cloud7.c8], removeFirstElementFromString_)) {
             
          if (await (streamingServiceType.current === cloud3.a)) { 
            // await V_Pause(player);
          } else
          if (await (streamingServiceType.current === cloud3.b)) {  
            await DMPause(player2.current); 
          } else
          if (await (streamingServiceType.current === cloud3.c)) {  
            await YTPause(player.current); 
          }
        } else /* turn up the volume */
        if (await stringComparison([cloud6.a, cloud6.a1, cloud6.a2], firstElementOfString__) && await stringComparison([cloud6.b, cloud6.c, cloud6.b1, cloud6.c1, cloud6.b2, cloud6.c2, cloud6.c3, cloud6.c4, cloud6.c5], removeFirstElementFromString_)) {
          if (await (streamingServiceType.current === cloud3.a)) { 
            // await V_TurnUp(player, vol);
          } else
          if (await (streamingServiceType.current === cloud3.b)) { 
            setVolume2(vol2 + 0.4)
          } else
          if (await (streamingServiceType.current === cloud3.c)) {   
            setVolume(vol + 0.4)
          }
        } else /* turn down the volume */
        if (await stringComparison([cloud5.a, cloud5.a1, cloud5.a2], firstElementOfString__) && await stringComparison([cloud5.b, cloud5.c, cloud5.b1, cloud5.c1, cloud5.b2, cloud5.c2, cloud5.c3, cloud5.c4, cloud5.c5], removeFirstElementFromString_)) {
          if (await (streamingServiceType.current === cloud3.a)) {
            // await V_TurnDown(player, vol);
          } else
          if (await (streamingServiceType.current === cloud3.b)) { 
            setVolume2(vol2 - 0.4)
          } else
          if (await (streamingServiceType.current === cloud3.c)) { 
            setVolume(vol - 0.4)
          }
        } else /* resume a video that was paused */
        if (await stringComparison([cloud4.a, cloud4.a1, cloud4.a2, cloud4.a3, cloud4.a4], firstElementOfString__) && await stringComparison([cloud4.b, cloud4.b1, cloud4.b2, cloud4.b3, cloud4.b4, cloud4.b5, cloud4.b6, cloud4.b7, cloud4.b8, cloud4.b9, cloud4.b10, cloud4.b11], removeFirstElementFromString_)) {
          if (await (streamingServiceType.current === cloud3.a)) {
            // await V_Play(player);
          } else
          if (await (streamingServiceType.current === cloud3.b)) {
            await DMPlay(player2.current); 
          } else
          if (await (streamingServiceType.current === cloud3.c)) {
            await YTPlay(player.current); 
          }
        } else /* remove a video that is currently playing */
        if (await stringComparison([cloud2.a, cloud2.a1, cloud2.a2, cloud2.a3, cloud2.a4, cloud2.a5, cloud2.a6, cloud2.a7, cloud2.a8, 'suppress', 'suppresses', 'suppressed', 'eliminate', 'eliminates', 'eliminated', 'delete', 'deletes', 'deletes'], firstElementOfString__) && await stringComparison([cloud2.b, cloud2.b1, cloud2.b2, cloud2.b3, cloud2.b4, cloud2.b5, cloud2.b6, cloud2.b7, cloud2.b8, cloud2.b9, cloud2.b10, cloud2.b11, cloud2.b12, cloud2.b13, cloud2.b14, cloud2.b15, cloud2.b16, cloud2.b17, cloud2.b18, cloud2.b19, cloud2.b20, cloud2.b21, cloud2.b22, cloud2.b23, cloud2.b24, cloud2.b25, cloud2.b26, 'suppress the video', 'suppresses the video', 'suppressed the video', 'eliminate the video', 'eliminates the video', 'eliminated the video', 'suppress this video', 'suppresses this video', 'suppressed this video', 'eliminate this video', 'eliminates this video', 'eliminated this video', 'delete the video', 'deletes the video', 'deletes the video', 'delete this video', 'deletes this video', 'deletes this video'], removeFirstElementFromString_)) {
          if (await (streamingServiceType.current === cloud3.a)) {
            // await V_Discard(player);
          } else
          if (await (streamingServiceType.current === cloud3.b)) {
            // await DM_Discard(player);
          } else
          if (await (streamingServiceType.current === cloud3.c)) {
            // await YT_Discard(player);
          }
        } else /* michael what's your name */
        if (await stringComparison([cloud.a], firstElementOfString__) && await stringComparison([cloud.b, cloud.c, cloud.d], removeFirstElementFromString_)) {

          speech.text = await transMany__(cloud.e) || cloud.e;
          await speechSynth.speak(speech);

          speech.text = await '';
          await speechSynth.speak(speech);
        } else /* Michael connect to youtube music or any music streaming services (apple music, amazon music, vimeo) */
        if (await (stringComparison([cloud82.a, cloud82.a2, cloud82.a4, cloud82.a6, cloud82.a8, cloud82.a10, cloud82.a12, cloud82.a13, cloud82.a14, cloud82.a15, cloud82.a16, cloud82.a17, cloud82.a24], firstElementOfString__)) && await (stringInclude(removeFirstElementFromString_, cloud82.b) || stringInclude(removeFirstElementFromString_, cloud82.a3) || stringInclude(removeFirstElementFromString_, cloud82.a5) || stringInclude(removeFirstElementFromString_, cloud82.a7) || stringInclude(removeFirstElementFromString_, cloud82.a9) || stringInclude(removeFirstElementFromString_, cloud82.a11) || stringInclude(removeFirstElementFromString_, cloud82.a18) || stringInclude(removeFirstElementFromString_, cloud82.a19) || stringInclude(removeFirstElementFromString_, cloud82.a20) || stringInclude(removeFirstElementFromString_, cloud82.a21) || stringInclude(removeFirstElementFromString_, cloud82.a22) || stringInclude(removeFirstElementFromString_, cloud82.a23) || stringInclude(removeFirstElementFromString_, cloud82.a24)) && await (stringInclude(removeFirstElementFromString_, cloud82.c)|| stringInclude(removeFirstElementFromString_, cloud82.a24) )) {
 
          if (await (firstElementOfString__ === 'tune')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('tune in', 'connect to');
          } else
          if (await (firstElementOfString__ === 'tunes')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('tunes in', 'connect to');
          } else
          if (await (firstElementOfString__ === 'tuned')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('tuned in', 'connect to');
          } else


          if (await (firstElementOfString__ === 'connects')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('connects to', 'connect to');
          } else
          if (await (firstElementOfString__ === 'connecttwo')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('connecttwo', 'connect to');
          } else
          if (await (firstElementOfString__ === 'connected')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('connected to', 'connect to');
          } else


          if (await (firstElementOfString__ === 'sign')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('sign in', 'connect to');
          } else
          if (await (firstElementOfString__ === 'signs')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('signs in', 'connect to');
          } else
          if (await (firstElementOfString__ === 'signed')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('signed in', 'connect to');
          } else


          if (await (firstElementOfString__ === 'log')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('log in', 'connect to');
          } else
          if (await (firstElementOfString__ === 'logs')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('logs in', 'connect to');
          } else
          if (await (firstElementOfString__ === 'logged')) {
            firstElementOfString__ = 'connect';
            removeFirstElementFromString_ = await removeFirstElementFromString_.replace('logged in', 'connect to');
          }

          await socket.emit(cloud82.d, {
            option1: firstElementOfString__,
            option2: removeFirstElementFromString_,
            option3: erase2__,
            option4: erase__
          });

          await socket.on(cloud82.d, async (message_) => {
            const message = await message_;

            speech.text = await transMany__(message.a) || message.a;
            await speechSynth.speak(speech);

            speech.text = await '';
            await speechSynth.speak(speech);

          });
        } else /* Michael search who is Einstein */
        if (await (stringInclude(removeFirstElementFromString_, cloud84.a) || stringInclude(removeFirstElementFromString_, cloud84.b) || stringInclude(removeFirstElementFromString_, cloud84.c) || stringInclude(removeFirstElementFromString_, cloud84.d) || stringInclude(removeFirstElementFromString_, cloud84.e) || stringInclude(removeFirstElementFromString_, cloud84.f) || stringInclude(removeFirstElementFromString_, cloud84.g) || stringInclude(removeFirstElementFromString_, cloud84.h) || stringInclude(removeFirstElementFromString_, cloud84.i) || stringInclude(removeFirstElementFromString_, cloud84.j) || stringInclude(removeFirstElementFromString_, cloud84.k) || stringInclude(removeFirstElementFromString_, cloud84.l))) {
          speech.text = await transMany__(cloud74.a) || cloud74.a;
          await speechSynth.speak(speech); 
 
          speech.text = await '';
          await speechSynth.speak(speech);  

          const newString = await removeFirstElementFromString(removeFirstElementFromString(tm));

          removeFirstElementFromString_ = await `${firstElementOfString__}${' '}${newString}`;

          await socket.emit(cloud75.a, {
            option1: firstElementOfString__,
            option2: removeFirstElementFromString_,
            option3: erase2__,
            option4: erase__
          });

          await socket.on(cloud75.a, async (message_) => { 
            displayIframeOrYoutube.current = await {iframe:true, other:false, daily: false};

            const message = await message_; 

            await setIframeValue(message.a);


            speech.text = await transMany__(message.b) || message.b;
            await speechSynth.speak(speech);

            await sleep(10000);  

            speech.text = await transMany__(message.c) || message.c;
            await speechSynth.speak(speech); 

            speech.text = '';
            await speechSynth.speak(speech); 
          });
        }   
      } 
    } 
  }
  /* End Michael */  
 
  return (  
    <Card sx={{borderRadius:{xs:0, sm:0, md:1}, backgroundColor:'black', textAlign: 'center',   }}>
      {/* <Box component={Icon} icon='game-icons:robot-golem' onClick={changeContinuityStop} sx={{ width: {xs:30, md:30}, height: {xs:30, md:30}, cursor:'pointer', mb:1, }} />  */}
      
      <TextField  variant="standard"  
        sx={{width:{xs:'100%', sm:'100%', md:'30%'}}}
        inputRef={inputValue} 
        placeholder="play a song using Michael"
        onKeyDown={(event) => {
          if (event.key === 'Enter'){
            handleSearchName()
          } 
        }}
        InputProps={{ 
          style: { color: 'white' },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton color="primary" onClick={handleSearchName}>
                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'white' }} />
              </IconButton>
            </InputAdornment>
          ),
      }}/>

      <div style={{display:displayIframeOrYoutube.current.iframe?'block':'none'}}>
        {iframeValue && <iframe 
          title="any" 
          src = {iframeValue} 
          width="100%"
          height={HEIGHTInner}
          frameBorder="0"
          allowFullScreen="allowfullscreen"
        >{}</iframe> }
      </div>

      <div style={{display:displayIframeOrYoutube.current.other?'block':'none'}}>
        {videoId && <YouTube videoId={videoId} opts={config} volume={vol} autoplay  onEnd={() => {YTPlay(player.current)}} onReady={(event) => {onPlayerReady_(event)}}  /> }
      </div>

      <div style={{display:displayIframeOrYoutube.current.daily?'block':'none'}}>
        {videoId2 && <Dailymotion video={videoId2} width={'100%'} autoplay height={HEIGHTInner} volume={vol2} onEnd={() => {DMPlay(player2.current)}} onApiReady={(event) => {onPlayerReady2(event)}}/>}
      </div>
 
     
    </Card>
  );
}



