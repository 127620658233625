import { useState, useRef, useEffect, useCallback } from 'react'; 
import { useSelector  } from 'react-redux';
import axios from 'axios';
import { useSpeechRecognition } from "react-speech-recognition";

// @mui  
import { Typography, Divider, Grid, TextField, Stack, Card } from '@mui/material'; 


import { LoadingButton } from '@mui/lab'; 


import {domainName} from '../../../utils/domain';



import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison,
       cloud83, cloud88, cloud89, cloud89_, cloud90, cloud91,  
          
       fisrtletterofEachStringToUpperCase, } from '../../../utils/translateFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1; 
// ----------------------------------------------------------------------

export default function ChangeProfileContent() {    
  const [error, setError] = useState("");
  const [initializeRegister, setInitializeRegister] = useState({
    full_name: '',
    continent: '',
    country: '',
    city: ''
  }); 

  const [Id, setId] = useState("");

  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);

  let usersDetailsValue = []; 
  let tokenValue = '';
  let typeValue = '';
  
  const i = useRef(0)
  if(i.current === 0 && allDataOnceReducerValue.user){
    usersDetailsValue = allDataOnceReducerValue.user.usersDetails[0];   
    tokenValue = allDataOnceReducerValue.user.token; 
    typeValue = allDataOnceReducerValue.user.type;  

    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: usersDetailsValue.NewFullName,
        continent: usersDetailsValue.Continent,
        country: usersDetailsValue.Country,
        city: usersDetailsValue.City, 
      }
    });

    setId(usersDetailsValue.Id);

    i.current +=1;
  }



   /*  Begin upload */ 
  const urlLocalRequest = `${domainName}/u/change/profile/content`;

  const upload = useCallback(async () => {  

    if(await (initializeRegister.full_name).trim() === ''){
      setError('The full name is required');
      return;
    } 

    if(await (initializeRegister.continent).trim() === ''){
      setError('The continent is required');
      return;
    }

    if(await (initializeRegister.country).trim() === ''){
      setError('The country is required');
      return;
    } 

    if(await (initializeRegister.city).trim() === ''){
      setError('The city is required');
      return;
    }

    const Id_ = await Id;    
    const token_ = await tokenValue;
    const type_ = await typeValue; 

    const fullname = await initializeRegister.full_name;
    const continent = await initializeRegister.continent;
    const country = await initializeRegister.country;
    const city = await initializeRegister.city;   
 
    const resp = await sendFileData(Id_, token_, type_, fullname, continent, country, city, urlLocalRequest);
 
    const jsonResponse = await resp.data; 
    const jsres = await jsonResponse.message; 

    await setError(jsres); 
  }, [Id, initializeRegister, tokenValue, typeValue, urlLocalRequest]);
 

  const sendFileData = (Id_, token_, type_, fullname_, continent_, country_, city_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { 
        Id: Id_,  
        token: token_,
        type: type_, 
        full_name: fullname_,
        continent: continent_,
        country: country_,
        city: city_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  } 
  /*  End upload */ 



  const onChangeFullName = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: e.target.value,
        continent: initializeRegister.continent,
        country: initializeRegister.country,
        city: initializeRegister.city, 
      }
    });

    setError('');
  }


  const onChangeContinent = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: initializeRegister.full_name,
        continent: e.target.value,
        country: initializeRegister.country,
        city: initializeRegister.city, 
      }
    });

    setError('');
  }
 

  const onChangeCountry = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: initializeRegister.full_name,
        continent: initializeRegister.continent,
        country: e.target.value,
        city: initializeRegister.city, 
      }
    });

    setError('');
  }

  const onChangeCity = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        full_name: initializeRegister.full_name,
        continent: initializeRegister.continent,
        country: initializeRegister.country,
        city: e.target.value, 
      }
    });

    setError('');
  }





  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
   

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {     
        let names; let continent_; let country_; let city_;  

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));     
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_); 


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11, cloud83.a12], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;
          const firstElementOfString_ = await firstElementOfString(t);  

           
          /* set names */
          if (await stringComparison([cloud89_.a, cloud89_.b, cloud89_.c], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            names = await tm;

            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: fisrtletterofEachStringToUpperCase(names),
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: initializeRegister.city, 
              }
            });

            await setError('');

            speech.text = await transMany__('The name has been updated') || 'The name has been updated';
            speechSynth.speak(speech);
          } 

          /* set continent */
          if (await stringComparison([cloud89.a], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            continent_ = await tm;

            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: fisrtletterofEachStringToUpperCase(continent_),
                country: initializeRegister.country,
                city: initializeRegister.city, 
              }
            });

            await setError('');

            speech.text = await transMany__('The continent has been updated') || 'The continent has been updated';
            speechSynth.speak(speech);
          }  

          
          /* set country */
          if (await stringComparison([cloud90.a], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            country_ = await tm;

            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: fisrtletterofEachStringToUpperCase(country_),
                city: initializeRegister.city, 
              }
            });

            await setError('');

            speech.text = await transMany__('The country has been updated') || 'The country has been updated';
            speechSynth.speak(speech);
          }



          /* set city */
          if (await stringComparison([cloud91.a], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            city_ = await tm;

            await setInitializeRegister({
              initializeRegister, 
              ...{ 
                full_name: initializeRegister.full_name,
                continent: initializeRegister.continent,
                country: initializeRegister.country,
                city: fisrtletterofEachStringToUpperCase(city_), 
              }
            });

            await setError('');

            speech.text = await transMany__('The city has been updated') || 'The city has been updated';
            speechSynth.speak(speech);
          }  
 
          
          /* send the form */
          if (await stringComparison([cloud88.a, cloud88.b, cloud88.c, cloud88.d, cloud88.e, cloud88.f, cloud88.g, cloud88.h, cloud88.i, cloud88.j], t)) {
            await upload();  
          }
 
  
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, initializeRegister, upload]);

  /* End Michael */
 

  return (
    <>  
      <Grid  item xs={12} sm={12} md={8} lg={6}>
        <Card >
          <Stack spacing={1} sx={{p:1, textAlign:'center', justifyContent:'center'}}>
            <Typography variant="string">
              Update Profile Content
            </Typography>

            <Divider/> 
              <Typography variant='button' sx={{ml:1}}>Full Name</Typography>
              <TextField name="Name" value={initializeRegister.full_name} onChange={onChangeFullName} label="Name" fullWidth/> 
              
              <Typography variant='button' sx={{ml:1}}>Location</Typography>
              <TextField name="Continent" value={initializeRegister.continent} onChange={onChangeContinent} label="Continent" fullWidth/> 

              <TextField name="Country" value={initializeRegister.country} onChange={onChangeCountry} label="Country" fullWidth/> 

              <TextField name="City" value={initializeRegister.city} onChange={onChangeCity} label="City" fullWidth/> 

              <Typography variant='subtitle2' color='red'>{error}</Typography>

              <LoadingButton fullWidth size="large"  type="button"  title='Share' variant="contained" onClick={upload}> 
                Update
              </LoadingButton> 
          </Stack>
        </Card>    
       
      </Grid>  
    </>
  );
}

