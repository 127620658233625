import TvRadioWatcherActionTypes from './tvRadioWatcherTypes'


export const TvRadioWatcherREQUEST = (item) => ({
    type: TvRadioWatcherActionTypes.Tv_Radio_Watcher_REQUEST,
    payload: item
}) 


export const TvRadioWatcherRESET = item => ({
    type: TvRadioWatcherActionTypes.Tv_Radio_Watcher_RESET,
    payload: item
}) 

