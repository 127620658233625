
export const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i-=1) {
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array; 
};  

export const shufflePhotoAllDataOnce = (prevItems) => {  
    const _prevItemsPhoto = shuffle(prevItems.AllCategoryViewVideoAudioPhotoSentPerDivisionPhoto).slice(0, 10);

    return {...prevItems,   
        AllCategoryViewVideoAudioPhotoSentPerDivisionPhoto: _prevItemsPhoto,  
    };  
}

export const shuffleAudioAllDataOnce = (prevItems) => {  
    const _prevItemsAudio = shuffle(prevItems.AllCategoryViewVideoAudioPhotoSentPerDivisionAudio).slice(0, 8);

    return {...prevItems,   
        AllCategoryViewVideoAudioPhotoSentPerDivisionAudio: _prevItemsAudio,  
    };  
}

export const shuffleVideoAllDataOnce = (prevItems) => {  
    const _prevItemsVideo = shuffle(prevItems.ViewVideoAudioPhoto).slice(0, 6);

    return {...prevItems,   
        ViewVideoAudioPhoto: _prevItemsVideo,  
    };  
}




export const shuffleMagazineAllDataOnce = (prevItems) => {  
    const _prevItems = shuffle(prevItems.magazine).slice(0, 4);

    return {...prevItems,   
        magazine: _prevItems,  
    };  
}

export const shuffleEventCardAllDataOnce = (prevItems) => {  
    const _prevItems = shuffle(prevItems.eventCard).slice(0, 4);

    return {...prevItems,   
        eventCard: _prevItems,  
    };  
}

export const shuffleWishSentAllDataOnce = (prevItems) => {  
    const _prevItems = shuffle(prevItems.wishesSent).slice(0, 4);

    return {...prevItems,   
        wishesSent: _prevItems,  
    };  
}

export const shuffleWishReveivedAllDataOnce = (prevItems) => {  
    const _prevItems = shuffle(prevItems.wishesReceived).slice(0, 4);

    return {...prevItems,   
        wishesReceived: _prevItems,  
    };  
}