
import { memo } from 'react'; 
import { useCookies  } from 'react-cookie';
import PropTypes from 'prop-types';
// @mui 
import { Typography, ListItemButton, Box } from '@mui/material';

// icons
import { Icon } from '@iconify/react';
 

 
// ----------------------------------------------------------------------


export const BusinessCardWidget = memo(({OpenBusinessCard}) => {  
  const [cookies, ] = useCookies(['_token_planetryvilage']);  

  const textTitle = (cookies._token_planetryvilage !== undefined) && ( cookies._token_planetryvilage.type === 'Elderly People'? 'Old School Cards':
                    cookies._token_planetryvilage.type === 'Investor'? 'Investor Cards':
                    (cookies._token_planetryvilage.type === 'Organisation Owner' || 
                    	cookies._token_planetryvilage.type === 'Shop Owner' || 
                    	cookies._token_planetryvilage.type === 'Patient')? 'Business Cards':
                    cookies._token_planetryvilage.type === 'New Generation'? 'Universal Cards': 
                    cookies._token_planetryvilage.type === 'Pupil'? 'School Cards': 
                    'Student Cards'); 

  return (
    <> 
      <ListItemButton onClick={OpenBusinessCard}>
        <Typography  sx={{mr:{md:1}, fontSize:{xs:12} }} >{textTitle}</Typography> 
        <Box component={Icon} icon='mdi:badge-account-horizontal-outline' sx={{ width: {xs:30, md:20}, height: {xs:30, md:20}, }} /> 
      </ListItemButton> 
    </>
  );
})


BusinessCardWidget.propTypes = {  
  OpenBusinessCard: PropTypes.func,  
};
 