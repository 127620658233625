
import { useSelector  } from 'react-redux';  
import { Helmet } from 'react-helmet-async';   
import { useNavigate, } from 'react-router-dom'; 
 
// mui 
import { Container, Grid, IconButton, Stack } from '@mui/material'; 
import WestIcon from '@mui/icons-material/West';  



// sections
import {   
  UpdateVillage,
} from '../../../sections/@users/villageUser';
  


// ----------------------------------------------------------------------

export default function UpdateVillagePage() {   
  const navigate = useNavigate();   

  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);

  let myVillagesValue = []; 

  if(allDataOnceReducerValue.user){
    myVillagesValue = allDataOnceReducerValue.user.myVillages;   
  } 
 
 

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Update Village </title>
      </Helmet>

      <Container maxWidth="xl" sx={{p:0}}>  
        <Stack textAlign="center" justifyContent="center">
          <IconButton aria-label="back" className="back" sx={{fontSize:13, color:'white'}} onClick={() => navigate('/create/village') }>
            <WestIcon/>   
          </IconButton> 
        </Stack>   
        
        <Grid container spacing={1} sx={{p:2}} textAlign="center" justifyContent="center">  
          <Grid  item xs={12} sm={12} md={12}>
            <Grid container spacing={1} textAlign="center" justifyContent="center">  
  
              {myVillagesValue.map((item, key) => (
              <UpdateVillage key={key} item={item}  />
              ))} 

            </Grid>
          </Grid> 

        </Grid> 
      </Container>
    </>
  );
}
