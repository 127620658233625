 
import PropTypes from 'prop-types';
// mui
import { Avatar, Divider, IconButton, Typography, Card   } from '@mui/material'; 

import { grey } from '@mui/material/colors'; 
import SendIcon from '@mui/icons-material/Send';

// utils
import {lowerCaseAllWordsExceptFirstLetters} from '../../../utils/formatWord';


import {domainName} from '../../../utils/domain';
// ----------------------------------------------------------------------
 
Notifications.propTypes = {
  hideNotificationCard: PropTypes.func,
  name: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string, 
  tk: PropTypes.string, 
  category: PropTypes.string, 
};

export default function Notifications({category, hideNotificationCard, name, date, image, tk}) {   
  const nameUpper = lowerCaseAllWordsExceptFirstLetters(name);  

  const title= category==='Birthday'?`Wish a Happy Birthday`:
                   category==='Wedding'?`Wish a Happy Wedding Anniversary`:
                  `Wish a Happy Valentine's Day`;

  const subheader= category==='Birthday'?`${nameUpper} will celebrate a Birthday on the ${date}`:
                       category==='Wedding'?`${nameUpper} will celebrate a Wedding anniversary on the ${date}`:
                       `${nameUpper} would like to receive a Valentine's Day card on the ${date}`; 

  return (
    <Card sx={{backgroundColor:grey[300], color:'black', p:1, }}>  
      <Avatar sx={{ bgcolor: grey[500] }} alt='' src={`${domainName}/EddyDreams/Home/images/${image}`} /> 
         
      <Typography sx={{fontSize:{xs:'13px', md:'15px'}, }}>
        {title}
        <IconButton aria-label="send" className="notify" onClick={() => {hideNotificationCard(nameUpper, image, tk);}}>
          <SendIcon sx={{color:'black'}}/>
        </IconButton>
        
      </Typography>

      
      <Typography>{subheader}</Typography>
      
      <Divider/>
    </Card>
  );
}

