import {memo } from 'react'
import PropTypes from 'prop-types';
// @mui 
import { Stack, } from '@mui/material'; 

// hooks
import useResponsive from '../../../../hooks/useResponsive';

import {BusinessCardWidget, AllDreamsWidget, ViewVillageWidget} from './AllMenus';

  
// ----------------------------------------------------------------------



const TopMenu = ({OpenAllDreams, OpenViewVillage, OpenBusinessCard}) => {  
  const isDesktop = useResponsive('up', 'lg'); 

  const POSITION = isDesktop?'fixed' : 'relative';

  return ( 
    <>  
      <Stack direction='row' spacing={0} sx={{color:'white', position:POSITION, zIndex:2, }}  > 
        <AllDreamsWidget OpenAllDreams={OpenAllDreams} />
        <ViewVillageWidget OpenViewVillage={OpenViewVillage} />
        <BusinessCardWidget OpenBusinessCard={OpenBusinessCard}/>
      </Stack>
    </>
  );
}

TopMenu.propTypes = {  
  OpenBusinessCard: PropTypes.func, 
  OpenAllDreams: PropTypes.func, 
  OpenViewVillage: PropTypes.func,  
};

export default memo(TopMenu);