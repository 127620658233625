import SpecificSeasonActionTypes from './specificSeasonTypes'


export const SpecificSeasonREQUEST = (item) => ({
    type: SpecificSeasonActionTypes.Specific_Season_REQUEST,
    payload: item
}) 


export const SpecificSeasonRESET = item => ({
    type: SpecificSeasonActionTypes.Specific_Season_RESET,
    payload: item
}) 