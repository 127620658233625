import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, } from 'react-router-dom'; 
import { useCookies } from 'react-cookie';    
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
 
 
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Card, Typography, Box, 
         FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// icons 
import { Icon } from '@iconify/react'; 

// components
import Iconify from '../../../components/iconify';


 
import {LogInREQUEST, LogInRESET} from '../../../redux/login/loginActions'; 



import {languages, setAutoTranslate, newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud, cloud13, cloud63,
       cloud66, cloud67, cloud83, cloud86, cloud87, cloud88, cloud88_,
       cloud89___, cloud89__, cloud100, cloud101, nativeAct, restructureEmail, restructurePassword,
       scrollDownMain, scrollUpMain} from '../../../utils/translateFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------
 
export default function LoginForm() {
  const navigate = useNavigate(); 
  const dispatch = useDispatch(); 


  const logInValue = useSelector(state => state.logInValue);

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");  
  const [initializeLogin, setInitializeLogin] = useState({email:'', password:''});
  const [checked, setChecked] = useState(false);
  const [languageTo, setLanguageTo] = useState(''); 
 

  const [cookies, setCookie] = useCookies(['_token_planetryvilage']); 

    
  useEffect(() => {  
    if(cookies._token_planetryvilage !== undefined) {
      navigate('/', { replace: true });
    } 
  }); 
 

  useEffect(() => {  
    if(logInValue.user){
      if(logInValue.user.message === "An error occured!"){
        setError("An error occured!"); 
      } 
      else 
      if(logInValue.user.message === "This email address does not exist. Please register."){
        setError("This email address does not exist. Please register."); 
      }else 
      if(logInValue.user.message === "This password is incorrect"){
        setError("This password is incorrect"); 
      } 

      dispatch(LogInRESET(null));
    } 
  },[logInValue.user, dispatch]);  


  useEffect(() => { 
    if(logInValue.user){ 
      const _token = logInValue.user.token;
      const _type = logInValue.user.type; 

      if(_token && _type) { 
        if(checked) {
          setCookie('_token_planetryvilage', {token:_token, type:_type}, {maxAge: 60*60*24*365*60, }); // 60 years
        } else {
          setCookie('_token_planetryvilage', {token:_token, type:_type}, {maxAge: 60*60*24, }); // 1 day
        }

        dispatch(LogInRESET(null)); 
        navigate('/', { replace: true }); 
      } 
    } 
  },[logInValue.user, checked, setCookie, dispatch, navigate]);  

   

  const handleClick = useCallback(async () => {  
    if(await (initializeLogin.email).trim() === ''){
      setError('The email is required');
      return;
    } 

    if(await (initializeLogin.password).trim() === ''){
      setError('The password is required');
      return;
    }

    dispatch(LogInREQUEST(initializeLogin)); 
  }, [dispatch, initializeLogin]);
 
 


  const onChangeEmail = (e) => {   
    setInitializeLogin({
      initializeLogin, 
      ...{
        email: e.target.value, 
        password: initializeLogin.password, 
      }
    });

    setError(''); 
  }

  const onChangePassword = (e) => {
    setInitializeLogin({
      initializeLogin, 
      ...{
        email:initializeLogin.email,
        password: e.target.value, 
      }
    });
    
    setError('');
  }

  const onChangeRemember = (e) => {  
    setChecked(e.target.checked);
  }
 

  const onChangeLanguage = (e) => {
    setLanguageTo(e.target.value); 
    setAutoTranslate(e.target.value)
  };




  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
    isMicrophoneAvailable
  } = useSpeechRecognition(); 
  
  const scrollMain = useRef(0); 

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {     
        let password_; let email_; let tongue; 

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));     
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_); 


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;
          const firstElementOfString_ = await firstElementOfString(t);
          const removeFirstElementFromString_ = await removeFirstElementFromString(t);

          const firstElementOfString2_ = await firstElementOfString(removeFirstElementFromString_);
          const removeFirstElementFromString2_ = await removeFirstElementFromString(removeFirstElementFromString_);

          /* navigate to forgotten password page */
          if (await stringComparison([cloud87.a, cloud87.b, cloud87.c, cloud87.d, cloud87.e, cloud87.f, cloud87.g], t)) {
            navigate('/forgotten/password', { replace: true }); 
          }
          
          /* navigate to register page */
          if (await stringComparison([cloud86.a, cloud86.b, cloud86.c, cloud86.d, cloud86.e, cloud86.f, cloud86.g, cloud86.h, cloud86.i, cloud86.j, cloud86.k, cloud86.l, cloud86.m, cloud86.n], t)) {
            navigate('/register', { replace: true });
          }

          if (await stringComparison([cloud89___.a], firstElementOfString_)) {
            t = await firstElementOfString_;

            const a2 = await transMany__(`${'4 '}${firstElementOfString_}`) || `${'4 '}${firstElementOfString_}`;
            const a = await a2.replace("4 ", "");

            if (await (a.match(/(\w+)/g).length === 1)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            }

            password_ = await tm;
          } else {
            password_ = await '';
          }


          if (await stringComparison([cloud89__.a], firstElementOfString_)) {
            t = await firstElementOfString_; 

            const a3 = await transMany__(`${'4 '}${firstElementOfString_}`) || `${'4 '}${firstElementOfString_}`;
            const a = await a3.replace("4 ", "");

            if (await (a.match(/(\w+)/g).length === 1)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
            }

            email_ = await tm;
          } else {
            email_ = await '';
          }


          if (await stringComparison([cloud100.a, cloud100.b, cloud100.c, cloud100.d], `${firstElementOfString_}${' '}${firstElementOfString2_}`)) {
            t = await `${firstElementOfString_}${' '}${firstElementOfString2_}`;
            tongue = await removeFirstElementFromString2_;
          }
          
          /* set native language */
          if (await stringComparison([cloud100.a, cloud100.b, cloud100.c, cloud100.d], t)) {
            await nativeAct(tongue, speech, speechSynth);  
          }
          
          /* set email */
          if (await stringComparison([cloud89__.a], t)) {   
            await setInitializeLogin({
              initializeLogin, 
              ...{
                email: restructureEmail(email_), 
                password: initializeLogin.password, 
              }
            }); 

            await setError('');

            speech.text = await transMany__('The email has been updated') || 'The email has been updated';
            speechSynth.speak(speech);
          }
          
          /* set password */
          if (await stringComparison([cloud89___.a], t)) {
            await setInitializeLogin({
              initializeLogin, 
              ...{
                email: initializeLogin.email, 
                password: restructurePassword(password_), 
              }
            }); 
 
            await setError('');

            speech.text = await transMany__('The password has been updated') || 'The password has been updated';
            speechSynth.speak(speech);
          }
          
          /* show password */
          if (await stringComparison([cloud101.a1, cloud101.a2, cloud101.a3, cloud101.a4, cloud101.a5, cloud101.a6, cloud101.a7, cloud101.a8, cloud101.a9, cloud101.a10, cloud101.a11, cloud101.a12, cloud101.a13, cloud101.a14, cloud101.a15, cloud101.a16, cloud101.a17, cloud101.a18], t)) {
            await setShowPassword(!showPassword);

            speech.text = await transMany__('The visibility of the password has changed') || 'The visibility of the password has changed';
            speechSynth.speak(speech);
          } 
          
          /* activate remember me */
          if (await stringComparison(['remember me'], t)) {
            await setChecked(true);

            speech.text = await transMany__('I will remember you') || 'I will remember you';
            speechSynth.speak(speech);
          }  


          /* send the form log in */
          if (await stringComparison([cloud88_.a, cloud88_.b, cloud88_.c, cloud88_.d, cloud88_.e, cloud88_.f, cloud88_.g, cloud88_.h, cloud88_.i], t)) {
            await handleClick();  
          }
          
          /* send the form */
          if (await stringComparison([cloud88.a, cloud88.b, cloud88.c, cloud88.d, cloud88.e, cloud88.f, cloud88.g, cloud88.h, cloud88.i, cloud88.j], t)) {
            await handleClick();  
          }

          
          /* beginning down up stop scrollbar */
          if (await stringComparison([cloud67.a, cloud67.b, cloud67.c], t)) { 
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollDownMain(window);
          }

          if (await stringComparison([cloud66.a, cloud66.b, cloud66.c], t)) {  
            await clearInterval(scrollMain.current);
            scrollMain.current = await scrollUpMain(window);
          }

          if (await stringComparison([cloud63.a, cloud63.b, cloud63.c], t)) {  
            await clearInterval(scrollMain.current);
          }
          /* end down up stop scrollbar */


          /* Michael what's your name */
          if (await stringComparison([cloud13.a, cloud13.b, cloud13.c, cloud13.d, cloud13.a2, cloud13.b2, cloud13.c2, cloud13.d2], t)) {
            speech.text = await transMany__(cloud13.e) || cloud13.e;
            await speechSynth.speak(speech); 
          }
          
          /* Michael where do you live */
          if (await stringComparison([cloud.a, cloud.b, cloud.c, cloud.d, cloud.a2, cloud.b2, cloud.c2, cloud.d2, cloud.a3, cloud.b3, cloud.c3, cloud.d3, cloud.d4, cloud.d5], t)) {
            speech.text = await transMany__(cloud.e) || cloud.e;
            await speechSynth.speak(speech); 
          } 
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, showPassword, initializeLogin, handleClick, navigate]);


  const listenContinuously = () => {
    if (isMicrophoneAvailable && SpeechRecognition.browserSupportsSpeechRecognition()) {
      SpeechRecognition.startListening({
        continuous: true,
        language: newLang(),
      });
    }
  }; 
   
  
  useEffect(() => {
    listenContinuously();
  })

  const changeContinuityStop = () => {

    if(listening) {
      SpeechRecognition.stopListening();
    } else {
      listenContinuously();
    } 
  }
  /* End Michael */  




  return (
    <>
     <Card sx={{p:2, boxShadow:10, }}> 
      <Stack spacing={3}> 
        <Box component={Icon} icon='game-icons:robot-golem' onClick={changeContinuityStop} sx={{ width: {xs:30, md:30}, height: {xs:30, md:30}, cursor:'pointer' }} />  

        <FormControl  >
            <InputLabel id="demo-simple-select-autowidth-label-g">Select a language</InputLabel>
            <Select
                labelId="demo-simple-select-autowidth-label-g"
                id="demo-simple-select-autowidth-g"
                value={languageTo}
                onChange={onChangeLanguage}
                autoWidth
                label="Select a language" 
            >   
              {languages.map((item, key) => (
                <MenuItem key={key} value={item.code} className="notranslate">{item.name}</MenuItem>  
              ))} 
            </Select>
        </FormControl>

        <TextField  name="email" value={initializeLogin.email} onChange ={onChangeEmail} label="Email address" />

        <TextField
          name="password"
          label="Password" 
          value={initializeLogin.password} onChange ={onChangePassword} 
          type={showPassword ? 'text' : 'password'} 
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> 
 
        <Typography variant='subtitle2' color='red'>{error}</Typography> 
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" checked={checked} onChange={onChangeRemember} label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Remember me
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
     </Card>
    </>
  );
}
