

// ----------------------------------------------------------------------

const halloweenBackgroundDetails = [
  {
  	id: 1, 
    back: 'halloween.gif'
   },  

  {
  	id: 2, 
    back: 'halloweenb.gif'
   },  
   
  {
  	id: 3, 
    back: 'halloweenc.gif'
   },  
   
  {
  	id: 4, 
    back: 'halloweend.gif'
   },  
   
  {
  	id: 5, 
    back: 'halloweene.gif'
   },  
   
  {
  	id: 6, 
    back: 'halloweenf.gif'
   },  
   
  {
  	id: 7, 
    back: 'halloweeng.gif'
   },  
   
  {
  	id: 8, 
    back: 'halloweenh.gif'
   },  
   
  {
  	id: 9, 
    back: 'halloweeni.gif'
   },  
   
  {
  	id: 10, 
    back: 'halloweenj.gif'
   },  
   
  {
  	id: 11, 
    back: 'halloween1.jpg'
   },  
   
  {
  	id: 12, 
    back: 'halloween2.jpg'
   },  
   
  {
  	id: 13, 
    back: 'halloween3.jpg'
   },  
   
  {
  	id: 14, 
    back: 'halloween4.jpg'
   },  
   
  {
  	id: 15, 
    back: 'halloween5.jpg'
   },  
   
  {
  	id: 16, 
    back: 'halloween6.jpg'
   },  
   
  {
  	id: 17, 
    back: 'halloween7.jpg'
   },  
   
  {
  	id: 18, 
    back: 'halloween8.jpg'
   },  
   
  {
  	id: 19, 
    back: 'halloween9.jpg'
   },  
   
  {
  	id: 20, 
    back: 'halloween10.jpg'
   },   
   
  {
  	id: 21, 
    back: 'halloween11.jpg'
   },   
   
  {
  	id: 22, 
    back: 'halloween12.jpg'
   },   
   
  {
  	id: 23, 
    back: 'halloween13.jpg'
   },   
   
  {
  	id: 24, 
    back: 'halloween14.jpg'
   },   
   
  {
  	id: 25, 
    back: 'halloween15.jpg'
   },   
   
  {
  	id: 26, 
    back: 'halloween16.jpg'
   },   
   
  {
  	id: 27, 
    back: 'halloween17.jpg'
   },   
   
  {
  	id: 28, 
    back: 'halloween18.jpg'
   },   
   
  {
  	id: 29, 
    back: 'halloween19.jpg'
   },   
   
  {
  	id: 30, 
    back: 'halloween20.jpg'
   },   
 
];

export default halloweenBackgroundDetails;
