
import {useState, useEffect} from 'react'; 


export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height
  };
}

 
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return windowDimensions;
}



export async function playCurrentAudioPauseOther() {
    await document.querySelectorAll('audio').forEach(
        async a => {
            await a.addEventListener('play', async () => {
                await pauseOtherAudios(a);
            }, true);
        }
    ); 
};
 

const pauseOtherAudios = async (element) => {
    await document.querySelectorAll('audio').forEach(
        async b => {
            if (b === element) {
            	document.querySelectorAll('video').forEach(c => c && c.pause()); 
            	return; 
            }

            if (!b.paused) {
                b.pause();
            }
        }
    );

}

 
export async function playCurrentVideoPauseOther() {
     await document.querySelectorAll('video').forEach(
        async a => {
            await a.addEventListener('play', async () => {
                await pauseOtherVideos(a);
            }, true);
        }
    ); 
};

const pauseOtherVideos = async (element) => {
    await document.querySelectorAll('video').forEach(
        async b => {
            if (b === element) {
            	document.querySelectorAll('audio').forEach(c => c && c.pause()); 
            	return; 
            }

            if (!b.paused) {
                b.pause();
            }
        }
    );

}



export function isFileAnImage (file) { 
    return file && (file.type).split('/')[0] === 'image'; 
} 

export function isFileAnAudio (file) {
    return file && (file.type).split('/')[0] === 'audio';
}

export function isFileAVideo(file) {
    return file && (file.type).split('/')[0] === 'video';
}


export const fileAudioExecution = async (file, resetAllFile) => {
    const audioVideo = await document.createElement('audio');
    audioVideo.preload = await 'metadata';
    audioVideo.onloadedmetadata = async () => {
        await window.URL.revokeObjectURL(audioVideo.src);
        const duration = await audioVideo.duration;

        if (await (Math.floor(duration) > 120)) {
            resetAllFile();
        }
    }

    audioVideo.src = await URL.createObjectURL(file);
}


export const fileVideoExecution = async (file, resetAllFile) => {
    const audioVideo = await document.createElement('video');
    audioVideo.preload = await 'metadata';
    audioVideo.onloadedmetadata = async () => {
        await window.URL.revokeObjectURL(audioVideo.src);
        const duration = await audioVideo.duration;

        if (await (Math.floor(duration) > 120)) { 
            resetAllFile(); 
        }
    }
    audioVideo.src = await URL.createObjectURL(file);
}


export const fileDuration_ = async (file, resetAllFile) => {
    if (await isFileAnAudio(file)) {
        await fileAudioExecution(file, resetAllFile);
    } else  {
        await fileVideoExecution(file, resetAllFile);
    }
}



