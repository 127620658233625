
const ViewVillageActionTypes = { 
    View_Village_REQUEST: 'View_Village_REQUEST',
    View_Village_SUCCESS:'View_Village_SUCCESS',
    View_Village_FAILURE: 'View_Village_FAILURE', 
}
 
export default ViewVillageActionTypes

  

