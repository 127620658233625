import CreateConferenceRoomActionTypes from './createConferenceRoomTypes'


export const CreateConferenceRoomREQUEST = (item) => ({
    type: CreateConferenceRoomActionTypes.Create_Conference_Room_REQUEST,
    payload: item
}) 


export const CreateConferenceRoomRESET = item => ({
    type: CreateConferenceRoomActionTypes.Create_Conference_Room_RESET,
    payload: item
}) 