// ----------------------------------------------------------------------

const wishCardDetails = [
  {
    id: 1,  
    numberOfLikes: '956200',
    numberOfViews: '482888000',
    numberOfComments: '5',
    message: "Bill Gates never worked alone, he started Microsoft with his friend Paul Allen,  and then worked with a team. At the beginning of Microsoft, he read every code sold.",
    excited: "Is this video extremely astonishing?",
    category: 'Birthday',
    fileURL: 'ai.mp4',
    photoURL: 'inves2.jpeg',
    backgroundPhotoURL: 'inves1.jpeg',
    fullName: 'Ariana Poline',
    receiver: 'Panga Temotia',
    city: 'California',
    country: 'USA',
    continent: 'America'
   },

  {
    id: 2,
    numberOfLikes: '214553352',
    numberOfViews: '1025225544',
    numberOfComments: '10255511222',
    message: "Bill Gates never worked alone, he started Microsoft with his friend Paul Allen,  and then worked with a team. At the beginning of Microsoft, he read every code sold.",
    excited: 'Is this video extremely astonishing?',
    category: 'Valentine',
    fileURL: 'ai.mp4',
    photoURL: 'inves2.jpeg',
    backgroundPhotoURL: 'inves1.jpeg',
    fullName: 'Ariana Poline',
    receiver: 'Panga Temotia',
    city: 'California',
    country: 'USA',
    continent: 'America'
  },

  {
    id: 3,
    numberOfLikes: '95555532000',
    numberOfViews: '20000',
    numberOfComments: '302555441',
    message: "Bill Gates never worked alone, he started Microsoft with his friend Paul Allen,  and then worked with a team. At the beginning of Microsoft, he read every code sold.",
    excited: 'Is this video extremely astonishing?',
    category: 'Wedding',
    fileURL: 'ai.mp4',
    photoURL: 'inves2.jpeg',
    backgroundPhotoURL: 'inves1.jpeg',
    fullName: 'Ariana Poline',
    receiver: 'Panga Temotia',
    city: 'California',
    country: 'USA',
    continent: 'America'
  },
 
];

export default wishCardDetails;
 