

// ----------------------------------------------------------------------

const birthdayBackgroundDetails = [
  {
  	id: 1, 
    back: 'product33.gif'
   },  

  {
  	id: 2, 
    back: 'product33b.gif'
   },  
   
  {
  	id: 3, 
    back: 'product33c.gif'
   },  
   
  {
  	id: 4, 
    back: 'product33d.gif'
   },  
   
  {
  	id: 5, 
    back: 'product33e.gif'
   },  
   
  {
  	id: 6, 
    back: 'product33f.gif'
   },  
   
  {
  	id: 7, 
    back: 'product33g.gif'
   },  
   
  {
  	id: 8, 
    back: 'product33h.gif'
   },  
   
  {
  	id: 9, 
    back: 'product33i.gif'
   },  
   
  {
  	id: 10, 
    back: 'product33j.gif'
   },  
   
  {
  	id: 11, 
    back: 'birthday1.jpg'
   },  
   
  {
  	id: 12, 
    back: 'birthday2.jpg'
   },  
   
  {
  	id: 13, 
    back: 'birthday3.jpg'
   },  
   
  {
  	id: 14, 
    back: 'birthday4.jpg'
   },  
   
  {
  	id: 15, 
    back: 'birthday5.jpg'
   },  
   
  {
  	id: 16, 
    back: 'birthday6.jpg'
   },  
   
  {
  	id: 17, 
    back: 'birthday7.jpg'
   },  
   
  {
  	id: 18, 
    back: 'birthday8.jpg'
   },  
   
  {
  	id: 19, 
    back: 'birthday9.jpg'
   },  
   
  {
  	id: 20, 
    back: 'birthday10.jpg'
   },   
   
  {
  	id: 21, 
    back: 'birthday11.jpg'
   },   
   
  {
  	id: 22, 
    back: 'birthday12.jpg'
   },   
   
  {
  	id: 23, 
    back: 'birthday13.jpg'
   },   
   
  {
  	id: 24, 
    back: 'birthday14.jpg'
   },   
   
  {
  	id: 25, 
    back: 'birthday15.jpg'
   },   
   
  {
  	id: 26, 
    back: 'birthday16.jpg'
   },   
   
  {
  	id: 27, 
    back: 'birthday17.jpg'
   },   
   
  {
  	id: 28, 
    back: 'birthday18.jpg'
   },   
   
  {
  	id: 29, 
    back: 'birthday19.jpg'
   },   
   
  {
  	id: 30, 
    back: 'birthday20.jpg'
   },   
 
];

export default birthdayBackgroundDetails;
