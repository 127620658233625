 

export const cloud99 = {
    a: 'description'
};


export const cloud100 = {
    a: 'select',
    b: 'select a file',
    c: 'select file',
    d: 'selects a file',
    e: 'selects file',
    f: 'selected a file',
    g: 'selected file',
    h: 'selects',
    i: 'selected',
    j: 'selection',
    k: 'collection'
};


export const cloud101 = {
    a: 'save',
    b: 'saves',
    c: 'saved',
    d: 'share',
    e: 'shares',
    f: 'shared',
    g: 'send',
    h: 'sends',
    i: 'sent'
};

