 
import PropTypes from 'prop-types';
// @mui
import { Card, Grid, Typography, Stack, Divider, Avatar  } from '@mui/material';  
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors'; 


import {domainName} from '../../../utils/domain';
// ----------------------------------------------------------------------



export const Watch = ({item, features, specificCompetitionReducerValue}) => { 
  const { 
  	promoterPhoto,
    NewFirstName,
    NewLastName, 
    Description,
    DateOnline,
    TimeFrom,
    TimeTo,   
    usersPhoto,
    NewFullName,
    RoomName,
    setchallengesIdentifier
  } = item;

  const {
  	buttonText, 
  	backColor, 
  	color
  } = features;



 
  const UserSession = 'User Challenge';
  const FirstExtension = 'Elderly-People';
  const SecondExtension = 'Competition';
  const ThirdExtension = 'television';
  const FourExtension = 'Unknown';
  const FifthExtension = 'Unknown';
  let SixthExtension; 
  let promoterOruserOrteacherTk_;
  let promoterOruserOrteacher_;  
  let DivisionName_;
  let token_;
  let type_; 

  if(specificCompetitionReducerValue.user){ 
    SixthExtension = specificCompetitionReducerValue.user.Sixth_Extension; 
    promoterOruserOrteacherTk_ = specificCompetitionReducerValue.user.promoterOruserOrteacherTk;  
    promoterOruserOrteacher_ = specificCompetitionReducerValue.user.promoterOruserOrteacher;  
    DivisionName_ = specificCompetitionReducerValue.user.DivisionName;     
    token_ = specificCompetitionReducerValue.user.token;  
    type_ = specificCompetitionReducerValue.user.type;  
  }

  return (  
    <Grid  item xs={12} sm={6} md={3} > 
      <Typography variant="subtitle" color="white" fontSize="14px">
        Watch this competition
      </Typography>

      <Card sx={{
        boxShadow: 0,
        textAlign: 'left',
        color: {color},   
        backgroundColor: backColor, 
        minHeight:'400px'
        // borderRadius:0,
         
      }}>  

        <Stack spacing={1} sx={{ p: 2 }}>
            <Stack direction="row"> 
                <Avatar sx={{ bgcolor: grey[500], height:{xs:40, sm:40, md:50}, width:{xs:40, sm:40, md:50}, mr:1 }}  alt='' src={`${domainName}/EddyDreams/Home/images/${promoterPhoto}`} />
                <Typography variant="subtitle1" sx={{fontSize:14}}>
                    The promoter is {NewFirstName}{' '}{NewLastName}
                </Typography>  
            </Stack>

            <Divider sx={{backgroundColor:'black', m:1, }} /> 

            <Stack direction="row" alignItems="left" >
                <Stack>
                    <Typography variant="subtitle1" >
                        {Description}
                    </Typography> 

                    <Typography variant="subtitle2" sx={{fontSize:13}}>
                        The competition will be broadcast on the {DateOnline} from {TimeFrom} to {TimeTo}
                    </Typography>   


                    <Divider sx={{backgroundColor:'black', m:1, }} />
               
                    <Stack direction="row" > 
                        <Avatar sx={{ bgcolor: grey[500], height:{xs:40, sm:40, md:50}, width:{xs:40, sm:40, md:50}, mr:1 }}  alt='' src={`${domainName}/EddyDreams/Home/images/${usersPhoto}`} />
                        <Typography variant="subtitle1" sx={{fontSize:14}}>
                            {NewFullName} is the current challenger
                        </Typography>     
                    </Stack> 

                    <Divider sx={{backgroundColor:'black', m:1, }} /> 

                    <LoadingButton  fullWidth size="large" variant="contained" onClick={() => {window.open(`${domainName}/u/CH/WUA/I/${RoomName}&${promoterOruserOrteacherTk_}&${promoterOruserOrteacher_}&${setchallengesIdentifier}&${DivisionName_}&${UserSession}&${FirstExtension}&${SecondExtension}&${ThirdExtension}&${FourExtension}&${FifthExtension}&${SixthExtension}${'&'}${token_}${'&'}${type_}`, '_blank')}}>
                        {buttonText}
                    </LoadingButton>
                </Stack>
            </Stack>
        </Stack>
      </Card>
    </Grid> 
  );
}


Watch.propTypes = {    
  item: PropTypes.object, 
  features: PropTypes.object, 
  specificCompetitionReducerValue: PropTypes.object, 
};
