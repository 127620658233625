

// ----------------------------------------------------------------------

const thanksgivingBackgroundDetails = [
  {
  	id: 1, 
    back: 'thanks.gif'
   },  
 
  {
  	id: 2, 
    back: 'thanksb.gif'
   },  
   
  {
  	id: 3, 
    back: 'thanksc.gif'
   },  
   
  {
  	id: 4, 
    back: 'thanksd.gif'
   },  
   
  {
  	id: 5, 
    back: 'thankse.gif'
   },  
   
  {
  	id: 6, 
    back: 'thanksf.gif'
   },  
   
  {
  	id: 7, 
    back: 'thanksg.gif'
   },  
   
  {
  	id: 8, 
    back: 'thanksh.gif'
   },  
   
  {
  	id: 9, 
    back: 'thanksi.gif'
   },  
   
  {
  	id: 10, 
    back: 'thanksj.gif'
   },  
   
  {
  	id: 11, 
    back: 'thanks1.jpg'
   },  
   
  {
  	id: 12, 
    back: 'thanks2.jpg'
   },  
   
  {
  	id: 13, 
    back: 'thanks3.jpg'
   },  
   
  {
  	id: 14, 
    back: 'thanks4.jpg'
   },  
   
  {
  	id: 15, 
    back: 'thanks5.jpg'
   },  
   
  {
  	id: 16, 
    back: 'thanks6.jpg'
   },  
   
  {
  	id: 17, 
    back: 'thanks7.jpg'
   },  
   
  {
  	id: 18, 
    back: 'thanks8.jpg'
   },  
   
  {
  	id: 19, 
    back: 'thanks9.jpg'
   },  
   
  {
  	id: 20, 
    back: 'thanks10.jpg'
   },   
   
  {
  	id: 21, 
    back: 'thanks11.jpg'
   },   
   
  {
  	id: 22, 
    back: 'thanks12.jpg'
   },   
   
  {
  	id: 23, 
    back: 'thanks13.jpg'
   },   
   
  {
  	id: 24, 
    back: 'thanks14.jpg'
   },   
   
  {
  	id: 25, 
    back: 'thanks15.jpg'
   },   
   
  {
  	id: 26, 
    back: 'thanks16.jpg'
   },   
   
  {
  	id: 27, 
    back: 'thanks17.jpg'
   },   
   
  {
  	id: 28, 
    back: 'thanks18.jpg'
   },   
   
  {
  	id: 29, 
    back: 'thanks19.jpg'
   },   
   
  {
  	id: 30, 
    back: 'thanks20.jpg'
   },   
 
];

export default thanksgivingBackgroundDetails;
