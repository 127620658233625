
import { useState, useRef, useEffect} from 'react';  
import { useNavigate, } from 'react-router-dom';  
import { useCookies  } from 'react-cookie';  
import axios from 'axios'; 
import { useSpeechRecognition } from "react-speech-recognition";
 
// mui
import { Stack, Typography, Grid, TextField, Card, Divider, } from '@mui/material'; 
 
import { LoadingButton } from '@mui/lab';  

// mock  
import weekBackgroundDetails from '../../../_mock/weekBackgroundDetails'; 
import monthBackgroundDetails from '../../../_mock/monthBackgroundDetails'; 
import easterBackgroundDetails from '../../../_mock/easterBackgroundDetails'; 
import halloweenBackgroundDetails from '../../../_mock/halloweenBackgroundDetails'; 
import thanksgivingBackgroundDetails from '../../../_mock/thanksgivingBackgroundDetails'; 
import christmasBackgroundDetails from '../../../_mock/christmasBackgroundDetails';     
import yearBackgroundDetails from '../../../_mock/yearBackgroundDetails';     


import {isFileAnImage, isFileAnAudio, isFileAVideo, fileDuration_} from '../../../utils/otherFunctions';

import {ExciteRadioGroup, BackgroundRadioGroup, VideoSelection, CategoryEvent} from './AllCreatedFiles';
  

import {domainName} from '../../../utils/domain';

 
import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, 
     } from '../../../utils/translateFunctions';
 
 

import { cloud123, cloud124, cloud125, cloud126, cloud127, cloud128, cloud129, cloud130, cloud131, cloud197, 
         findBackgroundTemplate, cloud100, cloud101,
          } from '../../../utils/automateCreateEventFeastCardFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------
 

export default function CreateEventCard() {   
  const navigate = useNavigate();   

  const token = useRef(null); 
  const type = useRef(null);


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 
  

  const [category, setSelectedCard] = useState('New Week');   

  const handleSelectedCard = (category) => {
    setSelectedCard(category);
  }

  const [files, setFiles] = useState([]);  
  const [maxMinute, setMaxMinute] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);   
  const [preview, setPreview] = useState({imageURL:'', audioURL: '', videoURL: ''});
  const [fileTypeCheck, setFileTypeCheck] = useState({imageFile:false, audioFile:false, videoFile:false});
 
  const [excited, setExcited] = useState('Are you left open-mouthed by this video?'); 

  const imageTemplateInitial = category === 'New Week'?'week.gif':
                               category === 'New Month'?'month.gif':
                               category === 'Easter'?'product4.gif':
                               category === 'Halloween'?'halloween.gif':
                               category === 'Thanksgiving'?'thanks.gif':
                               category === 'Christmas'?'product01.gif':
                               category === 'New Year'?'product29.gif':
                               '';

  const [imageTemplate, setImageTemplate] = useState(imageTemplateInitial);
  const [message, setMessage] = useState(''); 

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);

    setMaxMinute('');
  }
  


  const handleExcited = (event) => {
    setExcited(event.target.value); 

    setMaxMinute('');
  }; 

  const handleImageTemplate = (event) => {
    setImageTemplate(event.target.value); 

    setMaxMinute('');
  };  



 

  
  /*  Begin upload */
  let urlLocalComplete; 
  let urlLocalData;
  let urlLink2;
  let urlLink;
  let options;
  let EndPoints;
  let fileCategory;
  const urlLocalRequest = `${domainName}/u/create/event/card`;
  const TFile = [];
  let numberOfFiles = 0; 


  const generateUrlFile = (urlLink_) => {
    urlLink2 = `${urlLink_}${'-data'}`;
    urlLink = `${urlLink_}${'-complete'}`;

    return {
      urlLink2,
      urlLink
    };
  }



  const upload = async () => {  
    if(await (message).trim() === ''){
      setMaxMinute('The message is required');
      return;
    }   
    if(await (excited).trim() === ''){
      setMaxMinute('The excitement is required');
      return;
    }  
    if(await (imageTemplate).trim() === ''){
      setMaxMinute('The excitement is required');
      return;
    }  

    if(await files.length === 0){
      setMaxMinute('Select a file');
      return;
    }
 
 

    TFile[0] = files;
    numberOfFiles = await files.length;

    if(fileTypeCheck.imageFile) {  
      fileCategory = 'images';
    } else 
    if(fileTypeCheck.audioFile) { 
      fileCategory = 'audios';
    } else 
    if (fileTypeCheck.videoFile) { 
      fileCategory = 'videos';
    }
 

    urlLink2 = await generateUrlFile(urlLocalRequest).urlLink2;

    urlLink = await generateUrlFile(urlLocalRequest).urlLink;

    urlLocalComplete = await urlLink;
    urlLocalData = await urlLink2;


    EndPoints = await {
      UPLOAD: urlLocalComplete,
      UPLOAD_DATA: urlLocalData,
      UPLOAD_REQUEST: urlLocalRequest
    }
 

    options = await {
      url: EndPoints.UPLOAD,
      startingByte: 0,
      // chunkSize: 100000,
      chunkSize: 1000000,
      fileId: '',
      arrayId: ''
    };
 

    uploadTrigger();  
  }


  const uploadTrigger = async () => {  
    const optionResult = await uploadCreateDirectory();

    const optionResultArrayIdLength = await optionResult.arrayId.length;
    const filesLength = await numberOfFiles;

    if (await (optionResultArrayIdLength > 0)) {
      for (let z = 0; z < filesLength; z+=1) {
        // const files_ = TFile[0].files[z];
        const files_ = (TFile[0])[z];
        if (files_) {   
          uploadExecution(files_, z);
        }
      }
    }
  }
 
 

  const uploadCreateDirectory = async () => {
    const message_ = await message; 
    const EventCardsType = await category;
    const excited_ = await excited;
    const imageTemplate_ = await imageTemplate;

    const resp = await sendFile(numberOfFiles, fileCategory, EventCardsType, EndPoints.UPLOAD_REQUEST);

    try {
      const jsonResponse = await resp.data; 
      const jsres = await jsonResponse.message;

      if (await (jsres)) {
        await setMaxMinute(jsres);
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        };    
        return options;
      }     

      if (await (resp.status === 400)) {
        await console.log('An error occured in the directory');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      }  


      options = await {
        ...options,
        ...jsonResponse
      };

      const respData = await sendFileData(options.arrayId, message_, EventCardsType, excited_, imageTemplate_, token.current, type.current, fileCategory, EndPoints.UPLOAD_DATA);
       
      if (await (respData.status === 400)) {
        await console.log('Missing file and content');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      } 

      return options; 
    } catch (err) {
      console.error('failed to read file: ', err);

      options = await {
        ...options,
        ...{
          arrayId: []
        }
      };
      return options;
    } 
  }


  const uploadFileChunks = async (file, options, i) => {
    let options_;

    if (await isFileAnImage(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          chunkSize: 1000
        }
      };
    } else
    if (await isFileAnAudio(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          chunkSize: 10000
        }
      };
    } else {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        }
      };
    }
    await sendFileChunks(file, options_.startingByte, options_.chunkSize, options_, i);

  }


  const sendFileChunks = async (file, startingByte, endingByte_, options_, i) => {
    const fileSize = await file.size;
    let endingByte = await endingByte_;

    const formData = await new FormData();
    const xhr = await new XMLHttpRequest();

    if (fileSize < endingByte) {
      endingByte = await fileSize;
    }
 

    if (endingByte < fileSize) {
      xhr.onreadystatechange = async () => {
        if (await (xhr.readyState === XMLHttpRequest.DONE)) {
          const percentageUploaded = await (endingByte / fileSize) * 100;  

          await setMaxMinute('Upload in progress');
          await setProgressPercentage(parseInt(percentageUploaded, 10)); 

          await sendFileChunks(file, startingByte + options_.chunkSize, startingByte + (options_.chunkSize * 2), options_, i);
        }
      }
    } else { 
      await setProgressPercentage(0);

      await setMessage('');

      await setPreview({
        preview, 
        ...{
          imageURL: '', 
          audioURL: '', 
          videoURL: ''
        }
      });

      await setFiles([]);
      await setMaxMinute(''); 
    }


    const chunk = await file.slice(startingByte, endingByte);

    await formData.append('chunk', chunk, file.name);
    await formData.append('startingByte', startingByte);
    await formData.append('endingByte', endingByte);
    await formData.append('fileId', options_.fileId);
    await formData.append('X-fileCategory', fileCategory);

    await xhr.open('POST', options_.url, true);
    await xhr.setRequestHeader('X-File-Id', options_.fileId);
    await xhr.setRequestHeader('X-File-Size', fileSize);
    await xhr.setRequestHeader('X-startingByte', startingByte);
    await xhr.setRequestHeader('X-endingByte', endingByte);
    await xhr.setRequestHeader('X-fileCategory', fileCategory);

    await xhr.send(formData);
  }
 

  const sendFile = (numberOfFiles_, fileCategory_, EventCardsType_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        numberOfFiles: numberOfFiles_, 
        fileCategory: fileCategory_,
        EventCardsType: EventCardsType_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }
   
 

  const sendFileData = (fileData, message_, EventCardsType_, excited_, imageTemplate_, token_, type_, fileCategory_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        fileData_: fileData,
        Message: message_,
        Excited: excited_,
        ImagePhoto: imageTemplate_,
        EventCardsType: EventCardsType_,
        token: token_,
        type: type_, 
        fileCategory: fileCategory_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }



  const uploadExecution = async (files_, i) => {
    let newFile;

    if (await isFileAnImage(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.jpg');
    } else 
    if (await isFileAnAudio(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.wav');
    } else {
      newFile = await renameFile(files_, 'planetryvilage.webm');
    }

    if (!newFile) {
      await console.log("You must first select the file to upload it");
    } else {
      try {
        await uploadFileChunks(newFile, options, i);
      } catch (err) {
        console.error('failed to read file: ', err);
      }
    }
  }


  function renameFile (originalFile_, newName_) {
    return new File([originalFile_], newName_, {
      type: originalFile_.type,
      lastModified: originalFile_.lastModified,
    });
  }
  /*  End upload */



  const resetAllFile = () => {
  	setFileTypeCheck({
  		fileTypeCheck, 
  		...{
  			imageFile:false, 
  			audioFile:false, 
  			videoFile:false
  		}
  	});

  	setPreview({
  		preview, 
  		...{
  			imageURL: '', 
  			audioURL: '', 
  			videoURL: ''
  		}
  	});

  	setFiles([]);
  	setMaxMinute('Max 2 minutes');
  }


 

  // check if a file is an image or an audio or a video
  const fileChecker = async (file) => {
  	if (await isFileAnImage(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:true, 
  				audioFile:false, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: URL.createObjectURL(file), 
  				audioURL: '', 
  				videoURL: ''
  			}
  		});
  	} else 
  	if(await isFileAnAudio(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:true, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: URL.createObjectURL(file), 
  				videoURL: ''
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else
  	if(await isFileAVideo(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:false, 
  				videoFile:true
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: '', 
  				videoURL: URL.createObjectURL(file)
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else{
  		// the file is nor an image nor an audio nor a video.  Reset everything
  		resetAllFile();
  	}
  }
   
  const handleFilesChange = (e) => {
   	if (e.target.files) {
      setFiles(e.target.files);   
      fileChecker(e.target.files[0]); 

      setMaxMinute('');
    }   
  }; 






  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
   
  const inputElement = useRef();   
  const triggerUploadClick = useRef(); 

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {    
        let message; let image;

        let  tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  
          const removeFirstElementFromString_ = await removeFirstElementFromString(t);   
  
          
          // select create a new week card
          if (await stringComparison(['create a new week card', 'create new week card',   'creates a new week card', 'creates new week card',  'created a new week card', 'created new week card'], t)) {
            await setSelectedCard('New Week');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }
          
          // select create a new month card
          if (await stringComparison(['create a new month card', 'create new month card',   'creates a new month card', 'creates new month card',   'created a new month card', 'created new month card'], t)) {
            await setSelectedCard('New Month');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }
          
          // select create an easter card
          if (await stringComparison(['create an easter card', 'create easter card',   'creates an easter card', 'creates easter card',    'created an easter card', 'created easter card'], t)) {
            await setSelectedCard('Easter');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }
          
          // select create a halloween card
          if (await stringComparison(['create a halloween card', 'create halloween card',   'creates a halloween card', 'creates halloween card',   'created a halloween card', 'created halloween card'], t)) {
            await setSelectedCard('Halloween');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }
          
          // select create a Thanksgiving card
          if (await stringComparison(['create a thanksgiving card', 'create thanksgiving card',   'creates a thanksgiving card', 'creates thanksgiving card',  'created a thanksgiving card', 'created thanksgiving card'], t)) {
            await setSelectedCard('Thanksgiving');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }
          
          // select create a Christmas card
          if (await stringComparison(['create a christmas card', 'create christmas card',    'creates a christmas card', 'creates christmas card',   'created a christmas card', 'created christmas card'], t)) {
            await setSelectedCard('Christmas');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }
          
          // select create a New Year card
          if (await stringComparison(['create a new year card', 'create new year card',   'creates a new year card', 'creates new year card',  'created a new year card', 'created new year card'], t)) {
            await setSelectedCard('New Year');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }
          
          // description
          if (await stringComparison([cloud123.a], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            message = await tm;

            await setMessage(message);

            speech.text = await transMany__('The description has been updated') || 'The description has been updated';
            speechSynth.speak(speech); 
          } 
           
          
          // one
          if (await stringComparison([cloud124.a, cloud124.b, cloud124.c], t)) {
            const excitement = await "Are you left open-mouthed by this video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // two
          if (await stringComparison([cloud125.a, cloud125.b, cloud125.c], t)) { 
            const excitement = await "Are you excessively astonished by this video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }

          // three
          if (await stringComparison([cloud126.a, cloud126.b, cloud126.c], t)) {
            const excitement = await "Are you excited to watch my video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // four 
          if (await stringComparison([cloud127.a, cloud127.b, cloud127.c], t)) {
            const excitement = await "Are you perplexed by my video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // five
          if (await stringComparison([cloud128.a, cloud128.b], t)) {
            const excitement = await "Are you stupefied by my video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }

          // six
          if (await stringComparison([cloud129.a, cloud129.b], t)) {
            const excitement = await "Are you flabbergasted by this video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // seven
          if (await stringComparison([cloud130.a, cloud130.b, cloud130.c], t)) {
            const excitement = await "Is this video extremely amazing?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // eight
          if (await stringComparison([cloud131.a, cloud131.b], t)) {
            const excitement = await "Is this video extremely astonishing?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }

          // select a template
          if(await (category === 'New Week')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(weekBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          }

          // new month
          if(await (category === 'New Month')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(monthBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          }


          // easter
          if(await (category === 'Easter')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(easterBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          }

          // halloween
          if(await (category === 'Halloween')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(halloweenBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          }


          // thanksgiving
          if(await (category === 'Thanksgiving')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(thanksgivingBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          }

          // christmas
          if(await (category === 'Christmas')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(christmasBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          }

          // new year
          if(await (category === 'New Year')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(yearBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          }




          // select a file
          if (await stringComparison([cloud100.a, cloud100.b, cloud100.c, cloud100.d, cloud100.e, cloud100.f, cloud100.g, cloud100.h, cloud100.i, cloud100.j, cloud100.k], t)) {
            await inputElement.current.click();

            speech.text = await transMany__("The dialog box is open") || "The dialog box is open";
            speechSynth.speak(speech); 
          }
          

          // save the event card
          if (await stringComparison([cloud101.a, cloud101.b, cloud101.c, cloud101.d, cloud101.e, cloud101.f], t)) {
            await triggerUploadClick.current.click();

            speech.text = await transMany__("The save has been triggered") || "The save has been triggered";
            speechSynth.speak(speech); 
          }
  
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, category]);

 
  /* End Michael */  




 
 
  return (
    <>   
      <CategoryEvent handleSelectedCard={handleSelectedCard} />

      <Grid  item xs={12} sm={12} md={8} lg={6}>
        <Card sx={{borderRadius:{xs:0, sm:0, md:'10px'}}}>
          <Stack spacing={1} sx={{p:1, textAlign:'center', justifyContent:'center'}}>
            {/* <Typography variant="string">
              Create a 
              {(category === 'New Week'&& ' New Week') ||  
              (category === 'New Month'&& ' New Month') ||  
              (category === 'Easter'&& 'n Easter') ||  
              (category === 'Halloween'&& ' Halloween') || 
              (category === 'Thanksgiving'&& ' Thanksgiving') || 
              (category === 'Christmas'&& ' Christmas') || 
              (category === 'New Year'&& ' New Year')} card
            </Typography> */}

            <Typography variant="string" sx={{display:category === 'New Week'?'block':'none'}}>
              Create a New Week card
            </Typography>

            <Typography variant="string" sx={{display:category === 'New Month'?'block':'none'}}>
              Create a New Month card
            </Typography>

            <Typography variant="string" sx={{display:category === 'Easter'?'block':'none'}}>
              Create a Easter card
            </Typography>
 
            <Typography variant="string" sx={{display:category === 'Halloween'?'block':'none'}}>
              Create a Halloween card
            </Typography>

            <Typography variant="string" sx={{display:category === 'Thanksgiving'?'block':'none'}}>
              Create a Thanksgiving card
            </Typography>

            <Typography variant="string" sx={{display:category === 'Christmas'?'block':'none'}}>
              Create a Christmas card
            </Typography>

            <Typography variant="string" sx={{display:category === 'New Year'?'block':'none'}}>
              Create a New Year card
            </Typography>

            <Divider/>  

                <TextField name="Message" value={message} onChange={handleChangeMessage} label="Message" multiline minRows={3}/>
 
                <ExciteRadioGroup handleExcited={handleExcited} excited={excited}/>
                
                {category === 'New Week' && (
                  <BackgroundRadioGroup backgroundDetails={weekBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )}

                {category === 'New Month' && (
                  <BackgroundRadioGroup backgroundDetails={monthBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )}

                {category === 'Easter' && (
                  <BackgroundRadioGroup backgroundDetails={easterBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )}

                {category === 'Halloween' && (
                  <BackgroundRadioGroup backgroundDetails={halloweenBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )}

                {category === 'Thanksgiving' && (
                  <BackgroundRadioGroup backgroundDetails={thanksgivingBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )}

                {category === 'Christmas' && (
                  <BackgroundRadioGroup backgroundDetails={christmasBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )}

                {category === 'New Year' && (
                  <BackgroundRadioGroup backgroundDetails={yearBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )} 

                <VideoSelection inputElement={inputElement} handleFilesChange={handleFilesChange} progressPercentage={progressPercentage} files={files} fileTypeCheck={fileTypeCheck} preview={preview} />
                
                <Typography variant='subtitle2' color='red'>{maxMinute}</Typography>

                <LoadingButton fullWidth size="large"  type="button"  title='Share' variant="contained" ref={triggerUploadClick} onClick={upload}> 
                  Create
                </LoadingButton> 
          </Stack>
        </Card> 
      </Grid>
    </>
  );
}
