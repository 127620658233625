 
import { useRef, useEffect, } from 'react'; 
import { useSelector, useDispatch  } from 'react-redux'; 
import { useNavigate,  } from 'react-router-dom'; 
import { Helmet } from 'react-helmet-async';  
import { useSpeechRecognition } from "react-speech-recognition";

// mui
import { Container, Grid, Card, Box, InputAdornment, IconButton, Autocomplete, Popper, TextField, } from '@mui/material';  

import { styled } from '@mui/material/styles';


// icons
import { Icon } from '@iconify/react'; 


import { LoadingButton } from '@mui/lab';

import BubblePreloader from 'react-bubble-preloader';


// components 
import Iconify from '../../components/iconify'; 

 
// sections 
import {  
  WishCardFullScreen,
} from '../../sections/@users/homeUser';
 

// mock  
// import wishCardDetails from '../../_mock/wishCardDetails'; 


import {ViewOneFeastCardVideoRESET,} from '../../redux/viewOneFeastCardVideo/viewOneFeastCardVideoActions'; 
import {ReportAbuseRESET,} from '../../redux/reportAbuse/reportAbuseActions'; 



// util
import { removeCommaQuestionMarkFullStopLowerCase, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, cloud144
     } from '../../utils/translateFunctions';
 
// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});


export default function WishesSentLoadPage() {  
  const navigate = useNavigate();    
  const dispatch = useDispatch(); 
  
  const { 
    wish
  } = { 
    wish: 'WISH'
  };

  const {
    wishOption
  } ={
    wishOption : 'Wishes Sent'
  };

  // const [searchCategory, setSearchCategory] = useState(''); 
  const inputValue = useRef(''); 

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i-=1) {
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array; 
  };  



  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);

  // let wishesSentValue = useMemo(() => [] , []);   
  const wishesSentValue = useRef([]);
  const isloading = useRef(true);
  

  const i = useRef(0);

  if(allDataOnceReducerValue.user && i.current === 0){
    isloading.current = allDataOnceReducerValue.loading;
    wishesSentValue.current = allDataOnceReducerValue.user.wishesSent; 
    wishesSentValue.current = shuffle(wishesSentValue.current).slice(0, 4);     
    
    i.current +=1      
  } 
  
  const handleReloadPage = async () => {
    // window.location.reload();  
    navigate('/wishes/sent', { replace: true }); 
  }
  

  /* const filteredCategory = useMemo(() => wishesSentValue.filter(
    (file) =>  
       (
        (`${file.NewFullName}${' '}${file.categoryDivision}${' cards '}${file.DateCreated}${' '}${file.FeastCardsId}`)
        .toLowerCase()
        .includes(searchCategory.toLowerCase())    
      )  
  ) , [wishesSentValue, searchCategory]); */

  /* const handleSearchName = () => { 
    setSearchCategory(inputValue.current.value); 
    inputValue.current.value = ''; 
  }  */
  
  // const filteredCategory = useMemo(() => wishesSentValue, [wishesSentValue]);
  const filteredCategory = wishesSentValue.current;

  const handleSearchName = async () => {  
    let txtValue;

    const showItemElement = await document.getElementsByClassName('showItem');
    const input = await inputValue.current;

    const filter = await input.value.toUpperCase();


    for (let i = 0; i < showItemElement.length; i+=1) {
      const a1 = showItemElement[i];
      txtValue = a1.textContent || a1.innerText;

      const filterValue = txtValue.toUpperCase().indexOf(filter);
      if ((filterValue > -1)) {
        showItemElement[i].style.display = "";
      } else {
        showItemElement[i].style.display = "none";
      }
    }
  }

  const handleSearchNameSpecific = async (song) => {  
    let txtValue;
    inputValue.current.value= await song;

    const showItemElement = await document.getElementsByClassName('showItem'); 

    const filter = await song.toUpperCase();


    for (let i = 0; i < showItemElement.length; i+=1) {
      const a1 = showItemElement[i];
      txtValue = a1.textContent || a1.innerText;

      const filterValue = txtValue.toUpperCase().indexOf(filter);
      if ((filterValue > -1)) {
        showItemElement[i].style.display = "";
      } else {
        showItemElement[i].style.display = "none";
      }
    }
  }

  useEffect(() => { 
    dispatch(ViewOneFeastCardVideoRESET(null));
    dispatch(ReportAbuseRESET(null)); 
  }, [dispatch]);





  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
       

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {  
        let searchSomething;  

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  
          // const removeFirstElementFromString_ = await removeFirstElementFromString(t); 
          
          // search a specific song 
          if (await stringComparison([cloud144.a, cloud144.b, cloud144.c, cloud144.d, cloud144.e, cloud144.f, cloud144.g, cloud144.h, cloud144.i, cloud144.j, cloud144.k, cloud144.l], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            searchSomething = await tm; 
            // await setSearchCategory(searchSomething);
            await handleSearchNameSpecific(searchSomething);
          } 
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, ]);

 
  /* End Michael */  

  


  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Wishes Sent </title>
      </Helmet>
      
      
      <Container maxWidth="xl" sx={{p:0, m:0}}> 
        
          <Grid container spacing={0.3} sx={{pt:{xs:0.2, sm:0.2, md:0}}} textAlign='center' justifyContent='center'> 
            <Grid  item xs={12} sm={12} md={6} >  
              <Card sx={{borderRadius:{xs:0, sm:0, md:1}, mb:{xs:0, sm:0, md:2}}}>
                <Box component={Icon} icon='ic:twotone-call-made' sx={{ width: 25, height: 17, mr:1}} /> 
                Wishes Sent
              </Card>
            </Grid>


            
            <Grid  item xs={12} sm={12} md={12} >
              <Grid container spacing={2} sx={{pb:1, }} justifyContent='center' textAlign='center'>  
                <Grid  item xs={12} sm={12} md={6} sx={{mb: {xs:0, sm:0, md:1}, mt: {xs:0.2, sm:0.2, md:0}}}>  
                  <Autocomplete  
                    autoHighlight 
                    popupIcon={null}
                    PopperComponent={StyledPopper}
                    options={wishesSentValue.current}
                    getOptionLabel={(post) => `${post.NewFullName}${' '}${post.categoryDivision}${' cards '}${post.DateCreated}${' '}${post.FeastCardsId}`} 
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField  variant="standard"
                      inputRef={inputValue}
                      {...params}
                      placeholder="Search..."
                      onKeyDown={(event) => {
                        if (event.key === 'Enter'){
                          handleSearchName()
                        } 
                      }}

                      onChange={handleSearchName}
                      
                      InputProps={{
                        style: { color: 'white' },
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton color="primary" onClick={handleSearchName}>
                              <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>


            {/*  wishes sent */}
            {filteredCategory.map((item, key) => (
              <Grid  item xs={12} sm={12} md={12} key={key}>  
                <WishCardFullScreen wishItem={item} eventOrWish={wish} wishOption={wishOption}/> 
              </Grid>
            ))}


            {/**/}
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}  alignItems="center" justifyContent="center"> 
                 
                {isloading.current && 
                  <BubblePreloader
                  bubble={{ width: '2rem', height: '2rem' }}
                  animation={{ speed: 2 }}
                  style={{margin:'auto', padding:0, textAlign:'center', position: 'relative'}}
                  colors={['blue', 'aqua', '#f3f3f3']}
                 />  
                } 
 
                 
                {!isloading.current && 
                <LoadingButton sx={{marginTop:5, }}  size="small" title='button load more magazines' variant="contained"  onClick={()=>{handleReloadPage()}} >
                  Load more wish cards
                </LoadingButton> 
                }
              </Grid> 
            </Grid> 
            {/**/}

          </Grid>  
        
      </Container>  
    </>
  );
}
