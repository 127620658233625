import { useState, useEffect, useCallback } from 'react'; 
import { useSelector  } from 'react-redux';
import axios from 'axios';
import { useSpeechRecognition } from "react-speech-recognition";

// @mui 
import { IconButton, Typography, Divider, Grid, TextField, Stack, InputAdornment, Card } from '@mui/material'; 


import { LoadingButton } from '@mui/lab'; 

// components
import Iconify from '../../../components/iconify';
 
import {domainName} from '../../../utils/domain';



import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, cloud88,
       cloud101, restructurePassword,} from '../../../utils/translateFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------
 

export default function ChangePassword() {   
  const [showPassword, setShowPassword] = useState(false);   
  const [showNewPassword, setShowNewPassword] = useState(false);   
  const [error, setError] = useState("");
  const [initializeRegister, setInitializeRegister] = useState({
    old_Password: '',
    new_Password: '' 
  }); 



  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);
 
  let tokenValue = '';
  let typeValue = '';
  
   
  if(allDataOnceReducerValue.user){  
    tokenValue = allDataOnceReducerValue.user.token;  
    typeValue = allDataOnceReducerValue.user.type;    
  }



   /*  Begin upload */ 
  const urlLocalRequest = `${domainName}/u/change/password`;

  const upload = useCallback(async () => {  

    if(await (initializeRegister.old_Password).trim() === ''){
      setError('The old password is required');
      return;
    } 

    if(await (initializeRegister.new_Password).trim() === ''){
      setError('The new password is required');
      return;
    } 
    
    const token_ = await tokenValue;
    const type_ = await typeValue; 

    const oldPassword = await initializeRegister.old_Password;    
    const newPassword = await initializeRegister.new_Password;  
 
    const resp = await sendFileData(token_, type_, oldPassword, newPassword, urlLocalRequest);
 
    const jsonResponse = await resp.data; 
    const jsres = await jsonResponse.message; 

    await setError(jsres); 
  },[initializeRegister, tokenValue, typeValue, urlLocalRequest]);
 

  const sendFileData = (token_, type_, oldPassword_, newPassword_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {   
        token: token_,
        type: type_, 
        old_Password: oldPassword_,
        new_Password: newPassword_, 
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  } 
  /*  End upload */ 

  const onChangeOldPassword = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        old_Password: e.target.value,
        new_Password: initializeRegister.new_Password, 
      }
    });

    setError('');
  }

  const onChangeNewPassword = (e) => {
    setInitializeRegister({
      initializeRegister, 
      ...{ 
        old_Password: initializeRegister.old_Password,
        new_Password: e.target.value, 
      }
    });

    setError('');
  }



 
  
  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
   

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {    

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));  
        const PlanetryVilageLastnamePlanetryVilage = await localStorage.getItem("PlanetryVilageLastnamePlanetryVilage");    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_); 


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          const t = await removeFirstElementFromString__;
          const firstElementOfString_ = await firstElementOfString(t);
          const removeFirstElementFromString_ = await removeFirstElementFromString(t);

          const firstElementOfString2_ = await firstElementOfString(removeFirstElementFromString_);
          // const removeFirstElementFromString2_ = await removeFirstElementFromString(removeFirstElementFromString_);
          
          
          // set old password
          if (await stringComparison(['password'], firstElementOfString_)) { 
            const a2 = await transMany__(`${'4 '}${firstElementOfString_}`) || `${'4 '}${firstElementOfString_}`;
            const a = await a2.replace("4 ", "");

            if (await (a.match(/(\w+)/g).length === 1)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
            }
 
            const oldPassword_ = await tm;

            await setInitializeRegister({
              initializeRegister, 
              ...{
                old_Password: restructurePassword(oldPassword_), 
                new_Password:   initializeRegister.new_Password, 
              }
            }); 
 
            await setError('');

            speech.text = await transMany__('The old password has been updated') || 'The old password has been updated';
            speechSynth.speak(speech);
          }  

          // set old password -- another way
          if (await stringComparison(['old password'], `${firstElementOfString_}${' '}${firstElementOfString2_}`)) { 

            const a2 = await transMany__(`${'4 '}${firstElementOfString_}${' '}${firstElementOfString2_}`) || `${'4 '}${firstElementOfString_}${' '}${firstElementOfString2_}`;
            const a = await a2.replace("4 ", "");  

            if (await (a.match(/(\w+)/g).length === 1)) {
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
              }else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
              }
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
              }else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
              }
              
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
              } else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
              }
              
            } else
            if (await (a.match(/(\w+)/g).length === 4)) { 
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))))));
              } else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)))));
              }
              
            } else
            if (await (a.match(/(\w+)/g).length === 5)) { 
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)))))));
              } else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))))));
              }
              
            }

            const oldPassword_ = await tm;

            await setInitializeRegister({
              initializeRegister, 
              ...{
                old_Password: restructurePassword(oldPassword_), 
                new_Password:   initializeRegister.new_Password, 
              }
            }); 
 
            await setError('');

            speech.text = await transMany__('The old password has been updated') || 'The old password has been updated';
            speechSynth.speak(speech);
          } 
          
          // set new password
          if (await stringComparison(['new password'], `${firstElementOfString_}${' '}${firstElementOfString2_}`)) { 

            const a2 = await transMany__(`${'4 '}${firstElementOfString_}${' '}${firstElementOfString2_}`) || `${'4 '}${firstElementOfString_}${' '}${firstElementOfString2_}`;
            const a = await a2.replace("4 ", "");

            if (await (a.match(/(\w+)/g).length === 1)) {
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
              }else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
              }
            } else
            if (await (a.match(/(\w+)/g).length === 2)) {
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
              }else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
              }
              
            } else
            if (await (a.match(/(\w+)/g).length === 3)) {
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))));
              } else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)));
              }
              
            } else
            if (await (a.match(/(\w+)/g).length === 4)) { 
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))))));
              } else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)))));
              }
              
            } else
            if (await (a.match(/(\w+)/g).length === 5)) { 
              if (PlanetryVilageLastnamePlanetryVilage === 'en') {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm)))))));
              } else {
                tm = await removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(removeFirstElementFromString(tm))))));
              }
              
            }


            const newPassword_ = await tm;

            await setInitializeRegister({
              initializeRegister, 
              ...{
                old_Password: initializeRegister.old_Password, 
                new_Password: restructurePassword(newPassword_), 
              }
            }); 
 
            await setError('');

            speech.text = await transMany__('The new password has been updated') || 'The new password has been updated';
            speechSynth.speak(speech);
          } 
           
           
          
          /* show password */
          if (await stringComparison([cloud101.a1, cloud101.a2, cloud101.a3, cloud101.a4, cloud101.a5, cloud101.a6, cloud101.a7, cloud101.a8, cloud101.a9, cloud101.a10, cloud101.a11, cloud101.a12, cloud101.a13, cloud101.a14, cloud101.a15, cloud101.a16, cloud101.a17, cloud101.a18], t)) {
            await setShowNewPassword(!showNewPassword);
            await setShowPassword(!showPassword);

            speech.text = await transMany__('The visibility of the password has changed') || 'The visibility of the password has changed';
            speechSynth.speak(speech);
          } 
           
          
          /* send the form */
          if (await stringComparison([cloud88.a, cloud88.b, cloud88.c, cloud88.d, cloud88.e, cloud88.f, cloud88.g, cloud88.h, cloud88.i, cloud88.j], t)) {
            await upload();  
          }
 
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, showNewPassword, showPassword, initializeRegister, upload]);

  /* end Michael */



  return (
    <>    
      <Grid  item xs={12} sm={12} md={8} lg={6}>
        <Card >
          <Stack spacing={1} sx={{p:1, textAlign:'center', justifyContent:'center'}}>
            <Typography variant="string">
              Update Password
            </Typography>

            <Divider/> 
              <TextField
                value={initializeRegister.old_Password} onChange={onChangeOldPassword}
                name="old_Password"
                label="Old Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                    </InputAdornment>
                ),
                }}
              />

              <TextField
                value={initializeRegister.new_Password} onChange={onChangeNewPassword}
                name="new_Password"
                label="New Password"
                type={showNewPassword ? 'text' : 'password'}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                            <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                    </InputAdornment>
                ),
                }}
              /> 

              <Typography variant='subtitle2' color='red'>{error}</Typography>

              <LoadingButton fullWidth size="large"  type="button"  title='Share' variant="contained" onClick={upload} > 
                Update
              </LoadingButton>
          </Stack>
        </Card>    
       
      </Grid>  
    </>
  );
}

