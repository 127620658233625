 
import PropTypes from 'prop-types';
import { TimelineDot, TimelineOppositeContent, TimelineContent, 
	   TimelineConnector, TimelineSeparator, TimelineItem, Timeline} from '@mui/lab';
 
import {Typography, Avatar, } from '@mui/material';
import { grey } from '@mui/material/colors';



import {domainName} from '../../../utils/domain';
// ----------------------------------------------------------------------

export const TimelinePeople = ({people}) => { 
  const alignI = "right";

  return (
    <Timeline position="alternate" sx={{color:'white', }}>  

    {people.map((item, key) => (
      <TimelineItem key={key} >
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align={alignI}
          variant="body2"  
        >
          {item.NewFullName}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={{minHeight:20}} />
          <TimelineDot>
            <Avatar sx={{ bgcolor: grey[500], height:60, width:60, }}  alt='' src={`${domainName}/EddyDreams/Home/images/${item.Photo}`} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography  component="span" sx={{fontSize:{xs:12, sm:12, md:15}, }}>
            {item.City}{','}
          </Typography>
          <Typography sx={{fontSize:{xs:12, sm:12, md:15}, }}>
            {item.Country}{','} 
          </Typography>
          <Typography sx={{fontSize:{xs:12, sm:12, md:15}, }}>
            {item.Continent}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    ))}

    </Timeline>
  );
}


TimelinePeople.propTypes = {    
  people: PropTypes.arrayOf(PropTypes.object),     
};