import CreateTvChannelActionTypes from './createTvChannelTypes'


export const CreateTvChannelREQUEST = (item) => ({
    type: CreateTvChannelActionTypes.Create_Tv_Channel_REQUEST,
    payload: item
}) 


export const CreateTvChannelRESET = item => ({
    type: CreateTvChannelActionTypes.Create_Tv_Channel_RESET,
    payload: item
}) 