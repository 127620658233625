 
import axios from 'axios';  

export const languages = [{ 
        code: '',
        name: 'select a language',
        codeLang: ''
    },  
    {
        code: 'en',
        name: 'English',
        codeLang: 'en-US'
    },
    {
        code: 'abk',
        name: 'Abkhazian',
        codeLang: ''
    },
    {
        code: 'aa',
        name: 'Afar',
        codeLang: ''
    },
    {
        code: 'af',
        name: 'Afrikaans',
        codeLang: 'af-ZA'
    },
    {
        code: 'ak',
        name: 'Akan',
        codeLang: ''
    },
    {
        code: 'sq',
        name: 'Albanian',
        codeLang: 'sq-AL'
    },
    {
        code: 'am',
        name: 'Amharic',
        codeLang: 'am-ET'
    },
    {
        code: 'ar',
        name: 'Arabic',
        codeLang: 'ar-EG'
    },
    {
        code: 'an',
        name: 'Aragonese',
        codeLang: ''
    },
    {
        code: 'hy',
        name: 'Armenian',
        codeLang: 'hy-AM'
    },
    {
        code: 'as',
        name: 'Assamese',
        codeLang: ''
    },
    {
        code: 'av',
        name: 'Avaric',
        codeLang: ''
    },
    {
        code: 'ae',
        name: 'Avestan',
        codeLang: ''
    },
    {
        code: 'ay',
        name: 'Aymara',
        codeLang: ''
    },
    {
        code: 'az',
        name: 'Azerbaijani',
        codeLang: 'az-AZ'
    },
    {
        code: 'bm',
        name: 'Bambara',
        codeLang: ''
    },
    {
        code: 'ba',
        name: 'Bashkir',
        codeLang: ''
    },
    {
        code: 'eu',
        name: 'Basque',
        codeLang: 'eu-ES'
    },
    {
        code: 'be',
        name: 'Belarusian',
        codeLang: ''
    },
    {
        code: 'bn',
        name: 'Bengali',
        codeLang: 'bn-BD'
    },
    {
        code: 'bh',
        name: 'Bihari languages',
        codeLang: ''
    },
    {
        code: 'bi',
        name: 'Bislama',
        codeLang: ''
    },
    {
        code: 'bs',
        name: 'Bosnian',
        codeLang: 'bs-BA'
    },
    {
        code: 'br',
        name: 'Breton',
        codeLang: ''
    },
    {
        code: 'bg',
        name: 'Bulgarian',
        codeLang: 'bg-BG'
    },
    {
        code: 'my',
        name: 'Burmese',
        codeLang: 'my-MM'
    },
    {
        code: 'ca',
        name: 'Catalan, Valencian',
        codeLang: 'ca-ES'
    },
    {
        code: 'km',
        name: 'Central Khmer',
        codeLang: 'km-KH'
    },
    {
        code: 'ch',
        name: 'Chamorro',
        codeLang: ''
    },
    {
        code: 'ce',
        name: 'Chechen',
        codeLang: ''
    },
    {
        code: 'ny',
        name: 'Chichewa, Chewa, Nyanja',
        codeLang: ''
    },
    {
        code: 'zh-CN',
        name: 'Chinese',
        codeLang: 'zh-cmn-Hans-CN'
    },
    {
        code: 'cu',
        name: 'Church Slavonic',
        codeLang: ''
    },
    {
        code: 'cu',
        name: 'Old Bulgarian',
        codeLang: ''
    },
    {
        code: 'cu',
        name: 'Old Church Slavonic',
        codeLang: ''
    },
    {
        code: 'cv',
        name: 'Chuvash',
        codeLang: ''
    },
    {
        code: 'kw',
        name: 'Cornish',
        codeLang: ''
    },
    {
        code: 'co',
        name: 'Corsican',
        codeLang: ''
    },
    {
        code: 'cr',
        name: 'Cree',
        codeLang: ''
    },
    {
        code: 'hr',
        name: 'Croatian',
        codeLang: 'hr-HR'
    },
    {
        code: 'cs',
        name: 'Czech',
        codeLang: 'cs-CZ'
    },
    {
        code: 'da',
        name: 'Danish',
        codeLang: 'da-DK'
    },
    {
        code: 'dv',
        name: 'Divehi',
        codeLang: ''
    },
    {
        code: 'dv',
        name: 'Dhivehi',
        codeLang: ''
    },
    {
        code: 'dv',
        name: 'Maldivian',
        codeLang: ''
    },
    {
        code: 'nl',
        name: 'Dutch',
        codeLang: 'nl-NL'
    },
    {
        code: 'dz',
        name: 'Dzongkha',
        codeLang: ''
    },
    {
        code: 'eo',
        name: 'Esperanto',
        codeLang: ''
    },
    {
        code: 'et',
        name: 'Estonian',
        codeLang: 'et-EE'
    },
    {
        code: 'ee',
        name: 'Ewe',
        codeLang: ''
    },
    {
        code: 'fo',
        name: 'Faroese',
        codeLang: ''
    },
    {
        code: 'fj',
        name: 'Fijian',
        codeLang: ''
    },
    {
        code: 'fil',
        name: 'Tagalog',
        codeLang: 'fil-PH'
    },
    {
        code: 'fi',
        name: 'Finnish',
        codeLang: 'fi-FI'
    },
    {
        code: 'fr',
        name: 'French',
        codeLang: 'fr-FR'
    },
    {
        code: 'ff',
        name: 'Fulah',
        codeLang: ''
    },
    {
        code: 'gd',
        name: 'Gaelic, Scottish Gaelic',
        codeLang: ''
    },
    {
        code: 'gl',
        name: 'Galician',
        codeLang: 'gl-ES'
    },
    {
        code: 'lg',
        name: 'Ganda',
        codeLang: ''
    },
    {
        code: 'ka',
        name: 'Georgian',
        codeLang: 'ka-GE'
    },
    {
        code: 'de',
        name: 'German',
        codeLang: 'de-DE'
    },
    {
        code: 'ki',
        name: 'Gikuyu, Kikuyu',
        codeLang: ''
    },
    {
        code: 'el',
        name: 'Greek (Modern)',
        codeLang: 'el-GR'
    },
    {
        code: 'kl',
        name: 'Greenlandic, Kalaallisut',
        codeLang: ''
    },
    {
        code: 'gn',
        name: 'Guarani',
        codeLang: ''
    },
    {
        code: 'gu',
        name: 'Gujarati',
        codeLang: 'gu-IN'
    },
    {
        code: 'ht',
        name: 'Haitian, Haitian Creole',
        codeLang: ''
    },
    {
        code: 'ha',
        name: 'Hausa',
        codeLang: ''
    },
    {
        code: 'iw',
        name: 'Hebrew',
        codeLang: 'iw-IL'
    },
    {
        code: 'hz',
        name: 'Herero',
        codeLang: ''
    },
    {
        code: 'hi',
        name: 'Hindi',
        codeLang: ' hi-IN'
    },
    {
        code: 'ho',
        name: 'Hiri Motu',
        codeLang: ''
    },
    {
        code: 'hu',
        name: 'Hungarian',
        codeLang: 'hu-HU'
    },
    {
        code: 'is',
        name: 'Icelandic',
        codeLang: 'is-IS'
    },
    {
        code: 'io',
        name: 'Ido',
        codeLang: ''
    },
    {
        code: 'ig',
        name: 'Igbo',
        codeLang: ''
    },
    {
        code: 'id',
        name: 'Indonesian',
        codeLang: 'id-ID'
    },
    {
        code: 'ia',
        name: 'Interlingua',
        codeLang: ''
    },
    {
        code: 'ie',
        name: 'Interlingue',
        codeLang: ''
    },
    {
        code: 'iu',
        name: 'Inuktitut',
        codeLang: ''
    },
    {
        code: 'ik',
        name: 'Inupiaq',
        codeLang: ''
    },
    {
        code: 'ga',
        name: 'Irish',
        codeLang: ''
    },
    {
        code: 'it',
        name: 'Italian',
        codeLang: 'it-IT'
    },
    {
        code: 'ja',
        name: 'Japanese',
        codeLang: 'ja-JP'
    },
    {
        code: 'jv',
        name: 'Javanese',
        codeLang: 'jv-ID'
    },
    {
        code: 'kn',
        name: 'Kannada',
        codeLang: 'kn-IN'
    },
    {
        code: 'kr',
        name: 'Kanuri',
        codeLang: ''
    },
    {
        code: 'ks',
        name: 'Kashmiri',
        codeLang: ''
    },
    {
        code: 'kk',
        name: 'Kazakh',
        codeLang: 'kk-KZ'
    },
    {
        code: 'km',
        name: 'Khmer (Cambodia)',
        codeLang: 'km-KH'
    },
    {
        code: 'rw',
        name: 'Kinyarwanda',
        codeLang: ''
    },
    {
        code: 'kv',
        name: 'Komi',
        codeLang: ''
    },
    {
        code: 'kg',
        name: 'Kongo',
        codeLang: ''
    },
    {
        code: 'ko',
        name: 'Korean',
        codeLang: 'ko-KR'
    },
    {
        code: 'kj',
        name: 'Kwanyama, Kuanyama',
        codeLang: ''
    },
    {
        code: 'ku',
        name: 'Kurdish',
        codeLang: ''
    },
    {
        code: 'ky',
        name: 'Kyrgyz',
        codeLang: ''
    },
    {
        code: 'lo',
        name: 'Lao',
        codeLang: 'lo-LA'
    },
    {
        code: 'la',
        name: 'Latin',
        codeLang: ''
    },
    {
        code: 'lv',
        name: 'Latvian',
        codeLang: 'lv-LV'
    },
    {
        code: 'lb',
        name: 'Letzeburgesch',
        codeLang: ''
    },
    {
        code: 'lb',
        name: 'Luxembourgish',
        codeLang: ''
    },
    {
        code: 'li',
        name: 'Limburgish',
        codeLang: ''
    },
    {
        code: 'li',
        name: 'Limburgan',
        codeLang: ''
    },
    {
        code: 'li',
        name: 'Limburger',
        codeLang: ''
    },
    {
        code: 'ln',
        name: 'Lingala',
        codeLang: ''
    },
    {
        code: 'lt',
        name: 'Lithuanian',
        codeLang: 'lt-LT'
    },
    {
        code: 'lu',
        name: 'Luba-Katanga',
        codeLang: ''
    },
    {
        code: 'mk',
        name: 'Macedonian',
        codeLang: 'mk-MK'
    },
    {
        code: 'mg',
        name: 'Malagasy',
        codeLang: ''
    },
    {
        code: 'ms',
        name: 'Malay',
        codeLang: 'ms-MY'
    },
    {
        code: 'ml',
        name: 'Malayalam',
        codeLang: 'ml-IN'
    },
    {
        code: 'mt',
        name: 'Maltese',
        codeLang: ''
    },
    {
        code: 'gv',
        name: 'Manx',
        codeLang: ''
    },
    {
        code: 'mi',
        name: 'Maori',
        codeLang: ''
    },
    {
        code: 'mr',
        name: 'Marathi',
        codeLang: ''
    },
    {
        code: 'mh',
        name: 'Marshallese',
        codeLang: ''
    },
    {
        code: 'ro',
        name: 'Moldovan, Moldavian',
        codeLang: ''
    },
    {
        code: 'ro',
        name: 'Romanian',
        codeLang: 'ro-RO'
    },
    {
        code: 'mn',
        name: 'Mongolian',
        codeLang: 'mn-MN'
    },
    {
        code: 'na',
        name: 'Nauru',
        codeLang: ''
    },
    {
        code: 'nv',
        name: 'Navajo, Navaho',
        codeLang: ''
    },
    {
        code: 'nd',
        name: 'Northern Ndebele',
        codeLang: ''
    },
    {
        code: 'ng',
        name: 'Ndonga',
        codeLang: ''
    },
    {
        code: 'ne',
        name: 'Nepali',
        codeLang: 'ne-NP'
    },
    {
        code: 'se',
        name: 'Northern Sami',
        codeLang: ''
    },
    {
        code: 'no',
        name: 'Norwegian',
        codeLang: 'no-NO'
    },
    {
        code: 'nb',
        name: 'Norwegian Bokmål',
        codeLang: 'no-NO'
    },
    {
        code: 'nn',
        name: 'Norwegian Nynorsk',
        codeLang: 'no-NO'
    },
    {
        code: 'ii',
        name: 'Nuosu, Sichuan Yi',
        codeLang: ''
    },
    {
        code: 'oc',
        name: 'Occitan (post 1500)',
        codeLang: ''
    },
    {
        code: 'oj',
        name: 'Ojibwa',
        codeLang: ''
    },
    {
        code: 'or',
        name: 'Oriya',
        codeLang: ''
    },
    {
        code: 'om',
        name: 'Oromo',
        codeLang: ''
    },
    {
        code: 'os',
        name: 'Ossetian, Ossetic',
        codeLang: ''
    },
    {
        code: 'pi',
        name: 'Pali',
        codeLang: ''
    },
    {
        code: 'pa',
        name: 'Panjabi, Punjabi',
        codeLang: 'pa-Guru-IN'
    },
    {
        code: 'ps',
        name: 'Pashto, Pushto',
        codeLang: ''
    },
    {
        code: 'fa',
        name: 'Persian',
        codeLang: 'fa-IR'
    },
    {
        code: 'pl',
        name: 'Polish',
        codeLang: 'pl-PL'
    },
    {
        code: 'pt',
        name: 'Portuguese',
        codeLang: 'pt-PT'
    },
    {
        code: 'qu',
        name: 'Quechua',
        codeLang: ''
    },
    {
        code: 'rm',
        name: 'Romansh',
        codeLang: ''
    },
    {
        code: 'rn',
        name: 'Rundi',
        codeLang: ''
    },
    {
        code: 'ru',
        name: 'Russian',
        codeLang: 'ru-RU'
    },
    {
        code: 'sm',
        name: 'Samoan',
        codeLang: ''
    },
    {
        code: 'sg',
        name: 'Sango',
        codeLang: ''
    },
    {
        code: 'sa',
        name: 'Sanskrit',
        codeLang: ''
    },
    {
        code: 'sc',
        name: 'Sardinian',
        codeLang: ''
    },
    {
        code: 'sr',
        name: 'Serbian',
        codeLang: 'sr-RS'
    },
    {
        code: 'sn',
        name: 'Shona',
        codeLang: ''
    },
    {
        code: 'sd',
        name: 'Sindhi',
        codeLang: ''
    },
    {
        code: 'si',
        name: 'Sinhala, Sinhalese',
        codeLang: 'si-LK'
    },
    {
        code: 'sk',
        name: 'Slovak',
        codeLang: 'sk-SK'
    },
    {
        code: 'sl',
        name: 'Slovenian',
        codeLang: 'sl-SI'
    },
    {
        code: 'so',
        name: 'Somali',
        codeLang: ''
    },
    {
        code: 'st',
        name: 'Sotho, Southern',
        codeLang: ''
    },
    {
        code: 'nr',
        name: 'South Ndebele',
        codeLang: ''
    },
    {
        code: 'es',
        name: 'Spanish',
        codeLang: 'es-ES'
    },
    {
        code: 'su',
        name: 'Sundanese',
        codeLang: 'su-ID'
    },
    {
        code: 'sw',
        name: 'Swahili',
        codeLang: 'sw-TZ'
    },
    {
        code: 'ss',
        name: 'Swati',
        codeLang: ''
    },
    {
        code: 'sv',
        name: 'Swedish',
        codeLang: 'sv-SE'
    },
    {
        code: 'tl',
        name: 'Tagalog',
        codeLang: ''
    },
    {
        code: 'ty',
        name: 'Tahitian',
        codeLang: ''
    },
    {
        code: 'tg',
        name: 'Tajik',
        codeLang: ''
    },
    {
        code: 'ta',
        name: 'Tamil',
        codeLang: 'ta-SG'
    },
    {
        code: 'tt',
        name: 'Tatar',
        codeLang: ''
    },
    {
        code: 'te',
        name: 'Telugu',
        codeLang: 'te-IN'
    },
    {
        code: 'th',
        name: 'Thai',
        codeLang: 'th-TH'
    },
    {
        code: 'bo',
        name: 'Tibetan',
        codeLang: ''
    },
    {
        code: 'ti',
        name: 'Tigrinya',
        codeLang: ''
    },
    {
        code: 'to',
        name: 'Tonga (Tonga Islands)',
        codeLang: ''
    },
    {
        code: 'ts',
        name: 'Tsonga',
        codeLang: ''
    },
    {
        code: 'tn',
        name: 'Tswana',
        codeLang: ''
    },
    {
        code: 'tr',
        name: 'Turkish',
        codeLang: 'tr-TR'
    },
    {
        code: 'tk',
        name: 'Turkmen',
        codeLang: ''
    },
    {
        code: 'tw',
        name: 'Twi',
        codeLang: ''
    },
    {
        code: 'ug',
        name: 'Uighur, Uyghur',
        codeLang: ''
    },
    {
        code: 'uk',
        name: 'Ukrainian',
        codeLang: 'uk-UA'
    },
    {
        code: 'ur',
        name: 'Urdu',
        codeLang: 'ur-PK'
    },
    {
        code: 'uz',
        name: 'Uzbek',
        codeLang: 'uz-UZ'
    },
    {
        code: 've',
        name: 'Venda',
        codeLang: ''
    },
    {
        code: 'vi',
        name: 'Vietnamese',
        codeLang: 'vi-VN'
    },
    {
        code: 'vo',
        name: 'Volap_k',
        codeLang: ''
    },
    {
        code: 'wa',
        name: 'Walloon',
        codeLang: ''
    },
    {
        code: 'cy',
        name: 'Welsh',
        codeLang: ''
    },
    {
        code: 'fy',
        name: 'Western Frisian',
        codeLang: ''
    },
    {
        code: 'wo',
        name: 'Wolof',
        codeLang: ''
    },
    {
        code: 'xh',
        name: 'Xhosa',
        codeLang: ''
    },
    {
        code: 'yi',
        name: 'Yiddish',
        codeLang: ''
    },
    {
        code: 'yo',
        name: 'Yoruba',
        codeLang: ''
    },
    {
        code: 'za',
        name: 'Zhuang, Chuang',
        codeLang: ''
    },
    {
        code: 'zu',
        name: 'Zulu',
        codeLang: 'zu-ZA'
    }
];


export const languagesOfLibreTranslate = [{ 
        code: '',
        name: 'select your language',
        codeLang: ''
    }, 
    {
        code: 'en',
        name: 'English',
        codeLang: 'en-US'
    },
    {
        code: 'ar',
        name: 'Arabic',
        codeLang: 'ar-EG'
    },
    {
        code: 'az',
        name: 'Azerbaijani',
        codeLang: 'az-AZ'
    },
    {
        code: 'ca',
        name: 'Catalan, Valencian',
        codeLang: 'ca-ES'
    },
    {
        code: 'zh-CN',
        name: 'Chinese',
        codeLang: 'zh-cmn-Hans-CN'
    },
    {
        code: 'cs',
        name: 'Czech',
        codeLang: 'cs-CZ'
    },
    {
        code: 'da',
        name: 'Danish',
        codeLang: 'da-DK'
    },
    {
        code: 'nl',
        name: 'Dutch',
        codeLang: 'nl-NL'
    },
    {
        code: 'eo',
        name: 'Esperanto',
        codeLang: ''
    },
    {
        code: 'fi',
        name: 'Finnish',
        codeLang: 'fi-FI'
    },
    {
        code: 'fr',
        name: 'French',
        codeLang: 'fr-FR'
    },
    {
        code: 'de',
        name: 'German',
        codeLang: 'de-DE'
    },
    {
        code: 'el',
        name: 'Greek (Modern)',
        codeLang: 'el-GR'
    },
    {
        code: 'iw',
        name: 'Hebrew',
        codeLang: 'iw-IL'
    },
    {
        code: 'hi',
        name: 'Hindi',
        codeLang: ' hi-IN'
    },
    {
        code: 'hu',
        name: 'Hungarian',
        codeLang: 'hu-HU'
    },
    {
        code: 'id',
        name: 'Indonesian',
        codeLang: 'id-ID'
    },
    {
        code: 'ga',
        name: 'Irish',
        codeLang: ''
    },
    {
        code: 'it',
        name: 'Italian',
        codeLang: 'it-IT'
    },
    {
        code: 'ja',
        name: 'Japanese',
        codeLang: 'ja-JP'
    },
    {
        code: 'ko',
        name: 'Korean',
        codeLang: 'ko-KR'
    },
    {
        code: 'fa',
        name: 'Persian',
        codeLang: 'fa-IR'
    },
    {
        code: 'pl',
        name: 'Polish',
        codeLang: 'pl-PL'
    },
    {
        code: 'pt',
        name: 'Portuguese',
        codeLang: 'pt-PT'
    },
    {
        code: 'ru',
        name: 'Russian',
        codeLang: 'ru-RU'
    },
    {
        code: 'sk',
        name: 'Slovak',
        codeLang: 'sk-SK'
    },
    {
        code: 'es',
        name: 'Spanish',
        codeLang: 'es-ES'
    },
    {
        code: 'sv',
        name: 'Swedish',
        codeLang: 'sv-SE'
    },
    {
        code: 'tr',
        name: 'Turkish',
        codeLang: 'tr-TR'
    },
    {
        code: 'uk',
        name: 'Ukrainian',
        codeLang: 'uk-UA'
    },
];


export const translateText = async (text, targetLanguage) => {
    const url = `https://libretranslate.de/translate`; 

    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: { 
        q: text,
        // uncomment and add initial state
        source: 'auto',
        target: targetLanguage,
      } 
    }; 

    const response = await axios(url, config);
    return response.data.translatedText;  
}
 


export const transMany = async (a) => {    
    let PlanetryVilageLastnamePlanetryVilage;
    PlanetryVilageLastnamePlanetryVilage = await localStorage.getItem("PlanetryVilageLastnamePlanetryVilage"); 

    if(await (PlanetryVilageLastnamePlanetryVilage === null || PlanetryVilageLastnamePlanetryVilage === undefined)){
        PlanetryVilageLastnamePlanetryVilage = 'en';
    }

    if (PlanetryVilageLastnamePlanetryVilage === 'en') {
        return a;
    }

    const transText = await translateText(a, 'en'); 
    return transText;
} 


 
export const transMany__ = async (a) => {  
    let PlanetryVilageLastnamePlanetryVilage;
    PlanetryVilageLastnamePlanetryVilage = await localStorage.getItem("PlanetryVilageLastnamePlanetryVilage"); 

    if(await (PlanetryVilageLastnamePlanetryVilage === null || PlanetryVilageLastnamePlanetryVilage === undefined)){
        PlanetryVilageLastnamePlanetryVilage = 'en';
    }

    if(await (PlanetryVilageLastnamePlanetryVilage === 'zh-CN')){
        PlanetryVilageLastnamePlanetryVilage = 'zh';
    }

    const langDetails = await languagesOfLibreTranslate.filter(obj => obj.code === PlanetryVilageLastnamePlanetryVilage);
    
    /* check if the code language belong to libreTranslate */
    /* if that is not the case, the default code language is en */
    if (await (langDetails.length === 0)) PlanetryVilageLastnamePlanetryVilage = 'en';
    
    if (PlanetryVilageLastnamePlanetryVilage === 'en') {
        return a;
    }

    const transText = await translateText(a, PlanetryVilageLastnamePlanetryVilage);

    return transText;
}


export const getLanguageList = async () => { 
    const url = await `https://libretranslate.de/languages`; 

    const config = await {
      method: 'GET', 
      data: {  
      } 
    }; 

    const response = await axios(url, config);

    return response.data;
}




const findLangDetails = (code) => { 
    const langDetails = languages.filter(obj => obj.code === code);
    return langDetails;
}
 

/* get the language of the virtual assistant */
export const newLang =  () => { 
    const code =  localStorage.getItem("PlanetryVilageLastnamePlanetryVilage");  
    const langDetails =  findLangDetails(code); 
    
    let codeLang_;
    if(langDetails.length === 1) {
        codeLang_ = langDetails[0].codeLang;
    } else{
        codeLang_ = 'en-US'
    }

    return codeLang_;
}


/* first set the language code of the user */
export const setAutoTranslate = async (code) => {
    localStorage.setItem("PlanetryVilageLastnamePlanetryVilage", code);
    window.location.reload(false);
}

 

export const removeCommaQuestionMarkFullStopLowerCase = (str) => 
    (((str.split(',').join('')).split('?').join('')).split('.').join('')).toLowerCase();





// active function
export  const firstElementOfString = (string) => { 
    const array = string.split(" ");
    const firstElement = array[0];

    return firstElement;
}


export const removeFirstElementFromString = (string) => { 
    const strArray = string.split(" ");
    strArray.shift();

    const newString = strArray.join(" ");
    return newString;
}


export const cloud = { 
    a: 'where do you live',
    b: 'where did you live',
    c: 'where do you live?',
    d: 'where did you live?',
    e: 'I live in a supernatural world that has been created by Mbakop Ganso',

    a2: 'where do you leave',
    b2: 'where did you leave',
    c2: 'where do you leave?',
    d2: 'where did you leave?',

    a3: 'where do you lieve',
    b3: 'where did you lieve',
    c3: 'where do you lieve?',
    d3: 'where did you lieve?', 
    d4: 'where you live?',  
    d5: 'where you live', 
};

export const cloud13 = {
    a: 'what is your name',
    b: "what's your name",
    c: 'what is your name?',
    d: "what's your name?",
    e: 'my name is michael',

    a2: 'what is your nickname',
    b2: "what's your nickname",
    c2: 'what is your nickname?',
    d2: "what's your nickname?"
};

export const cloud63 = {
    a: 'stop',
    b: 'stopped',
    c: 'stops'
};

export const cloud66 = {
    a: 'up',
    b: 'up',
    c: 'up.',
    d: 'call up',
    e: 'scroll up'
};

export const cloud67 = {
    a: 'down',
    b: 'down',
    c: 'down.',
    d: 'call down',
    e: 'scroll down'
};


export const cloud82 = {
    a: 'connect',
    b: 'connect to',
    c: 'music',
    d: 'process command connect',
    a2: 'tune',
    a3: 'tune in',

    a4: 'tunes',
    a5: 'tunes in',

    a6: 'tuned',
    a7: 'tuned in',


    a8: 'connects',
    a9: 'connects to',

    a10: 'connected',
    a11: 'connected to',


    a12: 'sign',
    a13: 'signs',
    a14: 'signed',
    a15: 'log',
    a16: 'logs',
    a17: 'logged',

    a18: 'sign in',
    a19: 'signs in',
    a20: 'signed in',
    a21: 'log in',
    a22: 'logs in',
    a23: 'logged in', 

    a24: 'connecttwo',
};


export const cloud83 = {
    a: 'cortex',
    b: 'kotex',
    c: 'michael',
    d: 'god',

    e: 'goad',
    f: 'goal',
    g: 'goas',
    h: 'goat',
    i: 'gobo',
    j: 'gobs',
    k: 'goby',
    l: 'gods',
    m: 'goer',
    n: 'goes',
    o: 'gogo',
    p: 'gold',
    q: 'gone',
    r: 'gong',
    s: 'good',
    t: 'goof',
    u: 'gook',
    v: 'goon',
    w: 'goop',
    a2: 'goos',
    a3: 'gore',
    a4: 'gorp',
    a5: 'gory',
    a6: 'gosh',
    a7: 'gout',
    a8: 'gowd',
    a9: 'gowk',
    a10: 'gown',
    a11: 'goys',
    a12: 'mikel'
};

export const cloud84 = {
    a: 'search',
    b:'seek',
    c: 'fetch',
    d: 'searches',
    e:'seeks',
    f: 'fetches',
    g: 'searched',
    h:'seeked',
    i: 'fetched',

    j: 'identify',
    k: 'identifies',
    l: 'identified'
};

export const cloud85 = {
    a: 'login', 
    b: 'log in',
    c: 'lock in',
    d: 'look in',

    e: 'sign in',
    f: 'connection',
    g: 'connect',
    h: 'connects',
    i: 'connected'
};

export const cloud86 = {
    a: 'register', 
    b: 'registration',
    c: 'registring',
    d: 'registered',

    e: 'registers',
    f: 'sign up',
    g: 'signs up',
    h: 'signed up',

    i: 'enrol',
    j: 'enrols',
    k: 'enroled',

    l: 'enroll',
    m: 'enrolls',
    n: 'enrolled',
};
 
export const cloud87 = {
    a: 'forgotten password', 
    b: 'i have forgotten my password',
    c: 'reset password',
    d: 'password reset',
    e: 'i forgot my password',
    f: 'forgot password',
    g: 'resets password'
};

export const cloud88 = {
    a: 'submit', 
    b: 'send',
    c: 'save',
    d: 'forward',

    e: 'sends',
    f: 'sent',
    g: 'saves',
    h: 'saved',
    i: 'submits',
    j: 'submitted'
};

export const cloud88_ = {
    a: 'login', 
    b: 'log in',
    c: 'lock in',
    d: 'look in',

    e: 'sign in',
    f: 'connection',
    g: 'connect',
    h: 'connects',
    i: 'connected'
};


export const cloud89 = {
    a: 'continent' 
}; 

export const cloud89_ = {
    a: 'name',
    b: 'named',
    c: 'names'
}; 

export const cloud89__ = {
    a: 'email'
};

export const cloud89___ = {
    a: 'password'
}; 

export const cloud90 = {
    a: 'country' 
}; 

export const cloud91 = {
    a: 'city' 
}; 

export const cloud92 = {
    a: 'one',
    b: '1',
    c: 'huh',
    d: 'eh',
    e: 'new generation'
}

export const cloud93 = {
    a: 'two',
    b: 'to',
    c: '2',
    d: 'to',
    e: 'of',
    f: 'student'
}

export const cloud94 = {
    a: 'three',
    b: '3',
    c: 'tree',
    d: 'free',
    e: 'pupil',
    f: 'people'
}

export const cloud95 = {
    a: 'for',
    b: '4',
    c: 'four',
    d: 'forth',
    e: 'investor'
}

export const cloud96 = {
    a: 'five',
    b: '5',
    c: 'organisation owner'
}

export const cloud97 = {
    a: 'six',
    b: '6',
    c: 'shop owner'
}

export const cloud98 = {
    a: 'seven',
    b: '7',
    c: 'seth',
    d: 'elderly people'
}

export const cloud99 = {
    a: 'eight',
    b: '8',
    c: 'patient'
}

export const cloud100 = {
    a: 'mother tongue',
    b: 'first language',
    c: 'native tongue',
    d: 'native language'
}


export const cloud101 = {
    a1: 'show password',
    a2: 'shows password',
    a3: 'showed password',

    a4: 'display password',
    a5: 'displays password',
    a6: 'displayed password',

    a7: 'exhibit password',
    a8: 'exhibits password',
    a9: 'exhibited password',

    a10: 'expose password',
    a11: 'exposes password',
    a12: 'exposed password',

    a13: 'present password',
    a14: 'presents password',
    a15: 'presented password',

    a16: 'watch password',
    a17: 'watches password',
    a18: 'watched password',
}

export const stringComparison = (string, element) => {
    if ([...string].includes(element)) {
        return true;
    } 

    return false
}



export const capitalizeFirstLetter = (string) => `${string[0].toUpperCase()}${string.slice(1)}`;


export const findLangDetailsCode = (languageName) => { 
    const langDetails = languages.filter(obj => obj.name === languageName);
    return langDetails[0].code;
}

export const nativeAct = async (tongue_, speech, speechSynth) => {  
    if(await !tongue_) return; 

    const tongue = await capitalizeFirstLetter(tongue_); 
    const code = await findLangDetailsCode(tongue);

    speech.text = await transMany__("The mother tongue has been updated") || "The mother tongue has been updated";   
    await speechSynth.speak(speech);

    setAutoTranslate(code);
   
}



export const getElementBeforeYahoo = (string_) => {
    let index = string_.lastIndexOf('.'); 

    const dot = transMany__('dot') || 'dot';

    if(index < 0){
        string_ = string_.replace(dot, '.');
        index = string_.lastIndexOf('.'); 
    }   

    const  before = string_.slice(0, index);
    const tabBefore = before.split(' ');
    const element = tabBefore[tabBefore.length-2];
    const elementPos = tabBefore.length-2

    return {element, elementPos};
}


export const restructureEmail = (string_) => { 
    const {elementPos} = getElementBeforeYahoo(string_);  
    const string1 = string_.split(' ');
    string1[elementPos] = '@';

    const string = string1.join(' ');

    const a = string.split(' at').join('@');
    const b = a.split(' ').join('');
    const c = b.split('dot').join('.'); 


    return c;
}

export const restructurePassword = (string) => { 
    const b = string.split(' ').join('');
    const c = b.split('dot').join('.');  

    return c;
}


export const fisrtletterofEachStringToUpperCase = (string) => {
   const splitString = string.toLowerCase().split(' ');
   for (let i = 0; i < splitString.length; i +=1) { 
       splitString[i] = `${splitString[i].charAt(0).toUpperCase()}${splitString[i].substring(1)}`;     
   }

   return splitString.join(' '); 
}


export const scrollDownMain = async (button__) => {
    const button = await button__;
    const a = await setInterval(async () => {
        if (typeof(button) !== 'undefined' && button !== null) {
            await button.scrollBy(0, 1);
        }
    }, 15);
    return a
}

export const scrollUpMain = async (button__) => {
    const button = await button__;
    const a = await setInterval(async () => {
        if (typeof(button) !== 'undefined' && button !== null) {
            await button.scrollBy(0, -1);
        }
    }, 15);
    return a
}


export const stringInclude = (string, element) => {
    if (string.includes(element)) {
        return true;
    }  

    return false;
}


export const sleep = (milliseconds) => new Promise(resolve => setTimeout(resolve, milliseconds));



export const replaceWordTrack = (string_, a) => {
    let newString;

    if(a === 'played'){
        newString = string_.replace("played", "play");
    }else
    if(a === 'plays'){
        newString = string_.replace("plays", "play");
    }else
    if(a === 'is playing'){
        newString = string_.replace("is playing", "play");
    }


    if(a === 'song'){
        newString = string_.replace("song", "track");
    }else 
    if(a === 'audio'){
        newString = string_.replace("audio", "track");
    }else
    if(a === 'video'){
        newString = string_.replace("video", "track");
    }else
    if(a === 'music'){
        newString = string_.replace("music", "track");
    }else
    if(a === 'number'){
        newString = string_.replace("number", "track");
    }else
    if(a === 'segment'){
        newString = string_.replace("segment", "track");
    }else
    if(a === 'park'){
        newString = string_.replace("park", "track");
    }else
    if(a === 'top'){
        newString = string_.replace("top", "track");
    }

    if(a === 'the'){
        newString = string_.replace("the ", "");
    } 

    if(a === ' of '){
        newString = string_.replace("of ", "");
    } 

    if(a === 'of'){
        newString = string_.replace("of", "2");
    }



    if(a === 'eh'){
        newString = string_.replace("eh", "1");
    }else

    if(a === 'huh'){
        newString = string_.replace("huh", "1");
    }

    return newString;
}


export const replaceWordImage = (string_, a) => {
    let newString;

    if(a === 'display'){
        newString = string_.replace("display", "show");
    }else
    if(a === 'displayed'){
        newString = string_.replace("displayed", "show");
    }else
    if(a === 'displays'){
        newString = string_.replace("displays", "show");
    }else
    if(a === 'is displaying'){
        newString = string_.replace("is displaying", "show");
    }else


    if(a === 'showed'){
        newString = string_.replace("showed", "show");
    }else
    if(a === 'shows'){
        newString = string_.replace("shows", "show");
    }else
    if(a === 'is showing'){
        newString = string_.replace("is showing", "show");
    }else


    if(a === 'exhibited'){
        newString = string_.replace("exhibited", "show");
    }else
    if(a === 'exhibits'){
        newString = string_.replace("exhibits", "show");
    }else
    if(a === 'is exhibiting'){
        newString = string_.replace("is exhibiting", "show");
    }else
    if(a === 'exhibit'){
        newString = string_.replace("exhibit", "show");
    }else


    if(a === 'presented'){
        newString = string_.replace("presented", "show");
    }else
    if(a === 'presents'){
        newString = string_.replace("presents", "show");
    }else
    if(a === 'is presenting'){
        newString = string_.replace("is presenting", "show");
    }else
    if(a === 'present'){
        newString = string_.replace("present", "show");
    }else


    if(a === 'exposed'){
        newString = string_.replace("exposed", "show");
    }else
    if(a === 'exposes'){
        newString = string_.replace("exposes", "show");
    }else
    if(a === 'is exposing'){
        newString = string_.replace("is exposing", "show");
    }else
    if(a === 'expose'){
        newString = string_.replace("expose", "show");
    }else


    if(a === 'unveiled'){
        newString = string_.replace("unveiled", "show");
    }else
    if(a === 'unveils'){
        newString = string_.replace("unveils", "show");
    }else
    if(a === 'is unveiling'){
        newString = string_.replace("is unveiling", "show");
    }else
    if(a === 'unveil'){
        newString = string_.replace("unveil", "show");
    }else


    if(a === 'watched'){
        newString = string_.replace("watched", "show");
    }else
    if(a === 'watches'){
        newString = string_.replace("watches", "show");
    }else
    if(a === 'is watching'){
        newString = string_.replace("is watching", "show");
    }else
    if(a === 'watch'){
        newString = string_.replace("watch", "show");
    }else


    if(a === 'poster'){
        newString = string_.replace("poster", "show");
    }else
    if(a === 'posted'){
        newString = string_.replace("posted", "show");
    }else
    if(a === 'posts'){
        newString = string_.replace("posts", "show");
    }else
    if(a === 'is posting'){
        newString = string_.replace("is posting", "show");
    }else
    if(a === 'post'){
        newString = string_.replace("post", "show");
    }


    if(a === 'picture'){
        newString = string_.replace("picture", "picture");
    }else 
    if(a === 'pictures'){
        newString = string_.replace("pictures", "picture");
    }else
    if(a === 'pic'){
        newString = string_.replace("pic", "picture");
    }else 
    if(a === 'photograph'){
        newString = string_.replace("photograph", "picture");
    }else

    if(a === 'likeness'){
        newString = string_.replace("likeness", "picture");
    }else
    if(a === 'portrait'){
        newString = string_.replace("portrait", "picture");
    }else
    if(a === 'image'){
        newString = string_.replace("image", "picture");
    }else
    if(a === 'photos'){
        newString = string_.replace("photos", "picture");
    }else
    if(a === 'photographs'){
        newString = string_.replace("photographs", "picture");
    }else
    if(a === 'photo'){
        newString = string_.replace("photo", "picture");
    }else
    if(a === 'number'){
        newString = string_.replace("number", "picture");
    }else
    if(a === 'segment'){
        newString = string_.replace("segment", "picture");
    }


    if(a === 'the'){
        newString = string_.replace("the ", "");
    } 

    if(a === ' of '){
        newString = string_.replace("of ", "");
    } 

    if(a === 'of'){
        newString = string_.replace("of", "2");
    }


    
    if(a === 'eh'){
        newString = string_.replace("eh", "1");
    }else
    
    if(a === 'huh'){
        newString = string_.replace("huh", "1");
    }

    return newString;
}



export const playFullScreen = async (a) => {
    /* const {
        RequestFullscreen__,
        mozRequestFullScreen__,
        webkitRequestFullscreen__,
        msRequestFullscreen__
    } = await {
        RequestFullscreen__: a.RequestFullscreen,
        mozRequestFullScreen__: a.mozRequestFullScreen,
        webkitRequestFullscreen__: a.webkitRequestFullscreen,
        msRequestFullscreen__: a.msRequestFullscreen
    };

    if (RequestFullscreen__) {
        await a.RequestFullscreen();
        await a.play();
 
        await a.click(); 
    } else if (mozRequestFullScreen__) {
        a.play(); 

        await a.click(); 
    } else if (webkitRequestFullscreen__) {
        await a.webkitRequestFullscreen();
        await a.play();
 
        await a.click(); 
    } else if (msRequestFullscreen__) {
        a.msRequestFullscreen();
        a.play();
 
        await a.click(); 
    } */

    await a.click(); 

}


export const cloud29a = { 
    a: 'audio', 
    b: 'the audio', 
    c: 'audios', 
    d: 'the audios', 
    e: 'shared audio', 
    f: 'the shared audio', 
    g: 'shared audios', 
    h: 'the shared audios', 
    i: 'audio shared', 
    j: 'the audio shared', 
    k: 'audios shared', 
    l: 'the audios shared',
    m: 'audio share', 
    n: 'the audio share', 
    o: 'audios share', 
    p: 'the audios share'
};


export const cloud27a = { 
    a: 'video', 
    b: 'the video', 
    c: 'videos', 
    d: 'the videos', 
    e: 'shared video', 
    f: 'the shared video', 
    g: 'shared videos', 
    h: 'the shared videos', 
    i: 'video shared', 
    j: 'the video shared', 
    k: 'videos shared', 
    l: 'the videos shared',

    m: 'video share', 
    n: 'the video share', 
    o: 'videos share', 
    p: 'the videos share'
};


export const cloud31 = { 
    a: 'photo',
    b: 'picture',
    c: 'photograph',
    d: 'the photo',
    e: 'the picture',
    f: 'the photograph',
    g: 'photos',
    h: 'pictures',
    i: 'photographs',
    j: 'the photos',
    k: 'the pictures',
    l: 'the photographs',
    m: 'shared photo',
    n: 'shared picture',
    o: 'shared photograph',
    p: 'the shared photo',
    q: 'the shared picture',
    r: 'the shared photograph',
    s: 'shared photos',
    t: 'shared pictures',
    u: 'shared photographs',
    v: 'the shared photos',
    w: 'the shared pictures',
    a2: 'the shared photographs',
    a3: 'photo shared',
    a4: 'picture shared',
    a5: 'photograph shared',
    a6: 'the photo shared',
    a7: 'the picture shared',
    a8: 'the photograph shared',
    a9: 'photos shared',
    a10: 'pictures shared',
    a11: 'photographs shared',
    a12: 'the photos shared',
    a13: 'the pictures shared',
    a14: 'the photographs shared'

};



export const cloud142 = {
    a: 'i like that',
    b: 'i like it'
}

export const cloud143 = {
    a: 'track that',
    b: 'track it',
    c: 'follow it',
    d: 'follow that',
    e: 'track this',
    f: 'follow this'
}

export const cloud144 = {
    a: 'search',
    b:'seek',
    c: 'fetch',
    d: 'searches',
    e:'seeks',
    f: 'fetches',
    g: 'searched',
    h:'seeked',
    i: 'fetched',

    j: 'identify',
    k: 'identifies',
    l: 'identified'
}

export const cloud102 = {
    a: 'stop playing',
    b: 'stops playing',
    c: 'stopped playing'
};

export const cloud104 = {
    a: 'comment',
    b: 'add',
    c: 'comments',
    d: 'adds',
    e: 'commented',
    f: 'added' 
};

export const cloud105 = {
    a: 'show comment',
    b: 'show comment.',
    c: 'show a comment',
    d: 'open the room',
    e: 'open the page',
    f: 'open the comment room'
};

export const cloud106 = {
    a: 'hide comment',
    b: 'hide comment.',
    c: 'hide a comment',
    d: 'hide this comment',
    e: 'hides comment',
    f: 'hides comment.',
    g: 'hides a comment',
    h: 'hides this comment',
    i: 'close the room',
    j: 'close the page',
    k: 'close the comment room'
};



export const cloud107 = {
    a: 'send comment',
    b: 'send comment.',
    c: 'send a comment',
    d: 'send this comment',
    e: 'sent comment',
    f: 'sent comment.',
    g: 'sent a comment',
    h: 'sent this comment',
    i: 'sends comment',
    j: 'sends comment.',
    k: 'sends a comment',
    l: 'sends this comment'
};

export const cloud110 = {
    a: 'show picture one',
    b: 'show picture to',
    c: 'show picture two',
    d: 'show picture three',
    e: 'show picture of three',
    f: 'show picture tree',
    g: 'show picture for',
    h: 'show picture four',
    i: 'show picture five',
    j: 'show picture of five',
    k: 'show picture six',
    l: 'show picture of six',
    m: 'show picture seven',
    n: 'show picture nine'
};

export const cloud198 = {
    a: 'play',
    b: 'section'
};


export const cloud199 = {
    a: 'report this user',
    b: 'report user',
    c: 'open report panel',
    d: 'open the report panel',
    e: 'open report board',
    f: 'open the report board',
    g: 'opened the report panel',
    h: 'opened report board',
    i: 'opened the report board', 
    j: 'reports this user',
    k: 'reports user',
    l: 'reported this user',
    m: 'reported user',
    n: 'opens the report panel',
    o: 'opens report board',
    p: 'opens the report board',
    q: 'opens report board'
};
 

export const cloud200 = {
    a: 'close the report panel',
    b: 'close report panel',
    c: 'close the report board',
    d: 'close report board',
    e: 'closed the report panel',
    f: 'closed report panel',
    g: 'closed the report board',
    h: 'closed report board',
    i: 'closes the report panel',
    j: 'closes report panel',
    k: 'closes the report board',
    l: 'closes report board',
};



export const cloud201 = {
    a: 'user information',
    b: 'the user information',
    c: 'open the information board',
    d: 'open the information panel',
    e: 'open information board',
    f: 'open information panel',
    g: 'opened the information board',
    h: 'opened the information panel',
    i: 'opened information board',
    j: 'opened information panel',
    k: 'opens the information board',
    l: 'opens the information panel',
    m: 'opens information board',
    n: 'opens information panel'
};
 

export const cloud202 = {
    a: 'close user information',
    b: 'close the user information',
    c: 'close the information board',
    d: 'close the information panel',
    e: 'close information board',
    f: 'close information panel',
    g: 'closed information panel',
    i: 'closed the information panel',
    j: 'closed information board',
    k: 'closed the information board',
    l: 'closes user information',
    m: 'closes the user information',
    n: 'closes the information board',
    o: 'closes the information panel',
    p: 'closes information board',
    q: 'closes information panel' 
};




export const getIntoSpecificArea = async (a_) => {
    const a = await parseInt(a_, 10) - 1;

    const notify = await document.getElementsByClassName("notify");

    if (await (typeof(notify[a]) !== 'undefined' && notify[a] !== null)) {
        notify[a].click();
    }
}

export const getIntoSpecificVideo = async (a_) => {  
    const a = await parseInt(a_, 10) - 1;

    const b = await document.getElementsByClassName("play-video");

    if (await (typeof(b[a]) !== 'undefined' && b[a] !== null)) {  
        await b[a].play(); 
    }
}


export const cardBack = async () => {
    const prev_ = await document.getElementsByClassName("prev_")[0];

    if (await (typeof(prev_) !== 'undefined' && prev_ !== null)) {
        await prev_.click();
    }
}

export const cardNext = async () => {
    const next_ = await document.getElementsByClassName("next_")[0];
    if (await (typeof(next_) !== 'undefined' && next_ !== null)) {
        await next_.click();
    }
}


export const cloud300 = {
    a: 'select',
    b: 'select a file',
    c: 'select file',
    d: 'selects a file',
    e: 'selects file',
    f: 'selected a file',
    g: 'selected file',
    h: 'selects',
    i: 'selected',
    j: 'selection',
    k: 'collection'

} 


export const cloud301 = {
    a: 'take me to the homepage',
    b: 'take me to the homepage.',
    c: 'take me to the homepage',
    d: 'homepage',
    e: 'homepage.',
    f: 'homepage'
};
 

export const cloud302 = {
    a: 'change password', 
    b: 'change a password',
    c: 'change the password',

    d: 'changes password',
    e: 'changes a password',
    f: 'changes the password',
    g: 'changed password',
    h: 'changed a password',
    i: 'changed the password',
};
 

export const cloud303 = {
    a: 'update profile content',
    b: 'update the profile content',
    c: 'update profile content',
    d: 'update content',
    e: 'update the content',
    f: 'update content',

    a1: 'updates profile content',
    a2: 'updates the profile content',
    a3: 'updates profile content',
    a4: 'updates content',
    a5: 'updates the content',
    a6: 'updates content',

    a7: 'updated profile content',
    a8: 'updated the profile content',
    a9: 'updated profile content',
    a10: 'updated content',
    a11: 'updated the content',
    a12: 'updated content', 
};
 

export const cloud304 = {
    a: 'update profile picture',
    b: 'update the profile picture',
    c: 'update profile picture',
    d: 'update picture',
    e: 'update the picture',
    f: 'update picture',

    a1: 'updates profile picture',
    a2: 'updates the profile picture',
    a3: 'updates profile picture',
    a4: 'updates picture',
    a5: 'updates the picture',
    a6: 'updates picture',

    a7: 'updated profile picture',
    a8: 'updated the profile picture',
    a9: 'updated profile picture',
    a10: 'updated picture',
    a11: 'updated the picture',
    a12: 'updated picture',

    a13: 'update profile photo',
    a14: 'update the profile photo',
    a15: 'update profile photo',
    a16: 'update photo',
    a17: 'update the photo',
    a18: 'update photo',

    a19: 'updates profile photo',
    a20: 'updates the profile photo',
    a21: 'updates profile photo',
    a22: 'updates photo',
    a23: 'updates the photo',
    a24: 'updates photo',


    a25: 'updated profile photo',
    a26: 'updated the profile photo',
    a27: 'updated profile photo',
    a28: 'updated photo',
    a29: 'updated the photo',
    a30: 'updated photo',

    a31: 'updated profile photograph',
    a32: 'updated the profile photograph',
    a33: 'updated profile photograph',
    a34: 'updated photograph',
    a35: 'updated the photograph',
    a36: 'updated photograph',

    a37: 'updates profile photograph',
    a38: 'updates the profile photograph',
    a39: 'updates profile photograph',
    a40: 'updates photograph',
    a41: 'updates the photograph',
    a42: 'updates photograph',

    a43: 'updated profile photograph',
    a44: 'updated the profile photograph',
    a45: 'updated profile photograph',
    a46: 'updated photograph',
    a47: 'updated the photograph',
    a48: 'updated photograph',
};


export const cloud305 = {
    a: 'magazines',
    b: 'magazine',
    c: 'the magazines',
    d: 'the magazine'
};


export const cloud306 = {
    a: 'event card',
    b: 'event cards',
    c: 'card event',
    d: 'cards event',
    e: 'maps event',
    f: 'map event',
    g: 'event map',
    h: 'event maps',
    i: 'the event card',
    j: 'the event cards',
    k: 'the card event',
    l: 'the cards event',
    m: 'the maps event',
    n: 'the map event',
    o: 'the event map',
    p: 'the event maps'
};



export const cloud307 = { 
    a: 'upload a photo',
    b: 'upload a picture',
    c: 'upload a photograph',
    d: 'upload photo',
    e: 'upload picture',
    f: 'upload photograph',
    g: 'upload the photo',
    h: 'upload the picture',
    i: 'upload the photograph',
    k: 'upload photos',
    l: 'upload pictures',
    m: 'upload photographs',
    n: 'upload the photos',
    o: 'upload the pictures',
    p: 'upload the photographs',
    q: 'uploads a photo',
    r: 'uploads a picture',
    s: 'uploads a photograph',
    t: 'uploads photo',
    u: 'uploads picture',
    v: 'uploads photograph',
    w: 'uploads the photo',
    a2: 'uploads the picture',
    a3: 'uploads the photograph',
    a4: 'uploads photos',
    a5: 'uploads pictures',
    a6: 'uploads photographs',
    a7: 'uploads the photos',
    a8: 'uploads the pictures',
    a10: 'uploads the photographs',
    a11: 'uploaded a photo',
    a12: 'uploaded a picture',
    a13: 'uploaded a photograph',
    a14: 'uploaded photo',
    a15: 'uploaded picture',
    a16: 'uploaded photograph',
    a17: 'uploaded the photo',
    a18: 'uploaded the picture',
    a19: 'uploaded the photograph',
    a20: 'uploaded photos',
    a21: 'uploaded pictures',
    a22: 'uploaded photographs',
    a23: 'uploaded the photos',
    a24: 'uploaded the pictures',
    a25: 'uploaded the photographs',

    aa: 'download a photo',
    ba: 'download a picture',
    ca: 'download a photograph',
    da: 'download photo',
    ea: 'download picture',
    fa: 'download photograph',
    ga: 'download the photo',
    ha: 'download the picture',
    ia: 'download the photograph',
    ka: 'download photos',
    la: 'download pictures',
    ma: 'download photographs',
    na: 'download the photos',
    oa: 'download the pictures',
    pa: 'download the photographs',
    qa: 'downloads a photo',
    ra: 'downloads a picture',
    sa: 'downloads a photograph',
    ta: 'downloads photo',
    ua: 'downloads picture',
    va: 'downloads photograph',
    wa: 'downloads the photo',
    a2a: 'downloads the picture',
    a3a: 'downloads the photograph',
    a4a: 'downloads photos',
    a5a: 'downloads pictures',
    a6a: 'downloads photographs',
    a7a: 'downloads the photos',
    a8a: 'downloads the pictures',
    a10a: 'downloads the photographs',
    a11a: 'downloaded a photo',
    a12a: 'downloaded a picture',
    a13a: 'downloaded a photograph',
    a14a: 'downloaded photo',
    a15a: 'downloaded picture',
    a16a: 'downloaded photograph',
    a17a: 'downloaded the photo',
    a18a: 'downloaded the picture',
    a19a: 'downloaded the photograph',
    a20a: 'downloaded photos',
    a21a: 'downloaded pictures',
    a22a: 'downloaded photographs',
    a23a: 'downloaded the photos',
    a24a: 'downloaded the pictures',
    a25a: 'downloaded the photographs',
};
 

export const cloud308 = { 
    a: 'upload an audio', 
    b: 'upload audio', 
    c: 'upload the audio', 
    d: 'upload audios', 
    e: 'upload the audios', 
    f: 'uploads an audio', 
    g: 'uploads audio', 
    h: 'uploads the audio', 
    i: 'uploads audios', 
    j: 'uploads the audios', 
    k: 'uploaded an audio', 
    l: 'uploaded audio', 
    m: 'uploaded the audio', 
    n: 'uploaded audios', 
    o: 'uploaded the audios',

    aa: 'download an audio', 
    ba: 'download audio', 
    ca: 'download the audio', 
    da: 'download audios', 
    ea: 'download the audios', 
    fa: 'downloads an audio', 
    ga: 'downloads audio', 
    ha: 'downloads the audio', 
    ia: 'downloads audios', 
    ja: 'downloads the audios', 
    ka: 'downloaded an audio', 
    la: 'downloaded audio', 
    ma: 'downloaded the audio', 
    na: 'downloaded audios', 
    oa: 'downloaded the audios'
};
 

export const cloud309 = { 
    a: 'upload a video', 
    b: 'upload video', 
    c: 'upload the video', 
    d: 'upload videos', 
    e: 'upload the videos', 
    f: 'uploads a video', 
    g: 'uploads video', 
    h: 'uploads the video', 
    i: 'uploads videos', 
    j: 'uploads the videos', 
    k: 'uploaded a video', 
    l: 'uploaded video', 
    m: 'uploaded the video', 
    n: 'uploaded videos', 
    o: 'uploaded the videos',

    aa: 'download a video', 
    ba: 'download video', 
    ca: 'download the video', 
    da: 'download videos', 
    ea: 'download the videos', 
    fa: 'downloads a video', 
    ga: 'downloads video', 
    ha: 'downloads the video', 
    ia: 'downloads videos', 
    ja: 'downloads the videos', 
    ka: 'downloaded a video', 
    la: 'downloaded video', 
    ma: 'downloaded the video', 
    na: 'downloaded videos', 
    oa: 'downloaded the videos'
};



export const cloud310 = {
    a: 'create a tv channel',
    b: 'tv channel',
    c: 'created the tv channel',
    d: 'the tv channel'
};



export const cloud311 = {
    a: 'tv streaming',
    b: 'the tv broadcast',
    c: 'the tv streaming',
    d: 'tv broadcast'
};


export const cloud312 = {
    a: 'tv watcher',
    b: 'the tv watchers',
    c: 'the tv watcher',
    d: 'viewer',
    e: 'viewers',
    f: 'the viewer',
    g: 'the viewers'
};



export const cloud313 = {
    a: 'logout',
    b: 'disconnects',
    c: 'disconnection',
    d: 'look out',
    e: 'log out please',
    f: 'please log out',
    g: 'lookout',
    h: 'the disconnection',
    i: 'lockout',
    j: 'please disconnect',
    l: 'log out',
    m: 'disconnect please',
    n: 'lock out',
    o: 'disconnect'
};



