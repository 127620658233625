 
import PropTypes from 'prop-types';
import {Grid, Box, Typography} from '@mui/material'; 


import {domainName} from '../../../../utils/domain'; 

// --------------------------------------------------------

 
export function Page2({page2Features}) {  

  const { 
    height, 
    tagHtml, 
    photoHeight, 
    photoWidth, 
    textHeight,
    borderRadius,
    Comment1Page2,
    Photo1Page2
  } = page2Features; 

  const pageScroll = {
    overflowY: 'scroll', 
    height:textHeight,  
  };  

  
  const backImage = `${domainName}${'/EddyDreams/otherFiles/images/'}${Photo1Page2}`; 

  return (   
      <>
        <Grid container spacing={1} sx={{height:{height}, backgroundColor:'white'}} > 
          <Grid item xs={12} sm={12} md={12}>  
            <Box  component="img"
              sx={{  
                height: photoHeight,
                width: photoWidth,
                maxHeight: { xs: photoHeight, md: photoHeight },
                maxWidth: { xs: photoWidth, md: photoWidth },
                borderRadius: {borderRadius}, 
                objectFit:'cover'
              }}
              loading="lazy"

              alt=""  
              src={backImage}
            />
          </Grid> 

          <Grid item xs={12} sm={12} md={12}> 
            <div style={pageScroll}>
              <Typography variant={tagHtml} sx={{fontSize:{xs:26, sm:26, md:35}, font:`50px 'Lovers Quarrel'`}}>
                {Comment1Page2}
              </Typography>
            </div>
          </Grid>  
        </Grid>
      </>   
  );
}


Page2.propTypes = {    
  page2Features: PropTypes.object, 
};  
 