import { all } from 'redux-saga/effects';  

import { sagaRegisterScreen } from '../redux/sagas/sagaRegister';

import { sagaLogInScreen } from '../redux/sagas/sagaLogIn';

import { sagaForgottenPasswordScreen } from '../redux/sagas/sagaForgottenPassword';

import { sagaPasswordActivationScreen } from '../redux/sagas/sagaPasswordActivation';

import { sagaBusinessCardScreen } from '../redux/sagas/sagaBusinessCard';

import { sagaViewVideoPhotoScreen } from '../redux/sagas/sagaViewVideoPhoto';

import { sagaViewOneVideoPhotoScreen } from '../redux/sagas/sagaViewOneVideoPhoto';

import { sagaReportAbuseScreen } from '../redux/sagas/sagaReportAbuse';

import { sagaUserInfoScreen } from '../redux/sagas/sagaUserInfo';

import { sagaTvRadioStreamingScreen } from '../redux/sagas/sagaTvRadioStreaming';

import { sagaTvRadioWatcherScreen } from '../redux/sagas/sagaTvRadioWatcher';

import { sagaEventHallLiveScreen } from '../redux/sagas/sagaEventHallLive';

import { sagaViewVillageScreen } from '../redux/sagas/sagaViewVillage';

import { sagaViewVillageVideoAudioScreen } from '../redux/sagas/sagaViewVillageVideoAudio';

import { sagaViewOneVillageVideoAudioScreen } from '../redux/sagas/sagaViewOneVillageVideoAudio';

import { sagaUserDetailsScreen } from '../redux/sagas/sagaUserDetails';

import { sagaAllDataOnceScreen } from '../redux/sagas/sagaAllDataOnce';

import { sagaViewOneEventCardVideoScreen } from '../redux/sagas/sagaViewOneEventCardVideo';

import { sagaViewOneFeastCardVideoScreen } from '../redux/sagas/sagaViewOneFeastCardVideo';

import { sagaViewOneMagazineVideoScreen } from '../redux/sagas/sagaViewOneMagazineVideo';

import { sagaCreateConferenceRoomScreen } from '../redux/sagas/sagaCreateConferenceRoom';

import { sagaCreateTvChannelScreen } from '../redux/sagas/sagaCreateTvChannel';

import { sagaViewMyVillageVideoAudioScreen } from '../redux/sagas/sagaViewMyVillageVideoAudio';

import { sagaViewOneMyVillageVideoAudioScreen } from '../redux/sagas/sagaViewOneMyVillageVideoAudio';

import { sagaSpecificCompetitionScreen } from '../redux/sagas/sagaSpecificCompetition';

import { sagaViewOneCompetitionVideoAudioScreen } from '../redux/sagas/sagaViewOneCompetitionVideoAudio';

import { sagaSpecificSeasonScreen } from '../redux/sagas/sagaSpecificSeason';

import { sagaInnerLiveConferencingScreen } from '../redux/sagas/sagaInnerLiveConferencing';

function* rootSaga() {
    yield all([ 
        sagaRegisterScreen(), 
        sagaLogInScreen(), 
        sagaForgottenPasswordScreen(),
        sagaPasswordActivationScreen(),
        sagaBusinessCardScreen(),
        sagaViewVideoPhotoScreen(),
        sagaViewVideoPhotoScreen(),
        sagaViewOneVideoPhotoScreen(),
        sagaReportAbuseScreen(),
        sagaUserInfoScreen(),
        sagaTvRadioStreamingScreen(),
        sagaTvRadioWatcherScreen(),
        sagaEventHallLiveScreen(),
        sagaViewVillageScreen(),
        sagaViewVillageVideoAudioScreen(),
        sagaViewOneVillageVideoAudioScreen(),
        sagaUserDetailsScreen(),
        sagaAllDataOnceScreen(),
        sagaViewOneEventCardVideoScreen(),
        sagaViewOneFeastCardVideoScreen(),
        sagaViewOneMagazineVideoScreen(),
        sagaCreateConferenceRoomScreen(),
        sagaCreateTvChannelScreen(),
        sagaViewMyVillageVideoAudioScreen(),
        sagaViewOneMyVillageVideoAudioScreen(),
        sagaSpecificCompetitionScreen(),
        sagaViewOneCompetitionVideoAudioScreen(),
        sagaSpecificSeasonScreen(),
        sagaInnerLiveConferencingScreen(),
      ])
}

export default rootSaga; 