
const ViewVideoPhotoActionTypes = { 
    View_Video_Photo_REQUEST: 'View_Video_Photo_REQUEST',
    View_Video_Photo_SUCCESS:'View_Video_Photo_SUCCESS',
    View_Video_Photo_FAILURE: 'View_Video_Photo_FAILURE', 
}
 
export default ViewVideoPhotoActionTypes

  

 