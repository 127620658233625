

// ----------------------------------------------------------------------

const weddingBackgroundDetails = [
  {
  	id: 1, 
    back: 'product6.gif'
   },  

  {
  	id: 2, 
    back: 'product6b.gif'
   },  
   
  {
  	id: 3, 
    back: 'product6c.gif'
   },  
   
  {
  	id: 4, 
    back: 'product6d.gif'
   },  
   
  {
  	id: 5, 
    back: 'product6e.gif'
   },  
   
  {
  	id: 6, 
    back: 'product6f.gif'
   },  
   
  {
  	id: 7, 
    back: 'product6g.gif'
   },  
   
  {
  	id: 8, 
    back: 'product6h.gif'
   },  
   
  {
  	id: 9, 
    back: 'product6i.gif'
   },  
   
  {
  	id: 10, 
    back: 'product6j.gif'
   },  
   
  {
  	id: 11, 
    back: 'wedding1.jpg'
   },  
   
  {
  	id: 12, 
    back: 'wedding2.jpg'
   },  
   
  {
  	id: 13, 
    back: 'wedding3.jpg'
   },  
   
  {
  	id: 14, 
    back: 'wedding4.jpg'
   },  
   
  {
  	id: 15, 
    back: 'wedding5.jpg'
   },  
   
  {
  	id: 16, 
    back: 'wedding6.jpg'
   },  
   
  {
  	id: 17, 
    back: 'wedding7.jpg'
   },  
   
  {
  	id: 18, 
    back: 'wedding8.jpg'
   },  
   
  {
  	id: 19, 
    back: 'wedding9.jpg'
   },  
   
  {
  	id: 20, 
    back: 'wedding10.jpg'
   },   
   
  {
  	id: 21, 
    back: 'wedding11.jpg'
   },   
   
  {
  	id: 22, 
    back: 'wedding12.jpg'
   },   
   
  {
  	id: 23, 
    back: 'wedding13.jpg'
   },   
   
  {
  	id: 24, 
    back: 'wedding14.jpg'
   },   
   
  {
  	id: 25, 
    back: 'wedding15.jpg'
   },   
   
  {
  	id: 26, 
    back: 'wedding16.jpg'
   },   
   
  {
  	id: 27, 
    back: 'wedding17.jpg'
   },   
   
  {
  	id: 28, 
    back: 'wedding18.jpg'
   },   
   
  {
  	id: 29, 
    back: 'wedding19.jpg'
   },   
   
  {
  	id: 30, 
    back: 'wedding20.jpg'
   },   
 
];

export default weddingBackgroundDetails;
