
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import CreateTvChannelTypes from '../createTvChannel/createTvChannelTypes'; 

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}/u/create/tv/channel`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            token: user.token,
            type: user.type,
            ChannelType: user.ChannelType,
            TVChannelRadioChannelName: user.TVChannelRadioChannelName,
            DivisionName: user.DivisionName,
            RoomName: user.RoomName,
            Description: user.Description,
            DateOnline: user.DateOnline,
            TimeFrom: user.TimeFrom,
            TimeTo: user.TimeTo,
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaCreateTvChannel({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: CreateTvChannelTypes.Create_Tv_Channel_SUCCESS, response });
    } catch (error) {
        yield put({ type: CreateTvChannelTypes.Create_Tv_Channel_FAILURE, error: error.message });
    }
}

export function* sagaCreateTvChannelScreen() {
    yield takeLatest(CreateTvChannelTypes.Create_Tv_Channel_REQUEST, sagaCreateTvChannel);
}
