import PropTypes from 'prop-types';
import { subDays,  } from 'date-fns';
import { noCase } from 'change-case'; 
import { useEffect, useRef, useState, useMemo, useCallback, memo  } from 'react';  
import { useCookies  } from 'react-cookie'; 
import { useNavigate,  } from 'react-router-dom'; 
import { useSelector } from 'react-redux';  
import io from 'socket.io-client'; 
import { useSpeechRecognition } from "react-speech-recognition";
 

// @mui
import {
  Box,
  List,  
  Button, 
  Avatar, 
  Divider, 
  Typography,
  IconButton,
  ListItemText, 
  ListItemAvatar,
  ListItemButton,
  TextField,
  Grid,
  InputAdornment 
} from '@mui/material';
 

// utils
import { fToNow } from '../../../../utils/formatTime';

// components 
import Iconify from '../../../../components/iconify';
import Scrollbar from '../../../../components/scrollbar';

// util
import {useWindowDimensions} from '../../../../utils/otherFunctions';
import {reverseString, reverseArray, lowerCaseAllWordsExceptFirstLetters} from '../../../../utils/formatWord';
import {domainName} from '../../../../utils/domain';

  
import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, 
     } from '../../../../utils/translateFunctions';


import { cloud104a, cloud107a } from '../../../../utils/automateDreamFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------  

// const socket = io(`${domainName}`); 

export default function AllDreams() { 
  const navigate = useNavigate(); 
 
  const socket = useMemo(() => io(`${domainName}`), []);   
  

  const [numberNotifications, setNumberNotifications] = useState(0);
  const notifications = useRef([]);  
  const inputValue = useRef(''); 
  const token = useRef(null); 
  const type = useRef(null); 


 
  let profilename = ''; 
  let imagename = '';
  let newRoom = ''; 
  let allDreamsByCategoryValue = useMemo(() => [], []); 
  
  
  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);
  
  if(allDataOnceReducerValue.user){  
    profilename = allDataOnceReducerValue.user.usersDetails[0].NewFullName;
    imagename = allDataOnceReducerValue.user.usersDetails[0].Photo;
    newRoom = allDataOnceReducerValue.user.roomId;
    allDreamsByCategoryValue = reverseArray(allDataOnceReducerValue.user.allDreamsByCategory); 
  } 
 

  
  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 

  

  useEffect(() => {    
      if(numberNotifications === 0) {
        notifications.current = allDreamsByCategoryValue;
        setNumberNotifications(notifications.current.length); 
      }
  }, [allDreamsByCategoryValue, numberNotifications]); 
 

  
 
  useEffect(() => {   
    socket.on('message', async (message) => {   

      const CommentElement = await { 
        NewFullName: message.personName,
        Content: message.description,
        Photo: message.imagename,  
        // createdAt: subDays(new Date(`${reverseString(message.current_date)} ${message.current_time}`), 0), 
        DateSent: message.current_date,
        TimeSent: message.current_time
      }   

      notifications.current = [].concat(CommentElement, notifications.current);
      setNumberNotifications(notifications.current.length);
    }, [notifications]);

    return () => { 
      socket.off('message'); 
    }; 
  }, [notifications, socket]); 
 
  

  const { height } = useWindowDimensions();  

  const HEIGHTDesktop = `${height/3 *2}px`;  
  const HEIGHTMobile = `${height*0.6}px`; 



  const sendMessage = useCallback(async (token, userType, room, profilename, imagename, text, currentTime, currentDate) => { 
    const message = await {
      token,
      userType,
      room,
      profilename,
      imagename,
      text,
      currentTime,
      currentDate
    }; 

    await socket.emit('message2', message);
  }, [socket])
 

  const handleSendDream = useCallback(async () => {  
    if(inputValue.current.value){   
      const messageMessage = await inputValue.current.value;
      const currentTime = await new Date().toLocaleTimeString();  
      const currentDate = await new Date().toLocaleDateString();  
 

      const CommentElement = await { 
        NewFullName: profilename,
        Photo: imagename,  
        Content: messageMessage,
        TimeSent: currentTime, 
        DateSent: currentDate,
      };
   

      notifications.current = [].concat(CommentElement, notifications.current);   

      await sendMessage(token.current, type.current, newRoom, profilename, imagename, messageMessage, currentTime, currentDate);
      
      setNumberNotifications(notifications.current.length);
       
      inputValue.current.value = ''; 
    } 
  }, [imagename, newRoom, profilename, sendMessage]);


 
  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition();  

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {  
        let comment;   

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));      
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_); 


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;
          const firstElementOfString_ = await firstElementOfString(t); 


          if (await stringComparison([cloud104a.a, cloud104a.b, cloud104a.c, cloud104a.d, cloud104a.e, cloud104a.f, cloud104a.g, cloud104a.h, cloud104a.i, cloud104a.j, cloud104a.k], firstElementOfString_)) {
            t = await firstElementOfString_; 
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            comment = await tm;
            inputValue.current.value = await comment; 

            await inputValue.current.focus();

            speech.text = await transMany__('Your dream has been updated') || 'Your dream has been updated';
            await speechSynth.speak(speech); 
          }     

          if (await stringComparison([cloud107a.a, cloud107a.b, cloud107a.c, cloud107a.d, cloud107a.e, cloud107a.f, cloud107a.g, cloud107a.h, cloud107a.i, cloud107a.j, cloud107a.k, cloud107a.l, cloud107a.a1, cloud107a.a2, cloud107a.a3, cloud107a.a4, cloud107a.a5, cloud107a.a6, cloud107a.a7, cloud107a.a8, cloud107a.a9, cloud107a.a10, cloud107a.a11, cloud107a.a12], t)) {
            await handleSendDream();

            speech.text = await transMany__('Your dream has been shared') || 'Your dream has been shared';
            await speechSynth.speak(speech); 
          }
  
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, handleSendDream]);

 
  /* End Michael */  


  return (
    <>   
        <Grid container spacing={1}  sx={{p:1}}> 
          <Grid  item xs={12} sm={12} md={12}  >  
            <Box sx={{ flexGrow: 1, bgcolor:"lightgray"  }}>  
              <TextField fullWidth  
              inputRef={inputValue}  
              InputProps={{
                endAdornment: (
                  inputValue &&  <InputAdornment position="end"> 
                    <IconButton color="primary" onClick={handleSendDream}>
                        <Iconify icon="fa:send-o" />
                      </IconButton>
                  </InputAdornment>
                ),
              }}  
              InputLabelProps={{
                style: { color: 'blue' },
              }}
              name="dream" label="What's my dream" /> 
            </Box>
          </Grid> 
        </Grid> 

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: HEIGHTMobile, sm: HEIGHTMobile, md:HEIGHTDesktop } }}>
          <List
            disablePadding 
          >
            {notifications.current.map((notification, key) => (
              <NotificationItem key={key} notification={notification} />
            ))}
          </List> 
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} /> 
        
        <Grid container spacing={1}> 
          <Grid  item xs={12} sm={12} md={12}  > 
            <Box sx={{p:0, m:0}}>
              <Button fullWidth disableRipple>
                {numberNotifications}{' '} Dreams 
              </Button>
            </Box> 
          </Grid> 
        </Grid> 
    </>
  );
}


// ------------------------------------------------------------------------


const NotificationItem = memo(({ notification }) => {
  const { Photo, NewFullName } = renderContent(notification);
 
  const DateCreatedAt = fToNow( `${subDays(new Date(`${reverseString(notification.DateSent)} ${notification.TimeSent}`), 0)}` );

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral', width:30, height:30 }}>{Photo}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={NewFullName}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.secondary',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {DateCreatedAt}
          </Typography>
        }
      />
    </ListItemButton>
  );
})


NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date), 
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    NewFullName: PropTypes.string,
    Content: PropTypes.string,
    TimeSent: PropTypes.string,
    DateSent: PropTypes.string,
    type: PropTypes.string,
    Photo: PropTypes.any,
  }),
};
// ----------------------------------------------------------------------

function renderContent(notification) {
  const NewFullName = (
    <Typography variant="subtitle2">
      <Typography component="span"  className="notranslate">
        {lowerCaseAllWordsExceptFirstLetters(notification.NewFullName)}
      </Typography>
      <Typography component="span" variant="body2" sx={{ color: 'text.primary' }}>
        &nbsp; {noCase(notification.Content)}
      </Typography>
    </Typography>
  );

  return {
    Photo: notification.Photo ? <img alt={notification.NewFullName} src={`${domainName}/EddyDreams/Home/images/${notification.Photo}`} /> : null,
    NewFullName,
  }; 
}
