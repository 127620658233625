
import {useState, memo} from 'react'; 
import HTMLFlipBook from "react-pageflip"; 
import {Box, Avatar,} from '@mui/material'; 
import PropTypes from 'prop-types';

 

// icons
import { Icon } from '@iconify/react';  

import { grey } from '@mui/material/colors';

// hooks
import useResponsive from '../../../../hooks/useResponsive';

// utils
import {useWindowDimensions} from '../../../../utils/otherFunctions';
import {lowerCaseAllWordsExceptFirstLetters} from '../../../../utils/formatWord';


import {domainName} from '../../../../utils/domain';


import './Magazine.css';



import {Page1, Page2, Page3, Page4, Page5, Page6} from './AllPages';
 

// const ResizeScreen = 180;
// const ResizeFullScreen = 50;
const ResizeFullScreen = 60;



export const MagazineFullScreen = memo(({articleItem}) => {
  const [BackgroundColor, setBackgroundColor] = useState('white');  
  const multipleColor = ["black", "blue", "white", "red", "gray", "green"];
  

  const changeColor = () => {
    /* if (BackgroundColor === 'blue') {
      setBackgroundColor('white')  
    } else {
      setBackgroundColor('blue');
    } */

    const random = Math.floor(Math.random() * multipleColor.length);
    setBackgroundColor(multipleColor[random]); 
  }
 

  const { height, width } = useWindowDimensions();
  const isDesktop = useResponsive('up', 'lg'); 
 
  const WIDTH = isDesktop? ((width/2) - ResizeFullScreen) : (width-20);
  const HEIGHT = height - 50;



  const {
    City, 
    Comment1Page2,
    Comment1Page4,
    Comment2Page4,
    Content1Page3,
    Content1Page5,
    Content2Page3,
    Content3Page3,
    Continent,
    Country,
    // DateCreated, 
    // DateCreated2,
    NewFullName,
    NumberComments,
    NumberLikes,
    NumberViews,
    Photo,
    Photo1CoverPage,
    Photo1Page2,
    Photo1Page3,
    Photo1Page4,
    Photo1Page5,
    Photo2Page4,
    SecretCoverPage,
    SummarizeCoverPage,
    // SummarizeCoverPage2,
    TitleCoverPage,
    Video1Page5,
    WhoCoverPage,
    magazineId 
  }  = articleItem;


  const page1Features = isDesktop ? { 
      height: HEIGHT, 
      magazineId,
      NumberLikes,
      NumberViews,
      NumberComments,
      TitleCoverPage,
      SecretCoverPage,
      SummarizeCoverPage,
      WhoCoverPage,
      Photo1CoverPage
  } : { 
      height: HEIGHT, 
      magazineId,
      NumberLikes,
      NumberViews,
      NumberComments,
      TitleCoverPage,
      SecretCoverPage,
      SummarizeCoverPage,
      WhoCoverPage,
      Photo1CoverPage
  }; 


  const page2Features = isDesktop ? {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'h4',
      photoHeight: HEIGHT *2/3,
      photoWidth: WIDTH  - 15,
      textHeight: '150px',
      borderRadius:'10px',
      Comment1Page2,
      Photo1Page2
  } : {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT *2/3,
      photoWidth: WIDTH  - 10,
      textHeight: '150px',
      borderRadius:'10px',
      Comment1Page2,
      Photo1Page2
  };
 
  const page3Features = isDesktop ? {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT - 10,
      photoWidth: WIDTH / 2,
      textHeight: HEIGHT,
      borderRadius:'10px',
      Photo1Page3,
      Content1Page3,
      Content2Page3,
      Content3Page3
  } : {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT / 3,
      photoWidth: WIDTH / 2,
      textHeight: `${HEIGHT/2}px`,
      borderRadius:'10px',
      Photo1Page3,
      Content1Page3,
      Content2Page3,
      Content3Page3
  };
 
  const page4Features = isDesktop ? {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT / 2,
      photoWidth: WIDTH / 2 - 15,
      textHeight: `${HEIGHT/3}px`,
      borderRadius:'10px',
      Photo1Page4,
      Comment1Page4,
      Photo2Page4,
      Comment2Page4
  } : {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT / 2,
      photoWidth: WIDTH / 2 - 15,
      textHeight: `${HEIGHT/3}px`,
      borderRadius:'10px',
      Photo1Page4,
      Comment1Page4,
      Photo2Page4,
      Comment2Page4
  };


  const page5Features = isDesktop ? {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'h4',
      photoHeight: HEIGHT *2/3,
      photoWidth: WIDTH  - 15,
      textHeight: '150px',
      borderRadius:'10px',
      Content1Page5,
      Video1Page5
  } : {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT *2/3,
      photoWidth: WIDTH  - 10,
      textHeight: '100px',
      borderRadius:'10px',
      Content1Page5,
      Video1Page5
  };


  const page6Features = isDesktop ? {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'h3',
      photoHeight: HEIGHT *2/3,
      photoWidth: WIDTH  - 15,
      textHeight: '150px',
      borderRadius:'10px', 
      Photo1Page5,
      Photo,
      Continent,
      Country,
      City,
      NewFullName
  } : {
      width: WIDTH,
      height: HEIGHT,
      tagHtml: 'inherit',
      photoHeight: HEIGHT *2/3,
      photoWidth: WIDTH  - 10,
      textHeight: '100px',
      borderRadius:'10px',
      Photo1Page5,
      Photo,
      Continent,
      Country,
      City,
      NewFullName
  };  


  return (  
    <HTMLFlipBook width={WIDTH}  height={HEIGHT}  className="showItem" >
      <div> 
        <div className='firstLast'> 
          <h4> 
            Click the right corner to read this magazine
          </h4>

          <Box component={Icon} icon='mdi:hand-tap' sx={{ width:1, height: '100px', ml:'80px'}} onClick={changeColor}/> 

          <p>This magazine was written by</p> 
          <Avatar sx={{ bgcolor: grey[500], height:60, width:60, ml:{xs: WIDTH/19, sm: WIDTH/17.5, md: WIDTH/17.5} }}  alt='' src={`${domainName}${'/EddyDreams/Home/images/'}${Photo}`} />
          <p className="notranslate"> {lowerCaseAllWordsExceptFirstLetters(NewFullName)} </p> 
        </div>
      </div>
      
      <div>
        <Page1 page1Features={page1Features} BackgroundColor={BackgroundColor}/>
      </div>
          
      <div> 
        <Page2 page2Features={page2Features}/>
      </div>
       
      <div> 
        <Page3 page3Features={page3Features}/>
      </div>
       
      <div> 
        <Page4 page4Features={page4Features}/>
      </div>
       
      <div> 
        <Page5 page5Features={page5Features}/>
      </div>
       
      <div> 
        <Page6 page6Features={page6Features} BackgroundColor={BackgroundColor}/>
      </div> 
 
      <div> 
        <div className='firstLast'>
          <h3>Click the left corner to read the previous pages</h3>
          <Box component={Icon} icon='mdi:hand-tap' sx={{ width:1, height: '100px', pr:'100px'}} onClick={changeColor}/> 
          <p>This magazine was writtien by</p> 
          <Avatar sx={{ bgcolor: grey[500], height:60, width:60, ml:{xs: WIDTH/19, sm: WIDTH/17.5, md: WIDTH/17.5} }}  alt='' src={`${domainName}${'/EddyDreams/Home/images/'}${Photo}`} />
          <p className="notranslate">{lowerCaseAllWordsExceptFirstLetters(NewFullName)}</p>
        </div>
      </div>
    </HTMLFlipBook> 
  );
})


MagazineFullScreen.propTypes = {     
  articleItem: PropTypes.object,  
}; 
 