

import { useNavigate,  } from 'react-router-dom'; 
import { Helmet } from 'react-helmet-async';  
// mui
import { Container, Card, IconButton } from '@mui/material';   
import WestIcon from '@mui/icons-material/West';


// sections 
import {  
  TvStreaming, 
} from '../../sections/@users/createAllFilesUser';


// ----------------------------------------------------------------------

export default function TvStreamingPage() { 
  const navigate = useNavigate();  

  return (
    <>
      <Helmet>
        <title> PlanetryVilage | TV Streaming </title>
      </Helmet>
      
      <Container maxWidth="xl" sx={{mt:1, px:{xs:0, sm:0, md:1}, }}>

        <Card sx={{color: 'black', textAlign:'center', justifyContent:'center', borderRadius:{xs:0, sm:0, md:1}}}> 
          <IconButton aria-label="back" sx={{fontSize:13, color:'black', p:0}} onClick={() => navigate('/create/tv/channel') }>
            <WestIcon/>   
          </IconButton>
          
          <TvStreaming/> 
        </Card> 

        
      </Container> 
    </>
  );
}
