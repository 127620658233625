 
import PropTypes from 'prop-types';
import {Grid, Box, Typography} from '@mui/material'; 



import {domainName} from '../../../../utils/domain'; 

// -----------------------------------------------------------


export const Page3 = ({page3Features}) => { 

  const {
    height, 
    tagHtml, 
    photoHeight, 
    photoWidth, 
    textHeight,
    borderRadius,
    Photo1Page3,
    Content1Page3,
    Content2Page3,
    Content3Page3
  } = page3Features;

  const pageScroll = {
    overflowY: 'scroll', 
    height:textHeight,
  }; 

  return (   
      <>
        <Grid container spacing={1} sx={{height:{height}, backgroundColor:'white'}} > 
          <Grid item xs={12} sm={12} md={6}>  
            <Box  component="img"
              sx={{ 
                height: photoHeight,
                width: photoWidth,
                maxHeight: { xs: photoHeight, md: photoHeight },
                maxWidth: { xs: photoWidth, md: photoWidth }, 
                borderRadius: {borderRadius}, 
                objectFit:'cover'
              }}
              loading="lazy"

              alt=""  
              src={`${domainName}${'/EddyDreams/otherFiles/images/'}${Photo1Page3}`}
            />
          </Grid> 

          <Grid item xs={12} sm={12} md={6}> 
            <div style={pageScroll}>
              <Typography variant={tagHtml} sx={{mb:2, fontSize:{xs:26, sm:26, md:35}, font:`50px 'Lovers Quarrel'`}}>
                {Content1Page3} 
              </Typography>

              <Typography variant={tagHtml} sx={{mb:2, fontSize:{xs:26, sm:26, md:35}, font:`50px 'Lovers Quarrel'`}}>
                {Content2Page3}
              </Typography>

              <Typography variant={tagHtml} sx={{fontSize:{xs:26, sm:26, md:35}, font:`50px 'Lovers Quarrel'`}}>
                {Content3Page3} 
              </Typography>
            </div>
          </Grid>  
        </Grid>
      </>   
  );
}

Page3.propTypes = {    
  page3Features: PropTypes.object, 
}; 
 