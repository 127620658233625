import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
 

import { CookiesProvider } from 'react-cookie';

import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/index';

//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(  
	<Provider store={store}> 
      <PersistGate loading={null} persistor={persistor}>	
	    <CookiesProvider>
	      <HelmetProvider> 
	        <BrowserRouter>
	          <App />
            </BrowserRouter>
          </HelmetProvider>
        </CookiesProvider>
      </PersistGate>
    </Provider>  
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();
// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
