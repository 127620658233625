import { useState, useRef, useEffect, } from 'react'; 
import { useSelector  } from 'react-redux';
import axios from 'axios';
import { useSpeechRecognition } from "react-speech-recognition";
 
// @mui 
import { IconButton, Typography, Divider, Grid, Stack, LinearProgress, FormLabel, Card  } from '@mui/material'; 
import { LoadingButton } from '@mui/lab'; 


import PhotoCamera from '@mui/icons-material/PhotoCamera'; 

import {isFileAnImage} from '../../../utils/otherFunctions';
 

import {domainName} from '../../../utils/domain';



import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, transMany, firstElementOfString, 
         removeFirstElementFromString, stringComparison, cloud83, cloud88, cloud300, } from '../../../utils/translateFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1; 
// ----------------------------------------------------------------------
 

export default function ChangeProfilePhoto() {  
  const [files, setFiles] = useState([]);    
  const [maxMinute, setMaxMinute] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);   
  const [preview, setPreview] = useState({imageURL:'', audioURL: '', videoURL: ''});
  const [fileTypeCheck, setFileTypeCheck] = useState({imageFile:false, audioFile:false, videoFile:false}); 

  const [Id, setId] = useState("");
  const [Photo, setPhoto] = useState("");

  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);
 
  
  const i = useRef(0)
  if(i.current === 0 && allDataOnceReducerValue.user){
    const usersDetailsValue = allDataOnceReducerValue.user.usersDetails[0];     

    setId(usersDetailsValue.Id);
    setPhoto(usersDetailsValue.Photo);

    i.current +=1;
  }
  


  
  /*  Begin upload */
  let urlLocalComplete; 
  let urlLocalData;
  let urlLink2;
  let urlLink;
  let options;
  let EndPoints;
  let fileCategory;
  const urlLocalRequest = `${domainName}/u/change/profile/photo`;
  const TFile = [];
  let numberOfFiles = 0; 


  const generateUrlFile = (urlLink_) => {
    urlLink2 = `${urlLink_}${'-data'}`;
    urlLink = `${urlLink_}${'-complete'}`;

    return {
      urlLink2,
      urlLink
    };
  }


  const upload = async () => {   
    if(await files.length === 0){
      setMaxMinute('Select a file');
      return;
    }
 
 

    TFile[0] = files;
    numberOfFiles = await files.length;

    if(fileTypeCheck.imageFile) {  
      fileCategory = 'images';
    } else 
    if(fileTypeCheck.audioFile) { 
      fileCategory = 'audios';
    } else 
    if (fileTypeCheck.videoFile) { 
      fileCategory = 'videos';
    }
 

    urlLink2 = await generateUrlFile(urlLocalRequest).urlLink2;

    urlLink = await generateUrlFile(urlLocalRequest).urlLink;

    urlLocalComplete = await urlLink;
    urlLocalData = await urlLink2;


    EndPoints = await {
      UPLOAD: urlLocalComplete,
      UPLOAD_DATA: urlLocalData,
      UPLOAD_REQUEST: urlLocalRequest
    }
 

    options = await {
      url: EndPoints.UPLOAD,
      startingByte: 0,
      // chunkSize: 100000, 
      chunkSize: 1000000, 
      fileId: '',
      arrayId: ''
    };
 

    uploadTrigger();  
  }


  const uploadTrigger = async () => {  
    const optionResult = await uploadCreateDirectory();

    const optionResultArrayIdLength = await optionResult.arrayId.length;
    const filesLength = await numberOfFiles;

    if (await (optionResultArrayIdLength > 0)) {
      for (let z = 0; z < filesLength; z+=1) {
        // const files_ = TFile[0].files[z];
        const files_ = (TFile[0])[z];
        if (files_) {   
          uploadExecution(files_, z);
        }
      }
    }
  }


  const uploadCreateDirectory = async () => {
    const Id_ = await Id; 
    const Photo_ = await Photo;

    const resp = await sendFile(numberOfFiles, fileCategory, Photo_, EndPoints.UPLOAD_REQUEST);

    try {
      const jsonResponse = await resp.data;   

      if (await (resp.status === 400)) {
        await console.log('An error occured in the directory');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      }  


      options = await {
        ...options,
        ...jsonResponse
      };

      const respData = await sendFileData(options.arrayId, Id_, fileCategory, EndPoints.UPLOAD_DATA);
       
      if (await (respData.status === 400)) {
        await console.log('Missing file and content');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      } 

      return options; 
    } catch (err) {
      console.error('failed to read file: ', err);

      options = await {
        ...options,
        ...{
          arrayId: []
        }
      };
      return options;
    } 
  }


  const uploadFileChunks = async (file, options, i) => {
    let options_;

    if (await isFileAnImage(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          // chunkSize: 1000
          chunkSize: 100000
        }
      };
    } else {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        }
      };
    }
    await sendFileChunks(file, options_.startingByte, options_.chunkSize, options_, i);

  }


  const sendFileChunks = async (file, startingByte, endingByte_, options_, i) => {
    const fileSize = await file.size;
    let endingByte = await endingByte_;

    const formData = await new FormData();
    const xhr = await new XMLHttpRequest();

    if (fileSize < endingByte) {
      endingByte = await fileSize;
    }
 

    if (endingByte < fileSize) {
      xhr.onreadystatechange = async () => {
        if (await (xhr.readyState === XMLHttpRequest.DONE)) {
          const percentageUploaded = await (endingByte / fileSize) * 100;  

          await setMaxMinute('Upload in progress');
          await setProgressPercentage(parseInt(percentageUploaded, 10)); 

          await sendFileChunks(file, startingByte + options_.chunkSize, startingByte + (options_.chunkSize * 2), options_, i);
        }
      }
    } else { 
      await setProgressPercentage(0); 

      await setPreview({
        preview, 
        ...{
          imageURL: '', 
          audioURL: '', 
          videoURL: ''
        }
      });

      await setFiles([]);
      await setMaxMinute(''); 
    }


    const chunk = await file.slice(startingByte, endingByte);

    await formData.append('chunk', chunk, file.name);
    await formData.append('startingByte', startingByte);
    await formData.append('endingByte', endingByte);
    await formData.append('fileId', options_.fileId);
    await formData.append('X-fileCategory', fileCategory);

    await xhr.open('POST', options_.url, true);
    await xhr.setRequestHeader('X-File-Id', options_.fileId);
    await xhr.setRequestHeader('X-File-Size', fileSize);
    await xhr.setRequestHeader('X-startingByte', startingByte);
    await xhr.setRequestHeader('X-endingByte', endingByte);
    await xhr.setRequestHeader('X-fileCategory', fileCategory);

    await xhr.send(formData);
  }


  const sendFile = (numberOfFiles_, fileCategory_, Photo_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        numberOfFiles: numberOfFiles_, 
        fileCategory: fileCategory_,
        Photo: Photo_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }


  const sendFileData = (fileData, Id_, fileCategory_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        fileData_: fileData,
        Id: Id_,  
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }



  const uploadExecution = async (files_, i) => {
    let newFile;

    if (await isFileAnImage(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.jpg');
    } else {
      newFile = await renameFile(files_, 'planetryvilage.webm');
    }

    if (!newFile) {
      await console.log("You must first select the file to upload it");
    } else {
      try {
        await uploadFileChunks(newFile, options, i);
      } catch (err) {
        console.error('failed to read file: ', err);
      }
    }
  }


  function renameFile (originalFile_, newName_) {
    return new File([originalFile_], newName_, {
      type: originalFile_.type,
      lastModified: originalFile_.lastModified,
    });
  }
  /*  End upload */




  const resetAllFile = () => {
  	setFileTypeCheck({
  		fileTypeCheck, 
  		...{
  			imageFile:false, 
  			audioFile:false, 
  			videoFile:false
  		}
  	});

  	setPreview({
  		preview, 
  		...{
  			imageURL: '', 
  			audioURL: '', 
  			videoURL: ''
  		}
  	});

  	setFiles([]); 
  }


 

  // check if a file is an image or an audio or a video
  const fileChecker = async (file) => {
  	if (await isFileAnImage(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:true, 
  				audioFile:false, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: URL.createObjectURL(file), 
  				audioURL: '', 
  				videoURL: ''
  			}
  		});
  	} else {
  		// the file is nor an image nor an audio nor a video.  Reset everything
  		resetAllFile();
  	}
  }
   
  const handleFilesChange = (e) => {
   	if (e.target.files) {
      setFiles(e.target.files); 
      fileChecker(e.target.files[0]); 
    }   
  }; 
  





  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
   
  const inputElement = useRef();
  const triggerUploadClick = useRef();

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {      

        // let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));     
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_); 


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11, cloud83.a12], firstElementOfString__)) {
          const t = await removeFirstElementFromString__;
          // const firstElementOfString_ = await firstElementOfString(t); 
          

          // select a file
          if (await stringComparison([cloud300.a, cloud300.b, cloud300.c, cloud300.d, cloud300.e, cloud300.f, cloud300.g, cloud300.h, cloud300.i, cloud300.j, cloud300.k], t)) {
            await inputElement.current.click();
            await setMaxMinute('');

            speech.text = await transMany__('The dialog box is open') || 'The dialog box is open';
            speechSynth.speak(speech);
          }   
  
          
          /* send the form */
          if (await stringComparison([cloud88.a, cloud88.b, cloud88.c, cloud88.d, cloud88.e, cloud88.f, cloud88.g, cloud88.h, cloud88.i, cloud88.j], t)) {
            await triggerUploadClick.current.click(); 
          }
 
  
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript]);

  /* End Michael */


  return (
    <>  
      <Grid  item xs={12} sm={12} md={8} lg={6}>
        <Card >
          <Stack spacing={1} sx={{p:1, textAlign:'center', justifyContent:'center'}}>
            <Typography variant="string">
              Update Profile Photo
            </Typography>

            <Divider/>   

              <FormLabel id="selct-a-photo">select a photo</FormLabel>
            <IconButton color="primary" aria-label="upload picture"   title='select a video' component="label">
              <input hidden accept="image/*" type="file" ref={inputElement} onChange={handleFilesChange} />
              <PhotoCamera sx={{fontSize:{xs:30, md:40},}} />  
            </IconButton>
                
            {!!progressPercentage && 
            (<>
              <LinearProgress variant="determinate" value={progressPercentage}/>
              <Typography variant="body2" color="text.secondary">
                {`${Math.round(progressPercentage)}%`}
              </Typography> 
             </>
            )} 
                
            {(!!files.length) &&  
            (<>
              {fileTypeCheck.imageFile && <img src={preview.imageURL} alt='' style={{maxWidth: '250px', margin:'auto', padding:0, maxHeight: 'auto'}}/>}
   
            </>)}

            <Typography variant='subtitle2' color='red'>{maxMinute}</Typography>

            <LoadingButton fullWidth size="large"  type="button"  title='Share' variant="contained" ref={triggerUploadClick} onClick={upload}> 
              Update
            </LoadingButton> 
          </Stack>
        </Card>    
       
      </Grid>  
    </>
  );
}

