import { useState, memo } from 'react';
import PropTypes from 'prop-types';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MenuBookIcon from '@mui/icons-material/MenuBook'; 
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CloseIcon from '@mui/icons-material/Close';

// @mui
import { styled } from '@mui/material/styles';
import { Tooltip, Stack, Popover, IconButton, Typography } from '@mui/material';
// component
import Iconify from '../../../components/iconify';



 


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999, 
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(40),
  height: theme.spacing(5),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------




export const MagazineWishWidget = memo(({showAllArticles, showMagazines, showEventCards, showWishesSent, showWishesReceived}) => {    
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  return (
    <>
      <StyledRoot onClick={handleOpen}> 
        <Tooltip title="Filter"> 
          <Iconify icon="material-symbols:filter-list-rounded" width={24} height={24} /> 
        </Tooltip>
      </StyledRoot>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          <IconButton aria-label="close"  onClick={handleClose}>
            <CloseIcon sx={{fontSize:{xs:15, md:20}, color: 'black', ml:17}} />   
          </IconButton>   
          <IconButton aria-label="all" sx={{fontSize:{xs:13, md:15}}} onClick={() => {showAllArticles(); handleClose();}}>
            <RestartAltIcon sx={{fontSize:{xs:15, md:20}}} />  
            <Typography variant='button' sx={{ml:1}}>All</Typography>
          </IconButton>
          <IconButton aria-label="magazines" sx={{fontSize:{xs:13, md:15}}} onClick={() => {showMagazines(); handleClose();}}>
            <MenuBookIcon sx={{fontSize:{xs:15, md:20}}} /> 
            <Typography variant='button' sx={{ml:1}}> Magazines</Typography>
          </IconButton>
          <IconButton aria-label="event cards" sx={{fontSize:{xs:13, md:15}}} onClick={() => {showEventCards(); handleClose();}}>
            <CardGiftcardIcon sx={{fontSize:{xs:15, md:20}}} /> 
            <Typography variant='button' sx={{ml:1}}>Event Cards</Typography>
          </IconButton> 
          <IconButton aria-label="wishes sent" sx={{fontSize:{xs:13, md:15}}} onClick={() => {showWishesSent(); handleClose();}}>
            <CallMadeIcon sx={{fontSize:{xs:15, md:20}}} /> 
            <Typography variant='button' sx={{ml:1}}>Wishes Sent</Typography>
          </IconButton>
          <IconButton aria-label="wishes received" sx={{fontSize:{xs:13, md:15}}} onClick={() => {showWishesReceived(); handleClose();}}>
            <CallReceivedIcon sx={{fontSize:{xs:15, md:20}}} /> 
            <Typography variant='button' sx={{ml:1}}>Wishes Received</Typography>
          </IconButton>
        </Stack>
      </Popover>
    </>
  );
})


MagazineWishWidget.propTypes = {    
  showAllArticles: PropTypes.func,     
  showMagazines: PropTypes.func,     
  showEventCards: PropTypes.func,     
  showWishesSent: PropTypes.func,     
  showWishesReceived: PropTypes.func,   
};
 