 
import PropTypes from 'prop-types';
import {Grid, Box, Typography} from '@mui/material'; 



import {domainName} from '../../../../utils/domain'; 

// -----------------------------------------------------------


export const Page4 = ({page4Features}) => {  
  
  const {
    height, 
    tagHtml, 
    photoHeight, 
    photoWidth, 
    textHeight,
    borderRadius,
    Photo1Page4,
    Comment1Page4,
    Photo2Page4,
    Comment2Page4
  } = page4Features;

  const pageScroll = {
    overflowY: 'scroll', 
    height:textHeight, 
  }; 

  return (   
      <>
        <Grid container spacing={1} sx={{height:{height}, backgroundColor:'white'}} > 

          <Grid item xs={12} sm={12} md={12}> 
            <div style={pageScroll}> 
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant={tagHtml} sx={{fontSize:{xs:26, sm:26, md:31}, font:`50px 'Lovers Quarrel'`}}>
                    {Comment1Page4} 
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant={tagHtml} sx={{fontSize:{xs:26, sm:26, md:31}, font:`50px 'Lovers Quarrel'`}}>
                    {Comment2Page4}
                  </Typography> 
                </Grid>
              </Grid>
            </div>
          </Grid>  

          <Grid item xs={12} sm={12} md={12}>  
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6}> 
                <Box  component="img" 
                  sx={{ 
                    height: photoHeight,
                    width: photoWidth,
                    maxHeight: { xs: photoHeight, md: photoHeight },
                    maxWidth: { xs: photoWidth, md: photoWidth },  
                    borderRadius:{borderRadius}, 
                    objectFit:'cover'
                  }}
                  loading="lazy"

                  alt=""  
                  src={`${domainName}${'/EddyDreams/otherFiles/images/'}${Photo1Page4}`}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}> 
                <Box  component="img"
                  sx={{ 
                    height: photoHeight,
                    width: photoWidth,
                    maxHeight: { xs: photoHeight, md: photoHeight },
                    maxWidth: { xs: photoWidth, md: photoWidth }, 
                    borderRadius:{borderRadius}, 
                    objectFit:'cover'
                  }}
                  loading="lazy"

                  alt=""  
                  src={`${domainName}${'/EddyDreams/otherFiles/images/'}${Photo2Page4}`}
                />
              </Grid>
            </Grid>
          </Grid> 
        </Grid>
      </>   
  );
}


Page4.propTypes = {    
  page4Features: PropTypes.object, 
}; 
 