
import { useEffect, } from 'react'; 
import { Helmet } from 'react-helmet-async';  
import { useCookies  } from 'react-cookie';
import { useNavigate, useParams  } from 'react-router-dom'; 
import { useDispatch, useSelector } from 'react-redux';
// mui
import { Container, Typography, Grid, IconButton } from '@mui/material';  
import WestIcon from '@mui/icons-material/West';
 


// sections 
import {  
  OneFeastCardVideo,
} from '../../sections/@users/homeUser';


// hooks
// import useResponsive from '../../hooks/useResponsive';
  

 
import {ViewOneFeastCardVideoREQUEST,} from '../../redux/viewOneFeastCardVideo/viewOneFeastCardVideoActions'; 
  
// ----------------------------------------------------------------------
 

export default function ShowOneFeastCardReceivedVideo() { 
  const { _FeastCardsId } = useParams();  
  const navigate = useNavigate();  
  const dispatch = useDispatch();  

 
  const viewOneFeastCardVideoReducerValue = useSelector(state => state.viewOneFeastCardVideoReducerValue);
 
  let viewOneFeastCardVideoValue = []; 


  if(viewOneFeastCardVideoReducerValue.user){
    viewOneFeastCardVideoValue = viewOneFeastCardVideoReducerValue.user.message;  
  } 
 



  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      dispatch(ViewOneFeastCardVideoREQUEST({
        token: cookies._token_planetryvilage.token, 
        type: cookies._token_planetryvilage.type,
        FeastCardsId: _FeastCardsId,
        SenderReceiver: 'Receiver'
      }));
    } else {
      navigate('/login', { replace: true });  
    }
  }, [cookies._token_planetryvilage, dispatch, navigate, _FeastCardsId]); 
 

  const filteredCategory = viewOneFeastCardVideoValue
       
   
  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Wishes Received </title>
      </Helmet>
      
      
      <Container maxWidth="xl"  sx={{px:0, }} >  

          <Grid container spacing={0} sx={{pb:1, }}>   

            <Grid  item xs={12} sm={12} md={12}  sx={{m:'auto', p:0}}>  
              <IconButton aria-label="back" sx={{fontSize:13, color:'white', p:0}} className="back" onClick={() => navigate('/wishes/received') }>
                <WestIcon/>   
              </IconButton>
            </Grid> 

            <Grid  item xs={12} sm={12} md={12}  sx={{m:'auto', p:0}}>  

              {filteredCategory.length ? filteredCategory.map((item, key) => (
              <OneFeastCardVideo key={key} cardItem = {item}/>
              )) :
               (<Typography sx={{color:'white'}}>{''}</Typography>)
              }  
            </Grid>
            
          </Grid>  

      </Container>  
    </>
  );
}
