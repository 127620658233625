import { useState, memo } from 'react';
import PropTypes from 'prop-types';
 
import CloseIcon from '@mui/icons-material/Close';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import AudiotrackIcon from '@mui/icons-material/Audiotrack'; 

// @mui
import { styled } from '@mui/material/styles';
import { Tooltip, Stack, Popover, IconButton, Typography } from '@mui/material';

// component
import Iconify from '../../../components/iconify';

import useResponsive from '../../../hooks/useResponsive';


 

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999, 
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(18),
  height: theme.spacing(5),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));


// ----------------------------------------------------------------------


export const HomeWidget = memo(({handleFilterFiles}) => {   
  const isDesktop = useResponsive('up', 'lg');  
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
 

  const handleAllFilterFiles = (a) => {
    if(isDesktop){
      handleFilterFiles(a)
    }else{ 
      handleFilterFiles(a)
    } 
  }

  return (
    <>
      <StyledRoot onClick={handleOpen}> 
        <Tooltip title="Videos Photos Audio"> 
          <Iconify icon="material-symbols:filter-list-rounded" width={24} height={24} /> 
        </Tooltip>
      </StyledRoot>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          <IconButton aria-label="close"  onClick={handleClose}>
            <CloseIcon sx={{fontSize:{xs:15, md:20}, color: 'black', ml:17}} />   
          </IconButton>   

          <IconButton aria-label="videos" sx={{fontSize:{xs:13, md:15}}} onClick={() => {handleClose(); handleAllFilterFiles({videoBool: true, audioBool: false, photoBool : false});}}>
            <VideoCameraBackIcon sx={{fontSize:{xs:15, md:20}}} />  
            <Typography variant='button' sx={{ml:1}}>Videos</Typography>
          </IconButton>

          <IconButton aria-label="audio" sx={{fontSize:{xs:13, md:15}}} onClick={() => {handleClose(); handleAllFilterFiles({videoBool: false, audioBool: true, photoBool : false});}}>
            <AudiotrackIcon sx={{fontSize:{xs:15, md:20}}} />  
            <Typography variant='button' sx={{ml:1}}>Audio</Typography>
          </IconButton>     
        </Stack>
      </Popover>
    </>
  );
})


HomeWidget.propTypes = {     
  handleFilterFiles: PropTypes.func, 
};