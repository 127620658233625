 
import { useNavigate,  } from 'react-router-dom'; 
import PropTypes from 'prop-types';
import {CardActions, Grid, Typography, IconButton} from '@mui/material'; 

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat'; 
 

import { fShortenNumber } from '../../../../utils/formatNumber'; 
 


import {domainName} from '../../../../utils/domain';

// ---------------------------------------------------------------------

Page1.propTypes = { 
  page1Features: PropTypes.object, 
  BackgroundColor:  PropTypes.string,   
}; 

export default function Page1 ({page1Features, BackgroundColor})  {
  const navigate = useNavigate();   
  
  const {
    height,
    FeastCardsId,
    NumberLikes,
    NumberViews,
    NumberComments,
    eventCardBackgroundName,
    wishOption
  } = page1Features;    

  const backImage = `${domainName}${'/EddyDreams/gifs/'}${eventCardBackgroundName}`; 

  const URI =  wishOption === 'Wishes Received'? `${'/wishes/received/'}${FeastCardsId}`:`${'/wishes/sent/'}${FeastCardsId}`
  

  return (   
      <>
        <Grid container spacing={1} sx={{height:{height}, backgroundColor:'white', color:BackgroundColor, backgroundImage:`url(${backImage})`, backgroundSize: 'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} >
          <Grid item xs={12} sm={12} md={12}> 
            <CardActions disableSpacing  className="notify" onClick={() => {navigate(URI)}}>
              <IconButton aria-label="add to favorites" sx={{fontSize:{xs:13, md:15}, color:BackgroundColor}} >
                {fShortenNumber(NumberLikes)} <FavoriteBorderIcon sx={{fontSize:{xs:15, md:20}, }} />
              </IconButton>
              <IconButton aria-label="views" sx={{fontSize:{xs:13, md:15}, color:BackgroundColor}}>
                {fShortenNumber(NumberViews)} <VisibilityIcon  sx={{fontSize:{xs:15, md:20}}}/>
              </IconButton> 

              <IconButton aria-label="comment" sx={{fontSize:{xs:13, md:15}, color:BackgroundColor}}>
                {fShortenNumber(NumberComments)} <ChatIcon  sx={{fontSize:{xs:15, md:20}}}/>
              </IconButton> 
 
            </CardActions> 
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{display: 'inline-flex', flexDirection: 'row', justifyContent: {xs:'', md:'center'}, mt:'2vw' }}> 
            <Typography  sx={{fontSize:{xs:40, md:60}, font:`50px 'Lovers Quarrel'`}}>Open me, please</Typography>
          </Grid> 
        </Grid>
      </>   
  );
}


 