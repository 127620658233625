
import ViewOneFeastCardVideoActionTypes from './viewOneFeastCardVideoTypes'

const INITIAL_STATE = {
    loading : false,
    user : null,
    error : null
}

export const viewOneFeastCardVideoReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) { 
        case ViewOneFeastCardVideoActionTypes.View_One_Feast_Card_Video_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case ViewOneFeastCardVideoActionTypes.View_One_Feast_Card_Video_SUCCESS:
            return {
                ...state,
                user: action.response,
                loading: false,
            };

        case ViewOneFeastCardVideoActionTypes.View_One_Feast_Card_Video_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            }; 

        case ViewOneFeastCardVideoActionTypes.View_One_Feast_Card_Video_RESET:
            return {
                ...state,
                error: null,
                loading: false,
                user : null,
            };
        default:
            return state;
    }
}
