
import { useState, memo} from 'react'; 
import PropTypes from 'prop-types';
import axios from 'axios';  

// @mui
import { Card, Grid, Box, Typography, Stack, Divider, Avatar  } from '@mui/material';  
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';


import {domainName} from '../../../utils/domain';
// ----------------------------------------------------------------------



export const BreakingNews = memo(({item, features, tokenUser, type, competitionId}) => {   
  const { 
  	Photo,
    NewFirstName,
    NewLastName,  
    City,
    Country,
    Continent,
    CompetitionBegin,
    CompetitionEnd,
    EnrollmentEnd, 
    Description,
    token
  } = item;

  const [error, setError] = useState('');

  const {
  	buttonText, 
  	backColor, 
  	color
  } = features;



  /*  Begin upload */ 
  const urlLocalRequest = `${domainName}/u/enrol/competition/save`;

  const upload = async () => {    
    const setBreakingNewsIdentifier_ = await competitionId;
    const tokenPromoter_ = await token; 
    const token_ = await tokenUser;
    const type_ = await type;
 
    const resp = await sendFileData(token_, type_, tokenPromoter_, setBreakingNewsIdentifier_, urlLocalRequest);
 
    const jsonResponse = await resp.data; 
    const jsres = await jsonResponse.message; 

    await setError(jsres); 
  } 
 

  const sendFileData = (token_, type_, tokenPromoter_, setBreakingNewsIdentifier_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {   
        token: token_,
        type: type_, 
        tokenPromoter: tokenPromoter_,
        setBreakingNewsIdentifier: setBreakingNewsIdentifier_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  } 
  /*  End upload */ 

  return (  
    <Grid  item xs={12} sm={6} md={3} >    
      <Typography variant="subtitle" color="white" fontSize="14px">
        Breaking News
      </Typography>

      <Card sx={{
        boxShadow: 0,
        textAlign: 'left',
        color: {color},   
        backgroundColor: backColor, 
        minHeight:'400px'
        // borderRadius:0,
         
      }}>  

        <Stack spacing={1} sx={{ p: 2 }}>
          <Stack direction="row" > 

            <Avatar sx={{ bgcolor: grey[500], height:{xs:40, sm:40, md:50}, width:{xs:40, sm:40, md:50}, mr:1 }}  alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} />
            <Typography variant="subtitle1"  sx={{fontSize:14}} >
              The promoter{','} {NewFirstName}{' '}{NewLastName} from {City}{','} {Country}{','} {Continent}
            </Typography> 
          </Stack>

          <Divider sx={{backgroundColor:'black', m:1, }} /> 

          <Stack direction="row" alignItems="left" >
            <Stack>  
              <Box  sx={{fontSize:{xs:12, sm:12, md:14}}}>
                <Typography variant="body2">Message: </Typography >{Description}
              </Box> 


              <Divider sx={{backgroundColor:'black', m:1, }} />
               
              <Typography variant="subtitle2" sx={{fontSize:{xs:12, sm:12, md:14}}}>
                The competition will begin on the {CompetitionBegin} and end on the {CompetitionEnd} 
              </Typography>
               
              <Typography variant="subtitle2" sx={{fontSize:{xs:12, sm:12, md:14}}}>
                The enrolment will end on the {EnrollmentEnd}   
              </Typography> 

              <Divider sx={{backgroundColor:'black', m:1, }} /> 
              
              <Typography variant='subtitle2' color='red'>{error}</Typography>

              <LoadingButton  fullWidth size="large" variant="contained" onClick={upload}>
                {buttonText}
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Grid> 
  );
})


BreakingNews.propTypes = {    
  item: PropTypes.object, 
  features: PropTypes.object, 
  tokenUser: PropTypes.string, 
  type: PropTypes.string, 
  competitionId: PropTypes.string, 
};
