
import PropTypes from 'prop-types';

// mui
import { FormLabel, RadioGroup, FormControlLabel, Radio, Box  } from '@mui/material'; 

 
import {domainName} from '../../../utils/domain';
// ----------------------------------------------------------------------
 
BackgroundRadioGroup.propTypes = {
  backgroundDetails: PropTypes.arrayOf(PropTypes.object),
  handleImageTemplate: PropTypes.func,
  imageTemplate: PropTypes.string, 
};

export default function BackgroundRadioGroup({backgroundDetails, handleImageTemplate, imageTemplate}) { 
   
  return (
    <> 
        <FormLabel id="demo-radio-buttons-group-label">Select a template</FormLabel>
        <RadioGroup 
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Are you left open-mouthed by this video?"
            name="radio-buttons-group"
            value={imageTemplate}
            onChange={handleImageTemplate} 
        >  
            {backgroundDetails.map((item, key) => (
            <div key={key} style={{margin:1}}>
                <Box  component="img"
                    sx={{  
                      height:'80px',
                      width: '80px',
                      maxHeight: { xs: '80px', md: '80px' },
                      maxWidth: { xs: '100%', md: '100%' }, 
                      mb:1
                    }} 
                    
                    loading="lazy"

                    alt="photo"   
                    
                    src={`${domainName}/EddyDreams/gifs/${item.back}`}
                /> 
                <FormControlLabel value= {item.back} control={<Radio />} label=""  sx={{m:0, p:0}}  />
            </div>
            ))}  
        </RadioGroup>
    </>
  );
}

 
