
import { useState, useEffect, useRef, useMemo } from 'react'; 
import { Helmet } from 'react-helmet-async';   
import { useCookies  } from 'react-cookie';
import { useNavigate, useParams  } from 'react-router-dom'; 
import { useDispatch, useSelector } from 'react-redux';
import { useSpeechRecognition } from "react-speech-recognition"; 

// mui
import { Container, Grid, InputAdornment, IconButton, Autocomplete, Popper, TextField, Card  } from '@mui/material';  

import { styled } from '@mui/material/styles';


import { LoadingButton } from '@mui/lab';

import BubblePreloader from 'react-bubble-preloader';
 


// components 
import Iconify from '../../components/iconify'; 

// sections 
import {  
  VideoAudioPhoto, HomeWidget,
} from '../../sections/@users/villageUser';
 
  

 
import {ViewVillageVideoAudioREQUEST,} from '../../redux/viewVillageVideoAudio/viewVillageVideoAudioActions'; 

import {ViewOneVillageVideoAudioRESET,} from '../../redux/viewOneVillageVideoAudio/viewOneVillageVideoAudioActions';  
import {ReportAbuseRESET,} from '../../redux/reportAbuse/reportAbuseActions'; 



// util
import { removeCommaQuestionMarkFullStopLowerCase, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, stringInclude, replaceWordTrack,
       playFullScreen, cloud29a, cloud27a, cloud144
     } from '../../utils/translateFunctions';
 
// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});


export default function VillagePage() { 
  const navigate = useNavigate();  
  const dispatch = useDispatch(); 
  const { villageId } = useParams();  
  const inputValue = useRef(''); 
 
  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i-=1) {
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array; 
  };  

 
  const viewVillageVideoAudioReducerValue = useSelector(state => state.viewVillageVideoAudioReducerValue);
    


  const viewVillageVideoAudioValue = useRef([]);  
  const villageNameValue = useRef('');  
  const villageIdentifierValue = useRef([]); 
  const isloading = useRef(true);
  

  const i = useRef(0);
 
  if(viewVillageVideoAudioReducerValue.user && i.current === 0){
    viewVillageVideoAudioValue.current = viewVillageVideoAudioReducerValue.user.message; 
    viewVillageVideoAudioValue.current = shuffle(viewVillageVideoAudioValue.current).slice(0, 8);
       
    isloading.current = viewVillageVideoAudioReducerValue.loading;   
    villageNameValue.current = viewVillageVideoAudioReducerValue.user.getSessionName[0].name;  
    villageIdentifierValue.current = viewVillageVideoAudioReducerValue.user.sessionIdentifier;   
    
    i.current +=1 
  } 
   

  
  const handleReloadPage = async () => { 
    window.location.reload();  
  } 


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      dispatch(ViewVillageVideoAudioREQUEST({
        token: cookies._token_planetryvilage.token, 
        type: cookies._token_planetryvilage.type,
        sessionIdentifier: villageId,
      }));

      dispatch(ViewOneVillageVideoAudioRESET(null));
      dispatch(ReportAbuseRESET(null));
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, villageId, dispatch, navigate]); 


 
 

  const [searchField, setSearchField] = useState({
    videoBool: true, 
    audioBool: false, 
    photoBool: false
  });  

  const [searchCategory, setSearchCategory] = useState('');
 

  /* beginning video */
  const filteredFiles = viewVillageVideoAudioValue.current.filter( 
    (file) => 
       (
        file
        .fileTypeDivision
        .toLowerCase()
        .includes(('Video').toLowerCase())    
      )  
  );  

  const filteredCategory = useMemo(() => filteredFiles.filter(
    (file) =>  
       (
        (`${file.NewFullName}${' '}${file.Description}${' '}${file.DateCreated}${' '}${file.sessionvideoaudioId}`)
        .toLowerCase()
        .includes(searchCategory.toLowerCase())    
      )  
  ) , [filteredFiles, searchCategory]);
  /* end video */


  /* beginning audio */
  const filteredFilesAudio = viewVillageVideoAudioValue.current.filter(
    (file) => 
       (
        file
        .fileTypeDivision
        .toLowerCase()
        .includes(('Audio').toLowerCase())    
      )  
  ); 

  const filteredCategoryAudio = useMemo(() => filteredFilesAudio.filter(
    (file) =>  
       (
        (`${file.NewFullName}${' '}${file.Description}${' '}${file.DateCreated}${' '}${file.sessionvideoaudioId}`)
        .toLowerCase()
        .includes(searchCategory.toLowerCase())    
      )  
  ) , [filteredFilesAudio, searchCategory]); 
  /* end audio */
 

  const handleFilterFiles = a => {
    inputValue.current.value = '';  
    setSearchCategory(inputValue.current.value); 

    setSearchField({
      searchField, 
      ...a
    }); 
  };


  const handleSearchName = () => { 
    setSearchCategory(inputValue.current.value); 
    inputValue.current.value = '';  
  }




  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
       

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) { 
        let searchSomething;  

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  
          // const removeFirstElementFromString_ = await removeFirstElementFromString(t); 
          
          // search a specific song 
          if (await stringComparison([cloud144.a, cloud144.b, cloud144.c, cloud144.d, cloud144.e, cloud144.f, cloud144.g, cloud144.h, cloud144.i, cloud144.j, cloud144.k, cloud144.l], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            searchSomething = await tm; 
            await setSearchCategory(searchSomething);
          }

          /* the shared audios */
          if (await stringComparison([cloud29a.a, cloud29a.b, cloud29a.c, cloud29a.d, cloud29a.e, cloud29a.f, cloud29a.g, cloud29a.h, cloud29a.i, cloud29a.j, cloud29a.k, cloud29a.l, cloud29a.m, cloud29a.n, cloud29a.o, cloud29a.p], t)) {
            inputValue.current.value = await '';  
            await setSearchCategory(inputValue.current.value); 

            const a = await {videoBool: false, audioBool: true, photoBool : false}
            await setSearchField({
              searchField,
              ...a
            });
          }

          /* the shared videos */
          if (await stringComparison([cloud27a.a, cloud27a.b, cloud27a.c, cloud27a.d, cloud27a.e, cloud27a.f, cloud27a.g, cloud27a.h, cloud27a.i, cloud27a.j, cloud27a.k, cloud27a.l, cloud27a.m, cloud27a.n, cloud27a.o, cloud27a.p], t)) {
            inputValue.current.value = await '';  
            await setSearchCategory(inputValue.current.value); 
            
            const a = await {videoBool: true, audioBool: false, photoBool : false}
            await setSearchField({
              searchField,
              ...a
            });
          } 


          if (await stringInclude(t, "plays")) {
            t = await replaceWordTrack(t, 'plays');
          } else
          if (await stringInclude(t, "played")) {
            t = await replaceWordTrack(t, 'played');
          } else
          if (await stringInclude(t, 'is playing')) {
            t = await replaceWordTrack(t, 'is playing');
          }

          if (await stringInclude(t, "song")) {
            t = await replaceWordTrack(t, 'song');
          } else
          if (await stringInclude(t, "audio")) {
            t = await replaceWordTrack(t, 'audio');
          } else
          if (await stringInclude(t, "video")) {
            t = await replaceWordTrack(t, 'video');
          } else
          if (await stringInclude(t, "music")) {
            t = await replaceWordTrack(t, 'music');
          } else
          if (await stringInclude(t, "number")) {
            t = await replaceWordTrack(t, 'number');
          } else
          if (await stringInclude(t, "segment")) {
            t = await replaceWordTrack(t, 'segment');
          } else
          if (await stringInclude(t, "park")) {
            t = await replaceWordTrack(t, 'park');
          } else
          if (await stringInclude(t, "top")) {
            t = await replaceWordTrack(t, 'top');
          }


          if (await stringInclude(t, "the")) {
            t = await replaceWordTrack(t, 'the');
          }

          if (await stringInclude(t, " of ")) {
            t = await replaceWordTrack(t, ' of ');
          }

          if (await stringInclude(t, "of")) {
            t = await replaceWordTrack(t, 'of');
          }


          if (await stringInclude(t, "eh")) {
            t = await replaceWordTrack(t, 'eh');
          } else
          if (await stringInclude(t, "huh")) {
            t = await replaceWordTrack(t, 'huh');
          }
          
          if(await searchField.videoBool) {  
            for (let i = 0; i < filteredFiles.length; i+=1) {  
              if (stringComparison([`${"play track "}${i+1}`], t)) {   
                const a = document.getElementsByClassName(`${"play track "}${i}`)[0];
                if (typeof(a) !== 'undefined' && a !== null) {
                  playFullScreen(a);
                }
              }
            }
          }  

          
          if(await searchField.audioBool) {  
            for (let i = 0; i < filteredFilesAudio.length; i+=1) {  
              if (stringComparison([`${"play track "}${i+1}`], t)) {   
                const a = document.getElementsByClassName(`${"play track "}${i}`)[0];
                if (typeof(a) !== 'undefined' && a !== null) {
                  playFullScreen(a);
                }
              }
            }
          }


          const Y = ['play track four', 'play track for'];
          for (let i = 0; i < Y.length; i+=1) {
            if (stringComparison([Y[i]], t)) { 
              const a = document.getElementsByClassName(`${"play track "}${i}`)[0]
              if (typeof(a) !== 'undefined' && a !== null) {
                playFullScreen(a);
              }
            }
          }  

        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, searchField, filteredFiles.length, filteredFilesAudio.length]);

 
  /* End Michael */  
  
     
   
  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Home </title>
      </Helmet>
      
      
      <Container maxWidth="xl"  sx={{px:0, }} > 
        
          <Grid container spacing={2} sx={{pb:1, }} justifyContent='center' textAlign='center'>  


            <Grid  item xs={12} sm={6} md={6} sx={{mb: {xs:0, sm:0, md:1}, mt: {xs:0.2, sm:0.2, md:0.5, lg:0}}}>  

            <Card sx={{borderRadius:{xs:0, sm:1, md:1}}}> 
              {villageNameValue.current}  
            </Card>  
            {/* <TextField fullWidth inputRef={inputValue}  onChange={(e) => {inputValue.current.value = e.target.value} } // value={inputValue}   onChange={(e) => setInputValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end"> 
                    <IconButton color="primary" onClick={handleSearchName}>
                        <Iconify icon="ic:sharp-search" />
                      </IconButton>
                  </InputAdornment>
                ),
              }} 
              name="search" label="search" /> */}

            <Autocomplete  
              autoHighlight 
              popupIcon={null}
              PopperComponent={StyledPopper}
              options={viewVillageVideoAudioValue.current}
              getOptionLabel={(post) => `${post.NewFullName}${' '}${post.Description}${' '}${post.DateCreated}${' '}${post.sessionvideoaudioId}`} 
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField  variant="standard"
                  inputRef={inputValue}
                  {...params}
                  placeholder="Search..."
                  onKeyDown={(event) => {
                    if (event.key === 'Enter'){
                      handleSearchName()
                    } 
                  }}
                  InputProps={{
                    style: { color: 'white' },
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                       <IconButton color="primary" onClick={handleSearchName}>
                        <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                       </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />

          </Grid>

          <Grid  item xs={12} sm={12} md={12}  >  
            <Grid container spacing={1} sx={{pb:1, }}  justifyContent='center' textAlign='center'>   

              {searchField.videoBool && filteredCategory.map((item, key) => (
              <Grid key={key}  item xs={12} sm={12} md={6} lg={3}  > 
                <VideoAudioPhoto key={key} keyProp={key} cardItem = {item} villageIdentifier={villageIdentifierValue.current}/> 
              </Grid>
              )) 
              }

              {searchField.audioBool && filteredCategoryAudio.map((item, key) => (
              <Grid key={key}  item xs={12} sm={12} md={6} lg={3} > 
                <VideoAudioPhoto key={key} keyProp={key} cardItem = {item} villageIdentifier={villageIdentifierValue.current}/>
              </Grid>
              )) 
              }
            </Grid>
          </Grid>

          {/**/}
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}  alignItems="center" justifyContent="center"> 
                 
                {isloading.current && 
                  <BubblePreloader
                  bubble={{ width: '2rem', height: '2rem' }}
                  animation={{ speed: 2 }}
                  style={{margin:'auto', padding:0, textAlign:'center', position: 'relative'}}
                  colors={['blue', 'aqua', '#f3f3f3']}
                 />  
                } 
 
                 
                {!isloading.current && 
                <LoadingButton sx={{marginTop:5}}  size="small" title='button load more' variant="contained"  onClick={()=>{handleReloadPage()}} >
                  Load more
                </LoadingButton> 
                }
              </Grid> 
            </Grid> 
            {/**/}
            
        </Grid> 

        <HomeWidget handleFilterFiles={handleFilterFiles} />      
           
      </Container>  
    </>
  );
}
