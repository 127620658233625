

import AllDataOnceActionTypes from './allDataOnceTypes'


export const AllDataOnceREQUEST = (item) => ({
    type: AllDataOnceActionTypes.All_Data_Once_REQUEST,
    payload: item
}) 
 

export const AllDataOnceRESET = item => ({
    type: AllDataOnceActionTypes.All_Data_Once_RESET,
    payload: item
}) 


export const AllDataOnceShufflePhoto = () => ({
    type: AllDataOnceActionTypes.All_Data_Once_SHUFFLE_PHOTO,
    // payload: item
}) 

export const AllDataOnceShuffleAudio = () => ({
    type: AllDataOnceActionTypes.All_Data_Once_SHUFFLE_AUDIO,
    // payload: item
}) 

export const AllDataOnceShuffleVideo = () => ({
    type: AllDataOnceActionTypes.All_Data_Once_SHUFFLE_VIDEO,
    // payload: item
}) 




export const AllDataOnceShuffleMagazine = () => ({
    type: AllDataOnceActionTypes.All_Data_Once_SHUFFLE_MAGAZINE,
    // payload: item
}) 



export const AllDataOnceShuffleEventCard = () => ({
    type: AllDataOnceActionTypes.All_Data_Once_SHUFFLE_EVENT_CARD,
    // payload: item
}) 




export const AllDataOnceShuffleWishSent = () => ({
    type: AllDataOnceActionTypes.All_Data_Once_SHUFFLE_WISH_SENT,
    // payload: item
}) 

export const AllDataOnceShuffleWishReceived = () => ({
    type: AllDataOnceActionTypes.All_Data_Once_SHUFFLE_WISH_RECEIVED,
    // payload: item
}) 