import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { useCookies,  } from 'react-cookie'; 
import { useSelector } from 'react-redux'; 

// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, Avatar, IconButton, Popover, ListItemButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; 


// icons
import { Icon } from '@iconify/react';


import {domainName} from '../../../utils/domain';
// mocks_
// import account from '../../../_mock/account';

// import AccountSection from './AccountSection';

// ----------------------------------------------------------------------
/*
const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  }, 
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];
*/

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999, 
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(30),
  height: theme.spacing(5),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary, 
  // backgroundColor: theme.palette.background.paper,
  // borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  // borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderRadius: 50,
  width:33,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

export default function AccountWidget() {
  const navigate = useNavigate();  

  const [cookies, setCookie, removeCookie ] = useCookies(['_token_planetryvilage']);

  const logOut = () => {
    if(cookies._token_planetryvilage !== undefined) {
      setCookie('_token_planetryvilage', undefined, {maxAge: 0, }); 
      removeCookie('_token_planetryvilage');
      navigate('/login', { replace: true });
    }
  }



  let _NewFullName = ''; 
  let _Photo = 'user.png'; 
  let _EmailAddress = ''; 
  
  
  
  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);
  
  if(allDataOnceReducerValue.user){  
    _NewFullName = allDataOnceReducerValue.user.usersDetails[0].NewFullName;  
    _Photo = allDataOnceReducerValue.user.usersDetails[0].Photo;   
    _EmailAddress = allDataOnceReducerValue.user.usersDetails[0].EmailAddress;  
  } 



  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  }; 



  /* const [openAccountSection, setOpenAccountSection] = useState(null);

  const handleOpenAccountSection = (event) => {
    setOpenAccountSection(event.currentTarget);
  };

  const handleCloseAccountSection = () => {
    setOpenAccountSection(null);
  }; */

  const handleClickNavigateToUpdatePhoto = () => {
    navigate('/update/profile/photo', { replace: true });
  };  

  const handleClickNavigateToUpdateContent = () => {
    navigate('/update/profile/content', { replace: true });
  };

  const handleClickNavigateToUpdatePassword = () => {
    navigate('/update/password', { replace: true });
  };

  return (
    <>
     <StyledRoot onClick={handleOpen}> 
      <IconButton 
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar sx={{height:30, width:30}} src={`${domainName}/EddyDreams/Home/images/${_Photo}`} alt="photoURL" />
      </IconButton>
     </StyledRoot>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 240,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
 
        <Grid container spacing={0} textAlign='right'> 
          <Grid  item xs={12} sm={12} md={12}>
            <IconButton aria-label="close"  onClick={handleClose}>
              <CloseIcon sx={{fontSize:{xs:20, md:20}, color: 'black'}} />   
            </IconButton>  
            <Divider sx={{ borderStyle: 'dashed' }} />
          </Grid>
        </Grid> 

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap className="notranslate">
            {_NewFullName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {_EmailAddress}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <ListItemButton onClick={handleClickNavigateToUpdatePhoto}> 
              <Box component={Icon} icon='material-symbols:photo-camera' sx={{ width: 25, height: 25, mr:1}} />  
              <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Upload profile photo</Typography> 
          </ListItemButton> 
          <ListItemButton onClick={handleClickNavigateToUpdateContent}> 
              <Box component={Icon} icon='mdi:clipboard-text-outline' sx={{ width: 25, height: 25, mr:1}} />  
              <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Upload profile content</Typography> 
          </ListItemButton> 
          <ListItemButton onClick={handleClickNavigateToUpdatePassword}> 
              <Box component={Icon} icon='mdi:password' sx={{ width: 25, height: 25, mr:1}} />  
              <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>Update password</Typography> 
          </ListItemButton> 

          {/* {MENU_OPTIONS.map((option, key) => (
            <ListItemButton key={key} onClick={(e) => {handleOpenAccountSection(e); }}> 
              <Box component={Icon} icon={option.icon} sx={{ width: 15, height: 15, mr:1}} />  
              <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }}>{option.label}</Typography> 
            </ListItemButton> 
          ))}
          <AccountSection openAccountSection={openAccountSection} handleCloseAccountSection={handleCloseAccountSection} setOpen={setOpen}/> */}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <MenuItem onClick={handleClose} sx={{ m: 1 }}>
          Logout
        </MenuItem> */}

        <ListItemButton onClick={() => {handleClose(); logOut()}} sx={{ m: 1 }}> 
          <Box component={Icon} icon='mdi:location-exit' sx={{ width: 15, height: 15, mr:1}} /> 
          <Typography sx={{fontSize:{xs:'13px', sm:'13px', md:'15px'}, }} >Logout</Typography> 
        </ListItemButton> 
      </Popover>
    </>
  );
}
