
import { useNavigate, } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Tooltip, } from '@mui/material';

// component
import Iconify from '../../../components/iconify'; 




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999, 
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(30),
  height: theme.spacing(5),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 2,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 2,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));


// ---------------------------------------------------------------------- 

export default function SongVirtualAssistantWidget() {   
  const navigate = useNavigate();   

  return (
    <>
      <StyledRoot onClick={() => {navigate('/play/song/using/michael', { replace: true });}}> 
        <Tooltip title="Play a song using Michael"> 
          <Iconify icon="game-icons:love-song" width={24} height={24} color='red'/> 
        </Tooltip>
      </StyledRoot> 
    </>
  );
}

