    
import PropTypes from 'prop-types';
import {CardFullScreen} from '../cardEvent/AllPages';





export default function EventCardFullScreen ({wishItem, eventOrWish}) { 
  const _wishItem = wishItem;
  const _eventOrWish = eventOrWish;

  return (  
    <CardFullScreen wishItem={_wishItem} eventOrWish={_eventOrWish}/>
  );
}


EventCardFullScreen.propTypes = {    
  wishItem: PropTypes.object,
  eventOrWish: PropTypes.string,
}; 
 

