
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import ViewOneFeastCardVideoActionTypes from '../viewOneFeastCardVideo/viewOneFeastCardVideoTypes'

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}/u/view/feast/card/video/single`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            token: user.token,
            type: user.type,
            FeastCardsId: user.FeastCardsId,
            SenderReceiver: user.SenderReceiver,
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaViewOneFeastCardVideo({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: ViewOneFeastCardVideoActionTypes.View_One_Feast_Card_Video_SUCCESS, response });
    } catch (error) {
        yield put({ type: ViewOneFeastCardVideoActionTypes.View_One_Feast_Card_Video_FAILURE, error: error.message });
    }
}

export function* sagaViewOneFeastCardVideoScreen() {
    yield takeLatest(ViewOneFeastCardVideoActionTypes.View_One_Feast_Card_Video_REQUEST, sagaViewOneFeastCardVideo);
}
