
import BusinessCardActionTypes from './businessCardTypes'

const INITIAL_STATE = {
    loading : false,
    user : null,
    error : null
}

export const businessCardReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) { 
        case BusinessCardActionTypes.Business_Card_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case BusinessCardActionTypes.Business_Card_SUCCESS:
            return {
                ...state,
                user: action.response,
                loading: false,
            };

        case BusinessCardActionTypes.LOG_IN_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            }; 
        default:
            return state;
    }
}
