
import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux'; 
import { useCookies  } from 'react-cookie';
import { useNavigate, } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Card, Grid, Box, Typography, Stack, Divider, Avatar  } from '@mui/material';  
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';

 

import {domainName} from '../../../utils/domain';



// ----------------------------------------------------------------------

ConferenceLiveItem.propTypes = {    
  item: PropTypes.object, 
  features: PropTypes.object, 
};

export default function ConferenceLiveItem({item, features}) {   
  const navigate = useNavigate();  
  const token = useRef(null); 
  const type = useRef(null);


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
        token.current = cookies._token_planetryvilage.token;  
        type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]);   



  const { 
  	Photo,
    NewFullName,  
    City,
    Country,
    Continent,
    DateOnline,
    RoomName,
    TimeFrom,
    TimeTo, 
    Description,
    categoryDivision,
    descriptionIdentifier, 
  } = item;

  const {
  	buttonText, 
  	backColor, 
  	color, 
  } = features;
 


  const tvRadioStreamingReducerValue = useSelector(state => state.tvRadioStreamingReducerValue); 
 
  let UserSession = 'User';
  let FirstExtension = 'Unknown';
  let SecondExtension = 'Unknown';
  let ThirdExtension = 'Unknown';
  let FourExtension = 'Unknown';
  let FifthExtension =  'Unknown';

  if(tvRadioStreamingReducerValue.user){
    UserSession = tvRadioStreamingReducerValue.user.User_Session;
    FirstExtension = tvRadioStreamingReducerValue.user.First_Extension;
    SecondExtension = tvRadioStreamingReducerValue.user.Second_Extension;
    ThirdExtension = tvRadioStreamingReducerValue.user.Third_Extension;
    FourExtension = tvRadioStreamingReducerValue.user.Four_Extension;
    FifthExtension =  tvRadioStreamingReducerValue.user.Fifth_Extension;
  } 

  return (  
    <Grid  item xs={12} sm={6} md={4} >  
      <Card sx={{
        boxShadow: 0,
        textAlign: 'left',
        color: {color},   
        backgroundColor: backColor,  
        // borderRadius:0,
        borderRadius:{xs:0, sm:1, md:1}
         
      }}>  

        <Stack spacing={1} sx={{ p: 2 }}>
          <Stack direction="row" >
            {/* <Box  component="img"
              sx={{ 
                height: 150,
                width: '100%',
                maxHeight: { xs: 150, md: 150 },
                maxWidth: { xs: '100%', md: '100%' }, 
              }}

              alt="" 
              src={`/assets/files/${photoURL}`}
            /> */}
            <Avatar sx={{ bgcolor: grey[500], height:40, width:40 }}  alt='' src={`${domainName}/EddyDreams/Home/images/${Photo}`} />
          </Stack>

          <Stack direction="row" alignItems="left" >
            <Stack>
              <Typography variant="subtitle1" className="notranslate" >
                {NewFullName}
              </Typography> 

              <Typography variant="subtitle2" sx={{fontSize:12}}>
                from {City}{','} {Country}{','} {Continent}
              </Typography>  

              <Typography variant="subtitle2" sx={{fontSize:12}}> 

                {categoryDivision === 'Collective Intelligence' ? " is willing to debate about a passionate research subject topic in the Reasoning Room" :
                   categoryDivision === 'Birthday' ? " will be celebrating an incredible birthday" :
                   categoryDivision === 'Wedding' ? " will be celebrating a dream wedding" :
                   categoryDivision === 'Motivational Speech' ? " would like to make a motivational speech to uplift you" :
                   categoryDivision === 'Reality TV' ? " will show you his/her lifestyle" :
                   categoryDivision === 'Life Experience' ? " would like to share his/her life experience with you" :
                   categoryDivision === 'Dancers' ? " would like to teach you a dance" :
                   categoryDivision === 'Singers' ? " would like to teach you a song" :
                   categoryDivision === 'Hairstyle' ? " would like to teach the new generation how to do a famous hairstyle" :
                   categoryDivision === 'Make-Up' ? " would like to teach the new generation how to do a famous make-up style" :
                   categoryDivision === 'Outfit' ? " would like to teach you a fantastic dress code" :
                   categoryDivision === 'Gossip' ? " would like to gossip about a celebrity" :
                   categoryDivision === 'Talking Non-Stop' ? " would like to teach you how to talk non-stop" :
                   categoryDivision === 'Drawing' ? " would like to teach you a drawing technique" :
                   categoryDivision === 'Cooking' ? " would like to teach you how to cook an outstanding meal" :
                   categoryDivision === 'Gym' ? " would like to teach you some gym moves" :
                   categoryDivision === 'Freestyle' ? " would like to teach you a freestyle" :
                   categoryDivision === 'Study Room' ? " will be explaining a lesson" :
                   categoryDivision === 'Help' ? " would like you to explain a lesson" :
                   categoryDivision === 'Anniversary' ? " will be celebrating an incredible anniversary" :
                   categoryDivision === 'Advertising' ? " will showcase an invention" :
                   categoryDivision === 'Opportunity Zones' ? " will talk about the opportunity zones" :
                   ""}
              </Typography>   

              <Divider sx={{backgroundColor:'black', m:1, }} />

              <Typography variant="subtitle2" sx={{fontSize:12}}>
                Live conference set from {TimeFrom} to {TimeTo} on the {DateOnline}
              </Typography>   

              <Divider sx={{backgroundColor:'black', m:1, }} />
  

              <Box  sx={{fontSize:14}}>
                <Typography variant="body2">Concept: </Typography >{Description}
              </Box> 
               
              
              {/* <LoadingButton sx={{width:50, mt:2, p:0}} variant="contained" onClick={() => {navigate(`${'/u/V-M/index'}${'&'}${RoomName}${'&'}${descriptionIdentifier}${'&'}${categoryDivision}${'&'}${UserSession}${'&'}${FirstExtension}${'&'}${SecondExtension}${'&'}${ThirdExtension}${'&'}${FourExtension}${'&'}${FifthExtension}${'&'}${token.current}${'&'}${type.current}`, { replace: true }) }}>
                {buttonText}
              </LoadingButton> */}

              <LoadingButton sx={{width:50, mt:2, p:0}} variant="contained" onClick={() => {window.open(`${domainName}${'/u/V-M/index'}${'&'}${RoomName}${'&'}${descriptionIdentifier}${'&'}${categoryDivision}${'&'}${UserSession}${'&'}${FirstExtension}${'&'}${SecondExtension}${'&'}${ThirdExtension}${'&'}${FourExtension}${'&'}${FifthExtension}${'&'}${token.current}${'&'}${type.current}`, '_blank')}}>
                {buttonText}
              </LoadingButton> 
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Grid> 
  );
}




