
import { useState, useRef, useEffect} from 'react';  
import { useNavigate, } from 'react-router-dom';  
import { useCookies  } from 'react-cookie';  
import axios from 'axios'; 
import { useSelector,  } from 'react-redux'; 
import { useSpeechRecognition } from "react-speech-recognition"; 

// mui
import { Stack, Typography, Grid, TextField, Card, Divider, IconButton, Avatar } from '@mui/material'; 

import { grey } from '@mui/material/colors';  
import { LoadingButton } from '@mui/lab'; 
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// mock
import birthdayBackgroundDetails from '../../../_mock/birthdayBackgroundDetails'; 
import weddingBackgroundDetails from '../../../_mock/weddingBackgroundDetails'; 
import valentineBackgroundDetails from '../../../_mock/valentineBackgroundDetails';
 


import {isFileAnImage, isFileAnAudio, isFileAVideo, fileDuration_} from '../../../utils/otherFunctions'; 

import {Notifications, ExciteRadioGroup, BackgroundRadioGroup, VideoSelection, CategoryFeast} from './AllCreatedFiles';

import {domainName} from '../../../utils/domain';


 
import { newLang, removeCommaQuestionMarkFullStopLowerCase, transMany__, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, 
     } from '../../../utils/translateFunctions';
 
 

import { cloud123, cloud124, cloud125, cloud126, cloud127, cloud128, cloud129, cloud130, cloud131, cloud197, 
         findBackgroundTemplate, cloud100, cloud101, 
          } from '../../../utils/automateCreateEventFeastCardFunctions';
 


const speechSynth = window.speechSynthesis;
const speech = new SpeechSynthesisUtterance();
speech.lang = newLang();
speech.volume = 1;
speech.rate = 1;
speech.pitch = 1;
// ----------------------------------------------------------------------


export default function CreateFeastCard() {  
  const navigate = useNavigate();   

  const token = useRef(null); 
  const type = useRef(null);


  const [cookies, ] = useCookies(['_token_planetryvilage']);    
 
  useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      token.current = cookies._token_planetryvilage.token; 
      type.current = cookies._token_planetryvilage.type;
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, navigate]); 



  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue);

  let allCategoryAllNotificationsByDivisionValue = []; 
  let allCategoryAllNotificationsValentineValue = []; 

  if(allDataOnceReducerValue.user){ 
    allCategoryAllNotificationsByDivisionValue = allDataOnceReducerValue.user.allCategoryAllNotificationsByDivision;   
    allCategoryAllNotificationsValentineValue = allDataOnceReducerValue.user.allCategoryAllNotificationsValentine;   
  } 



  const [category, setSelectedCard] = useState('Birthday');  

  const handleSelectedCard = (category) => {
    setSelectedCard(category);
  }


  const [files, setFiles] = useState([]);  
  const [maxMinute, setMaxMinute] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);   
  const [preview, setPreview] = useState({imageURL:'', audioURL: '', videoURL: ''});
  const [fileTypeCheck, setFileTypeCheck] = useState({imageFile:false, audioFile:false, videoFile:false});

  const [personNameImage, setPersonNameImage] = useState({});
  const [excited, setExcited] = useState('Are you left open-mouthed by this video?');
  const [notificationCard, setNotificationCard] = useState(true);
  const [imageTemplate, setImageTemplate] = useState(category === 'Birthday'?'product33.gif':
                                                     category === 'Wedding'?'product6.gif':
                                                     'valentine.gif');
  const [message, setMessage] = useState(''); 

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);

    setMaxMinute('');
  }


  const hideNotificationCard = (name, image, token) => {
  	setNotificationCard(!notificationCard);
  	setPersonNameImage({
      personNameImage, 
      ...{
        names: name,
        images: image,
        tk: token,
      }
    });
  }  

  const backArrow = () => {
  	setNotificationCard(!notificationCard);
  	setPersonNameImage({});
  }


  const handleExcited = (event) => {
    setExcited(event.target.value);  

    setMaxMinute('');
  }; 

  const handleImageTemplate = (event) => {
    setImageTemplate(event.target.value); 

    setMaxMinute('');
  }; 


 

  
  /*  Begin upload */
  let urlLocalComplete; 
  let urlLocalData;
  let urlLink2;
  let urlLink;
  let options;
  let EndPoints;
  let fileCategory;
  const urlLocalRequest = `${domainName}/u/create/feast/card`;
  const TFile = [];
  let numberOfFiles = 0; 


  const generateUrlFile = (urlLink_) => {
    urlLink2 = `${urlLink_}${'-data'}`;
    urlLink = `${urlLink_}${'-complete'}`;

    return {
      urlLink2,
      urlLink
    };
  }



  const upload = async () => {  
    if(await (message).trim() === ''){
      setMaxMinute('The message is required');
      return;
    }   
    if(await (excited).trim() === ''){
      setMaxMinute('The excitement is required');
      return;
    }  
    if(await (imageTemplate).trim() === ''){
      setMaxMinute('The excitement is required');
      return;
    }  

    if(await files.length === 0){
      setMaxMinute('Select a file');
      return;
    }
 
 

    TFile[0] = files;
    numberOfFiles = await files.length;

    if(fileTypeCheck.imageFile) {  
      fileCategory = 'images';
    } else 
    if(fileTypeCheck.audioFile) { 
      fileCategory = 'audios';
    } else 
    if (fileTypeCheck.videoFile) { 
      fileCategory = 'videos';
    }
 

    urlLink2 = await generateUrlFile(urlLocalRequest).urlLink2;

    urlLink = await generateUrlFile(urlLocalRequest).urlLink;

    urlLocalComplete = await urlLink;
    urlLocalData = await urlLink2;


    EndPoints = await {
      UPLOAD: urlLocalComplete,
      UPLOAD_DATA: urlLocalData,
      UPLOAD_REQUEST: urlLocalRequest
    }
 

    options = await {
      url: EndPoints.UPLOAD,
      startingByte: 0,
      // chunkSize: 100000,
      chunkSize: 1000000,
      fileId: '',
      arrayId: ''
    };
 

    uploadTrigger();  
  }


  const uploadTrigger = async () => {  
    const optionResult = await uploadCreateDirectory();

    const optionResultArrayIdLength = await optionResult.arrayId.length;
    const filesLength = await numberOfFiles;

    if (await (optionResultArrayIdLength > 0)) {
      for (let z = 0; z < filesLength; z+=1) {
        // const files_ = TFile[0].files[z];
        const files_ = (TFile[0])[z];
        if (files_) {   
          uploadExecution(files_, z);
        }
      }
    }
  }
 
 

  const uploadCreateDirectory = async () => {
    const message_ = await message; 
    const EventCardsType = await category;
    const excited_ = await excited;
    const imageTemplate_ = await imageTemplate;
    const tk = await personNameImage.tk;

    const resp = await sendFile(numberOfFiles, fileCategory, EventCardsType, EndPoints.UPLOAD_REQUEST);

    try {
      const jsonResponse = await resp.data; 
      const jsres = await jsonResponse.message;

      if (await (jsres)) {
        await setMaxMinute(jsres);
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        };    
        return options;
      }     

      if (await (resp.status === 400)) {
        await console.log('An error occured in the directory');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      }  


      options = await {
        ...options,
        ...jsonResponse
      };

      const respData = await sendFileData(options.arrayId, message_, EventCardsType, excited_, imageTemplate_, token.current, tk, type.current, fileCategory, EndPoints.UPLOAD_DATA);
       
      if (await (respData.status === 400)) {
        await console.log('Missing file and content');
        options = await {
          ...options,
          ...{
            arrayId: []
          }
        }; 

        return options;
      } 

      return options; 
    } catch (err) {
      console.error('failed to read file: ', err);

      options = await {
        ...options,
        ...{
          arrayId: []
        }
      };
      return options;
    } 
  }


  const uploadFileChunks = async (file, options, i) => {
    let options_;

    if (await isFileAnImage(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          chunkSize: 1000
        }
      };
    } else
    if (await isFileAnAudio(file)) {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        },
        ...{
          chunkSize: 10000
        }
      };
    } else {
      options_ = await {
        ...options,
        ...{
          fileId: options.arrayId[i]
        }
      };
    }
    await sendFileChunks(file, options_.startingByte, options_.chunkSize, options_, i);

  }


  const sendFileChunks = async (file, startingByte, endingByte_, options_, i) => {
    const fileSize = await file.size;
    let endingByte = await endingByte_;

    const formData = await new FormData();
    const xhr = await new XMLHttpRequest();

    if (fileSize < endingByte) {
      endingByte = await fileSize;
    }
 

    if (endingByte < fileSize) {
      xhr.onreadystatechange = async () => {
        if (await (xhr.readyState === XMLHttpRequest.DONE)) {
          const percentageUploaded = await (endingByte / fileSize) * 100;  

          await setMaxMinute('Upload in progress');
          await setProgressPercentage(parseInt(percentageUploaded, 10)); 

          await sendFileChunks(file, startingByte + options_.chunkSize, startingByte + (options_.chunkSize * 2), options_, i);
        }
      }
    } else { 
      await setProgressPercentage(0);

      await setMessage('');

      await setPreview({
        preview, 
        ...{
          imageURL: '', 
          audioURL: '', 
          videoURL: ''
        }
      });

      await setFiles([]);
      await setMaxMinute(''); 
    }


    const chunk = await file.slice(startingByte, endingByte);

    await formData.append('chunk', chunk, file.name);
    await formData.append('startingByte', startingByte);
    await formData.append('endingByte', endingByte);
    await formData.append('fileId', options_.fileId);
    await formData.append('X-fileCategory', fileCategory);

    await xhr.open('POST', options_.url, true);
    await xhr.setRequestHeader('X-File-Id', options_.fileId);
    await xhr.setRequestHeader('X-File-Size', fileSize);
    await xhr.setRequestHeader('X-startingByte', startingByte);
    await xhr.setRequestHeader('X-endingByte', endingByte);
    await xhr.setRequestHeader('X-fileCategory', fileCategory);

    await xhr.send(formData);
  }
 

  const sendFile = (numberOfFiles_, fileCategory_, EventCardsType_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        numberOfFiles: numberOfFiles_, 
        fileCategory: fileCategory_,
        EventCardsType: EventCardsType_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }
   
 

  const sendFileData = (fileData, message_, EventCardsType_, excited_, imageTemplate_, token_, tk_, type_, fileCategory_, url_) => {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        fileData_: fileData,
        Message: message_,
        Excited: excited_,
        ImagePhoto: imageTemplate_,
        EventCardsType: EventCardsType_,
        token: token_,
        tk: tk_,
        type: type_, 
        fileCategory: fileCategory_
      }
    };
    const url = url_;
    const response = axios(url, config);

    return response;
  }



  const uploadExecution = async (files_, i) => {
    let newFile;

    if (await isFileAnImage(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.jpg');
    } else 
    if (await isFileAnAudio(files_)) {
      newFile = await renameFile(files_, 'planetryvilage.wav');
    } else {
      newFile = await renameFile(files_, 'planetryvilage.webm');
    }

    if (!newFile) {
      await console.log("You must first select the file to upload it");
    } else {
      try {
        await uploadFileChunks(newFile, options, i);
      } catch (err) {
        console.error('failed to read file: ', err);
      }
    }
  }


  function renameFile (originalFile_, newName_) {
    return new File([originalFile_], newName_, {
      type: originalFile_.type,
      lastModified: originalFile_.lastModified,
    });
  }
  /*  End upload */





  const resetAllFile = () => {
  	setFileTypeCheck({
  		fileTypeCheck, 
  		...{
  			imageFile:false, 
  			audioFile:false, 
  			videoFile:false
  		}
  	});

  	setPreview({
  		preview, 
  		...{
  			imageURL: '', 
  			audioURL: '', 
  			videoURL: ''
  		}
  	});

  	setFiles([]);
  	setMaxMinute('Max 2 minutes');
  }


 

  // check if a file is an image or an audio or a video
  const fileChecker = async (file) => {
  	if (await isFileAnImage(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:true, 
  				audioFile:false, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: URL.createObjectURL(file), 
  				audioURL: '', 
  				videoURL: ''
  			}
  		});
  	} else 
  	if(await isFileAnAudio(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:true, 
  				videoFile:false
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: URL.createObjectURL(file), 
  				videoURL: ''
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else
  	if(await isFileAVideo(file)) {
  		setFileTypeCheck({
  			fileTypeCheck, 
  			...{
  				imageFile:false, 
  				audioFile:false, 
  				videoFile:true
  			}
  		});

  		setPreview({
  			preview, 
  			...{
  				imageURL: '', 
  				audioURL: '', 
  				videoURL: URL.createObjectURL(file)
  			}
  		});

  		await fileDuration_(file, resetAllFile);
  	}else{
  		// the file is nor an image nor an audio nor a video.  Reset everything
  		resetAllFile();
  	}
  }
   
  const handleFilesChange = (e) => {
   	if (e.target.files) {
      setFiles(e.target.files);   
      fileChecker(e.target.files[0]); 

      setMaxMinute('');
    }   
  }; 



  

  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
   
  const inputElement = useRef();   
  const triggerUploadClick = useRef(); 
  

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {     
        let message; let image;

        let  tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  
          const removeFirstElementFromString_ = await removeFirstElementFromString(t);   
          
          // trigger a specific notification
          /* if (await stringComparison([cloud148.a], firstElementOfString_)) { 
            const part = await removeFirstElementFromString_; 

            await getIntoSpecificArea(part);
          } */  


          // select create a birthday card
          if (await stringComparison(['create a birthday card', 'create birthday card',   'creates a birthday card', 'creates birthday card',   'created a birthday card', 'created birthday card'], t)) {
            await setSelectedCard('Birthday');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }


          // select create a wedding card
          if (await stringComparison(['create a wedding card', 'create wedding card',   'creates a wedding card', 'creates wedding card',   'created a wedding card', 'created wedding card'], t)) {
            await setSelectedCard('Wedding');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }


          // select create a valentine card 
          if (await stringComparison(['create a valentine card', 'create valentine card', 'create a valentines day card', "create a valentine's day card",   'creates a valentine card', 'creates valentine card', 'creates a valentines day card', "creates a valentine's day card",    'created a valentine card', 'created valentine card', 'created a valentines day card', "created a valentine's day card"], t)) {
            await setSelectedCard('Valentine');

            speech.text = await transMany__('The card has been selected') || 'The card has been selected';
            speechSynth.speak(speech); 
          }
           
           
          
          // description
          if (await stringComparison([cloud123.a], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            message = await tm;

            await setMessage(message);

            speech.text = await transMany__('The description has been updated') || 'The description has been updated';
            speechSynth.speak(speech); 
          } 
           
          
          // one
          if (await stringComparison([cloud124.a, cloud124.b, cloud124.c], t)) {
            const excitement = await "Are you left open-mouthed by this video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // two
          if (await stringComparison([cloud125.a, cloud125.b, cloud125.c], t)) { 
            const excitement = await "Are you excessively astonished by this video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }

          // three
          if (await stringComparison([cloud126.a, cloud126.b, cloud126.c], t)) {
            const excitement = await "Are you excited to watch my video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // four 
          if (await stringComparison([cloud127.a, cloud127.b, cloud127.c], t)) {
            const excitement = await "Are you perplexed by my video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // five
          if (await stringComparison([cloud128.a, cloud128.b], t)) {
            const excitement = await "Are you stupefied by my video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }

          // six
          if (await stringComparison([cloud129.a, cloud129.b], t)) {
            const excitement = await "Are you flabbergasted by this video?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // seven
          if (await stringComparison([cloud130.a, cloud130.b, cloud130.c], t)) {
            const excitement = await "Is this video extremely amazing?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }
          
          // eight
          if (await stringComparison([cloud131.a, cloud131.b], t)) {
            const excitement = await "Is this video extremely astonishing?";
            await setExcited(excitement);

            speech.text = await transMany__(`${excitement}, has been selected`) || `${excitement}, has been selected`;
            speechSynth.speak(speech); 
          }

          // select a template
          // birthday 
          if(await (category === 'Birthday')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(birthdayBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          } 


          // wedding
          if(await (category === 'Wedding')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(weddingBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          } 

          // valentine
          if(await (category === 'Valentine')) {
            if (await stringComparison([cloud197.a, cloud197.b, cloud197.c, cloud197.d, cloud197.e, cloud197.f, cloud197.g, cloud197.h], firstElementOfString_)) {
              image = await removeFirstElementFromString_;

              if (await stringComparison(['one', '1'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 1);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['two', '2', 'to'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 2);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['three', '3', 'tree'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 3);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['four', '4', 'for'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 4);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['five', '5'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 5);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['six', '6'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 6);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seven', '7'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 7);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eight', '8'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 8);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nine', '9'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 9);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['ten', '10'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 10);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eleven', '11'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 11);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twelve', '12'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 12);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirteen', '13'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 13);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fourteen', '14'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 14);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['fifteen', '15'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 15);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['sixteen', '16'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 16);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['seventeen', '17'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 17);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['eighteen', '18'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 18);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['nineteen', '19'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 19);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty', '20'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 20);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty one', 'twenty-one', '21'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 21);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty two', 'twenty-two', '22'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 22);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty three', 'twenty-three', '23'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 23);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty four', 'twenty-four', '24'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 24);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty five', 'twenty-five', '25'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 25);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty six', 'twenty-six', '26'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 26);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty seven', 'twenty-seven', '27'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 27);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty eight', 'twenty-eight', '28'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 28);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['twenty nine', 'twenty-nine', '29'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 29);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }

              if (await stringComparison(['thirty', '30'], image)) {  
                const back = await findBackgroundTemplate(valentineBackgroundDetails, 30);
                await setImageTemplate(back);

                speech.text = await transMany__("This has been selected") || "This has been selected";
                speechSynth.speak(speech); 
              }
            }  
          } 



          // select a file
          if (await stringComparison([cloud100.a, cloud100.b, cloud100.c, cloud100.d, cloud100.e, cloud100.f, cloud100.g, cloud100.h, cloud100.i, cloud100.j, cloud100.k], t)) {
            await inputElement.current.click();

            speech.text = await transMany__("The dialog box is open") || "The dialog box is open";
            speechSynth.speak(speech); 
          }
          

          // save the event card
          if (await stringComparison([cloud101.a, cloud101.b, cloud101.c, cloud101.d, cloud101.e, cloud101.f], t)) {
            await triggerUploadClick.current.click();

            speech.text = await transMany__("The save has been triggered") || "The save has been triggered";
            speechSynth.speak(speech); 
          }
   
        } 
      }
    }
    michaelExexution();  
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, category]);

 
  /* End Michael */  



 
 
  return (
    <>   
      <CategoryFeast handleSelectedCard={handleSelectedCard} backArrow={backArrow} notificationCard={notificationCard} /> 
      
      <Grid  item xs={12} sm={12} md={8} lg={6}>
        <Card sx={{borderRadius:{xs:0, sm:0, md:'10px'}}}>
          <Stack spacing={1} sx={{p:1, textAlign:'center', justifyContent:'center'}}> 
            {/* <Typography variant="string">
              Create a 
              {(category === 'Birthday'&& ' birthday') ||  
              (category === 'Wedding'&& ' wedding') || 
              (category === 'Valentine'&& " Valentine's Day")} card 
              {personNameImage.images && <Avatar sx={{m:'auto', p:0, bgcolor: grey[500] }} alt='' src={`${domainName}/EddyDreams/Home/images/${personNameImage.images}`} />} 
              <Typography style={{display:personNameImage.names?'block':'none'}}> 
                {`${'for'}`}  
              </Typography> 
              <Typography style={{display:personNameImage.names?'block':'none'}}> 
                {` ${personNameImage.names}`}    
              </Typography>  
            </Typography> */}

            <Typography variant="string" sx={{display:category === 'Birthday'?'block':'none'}}>
              Create a Birthday card
              {personNameImage.images && <Avatar sx={{m:'auto', p:0, bgcolor: grey[500] }} alt='' src={`${domainName}/EddyDreams/Home/images/${personNameImage.images}`} />} 
              <Typography style={{display:personNameImage.names?'block':'none'}}> 
                {`${'for'}`}  
              </Typography> 
              <Typography className="notranslate" style={{display:personNameImage.names?'block':'none'}}> 
                {` ${personNameImage.names}`}    
              </Typography> 

            </Typography>

            <Typography variant="string" sx={{display:category === 'Wedding'?'block':'none'}}>
              Create a Wedding card
              {personNameImage.images && <Avatar sx={{m:'auto', p:0, bgcolor: grey[500] }} alt='' src={`${domainName}/EddyDreams/Home/images/${personNameImage.images}`} />} 
              <Typography style={{display:personNameImage.names?'block':'none'}}> 
                {`${'for'}`}  
              </Typography> 
              <Typography className="notranslate" style={{display:personNameImage.names?'block':'none'}}> 
                {` ${personNameImage.names}`}    
              </Typography>  
            </Typography>

            <Typography variant="string" sx={{display:category === 'Valentine'?'block':'none'}}>
              Create a Valentines Day card
              {personNameImage.images && <Avatar sx={{m:'auto', p:0, bgcolor: grey[500] }} alt='' src={`${domainName}/EddyDreams/Home/images/${personNameImage.images}`} />} 
              <Typography style={{display:personNameImage.names?'block':'none'}}> 
                {`${'for'}`}  
              </Typography> 
              <Typography className="notranslate" style={{display:personNameImage.names?'block':'none'}}> 
                {` ${personNameImage.names}`}    
              </Typography> 
            </Typography> 

            <Divider/> 

            {notificationCard?
            (
              <>  
                {category === 'Birthday' && allCategoryAllNotificationsByDivisionValue.map((item, key) => 
                  item.DivisionName === 'Birthday' &&
                  
                  <Notifications key={key} category={category} hideNotificationCard={hideNotificationCard} name={item.NewFullName} date={item.DateOnline} image={item.Photo} tk={item.Token}/> 
                  
                )}

                {category === 'Wedding' && allCategoryAllNotificationsByDivisionValue.map((item, key) => 
                  item.DivisionName === 'Wedding' &&
                  <Notifications key={key} category={category} hideNotificationCard={hideNotificationCard} name={item.NewFullName} date={item.DateOnline} image={item.Photo} tk={item.Token}/> 
                )} 

                {category === 'Valentine' && allCategoryAllNotificationsValentineValue.map((item, key) =>  
                  <Notifications key={key}  category={category} hideNotificationCard={hideNotificationCard} name={item.NewFullName} date={'14th of February'} image={item.Photo} tk={item.Token}/> 
                )}  
              </>
            ) : 
            (   
              <>
                <IconButton aria-label="arrow back" className="back"  onClick={backArrow}>
                  <ArrowBackIcon/>
                </IconButton>

                <TextField name="Message" value={message} onChange={handleChangeMessage} label="Message" multiline minRows={3} />
 
                <ExciteRadioGroup handleExcited={handleExcited} excited={excited}/>
                
                {category === 'Birthday' && (
                  <BackgroundRadioGroup backgroundDetails={birthdayBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )}

                {category === 'Wedding' && (
                  <BackgroundRadioGroup backgroundDetails={weddingBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )}

                {category === 'Valentine' && (
                  <BackgroundRadioGroup backgroundDetails={valentineBackgroundDetails} handleImageTemplate={handleImageTemplate} imageTemplate={imageTemplate} />
                )} 

                <VideoSelection inputElement={inputElement} handleFilesChange={handleFilesChange} progressPercentage={progressPercentage} files={files} fileTypeCheck={fileTypeCheck} preview={preview} />
                
                <Typography variant='subtitle2' color='red'>{maxMinute}</Typography>

                <LoadingButton fullWidth size="large"  type="button"  title='Share' variant="contained" ref={triggerUploadClick} onClick={upload}> 
                  Create
                </LoadingButton> 
              </>
            )} {/* End hide or show notificationCard */} 
          </Stack> 
        </Card>
      </Grid>
    </>
  );
}
