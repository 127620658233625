
const ViewOneCompetitionVideoAudioActionTypes = { 
    View_One_Competition_Video_Audio_REQUEST: 'View_One_Competition_Video_Audio_REQUEST',
    View_One_Competition_Video_Audio_SUCCESS:'View_One_Competition_Video_Audio_SUCCESS',
    View_One_Competition_Video_Audio_FAILURE: 'View_One_Competition_Video_Audio_FAILURE', 
    View_One_Competition_Video_Audio_RESET: 'View_One_Competition_Video_Audio_RESET', 
}
 
export default ViewOneCompetitionVideoAudioActionTypes

  

 