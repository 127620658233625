 
import PropTypes from 'prop-types';
// @mui
import { Card, Grid, Typography, Stack, Divider, Avatar  } from '@mui/material';   
import { grey } from '@mui/material/colors';


import {domainName} from '../../../utils/domain';

// ----------------------------------------------------------------------



export const JuryDecision = ({item, features}) => { 
  const { 
  	PhotoJury,
    NewFirstName,
    NewLastName,  
    CityJury,
    CountryJury,
    ContinentJury, 
    Rated,   
    PhotoUser,
    NewFullName,  
    CityUser,
    CountryUser,
    ContinentUser,
  } = item;

  const { 
  	backColor, 
  	color
  } = features;

  return (  
    <Grid  item xs={12} sm={6} md={6} >  

      <Card sx={{m:1, }}>
        <Typography variant="subtitle" color="black" fontSize="14px">
          Jury Decision History
        </Typography> 
      </Card> 

      <Card sx={{
        boxShadow: 0,
        textAlign: 'left',
        color: {color},   
        backgroundColor: backColor, 
        minHeight:'300px',
        // borderRadius:'3px',
         
      }}>  

        <Stack spacing={1} sx={{ p: 2 }}> 
            <Stack direction="column" alignItems="center"> 
                <Avatar sx={{ bgcolor: grey[500], height:{xs:40, sm:40, md:50}, width:{xs:40, sm:40, md:50}, mr:1 }}  alt='' src={`${domainName}/EddyDreams/Home/images/${PhotoUser}`} />
                 
                <Typography variant="subtitle1" sx={{fontSize:14}}>
                    I am {NewFullName}{','} the competitor{','}
                </Typography>

                <Typography variant="subtitle1" sx={{fontSize:14}}>
                    from {CityUser}{','}  {CountryUser}{','} {ContinentUser}
                </Typography>  
            </Stack> 

            <Divider sx={{backgroundColor:'black', m:1, }} /> 

            <Stack direction="column" alignItems="center" > 
                <Avatar sx={{ bgcolor: grey[500], height:{xs:40, sm:40, md:50}, width:{xs:40, sm:40, md:50}, mr:1 }}  alt='' src={`${domainName}/EddyDreams/Home/images/${PhotoJury}`} />
                
                <Typography variant="subtitle1" sx={{fontSize:14, color:'blue'}}>
                    Rated {Rated}{'%'}
                </Typography> 

                <Typography variant="subtitle1" sx={{fontSize:14}}>
                    by the jury{','} {NewFirstName}{' '}{NewLastName}{','} 
                </Typography> 

                <Typography variant="subtitle1" sx={{fontSize:14}}>
                    from {CityJury}{','}  {CountryJury}{','} {ContinentJury}
                </Typography> 
            </Stack>
        </Stack>
      </Card>
    </Grid> 
  );
}


JuryDecision.propTypes = {    
  item: PropTypes.object, 
  features: PropTypes.object, 
}; 
