 
import { useNavigate,  } from 'react-router-dom'; 
import PropTypes from 'prop-types';
import {CardActions, Grid, Typography, IconButton} from '@mui/material'; 

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat'; 
  

// utils
import { fShortenNumber } from '../../../../utils/formatNumber';  
 

import {domainName} from '../../../../utils/domain'; 


// -------------------------------------------------------------------------
 

export function Page1({page1Features, BackgroundColor}) {
  const navigate = useNavigate();   
  
  const {
    height,
    magazineId,
    NumberLikes,
    NumberViews,
    NumberComments,
    TitleCoverPage,
    SecretCoverPage,
    SummarizeCoverPage,
    WhoCoverPage,
    Photo1CoverPage
  } = page1Features;  
   
  
  const backImage = `${domainName}${'/EddyDreams/otherFiles/images/'}${Photo1CoverPage}`; 

  return (    
      <>
        <Grid container spacing={1} sx={{height:{height}, backgroundColor:'white', color:BackgroundColor, backgroundImage:`url(${backImage})`, backgroundSize: 'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat'}} >
          <Grid item xs={12} sm={12} md={12}>  
            <CardActions disableSpacing className="notify" onClick={() => {navigate(`${'/magazine/'}${magazineId}`)}}>
              <IconButton aria-label="add to favorites" sx={{fontSize:{xs:13, md:15}, color:BackgroundColor }} >
                {fShortenNumber(NumberLikes)} <FavoriteBorderIcon sx={{fontSize:{xs:15, md:20}, color:BackgroundColor  }} />
              </IconButton>
              <IconButton aria-label="views" sx={{fontSize:{xs:13, md:15}, color:BackgroundColor}}>
                {fShortenNumber(NumberViews)} <VisibilityIcon  sx={{fontSize:{xs:15, md:20}}}/>
              </IconButton> 

              <IconButton aria-label="comment" sx={{fontSize:{xs:13, md:15}, color:BackgroundColor}} >
                {fShortenNumber(NumberComments)} <ChatIcon  sx={{fontSize:{xs:15, md:20}}}/>
              </IconButton>   
            </CardActions> 
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{display: 'inline-flex', flexDirection: 'row', justifyContent: {xs:'', md:'center'}, mt:'2vw' }}> 
            <Typography sx={{fontSize:{xs:13, md:15}}}>{TitleCoverPage}</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12}  sx={{display: 'inline-flex', flexDirection: 'row', justifyContent: 'flex-start', mt:'2vw'}}> 
            <Typography sx={{maxWidth:{xs:120, md:120}, fontSize:{xs:13, md:15}, pl:2}}> {SecretCoverPage}</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{display: 'inline-flex', flexDirection: 'row', mt:'8vw'}}> 
            <Typography sx={{fontSize:10, pl:{xs:10, md:15}, pr:0, font:{xs:`30px 'Lovers Quarrel'`, md:`40px 'Lovers Quarrel'`}}}>{SummarizeCoverPage}</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} sx={{display: 'inline-flex', flexDirection: 'row', justifyContent: 'flex-start', mt:'3vw'}}> 
            <Typography sx={{pl:2, fontSize:20, fontFamily:'arial', maxWidth:'20vw', transform:'rotate(-20deg)', color:BackgroundColor}}> {WhoCoverPage} </Typography>
          </Grid>
        </Grid>
      </>   
  );
}


Page1.propTypes = { 
  page1Features: PropTypes.object, 
  BackgroundColor:  PropTypes.string, 
}; 
 