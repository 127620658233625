
import axios from 'axios'; 

import { put, takeLatest } from 'redux-saga/effects'; 
import RegisterActionTypes from '../register/registerTypes'; 

import {domainName} from '../../utils/domain';



const endpoint = {
    api : `${domainName}/u/register`
}



const sendFileData = (user) => {
    const config = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: { 
            full_name: user.full_name,
            continent: user.continent,
            country: user.country,
            city: user.city,
            user_type: user.user_type,
            email: user.email,
            password: user.password,
        }
    };
    const url = endpoint.api;
    const response = axios(url, config);

    return response;
}



export const userSent = async (user) => { 
    const resp = await sendFileData(user);

    try {
        const response = await resp.data;  

        return response;
    } catch (err) { 
        throw new Error('Failed to read file');
    }
}





function* sagaRegister({ payload }) {
    try {
        const response = yield (userSent(payload));
        yield put({ type: RegisterActionTypes.Register_SUCCESS, response });
    } catch (error) {
        yield put({ type: RegisterActionTypes.Register_FAILURE, error: error.message });
    }
}

export function* sagaRegisterScreen() {
    yield takeLatest(RegisterActionTypes.Register_REQUEST, sagaRegister);
}
