import RegisterActionTypes from './registerTypes'


export const RegisterREQUEST = (item) => ({
    type: RegisterActionTypes.Register_REQUEST,
    payload: item
})
 

export const RegisterRESET = item => ({
    type: RegisterActionTypes.Register_RESET,
    payload: item
}) 