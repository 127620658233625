
 
import { useRef, useEffect, } from 'react'; 
import { Helmet } from 'react-helmet-async';  
import { useCookies  } from 'react-cookie'; 
import { useNavigate,  } from 'react-router-dom'; 
import { useDispatch, useSelector  } from 'react-redux';  
import { useSpeechRecognition } from "react-speech-recognition"; 

// mui
import { Container, Grid, InputAdornment, IconButton, Autocomplete, Popper, TextField, } from '@mui/material';  

import { styled } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';

import BubblePreloader from 'react-bubble-preloader';


// components  
import Iconify from '../../components/iconify';


// sections 
import {  
  VideoOnly, HomeWidget,
} from '../../sections/@users/homeUser';


// hooks
// import useResponsive from '../../hooks/useResponsive';
  
 

import {ViewOneVideoPhotoRESET,} from '../../redux/viewOneVideoPhoto/viewOneVideoPhotoActions'; 
import {ReportAbuseRESET,} from '../../redux/reportAbuse/reportAbuseActions'; 
  
 

// util
import { removeCommaQuestionMarkFullStopLowerCase, 
       transMany, firstElementOfString, removeFirstElementFromString, stringComparison, cloud83, stringInclude, replaceWordTrack,
       playFullScreen, cloud144, cloud110, replaceWordImage,
     } from '../../utils/translateFunctions';

// import {  cloud57, cloud58, cloud59, cloud60, cloud61} from '../../utils/automateAllCommonFunctions';
 

// ----------------------------------------------------------------------

const StyledPopper = styled((props) => <Popper placement="bottom-start" {...props} />)({
  width: '280px !important',
});

export default function VideoLoadPage() {  
  const navigate = useNavigate();  
  const dispatch = useDispatch();

  // const [arrayLength, setArrayLength] = useState(8)


  const inputValue = useRef('');  
   
 
  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i-=1) {
      const j = Math.floor(Math.random() * (i + 1)); 
      [array[i], array[j]] = [array[j], array[i]]; 
    } 
    return array; 
  }; 

  
  /*
  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue); 
 
  let viewVideoPhotoValue = useMemo(() => [],  []); 
  let isloading = useMemo(() => true,  []); 
  

  const i = useRef(0);
 
  if(allDataOnceReducerValue.user && i.current === 0){   
    isloading = allDataOnceReducerValue.loading; 
    viewVideoPhotoValue = allDataOnceReducerValue.user.ViewVideoAudioPhoto; 
    viewVideoPhotoValue = shuffle(viewVideoPhotoValue).slice(0, 8) 
    
    i.current +=1
  } 
  */


  const allDataOnceReducerValue = useSelector(state => state.allDataOnceReducerValue); 
 
  const viewVideoPhotoValue =  useRef([]);
  const isloading = useRef(true);
  

  const i = useRef(0);

  if(allDataOnceReducerValue.user && i.current === 0){   
    isloading.current = allDataOnceReducerValue.loading; 
    viewVideoPhotoValue.current = allDataOnceReducerValue.user.ViewVideoAudioPhoto; 
    viewVideoPhotoValue.current = shuffle(viewVideoPhotoValue.current).slice(0, 8);  
    
    i.current +=1
  }  


  

 

  const handleLength = async () => { 
    // window.location.reload();  
    navigate('/videos', { replace: true }); 
  }
    

  const [cookies, ] = useCookies(['_token_planetryvilage']);  

   useEffect(() => {
    if(cookies._token_planetryvilage !== undefined) {  
      dispatch(ViewOneVideoPhotoRESET(null));
      dispatch(ReportAbuseRESET(null)); 
    } else {
      navigate('/login', { replace: true });  
    } 
  }, [cookies._token_planetryvilage, dispatch, navigate]);


  
  /* const [searchCategory, setSearchCategory] = useState('');
    

  const filteredCategory = useMemo(() => viewVideoPhotoValue.filter(
    (file) =>  
       (
        (`${file.NewFullName}${' '}${file.categoryDivision}${' '}${file.DateCreated}${' '}${file.uservideoaudiophotoId}${' '}${file.fileTypeDivision}`)  
        .toLowerCase()
        .includes(searchCategory.toLowerCase())    
      )  
  ) , [viewVideoPhotoValue, searchCategory]); */ 

  
  
  /* const handleSearchName = () => { 
    setSearchCategory(inputValue.current.value); 
    inputValue.current.value = ''; 
  }  */
  
  // const filteredCategory = useMemo(() => viewVideoPhotoValue, [viewVideoPhotoValue]);
  const filteredCategory = viewVideoPhotoValue.current;

  const handleSearchName = async () => {  
    let txtValue;

    const showItemElement = await document.getElementsByClassName('showItem');
    const input = await inputValue.current;

    const filter = await input.value.toUpperCase();


    for (let i = 0; i < showItemElement.length; i+=1) {
      const a1 = showItemElement[i];
      txtValue = a1.textContent || a1.innerText;

      const filterValue = txtValue.toUpperCase().indexOf(filter);
      if ((filterValue > -1)) {
        showItemElement[i].style.display = "";
      } else {
        showItemElement[i].style.display = "none";
      }
    }
  }

  const handleSearchNameSpecific = async (song) => {  
    let txtValue;
    inputValue.current.value= await song;

    const showItemElement = await document.getElementsByClassName('showItem'); 

    const filter = await song.toUpperCase();


    for (let i = 0; i < showItemElement.length; i+=1) {
      const a1 = showItemElement[i];
      txtValue = a1.textContent || a1.innerText;

      const filterValue = txtValue.toUpperCase().indexOf(filter);
      if ((filterValue > -1)) {
        showItemElement[i].style.display = "";
      } else {
        showItemElement[i].style.display = "none";
      }
    }
  }

 
  

  /* Beginning Michael */   
  const {
    // transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    // listening,
    // isMicrophoneAvailable
  } = useSpeechRecognition(); 
       

  useEffect(() => { 
    async function michaelExexution(){
      if (await (finalTranscript !== '')) {     
        let searchSomething;  

        let tm = await removeCommaQuestionMarkFullStopLowerCase(finalTranscript);    
        const t_ = await transMany(removeCommaQuestionMarkFullStopLowerCase(finalTranscript));    
        
        resetTranscript(); 
        
        const firstElementOfString__ = await firstElementOfString(t_); 
        const removeFirstElementFromString__ = await removeFirstElementFromString(t_);  


        if (await stringComparison([cloud83.a, cloud83.b, cloud83.c, cloud83.d, cloud83.e, cloud83.f, cloud83.g, cloud83.h, cloud83.i, cloud83.j, cloud83.k, cloud83.l, cloud83.m, cloud83.n, cloud83.o, cloud83.p, cloud83.q, cloud83.r, cloud83.s, cloud83.t, cloud83.u, cloud83.v, cloud83.w, cloud83.a2, cloud83.a3, cloud83.a4, cloud83.a5, cloud83.a6, cloud83.a7, cloud83.a8, cloud83.a9, cloud83.a10, cloud83.a11], firstElementOfString__)) {
          let t = await removeFirstElementFromString__;      
          const firstElementOfString_ = await firstElementOfString(t);  
          // const removeFirstElementFromString_ = await removeFirstElementFromString(t); 

           
          
          // search a specific song 
          if (await stringComparison([cloud144.a, cloud144.b, cloud144.c, cloud144.d, cloud144.e, cloud144.f, cloud144.g, cloud144.h, cloud144.i, cloud144.j, cloud144.k, cloud144.l], firstElementOfString_)) {
            t = await firstElementOfString_;
            tm = await removeFirstElementFromString(removeFirstElementFromString(tm));
            searchSomething = await tm; 
            // await setSearchCategory(searchSomething);
            await handleSearchNameSpecific(searchSomething);
          }

          /* the shared audios */
          /* if (await stringComparison([cloud29a.a, cloud29a.b, cloud29a.c, cloud29a.d, cloud29a.e, cloud29a.f, cloud29a.g, cloud29a.h, cloud29a.i, cloud29a.j, cloud29a.k, cloud29a.l, cloud29a.m, cloud29a.n, cloud29a.o, cloud29a.p], t)) {
            inputValue.current.value = await '';  
            await setSearchCategory(inputValue.current.value); 

            const a = await {videoBool: false, audioBool: true, photoBool : false}
            await setSearchField({
              searchField,
              ...a
            });
          } */

          /* the shared videos */
          /* if (await stringComparison([cloud27a.a, cloud27a.b, cloud27a.c, cloud27a.d, cloud27a.e, cloud27a.f, cloud27a.g, cloud27a.h, cloud27a.i, cloud27a.j, cloud27a.k, cloud27a.l, cloud27a.m, cloud27a.n, cloud27a.o, cloud27a.p], t)) {
            inputValue.current.value = await '';  
            await setSearchCategory(inputValue.current.value); 
            
            const a = await {videoBool: true, audioBool: false, photoBool : false}
            await setSearchField({
              searchField,
              ...a
            });
          } */
          
          /* the shared photos */
          /* if (await stringComparison([cloud31.a, cloud31.b, cloud31.c, cloud31.d, cloud31.e, cloud31.f, cloud31.g, cloud31.h, cloud31.i, cloud31.j, cloud31.k, cloud31.l, cloud31.m, cloud31.n, cloud31.o, cloud31.p, cloud31.q, cloud31.r, cloud31.s, cloud31.t, cloud31.u, cloud31.v, cloud31.w, cloud31.a2, cloud31.a3, cloud31.a4, cloud31.a5, cloud31.a6, cloud31.a7, cloud31.a8, cloud31.a9, cloud31.a10, cloud31.a11, cloud31.a12, cloud31.a13, cloud31.a14], t)) {
            inputValue.current.value = await '';  
            await setSearchCategory(inputValue.current.value); 
            
            const a = await {videoBool: false, audioBool: false, photoBool : true}
            await setSearchField({
              searchField,
              ...a
            });
          } */
           
 

          
          // beginning play video or audio using michael
          if (await stringInclude(t, "plays")) {
            t = await replaceWordTrack(t, 'plays');
          } else
          if (await stringInclude(t, "played")) {
            t = await replaceWordTrack(t, 'played');
          } else
          if (await stringInclude(t, 'is playing')) {
            t = await replaceWordTrack(t, 'is playing');
          }

          if (await stringInclude(t, "song")) {
            t = await replaceWordTrack(t, 'song');
          } else
          if (await stringInclude(t, "audio")) {
            t = await replaceWordTrack(t, 'audio');
          } else
          if (await stringInclude(t, "video")) {
            t = await replaceWordTrack(t, 'video');
          } else
          if (await stringInclude(t, "music")) {
            t = await replaceWordTrack(t, 'music');
          } else
          if (await stringInclude(t, "number")) {
            t = await replaceWordTrack(t, 'number');
          } else
          if (await stringInclude(t, "segment")) {
            t = await replaceWordTrack(t, 'segment');
          } else
          if (await stringInclude(t, "park")) {
            t = await replaceWordTrack(t, 'park');
          } else
          if (await stringInclude(t, "top")) {
            t = await replaceWordTrack(t, 'top');
          }


          if (await stringInclude(t, "the")) {
            t = await replaceWordTrack(t, 'the');
          }

          if (await stringInclude(t, " of ")) {
            t = await replaceWordTrack(t, ' of ');
          }

          if (await stringInclude(t, "of")) {
            t = await replaceWordTrack(t, 'of');
          }


          if (await stringInclude(t, "eh")) {
            t = await replaceWordTrack(t, 'eh');
          } else
          if (await stringInclude(t, "huh")) {
            t = await replaceWordTrack(t, 'huh');
          }
          
          /* if(await searchField.videoBool) {  
            for (let i = 0; i < filteredCategory.length; i+=1) {  
              if (stringComparison([`${"play track "}${i+1}`], t)) {    
                const a = document.getElementsByClassName(`${"play track "}${i}`)[0];
                if (typeof(a) !== 'undefined' && a !== null) {
                  playFullScreen(a);
                }
              }
            }
          }  */

          for (let i = 0; i < viewVideoPhotoValue.current.length; i+=1) {  
            if (stringComparison([`${"play track "}${i+1}`], t)) {    
              const a = document.getElementsByClassName(`${"play track "}${i}`)[0];
              if (typeof(a) !== 'undefined' && a !== null) {
                playFullScreen(a);
              }
            }
          }

          
          /* if(await searchField.audioBool) {  
            for (let i = 0; i < filteredFilesAudio.length; i+=1) {  
              if (stringComparison([`${"play track "}${i+1}`], t)) {    
                const a = document.getElementsByClassName(`${"play track "}${i}`)[0];
                if (typeof(a) !== 'undefined' && a !== null) {
                  playFullScreen(a);
                }
              }
            }
          } */
 

          const Y = ['play track four', 'play track for'];
          for (let i = 0; i < Y.length; i+=1) {
            if (stringComparison([Y[i]], t)) { 
              const a = document.getElementsByClassName(`${"play track "}${i}`)[0]
              if (typeof(a) !== 'undefined' && a !== null) {
                playFullScreen(a);
              }
            }
          } 
          // end play video or audio using michael   


          // beginning show picture using michael
          if (await stringInclude(t, "displayed")) {
            t = await replaceWordImage(t, 'displayed');
          } else
          if (await stringInclude(t, "displays")) {
            t = await replaceWordImage(t, 'displays');
          } else
          if (await stringInclude(t, 'is displaying')) {
            t = await replaceWordImage(t, 'is displaying');
          } else
          if (await stringInclude(t, "display")) {
            t = await replaceWordImage(t, 'display');
          }


          if (await stringInclude(t, "showed")) {
            t = await replaceWordImage(t, 'showed');
          }
          if (await stringInclude(t, "shows")) {
            t = await replaceWordImage(t, 'shows');
          }
          if (await stringInclude(t, 'is showing')) {
            t = await replaceWordImage(t, 'is showing');
          } else


          if (await stringInclude(t, "exhibited")) {
            t = await replaceWordImage(t, 'exhibited');
          } else
          if (await stringInclude(t, "exhibits")) {
            t = await replaceWordImage(t, 'exhibits');
          } else
          if (await stringInclude(t, 'is exhibiting')) {
            t = await replaceWordImage(t, 'is exhibiting');
          } else
          if (await stringInclude(t, "exhibit")) {
            t = await replaceWordImage(t, 'exhibit');
          } else


          if (await stringInclude(t, "presented")) {
            t = await replaceWordImage(t, 'presented');
          } else
          if (await stringInclude(t, "presents")) {
            t = await replaceWordImage(t, 'presents');
          } else
          if (await stringInclude(t, 'is presenting')) {
            t = await replaceWordImage(t, 'is presenting');
          } else
          if (await stringInclude(t, "present")) {
            t = await replaceWordImage(t, 'present');
          } else


          if (await stringInclude(t, "exposed")) {
            t = await replaceWordImage(t, 'exposed');
          } else
          if (await stringInclude(t, "exposes")) {
            t = await replaceWordImage(t, 'exposes');
          } else
          if (await stringInclude(t, 'is exposing')) {
            t = await replaceWordImage(t, 'is exposing');
          } else
          if (await stringInclude(t, "expose")) {
            t = await replaceWordImage(t, 'expose');
          } else


          if (await stringInclude(t, "unveiled")) {
            t = await replaceWordImage(t, 'unveiled');
          } else
          if (await stringInclude(t, "unveils")) {
            t = await replaceWordImage(t, 'unveils');
          } else
          if (await stringInclude(t, 'is unveiling')) {
            t = await replaceWordImage(t, 'is unveiling');
          } else
          if (await stringInclude(t, "unveil")) {
            t = await replaceWordImage(t, 'unveil');
          } else


          if (await stringInclude(t, "watched")) {
            t = await replaceWordImage(t, 'watched');
          } else
          if (await stringInclude(t, "watches")) {
            t = await replaceWordImage(t, 'watches');
          } else
          if (await stringInclude(t, 'is watching')) {
            t = await replaceWordImage(t, 'is watching');
          } else
          if (await stringInclude(t, "watch")) {
            t = await replaceWordImage(t, 'watch');
          } else


          if (await stringInclude(t, "poster")) {
            t = await replaceWordImage(t, 'poster');
          } else
          if (await stringInclude(t, "posted")) {
            t = await replaceWordImage(t, 'posted');
          } else
          if (await stringInclude(t, "posts")) {
            t = await replaceWordImage(t, 'posts');
          } else
          if (await stringInclude(t, 'is posting')) {
            t = await replaceWordImage(t, 'is posting');
          } else
          if (await stringInclude(t, "post")) {
            t = await replaceWordImage(t, 'post');
          }


          if (await stringInclude(t, "image")) {
            t = await replaceWordImage(t, 'image');
          } else
          if (await stringInclude(t, "photograph")) {
            t = await replaceWordImage(t, 'photograph');
          } else
          if (await stringInclude(t, "likeness")) {
            t = await replaceWordImage(t, 'likeness');
          } else
          if (await stringInclude(t, "portrait")) {
            t = await replaceWordImage(t, 'portrait');
          } else
          if (await stringInclude(t, "pictures")) {
            t = await replaceWordImage(t, 'pictures');
          } else
          if (await stringInclude(t, "photos")) {
            t = await replaceWordImage(t, 'photos');
          } else
          if (await stringInclude(t, "photographs")) {
            t = await replaceWordImage(t, 'photographs');
          } else
          if (await stringInclude(t, "number")) {
            t = await replaceWordImage(t, 'number');
          } else
          if (await stringInclude(t, "segment")) {
            t = await replaceWordImage(t, 'segment');
          } else
          if (await stringInclude(t, "photo")) {
            t = await replaceWordImage(t, 'photo');
          } else
          if (await stringInclude(t, "picture")) {
            t = await replaceWordImage(t, 'picture');
          } else
          if (await stringInclude(t, 'pic')) {
            t = await replaceWordImage(t, 'pic');
          }

          /* if(await searchField.photoBool) {  
            for (let i = 0; i < filteredFilesPhoto.length; i+=1) {  
              if (stringComparison([`${"show picture "}${i+1}`], t)) {    
                const a = document.getElementsByClassName(`${"show picture "}${i}`)[0];
                if (typeof(a) !== 'undefined' && a !== null) {
                  a.click();
                }
              }
            }
          }  */


          if (await stringComparison([cloud110.a], t)) { 
            const a = document.getElementsByClassName(`${"show picture "}${0}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.b, cloud110.c, cloud110.b2], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${1}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.d, cloud110.e, cloud110.f], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${2}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.g, cloud110.h], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${3}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.i, cloud110.j], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${4}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.k, cloud110.l], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${5}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.m], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${6}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          }

          if (await stringComparison([cloud110.n], t)) {
            const a = document.getElementsByClassName(`${"show picture "}${8}`)[0];
            if (typeof(a) !== 'undefined' && a !== null) {
              a.click();
            }
          } 
  
          // end show picture using michael

        } 
      }
    }
    michaelExexution(); 
  }, [interimTranscript, finalTranscript, resetTranscript, navigate, viewVideoPhotoValue.current.length]);
   
 
  /* End Michael */  

   
  return (
    <>
      <Helmet>
        <title> PlanetryVilage | Videos </title>
      </Helmet>
      
      
      <Container maxWidth="xl"  sx={{px:0, }} > 
        
          <Grid container spacing={1} sx={{pb:1, }} >  

            <Grid  item xs={12} sm={12} md={12} lg={12}> 
              <Grid container spacing={1}  alignItems="center" justifyContent="center">
                <Grid  item xs={12} sm={6} md={6} lg={12} > 
                  {/* <CategoryFiles handleFilterCategory={handleFilterCategory} /> */}
                  <Grid container spacing={1}  alignItems="center" justifyContent="center">
                   <Grid  item xs={12} sm={12} md={6}  >  
                    <Autocomplete
                    autoHighlight  
                    popupIcon={null}
                    PopperComponent={StyledPopper}
                    options={viewVideoPhotoValue.current}     
                    getOptionLabel={(post) => `${post.NewFullName}${' '}${post.categoryDivision}${' '}${post.DateCreated}${' '}${post.uservideoaudiophotoId}${' '}${post.fileTypeDivision}`} 
                    isOptionEqualToValue={(option, value) => option.id === value.id}   
                    renderInput={(params) => (
                      <TextField  variant="standard"
                        inputRef={inputValue} 

                        onKeyDown={(event) => {
                          if (event.key === 'Enter'){
                            handleSearchName()
                          } 
                        }}

                        onChange={handleSearchName}

                        {...params}
                        placeholder="Search..."
                        InputProps={{ 
                          style: { color: 'white' },
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton color="primary" onClick={handleSearchName}>
                                <Iconify icon={'eva:search-fill'} sx={{ ml: 1, width: 20, height: 20, color: 'text.disabled' }} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                   />
                  </Grid>
                 </Grid>
                </Grid> 

                
                
                { filteredCategory.map((item, key) => (
                  <Grid key={key}  item xs={12} sm={12} md={3} >
                    <VideoOnly key={key} keyProp={key} cardItem = {item}/>
                  </Grid>
                  )) 
                }
   
                 
              </Grid> 
            </Grid>

            
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}  alignItems="center" justifyContent="center"> 
                 
                {isloading.current && 
                  <BubblePreloader
                  bubble={{ width: '2rem', height: '2rem' }}
                  animation={{ speed: 2 }}
                  style={{margin:'auto', padding:0, textAlign:'center', position: 'relative'}}
                  colors={['blue', 'aqua', '#f3f3f3']}
                 />  
                } 
 
                 
                {!isloading.current && 
                <LoadingButton sx={{marginTop:5}}  size="small" title='button load more videos' variant="contained"  onClick={()=>{handleLength()}} >
                  Load more videos
                </LoadingButton> 
                }
              </Grid> 
            </Grid>
            
          </Grid> 

          <HomeWidget  />  
  
            
            
      </Container>  
    </>
  );
}



// onClick={()=>{handleCreate()}}